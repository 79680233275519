import { ColumnFormat } from 'common/types/viewColumn';
import _ from 'lodash';
import React, { Component, ReactElement, MouseEvent } from 'react';
// @ts-expect-error
import BooleanCell from './BooleanCell';
import DateCell from './DateCell';
import ErrorCell from './ErrorCell';
// @ts-expect-error
import GeospatialCell from './GeospatialCell';
// @ts-expect-error
import LocationCell from './LocationCell';
// @ts-expect-error
import renderNumber from './NumberCell';
// @ts-expect-error
import PointCell from './PointCell';
// @ts-expect-error
import renderText from './TextCell';
// @ts-expect-error
import URLCell from './URLCell';
import { SoQLType } from 'common/types/soql';
import JsonCell from './JsonCell';

export const isOkCell = (c: SoQLCell): c is SoQLCellOk => _.has(c, 'ok');
export const isErrorCell = (c: SoQLCell): c is SoQLCellError => _.has(c, 'error');

export function getErrorMessage(cell: SoQLCell): string {
  if (isErrorCell(cell)) {
    if (_.isString(cell.error.message)) {
      return cell.error.message;
    }
    if (_.isString(cell.error.message.english)) {
      return cell.error.message.english;
    }
  }
  return '';
}

const formatMapping = {
  'selected': 'format-bg-selected',
  'success': 'format-bg-success'
};

export interface CellOk { value: any } // TODO: soql-cell-generics
interface CellErrorMessage {
  english: string;
}
interface CellError {
  message: string | CellErrorMessage;
}
export interface SoQLCellOk {
  ok: CellOk;
}
export interface SoQLCellError {
  error: CellError;
}
export type SoQLCell = SoQLCellOk | SoQLCellError;

// End common types


interface Props {
  isDropping: boolean;
  isHidden: boolean;
  isEditing: boolean;
  failed: boolean;
  cell: SoQLCell;
  type: SoQLType;
  format: ColumnFormat;
  updateCell: () => void;
  editCell: () => void;
  width: number | string;
  canEdit: boolean;
  onMouseOver?:(event: MouseEvent<HTMLTableDataCellElement, globalThis.MouseEvent>) => void;
  onMouseOut?:() => void;
}

interface State {
  isSaving: boolean;
}

class TableCell extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      isSaving: false
    };
  }

  public shouldComponentUpdate(nextProps: Props): boolean {
    const interacting = (
      this.props.isEditing ||
      nextProps.isEditing ||
      this.props.width !== nextProps.width
    );

    const changed = (this.props.cell !== nextProps.cell ||
      !_.isEqual(this.props.format, nextProps.format) ||
      this.props.isDropping !== nextProps.isDropping ||
      this.props.isHidden !== nextProps.isHidden);

    return (
      interacting || changed
    );
  }

  private classesForCell(cell: SoQLCell | undefined, failed: boolean, isDropping: boolean, isHidden: boolean, canEdit: boolean, format: ColumnFormat): string {
    const c = ['table-cell', 'base'];
    // TODO: clean this up maybe, with
    // classNames lib
    // https://github.com/socrata/platform-ui/pull/11390#discussion_r428242393
    if (isDropping) { c.push('dropping'); }
    if (isHidden) { c.push('hidden'); }
    if (failed) { c.push('transform-failed'); }
    if (!cell) { c.push('not-yet-loaded'); }
    if (cell && isErrorCell(cell)) { c.push('error'); }
    if (cell && isOkCell(cell) && cell.ok === null) { c.push('empty'); }
    if (!canEdit) { c.push('not-editable'); }
    if (format.background) { c.push(formatMapping[format.background]); }

    return c.join(' ');
  }


  public render(): ReactElement {
    const { cell, type, failed, isDropping, isHidden, isEditing, updateCell, width, canEdit, onMouseOut, onMouseOver } = this.props;

    const format = this.props.format || {}; // hack for undefined formats ;_;

    let inner = null;
    if (cell) {
      const errorValue = _.get(cell, 'error.message.data.value');
      if (isErrorCell(cell) && !isEditing) {
        inner = (
          <ErrorCell error={getErrorMessage(cell)} isDropping={isDropping} />
        );
      } else {
        inner = (
          <Cell
            value={isErrorCell(cell) ? errorValue : cell.ok}
            isError={isErrorCell(cell)}
            type={type}
            format={format}
            isHidden={isHidden}
            isDropping={isDropping}
            isEditing={canEdit && isEditing}
            updateCell={canEdit ? updateCell : _.noop} />
        );
      }
    }

    return (
      <td
        style={{ width, 'minWidth': width }}
        onDoubleClick={this.props.editCell}
        className={this.classesForCell(cell, failed, isDropping, isHidden, canEdit, format)}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseOut}>
        {inner}
      </td>
    );
  }
}

interface RenderProps {
  value: any; // grrr
  isError: boolean;
  type: SoQLType;
  format: ColumnFormat;
  isHidden: boolean;
  isDropping: boolean;
  isEditing: boolean;
  updateCell: () => void;
}

function Cell(props: RenderProps): ReactElement | null {
  const { type } = props;
  switch (type) {
    case 'location':
      return <LocationCell {...props} />;
    case 'text':
      return renderText(props);
    case 'number':
      // This one is a little funky because there are several different
      // classes that render numbers differently, like a CurrencyCell, PercentCell,
      // etc, and I didn't want to mix a switch based on formats into this switch
      // which is based on actual data types, so the switch based on formats happens
      // in renderNumber, and it returns the correct renderer based on the formatting
      // rules for that column
      return renderNumber(props);
    case 'calendar_date':
    case 'date':
    case 'floating_timestamp':
    case 'fixed_timestamp':
      return <DateCell {...props} />;
    case 'url':
      return <URLCell {...props} />;
    case 'checkbox':
    case 'boolean':
      return <BooleanCell {...props} />;
    case 'point':
      return <PointCell {...props} />;
    case 'multipoint':
    case 'line':
    case 'multiline':
    case 'polygon':
    case 'multipolygon':
      return <GeospatialCell {...props} />;
    case 'json':
      return <JsonCell {...props} />;
    default:
      return null;
  }
}

export default TableCell;
