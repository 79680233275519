import './CalendarDateParameter.scss';
// Vendor Imports
import bindAll from 'lodash/bindAll';
import isEqual from 'lodash/isEqual';
import each from 'lodash/each';
import get from 'lodash/get';
import moment from 'moment';
import React, { Component } from 'react';
import classNames from 'classnames';

// Project Imports
import I18n from 'common/i18n';
import DatePicker, { DatePickerProps } from 'common/components/DatePicker';
import Dropdown from 'common/components/Dropdown';
import FilterFooter, { FilterFooterProps } from '../FilterFooter';
import Radiobutton from 'common/components/Radiobutton';
import RadiobuttonGroup from 'common/components/RadiobuttonGroup';
import type { DateClientContextVariable } from 'common/types/clientContextVariable';

// Constants
import {
  RELATIVE_FILTERS,
  RELATIVE_FILTER_VALUES
} from 'common/dates';
import { assertIsNotNil } from 'common/assertions';
import { Key } from 'common/types/keyboard/key';

const DATE_FORMAT = 'YYYY-MM-DDT00:00:00';
// TODO: Add tests
const scope = 'shared.components.filter_bar.calendar_date_filter';
export const PARAMETER_TYPES = {
  DAY: 'day',
  RELATIVE_DAY: 'relative_day'
};
interface CalendarDateParameterProps {
  parameter: DateClientContextVariable;
  onUpdate: (value: string) => void;
}

interface CalendarDateParameterState {
  date: string;
  relativeDate: {
    period: string,
    value: number,
    type: string
  },
  calendarDateParameterType: string;
}

class CalendarDateParameter extends Component<CalendarDateParameterProps, CalendarDateParameterState> {
  private dateParameter = React.createRef<HTMLDivElement>();
  inputs: NodeListOf<HTMLInputElement>;

  constructor(props: CalendarDateParameterProps) {
    super(props);

    bindAll(this, [
      'applyParameterOverride',
      'getInitialState',
      'onClickReset',
      'calculateDate',
      'renderSingleSelectDayPicker',
      'onChangeDate',
      'shouldDisableApply'
    ]);

    this.state = this.getInitialState();
  }

  getInitialState() {
    const { parameter } = this.props;

    let state;

    if (this.isRelativeValue(parameter)) {
      state = {
        date: moment().startOf('day').format(DATE_FORMAT),
        relativeDate: {
          ...get(RELATIVE_FILTER_VALUES, RELATIVE_FILTERS[parameter.overrideValue?.toUpperCase() || parameter.defaultValue.toUpperCase()]),
          type: RELATIVE_FILTERS[parameter.overrideValue?.toUpperCase() || parameter.defaultValue.toUpperCase()]
        },
        calendarDateParameterType: PARAMETER_TYPES.RELATIVE_DAY
      };
    } else {
      state = {
        date: parameter.overrideValue ?? parameter.defaultValue,
        relativeDate: {
          ...get(RELATIVE_FILTER_VALUES, RELATIVE_FILTERS.TODAY)},
          type: RELATIVE_FILTERS.TODAY,
        calendarDateParameterType: PARAMETER_TYPES.DAY
      };
    }

    return state;
  }

  componentDidMount() {
    if (this.dateParameter.current) {
      this.inputs = this.dateParameter.current.querySelectorAll('.date-picker-input');
      each(this.inputs, (input) => input.addEventListener('keyup', this.onEnterUpdateParameter));
    }
  }

  componentWillUnmount() {
    if (this.inputs) {
      each(this.inputs, (input) => input.removeEventListener('keyup', this.onEnterUpdateParameter));
    }
  }

  isRelativeValue(parameter: DateClientContextVariable) {
    if (
      parameter.overrideValue === RELATIVE_FILTERS.TODAY ||
      parameter.overrideValue === RELATIVE_FILTERS.YESTERDAY ||
      parameter.defaultValue === RELATIVE_FILTERS.TODAY ||
      parameter.defaultValue === RELATIVE_FILTERS.YESTERDAY
    ) {
      return true;
    }

    return false;
  }

  onEnterUpdateParameter(event: KeyboardEvent) {
    if (event.key === Key.Enter && !this.shouldDisableApply()) {
      event.stopPropagation();
      event.preventDefault();
      this.applyParameterOverride();
    }
  }

  applyParameterOverride() {
    const { onUpdate } = this.props;
    const { date, relativeDate, calendarDateParameterType } = this.state;

    onUpdate(calendarDateParameterType === PARAMETER_TYPES.DAY ? date : relativeDate.type);
  }

  shouldDisableApply() {
    // Compare the parameter date value returned by initial state to the current state
    // If they are the same disable apply button
    return isEqual(this.getInitialState(), this.state);
  }

  calculateDate(value?: string): moment.Moment {
    if (value === RELATIVE_FILTERS.TODAY) {
      return moment().startOf('day');
    } else if (value === RELATIVE_FILTERS.YESTERDAY) {
      return moment().subtract(1, 'days').startOf('day');
    } else {
      return moment(value);
    }
  }

  onChangeDate(date?: string) {
    assertIsNotNil(date);
    const alteredDate = this.calculateDate(date).format(DATE_FORMAT);

    this.setState({
      ...this.state,
      date: alteredDate,
    });
  }

  onChangeRelativeDate(relativeDate: string) {
    this.setState({
      ...this.state,
      relativeDate: {
        ...get(RELATIVE_FILTER_VALUES, relativeDate),
        type: relativeDate
      },
    });
  }

  renderSingleSelectDayPicker() {
    const startDate = this.calculateDate(this.state.date).format(DATE_FORMAT);

    const datePickerProps: DatePickerProps = {
      date: startDate,
      onChangeDate: this.onChangeDate,
      showTodayButton: true,
      showYesterdayButton: true
    };

    return (
      <div className="single-select-day-picker-parameter">
        <DatePicker {...datePickerProps} />
      </div>
    );
  }

  renderRelativeDateOptions() {
    const { relativeDate } = this.state;

    const options = [
      { title: I18n.t('relative_periods.today', { scope }), value: RELATIVE_FILTERS.TODAY },
      { title: I18n.t('relative_periods.yesterday', { scope }), value: RELATIVE_FILTERS.YESTERDAY }
    ];

    const dropdownProps = {
      disabled: false,
      onSelection: ({ value }: any) => this.onChangeRelativeDate(value),
      options,
      value: relativeDate.type,
      size: 'small'
    };

    return (
      <div className="relative-period">
        <Dropdown {...dropdownProps} />
      </div>
    );
  }

  onChangeSelectedRadionOption(parameterType: string) {
    this.setState({
      ...this.state,
      calendarDateParameterType: parameterType
    });
  }

  onClickReset() {
    const initState = this.getInitialState();

    this.setState({
      date: initState.date,
      relativeDate: initState.relativeDate,
      calendarDateParameterType: initState.calendarDateParameterType
    });
  }

  render() {
    const { calendarDateParameterType } = this.state;
    // TODO: re assess if we want a default value for singleSelect.by
    const footerProps: FilterFooterProps = {
      disableApplyFilter: this.shouldDisableApply(),
      onClickApply: this.applyParameterOverride,
      // Parameters will always be read only as they cannot be removed from the report
      isReadOnly: true
    };

    return (
      <div className={classNames('filter-controls', 'calendar-date-filter')} ref={this.dateParameter}>
        <div className="range-filter-container">
          <RadiobuttonGroup>
            <Radiobutton
              id="single-day-picker"
              checked={calendarDateParameterType === PARAMETER_TYPES.DAY}
              onChange={() => this.onChangeSelectedRadionOption(PARAMETER_TYPES.DAY)}
              label="Date">
                {this.renderSingleSelectDayPicker()}
              </Radiobutton>
            <Radiobutton
              id="relative-day-picker"
              checked={calendarDateParameterType === PARAMETER_TYPES.RELATIVE_DAY}
              onChange={() => this.onChangeSelectedRadionOption(PARAMETER_TYPES.RELATIVE_DAY)}
              label="Relative Date">
                {this.renderRelativeDateOptions()}
            </Radiobutton>
          </RadiobuttonGroup>
        </div>
        <FilterFooter {...footerProps} />
      </div>
    );
  }
}

export default CalendarDateParameter;
