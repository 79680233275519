/* eslint react/no-did-mount-set-state: 0 */
import ErrorMessage, { ValidationErrors } from 'common/components/ScheduleModal/components/ErrorMessage';
import { ZONES } from 'common/components/ScheduleModal/constants';
import * as helpers from 'common/components/ScheduleModal/helpers';
import { ChangeSchedule, ShowError } from 'common/components/ScheduleModal/types';
import Select from 'common/components/Select';
import { Schedule } from 'common/types/schedule';
import mtz from 'moment-timezone';
import moment from 'moment';
import _ from 'lodash';
import React, { Component } from 'react';
import Cron, { Spec } from '../cron';

interface ChangeScheduleProps {
  schedule: Schedule;
  onChange: ChangeSchedule;
  showError: ShowError;
}

interface CadenceMinutesProps {
  days: number;
  onChange: (s: number) => void;
  showError: ShowError;
}
function CadenceMinutesField({ days, showError, onChange }: CadenceMinutesProps) {
  const updateCadence = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(parseInt(e.target.value));

    helpers.validateInterval(e.target.value).forEach((error) => {
      showError({ name: 'cadence.interval_minutes', reason: error });
    });
  };

  let value = '';
  if (!_.isNaN(days)) {
    value = `${days}`;
  }

  return (
    <div className="scheduling-form-control control-frequency">
      <label htmlFor="interval">{helpers.t('interval')}</label>
      <div>
        <span id="every">{helpers.t('every')}</span>
        <input
          type="text"
          className="scheduling-form-textinput"
          name="interval"
          id="interval"
          value={value}
          onChange={updateCadence}
        />
        <span id="days">{`${helpers.t('days')}`}</span>
        <span id="min-max">{`${helpers.t('min')}: 1, ${helpers.t('max')}: 31`}</span>
      </div>
    </div>
  );
}
interface StartTimeProps {
  startTime: string;
  onChange: (s: string) => void;
}

export const generateStartTimeCandidates = () => {
  return _.range(0, 24, 0.5)
    .map((n) => n * 60)
    .map((rawMinutes) => mtz().hour(helpers.getHours(rawMinutes)).minute(helpers.getMinutes(rawMinutes)));
};

function StartTimeField({ startTime, onChange }: StartTimeProps) {
  const updateStartTime = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value);
  };

  const timeField = {
    name: 'cadence.start',
    label: 'start',
    isRequired: false,
    value: startTime,
    options: generateStartTimeCandidates().map((time) => {
      return {
        value: time.format('HH:mm'),
        title: time.format('HH:mm')
      };
    })
  };

  return (
    <div>
      <label htmlFor={timeField.name}>{helpers.t('time')}</label>
      <Select field={timeField} handleChange={updateStartTime} />
    </div>
  );
}

interface TimezoneProps {
  timezone: string;
  onChange: (s: string) => void;
}
function TimezoneField({ timezone, onChange }: TimezoneProps) {
  const updateTimezone = (e: React.ChangeEvent<HTMLSelectElement>) => {
    return onChange(e.target.value);
  };

  const timezoneField = {
    name: 'timezone',
    label: 'timezone',
    isRequired: false,
    value: timezone,
    options: _.map(ZONES, (zone) => {
      return {
        value: zone,
        title: `${zone} ${mtz().tz(zone).format('Z')}`,
        selected: timezone === zone
      };
    })
  };
  return (
    <div>
      <label htmlFor={timezoneField.name}>{helpers.t('timezone')}</label>
      <Select field={timezoneField} handleChange={updateTimezone} />
    </div>
  );
}

// lalala more wrongness
function getAbsolute(spec: Spec): number {
  if (spec.type === 'absolute') return spec.value;
  return 0;
}

interface CronProps {
  onChange: ChangeSchedule;
  schedule: Schedule;
  showError: ShowError;
  validation: ValidationErrors;
}

interface CronStateProps {
  edited: boolean;
  cron: Cron;
}

export default class CronBuilder extends Component<CronProps, CronStateProps> {
  state = {
    edited: false,
    cron: new Cron('0 0 * * *')
  };

  UNSAFE_componentWillMount() {
    // eslint-disable-line camelcase
    const { schedule } = this.props;
    if (schedule.cadence.cron) {
      this.setState({ cron: new Cron(schedule.cadence.cron) });
    }
  }

  newCronFromDateTime = (cron: Cron, datetime: moment.Moment) => {
    const newMins = { type: 'absolute', value: datetime.minutes() } as Spec;
    const newHours = { type: 'absolute', value: datetime.hours() } as Spec;
    return cron.setMinute(newMins).setHour(newHours);
  };

  onChangeStartTime = (newStartTime: string) => {
    const { schedule, onChange } = this.props;
    const now = mtz().tz(schedule.cadence.timezone!);
    const startTimeAsSubmitted = `${now.format('YYYY-MM-DD')}T${newStartTime}:00`;

    if (schedule.cadence.cron) {
      const { cron } = this.state;
      const timeToRun = moment(startTimeAsSubmitted);
      const newCron = this.newCronFromDateTime(cron, timeToRun).stringify();

      return onChange({
        ...schedule,
        cadence: {
          ...schedule.cadence,
          cron: newCron
        }
      });
    } else {
      const iso = mtz
        .tz(startTimeAsSubmitted, schedule.cadence.timezone!)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ssZ');
      return onChange({
        ...schedule,
        cadence: {
          ...schedule.cadence,
          start: iso
        }
      });
    }
  };

  onChangeTimezone = (timezone: string) => {
    const { schedule, onChange } = this.props;

    if (schedule.cadence.interval_minutes && schedule.cadence.start) {
      // take the start time (which is in utc) & change it to the old timezone
      const oldZoneStart = mtz(schedule.cadence.start).utc().tz(schedule.cadence.timezone!);

      // create new dt with new timezone and that hh mm, then reconvert to utc
      const newUTCstart = mtz()
        .tz(timezone)
        .set({
          hours: oldZoneStart.hours(),
          minutes: oldZoneStart.minutes()
        })
        .utc();

      return onChange({
        ...schedule,
        cadence: {
          ...schedule.cadence,
          start: newUTCstart.format('YYYY-MM-DDTHH:mm:ssZ'),
          timezone
        }
      });
    } else {
      return onChange({
        ...schedule,
        cadence: {
          ...schedule.cadence,
          timezone
        }
      });
    }
  };

  onChangeDays = (days: number) => {
    const { schedule, onChange } = this.props;
    const { cron } = this.state;

    // `!!days` is `true` if `days` is a number, `false` if it is `NaN`
    this.setState({ edited: !!days });

    if (schedule.cadence.cron) {
      let newCron;
      if (days === 1) {
        newCron = cron.setDay({ type: 'unrestricted' }).stringify();
      } else {
        // this doesn't work for many cases
        newCron = cron.setDay({ type: 'range', step: days, min: 1, max: 31 }).stringify();
      }

      return onChange({
        ...schedule,
        cadence: {
          ...schedule.cadence,
          cron: newCron,
          interval_minutes: helpers.daysToMinutes(days)
        }
      });
    } else {
      return onChange({
        ...schedule,
        cadence: {
          ...schedule.cadence,
          interval_minutes: helpers.daysToMinutes(days)
        }
      });
    }
  };

  getDays = () => {
    const { schedule } = this.props;
    const { cron, edited } = this.state;

    if (schedule.cadence.cron && cron.isValid()) {
      const cronDays = cron.getDay();
      if (cronDays.type === 'range') {
        return cronDays.step;
      } else if (cronDays.type === 'unrestricted' && (schedule.id || edited)) {
        return 1;
      } else {
        return NaN;
      }
    } else {
      // gahhhhhhhhhhhhhh this is so bad
      return helpers.minutesToDays(schedule.cadence.interval_minutes);
    }
  };

  getStartTime = () => {
    const { schedule } = this.props;
    const { cron } = this.state;
    if (schedule.cadence.cron) {
      return mtz()
        .utc()
        .set({
          hours: getAbsolute(cron.getHour()),
          minutes: getAbsolute(cron.getMinute())
        })
        .format('HH:mm');
    } else {
      return mtz(schedule.cadence.start!).utc().tz(schedule.cadence.timezone!).format('HH:mm');
    }
  };

  render() {
    try {
      const { schedule, showError, validation } = this.props;
      const startTime = this.getStartTime();
      const days = this.getDays();
      return (
        <div>
          <CadenceMinutesField onChange={this.onChangeDays} showError={showError} days={days} />
          <ErrorMessage validation={validation} name="cadence.interval_minutes" />

          <div className="scheduling-form-control scheduling-form-time">
            <StartTimeField startTime={startTime} onChange={this.onChangeStartTime} />
            <TimezoneField timezone={schedule.cadence.timezone || ''} onChange={this.onChangeTimezone} />
          </div>
          <ErrorMessage validation={validation} name="start" />
        </div>
      );
    } catch (e) {
      // react obfuscates any errors thrown
      console.error(e);
    }
    return null;
  }
}
