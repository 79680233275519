// Vendor Imports
import { bindAll, cloneDeep, each, get, head, includes, isEqual, isNil, isUndefined, map, range, set } from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import {
  ForgeDatePicker,
  ForgeDateRangePicker,
  ForgeRadio,
  ForgeSelect,
  ForgeTextField
} from '@tylertech/forge-react';

// Project Imports
import Picklist, { PicklistOption, PicklistSizes } from 'common/components/Picklist';
import FilterFooter from '../FilterFooter';
import { getCalendarDateRangeFilter, getDefaultFilterForColumn, isTodayOrYesterday } from '../filters';
import { addPopupListener } from './InputFocus';
import { Key } from 'common/types/keyboard/key';
import I18n from 'common/i18n';

// Constants
import {
  FILTER_TYPES,
  RELATIVE_FILTERS,
  RELATIVE_FILTER_VALUES,
  SINGLE_SELECT_BY,
  getFiscalYearStartMoment,
  getFiscalEndYear,
  getSelectByYearStartAndEndDates,
  getSelectByFiscalYearStartAndEndDates,
  RELATIVE_FILTER_TYPES
} from 'common/dates';
import { Filter, FilterEditorProps } from '../types';
import { RelativeDateFilter, TimeRangeFilter } from '../SoqlFilter';

const CUSTOM_PERIOD_INPUT_FIELD = { MIN: 1, STEP: 1 };
const DATE_FORMAT = 'YYYY-MM-DD';
const scope = 'shared.components.filter_bar.calendar_date_filter';

interface DateRange {
  /** Needs to be handled by moment */
  start: string;
  end?: string;
}

interface CalendarDateFilterState {
  value: DateRange;
  calendarDateFilterType: FILTER_TYPES;
  relativeDatePeriod: {
    period: string;
    value: string;
    type?: RELATIVE_FILTER_TYPES;
  };
  /** Should be one of SINGLE_SELECT_BY */
  singleSelectBy: string;
  filter?: Filter;
}

class CalendarDateFilter extends Component<FilterEditorProps, CalendarDateFilterState> {
  inputs: NodeListOf<HTMLInputElement> | null;
  dateFilter: HTMLDivElement;
  removePopupListener = () => {};
  popupListenerRemoved = false;

  constructor(props: FilterEditorProps) {
    super(props);

    bindAll(this, [
      'applyFilter',
      'getInitialState',
      'onChangeSingleSelectDay',
      'onChangeSingleSelectMonthMonth',
      'onChangeSingleSelectMonthYear',
      'onChangeSingleSelectYear',
      'onEnterUpdateFilter',
      'resetFilter',
      'setDate',
      'shouldDisableApply'
    ]);

    this.state = this.getInitialState();

    // If we have a filter period type of 'last_week' or 'last_month', we
    // want to coerce this to 'custom' as these filter types are now only
    // supported as custom ranges.
    const type = get(this.state, 'relativeDatePeriod.type');
    if (includes([RELATIVE_FILTERS.LAST_WEEK, RELATIVE_FILTERS.LAST_MONTH], type)) {
      set(this.state, 'relativeDatePeriod.type', RELATIVE_FILTERS.CUSTOM);
    }

    const singleSelectBy = get(this.props.filter, 'singleSelect.by');
    if (!isUndefined(singleSelectBy)) {
      set(this.state, 'calendarDateFilterType', FILTER_TYPES.RANGE);
    } else if (get(props.filter, 'arguments.type') === RELATIVE_FILTERS.DATE_TO_TODAY) {
      // When filter type is "date to today", we need to override calendarDateFilterType to relative
      // so that correct radio option is selected.
      set(this.state, 'calendarDateFilterType', FILTER_TYPES.RELATIVE);
    }

    // If we are in single select mode, modify the state to ensure
    // the end dates are set properly. When date filters are converted to queries,
    // a day is added to the end date, and the query is of the form ">= START and < END",
    // effectively making the query end-date-inclusive. For that reason, END should be
    // set to the last date we actually want included in the filter (which means subtracting
    // one day from the SINGLE_SELECT options).
    let { start, end } = this.state.value;
    if (singleSelectBy === SINGLE_SELECT_BY.YEAR) {
      const startMoment = this.getMoment(this.state.value.start);
      [start, end] = getSelectByYearStartAndEndDates(startMoment);
    } else if (singleSelectBy === SINGLE_SELECT_BY.FISCAL_YEAR) {
      const endMoment = this.getMoment(this.state.value.end);
      [start, end] = getSelectByFiscalYearStartAndEndDates(endMoment);
    } else if (singleSelectBy === SINGLE_SELECT_BY.MONTH) {
      const startMoment = this.getMoment(this.state.value.start).date(1);
      start = startMoment.format(DATE_FORMAT);
      end = startMoment.add(1, 'month').subtract(1, 'day').format(DATE_FORMAT);
    } else if (singleSelectBy === SINGLE_SELECT_BY.DAY) {
      const startMoment = this.getMoment(this.state.value.start);
      end = startMoment.format(DATE_FORMAT);

      // Set calendarDateFilterType by prop since this handle both
      // a single select day and relative "Today" filter
      const calendarDateFilterType = get(
        props,
        'filter.arguments.calendarDateFilterType',
        FILTER_TYPES.RANGE
      );

      // Combination of SINGLE_SELECT_BY.DAY and FILTER_TYPES.RELATIVE
      // means this is a relative "Today" filter.
      // In View mode only, treat it as if this is a normal SINGLE_SELECT_BY.DAY
      // filter but with current date selected.

      const { isReadOnly } = props;
      if (isReadOnly && calendarDateFilterType === FILTER_TYPES.RELATIVE) {
        // @ts-ignore
        start = this.getMoment(RELATIVE_FILTERS.TODAY);
        end = start;
      } else {
        set(this.state, 'calendarDateFilterType', calendarDateFilterType);
      }
    }
    set(this.state, 'value.start', start);
    set(this.state, 'value.end', end);
  }

  getDataProvider(props: FilterEditorProps) {
    return props.dataProvider[0];
  }

  getInitialState() {
    const { column, filter, relativeDateOptions } = this.props;
    const values = get(filter, 'arguments');

    const rangeMinStartOfDay = isNil(column.rangeMin)
      ? column.rangeMin
      : moment(column.rangeMin).startOf('day').format(DATE_FORMAT);

    const rangeMaxEndOfDay = isNil(column.rangeMax)
      ? column.rangeMax
      : moment(column.rangeMax).add(1, 'day').startOf('day').format(DATE_FORMAT);

    const firstRelativeDateOption = head(relativeDateOptions);
    const start = this.setDate(get(values, 'start', rangeMinStartOfDay));
    const end = this.setDate(get(values, 'end', rangeMaxEndOfDay));
    const firstRelativeDateOptionValue = get(firstRelativeDateOption, 'value', RELATIVE_FILTERS.TODAY);
    const defaultFilterOption = get(RELATIVE_FILTER_VALUES, firstRelativeDateOptionValue);

    return {
      calendarDateFilterType: get(values, 'calendarDateFilterType', FILTER_TYPES.RANGE),
      relativeDatePeriod: {
        period: get(values, 'period', defaultFilterOption.period),
        value: get(values, 'value', defaultFilterOption.value),
        type: get(values, 'type', defaultFilterOption.type)
      },
      singleSelectBy: get(filter, 'singleSelect.by'),
      value: { start, end }
    };
  }

  componentDidMount() {
    const { popupRef } = this.props;

    if (this.dateFilter) {
      this.inputs = this.dateFilter.querySelectorAll('.date-picker-input');
      each(this.inputs, (input) => input.addEventListener('keyup', this.onEnterUpdateFilter));

      this.removePopupListener = addPopupListener(popupRef, this.dateFilter, () => {
        this.popupListenerRemoved = true;
      });
    }
  }

  componentWillUnmount() {
    const { popupRef } = this.props;

    if (this.inputs) {
      each(this.inputs, (input) => input.removeEventListener('keyup', this.onEnterUpdateFilter));
    }

    if (!this.popupListenerRemoved) {
      popupRef?.current?.removeEventListener('forge-popup-position', this.removePopupListener);
    }
  }

  onChangeSingleSelectDay(date?: string) {
    if (!date) return;
    if (isTodayOrYesterday(date)) {
      this.setState({
        value: {
          start: date,
          end: this.setDate(this.props.column.rangeMax) // end must be set to something, doesn't matter what
        }
      });
    } else {
      this.setState({
        value: {
          start: moment(date).format(DATE_FORMAT),
          end: moment(date).format(DATE_FORMAT)
        }
      });
    }
  }

  onChangeSingleSelectMonthMonth(option: PicklistOption) {
    const startMoment = moment(this.state.value.start).month(option.value);
    const start = startMoment.format(DATE_FORMAT);
    const end = startMoment.add(1, 'month').subtract(1, 'day').format(DATE_FORMAT);

    this.setState({
      value: { start, end }
    });
  }

  onChangeSingleSelectMonthYear(option: PicklistOption) {
    const startMoment = moment(this.state.value.start).year(option.value);
    const start = startMoment.format(DATE_FORMAT);
    const end = startMoment.add(1, 'month').subtract(1, 'day').format(DATE_FORMAT);

    this.setState({
      value: { start, end }
    });
  }

  onChangeSingleSelectYear(option?: PicklistOption | null) {
    if (!option) return;
    let start;
    if (get(this.props.filter, 'singleSelect.by') === SINGLE_SELECT_BY.FISCAL_YEAR) {
      // The start of a fiscal year is actually the previous calendar year
      // e.g. 2018-09-01 is the start of the 2019 govt fiscal year
      start = getFiscalYearStartMoment()
        .year(option.value - 1)
        .format(DATE_FORMAT);
    } else {
      start = this.setDate(`${option.value}-01-01`);
    }
    const end = moment(start, DATE_FORMAT).add(1, 'year').subtract(1, 'day').format(DATE_FORMAT);

    this.setState(
      {
        value: { start, end }
      },
      this.applyFilter
    );
  }

  onChangeDateRangeToToday(detail: string) {
    const startMoment = this.getMoment(detail);
    const today = moment();

    if (startMoment === null || !startMoment.isBefore(today)) {
      return;
    }

    this.setState({
      value: {
        start: detail,
        end: 'today'
      }
    });
  }

  onChangeDateRange(detail: { from: string; to: string }) {
    const startMoment = this.getMoment(detail.from);
    const endMoment = this.getMoment(detail.to);

    if (startMoment === null || endMoment === null || !startMoment.isBefore(endMoment)) {
      return;
    }

    this.setState({
      value: {
        start: detail.from,
        end: detail.to
      }
    });
  }

  onEnterUpdateFilter(event: KeyboardEvent) {
    if (event.key === Key.Enter) {
      event.stopPropagation();
      event.preventDefault();

      if (!this.shouldDisableApply()) {
        this.applyFilter();
      }
    }
  }

  setCalenderDateFilterType = (type: FILTER_TYPES) => {
    this.setState({ calendarDateFilterType: type });
  };

  setDate(date?: string | number) {
    // Check for a valid value of 'today' or 'yesterday'
    if (isTodayOrYesterday(date)) {
      return date as RELATIVE_FILTER_TYPES;
    }

    // Checking if undefined because;
    //   moment(null).isValid() === false
    //   moment(undefined).isValid() === true
    return !isUndefined(date) && moment(date).isValid()
      ? // Getting default date without time to be able to compare
        // and find out if we should disable apply button.
        (date as string)
      : moment().format(DATE_FORMAT);
  }

  getMoment(value?: string) {
    if (value === RELATIVE_FILTERS.TODAY) {
      return moment().startOf('day');
    } else if (value === RELATIVE_FILTERS.YESTERDAY) {
      return moment().subtract(1, 'days').startOf('day');
    } else {
      return moment(value);
    }
  }

  shouldDisableApply() {
    // We need to compare the filter returned by getInitialState to disable the apply button on
    // first load. We manipulate the filter's values in getInitialState to be slightly different
    // than the filter passed into the prop when the filter is first added.
    return isEqual(this.getInitialState(), this.state);
  }

  resetFilter() {
    const { column, onUpdate } = this.props;
    const { rangeMin, rangeMax } = column;
    const defaultFilterOption = get(RELATIVE_FILTER_VALUES, RELATIVE_FILTERS.TODAY);

    // Not using updateValueState here because start & end dates might be same.
    // updateValueState checks if end is later then start.
    this.setState({
      value: {
        start: this.setDate(rangeMin),
        end: this.setDate(rangeMax)
      },
      relativeDatePeriod: {
        period: defaultFilterOption.period,
        // TODO: Check if value should actually be a string or a number
        // @ts-ignore
        value: defaultFilterOption.value as string,
        type: RELATIVE_FILTERS.TODAY
      },
      calendarDateFilterType: FILTER_TYPES.RANGE
    });

    const filter: Filter = cloneDeep(
      getDefaultFilterForColumn(column, this.props.dataProvider[0].datasetUid)
    );
    filter.isHidden = get(this.props, 'filter.isHidden', false);
    filter.isDrilldown = get(this.props, 'filter.isDrilldown', false);
    filter.singleSelect = get(this.props, 'filter.singleSelect');
    this.setState({ filter });
    onUpdate(filter);
  }

  applyFilter() {
    const { filter, onUpdate } = this.props;
    const { calendarDateFilterType, relativeDatePeriod, value } = this.state;

    if (calendarDateFilterType === FILTER_TYPES.RANGE) {
      onUpdate(
        getCalendarDateRangeFilter(
          filter as TimeRangeFilter | RelativeDateFilter,
          calendarDateFilterType,
          value
        )
      );
    } else {
      // "Date to today" filter is typed as Relative, but has start/end dates as if it were a range filter.
      if (relativeDatePeriod.type === RELATIVE_FILTERS.DATE_TO_TODAY) {
        // This call sets the right function type to construct SoQL query.
        // We are telling it to use RANGE filter without changing the state value,
        // since state value is used to determine which radio button is selected.
        onUpdate(
          getCalendarDateRangeFilter(filter as TimeRangeFilter | RelativeDateFilter, FILTER_TYPES.RANGE, {
            ...value,
            type: relativeDatePeriod.type
          })
        );
      } else {
        onUpdate(
          getCalendarDateRangeFilter(
            filter as TimeRangeFilter | RelativeDateFilter,
            calendarDateFilterType,
            relativeDatePeriod
          )
        );
      }
    }
  }

  onCustomDateInputChange = (value: string) => {
    const relativeDatePeriod = cloneDeep(this.state.relativeDatePeriod);
    set(relativeDatePeriod, 'value', value);
    this.setState({ relativeDatePeriod });
  };

  onRelativeDatePeriodChange = (option: PicklistOption) => {
    const datePeriod = get(RELATIVE_FILTER_VALUES, option.value);
    this.setState({
      relativeDatePeriod: {
        period: datePeriod.period,
        // TODO: Check if value should actually be a string or a number
        // @ts-ignore
        value: datePeriod.value as string,
        type: option.value
      }
    });
  };

  onCustomDatePeriodChange = (option: PicklistOption) => {
    const relativeDatePeriod = cloneDeep(this.state.relativeDatePeriod);
    set(relativeDatePeriod, 'period', option.value);
    this.setState({ relativeDatePeriod });
  };

  renderSingleSelectDayPicker() {
    const { column, isReadOnly } = this.props;
    const { calendarDateFilterType } = this.state;
    const dataProvider = this.getDataProvider(this.props);

    const startMoment = this.getMoment(this.state.value.start);
    const date = startMoment.format(DATE_FORMAT);
    // combination of dataset ID and column name should be unique in a global filter bar
    // this ID is used to associate input and label elements for accessibility
    const fieldId = `gfb-filter--${dataProvider?.datasetUid}--${column.fieldName}`;

    // common date picker element props
    const datePickerProps = {
      value: date,
      'on-forge-date-picker-change': (event: CustomEvent) => {
        this.onChangeSingleSelectDay(event.detail);
      },
      // show Today button only in View mode
      showToday: isReadOnly,
      valueMode: 'iso-string',
      // disable in edit mode only if the other radio button is selected
      disabled: !isReadOnly && calendarDateFilterType === FILTER_TYPES.RELATIVE
    };
    const datePickerElement = (
      <ForgeDatePicker {...datePickerProps}>
        <ForgeTextField>
          <input type="text" id={fieldId} />
          <label htmlFor={fieldId}>{this.props.column.name}</label>
        </ForgeTextField>
      </ForgeDatePicker>
    );

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setCalenderDateFilterType(event.target.value as FILTER_TYPES);
      // When "Relative" radio is selected, set the relative range to "Today"
      if (event.target.value === FILTER_TYPES.RELATIVE) {
        this.onRelativeDatePeriodChange({ value: RELATIVE_FILTERS.TODAY });
      }
    };

    // in view mode, only display the date picker.
    // in edit mode, display the date picker and an option for SoQL literal `today` value
    if (isReadOnly) {
      return <div className="range-filter-container">{datePickerElement}</div>;
    } else {
      return (
        <div className="range-filter-container">
          <div
            className="radio-button-group"
            role="radiogroup"
            aria-label={I18n.t('shared.components.filter_bar.config.filter_selection')}
          >
            <ForgeRadio>
              <input
                type="radio"
                id={`${fieldId}-date`}
                onChange={handleRadioChange}
                checked={calendarDateFilterType !== FILTER_TYPES.RELATIVE}
                name={`${fieldId}-selector`}
                value={FILTER_TYPES.RANGE}
              />
              <label htmlFor={`${fieldId}-date`}>{datePickerElement}</label>
            </ForgeRadio>
            <ForgeRadio>
              <input
                type="radio"
                id={`${fieldId}-today`}
                onChange={handleRadioChange}
                checked={calendarDateFilterType === FILTER_TYPES.RELATIVE}
                name={`${fieldId}-selector`}
                value={FILTER_TYPES.RELATIVE}
              />
              <label htmlFor={`${fieldId}-today`}>{I18n.t('relative_periods.today', { scope })}</label>
            </ForgeRadio>
          </div>
        </div>
      );
    }
  }

  renderSingleSelectMonthPicker() {
    const startMoment = moment(this.state.value.start);
    const selectedMonth = startMoment.month();
    const selectedYear = startMoment.year();

    // converts return object from getMonthOptions/getYearOptions { title, value } to { label, value }
    function convertOptions(options: { title: any; value: number }[]) {
      return options.map((option) => ({ label: option.title, value: option.value.toString() }));
    }

    const monthDropdownProps = {
      label: I18n.t('select_month', { scope }),
      value: selectedMonth.toString(),
      'on-change': (event: CustomEvent) => {
        this.onChangeSingleSelectMonthMonth({ value: Number.parseInt(event.detail) });
      },
      options: convertOptions(this.getMonthOptions()),
      className: 'single-select-month-dropdown-month',
      popupClasses: 'forge-select__popup'
    };

    const yearDropdownProps = {
      label: I18n.t('select_year', { scope }),
      value: selectedYear.toString(),
      'on-change': (event: CustomEvent) => {
        this.onChangeSingleSelectMonthYear({ value: Number.parseInt(event.detail) });
      },
      options: convertOptions(this.getYearOptions()),
      className: 'single-select-month-dropdown-year',
      popupClasses: 'forge-select__popup'
    };

    return (
      <div className="range-filter-container">
        <div className="single-select-month-picker">
          <div>
            <ForgeSelect {...monthDropdownProps} />
          </div>
          <div>
            <ForgeSelect {...yearDropdownProps} />
          </div>
        </div>
      </div>
    );
  }

  renderSingleSelectYearPicker() {
    const options = this.getYearOptions();
    let selectedYear;
    if (get(this.props.filter, 'singleSelect.by') === SINGLE_SELECT_BY.FISCAL_YEAR) {
      selectedYear = getFiscalEndYear(this.state.value.end);
    } else {
      selectedYear = moment(this.state.value.start).year();
    }

    const picklistProps = {
      onChange: this.onChangeSingleSelectYear,
      onSelection: this.onChangeSingleSelectYear,
      options,
      size: PicklistSizes.SMALL,
      enableForgeStyle: true,
      value: selectedYear
    };

    return (
      <div className="picklist-options-container">
        <Picklist {...picklistProps} />
      </div>
    );
  }

  getMonthOptions() {
    const months = range(0, 12);
    return map(months, (month) => ({
      title: I18n.t(`month_${month}`, { scope }),
      value: month
    }));
  }

  getYearOptions() {
    const { column } = this.props;
    const rangeMinMoment = moment(column.rangeMin, DATE_FORMAT);
    const rangeMaxMoment = moment(column.rangeMax, DATE_FORMAT);
    let rangeMinYear = rangeMinMoment.year();
    let rangeMaxYear = rangeMaxMoment.year();

    if (get(this.props.filter, 'singleSelect.by') === SINGLE_SELECT_BY.FISCAL_YEAR) {
      const fiscalYearMoment = getFiscalYearStartMoment();
      // If a data point occurs after the FY month, its actually in the next fiscal year, so add 1.
      // eg. Government fiscal year runs October to September, so 2018-11-15 is in govt FY 2019.
      rangeMinYear += rangeMinMoment.dayOfYear() >= fiscalYearMoment.dayOfYear() ? 1 : 0;
      rangeMaxYear += rangeMaxMoment.dayOfYear() >= fiscalYearMoment.dayOfYear() ? 1 : 0;
    }
    const years = range(rangeMinYear, rangeMaxYear + 1);

    return map(years, (year) => ({
      title: `${year}`,
      value: year
    }));
  }

  onKeyDownInputBox = (event: React.KeyboardEvent) => {
    if (event.key === '.') {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  renderDateRangePicker = () => {
    const { column, isReadOnly } = this.props;
    const { calendarDateFilterType } = this.state;
    const dataProvider = this.getDataProvider(this.props);

    // combination of dataset ID and column name should be unique in a global filter bar
    // this ID is used to associate input and label elements for accessibility
    const fieldId = `gfb-filter--${dataProvider?.datasetUid}--${column.fieldName}`;

    // Only provide the start and end dates initially when the component is first rendered.
    // Previously, continuous setStates were occurring with each key press while the user typed
    // in the input fields. This caused the inputs to rerender with new dates that were valid
    // but not what the user intended
    const previousStartValue = (document.getElementById(`${fieldId}-start`) as HTMLInputElement)?.value;
    const previousEndValue = (document.getElementById(`${fieldId}-end`) as HTMLInputElement)?.value;
    const startMoment = this.getMoment(this.state.value.start);
    const startDate = isUndefined(previousStartValue) ? startMoment.format(DATE_FORMAT) : undefined;
    const endMoment = this.getMoment(this.state.value.end);
    const endDate = isUndefined(previousEndValue) ? endMoment.format(DATE_FORMAT) : undefined;

    // common date picker element props
    const datePickerProps = {
      ...(startDate ? { from: startDate } : {}), // if startDate is undefined, the from key will not be added to the object
      ...(endDate ? { to: endDate } : {}),
      'on-forge-date-range-picker-change': (event: CustomEvent) => {
        this.onChangeDateRange(event.detail);
      },
      // show Today button only in View mode
      showToday: isReadOnly,
      valueMode: 'iso-string',
      disabled: calendarDateFilterType === FILTER_TYPES.RELATIVE
    };

    return (
      <ForgeDateRangePicker {...datePickerProps}>
        <ForgeTextField>
          <input type="text" id={`${fieldId}-start`} placeholder="mm/dd/yyyy" />
          <input type="text" id={`${fieldId}-end`} placeholder="mm/dd/yyyy" />
          <label htmlFor={fieldId}>{this.props.column.name}</label>
        </ForgeTextField>
      </ForgeDateRangePicker>
    );
  };

  renderRelativeTimePeriod = () => {
    const { popupRef, relativeDateOptions } = this.props;
    const { relativeDatePeriod, calendarDateFilterType } = this.state;

    if (!relativeDateOptions) {
      return null;
    }

    const dropdownProps = {
      label: I18n.t('relative_date_label', { scope }),
      value: relativeDatePeriod.type,
      'on-change': (event: CustomEvent) => {
        // trigger popup repositioning logic
        if (typeof popupRef?.current?.position === 'function') {
          popupRef.current.position();
        }
        this.onRelativeDatePeriodChange({ value: event.detail });
      },
      options: relativeDateOptions,
      popupClasses: 'forge-select__popup',
      disabled: calendarDateFilterType !== FILTER_TYPES.RELATIVE
    };

    return (
      <div>
        <div className="relative-date-container">
          <div className="relative-period">
            <ForgeSelect {...dropdownProps} />
          </div>
        </div>
      </div>
    );
  };

  renderCustomTimePeriod = () => {
    const { relativeDatePeriod, calendarDateFilterType } = this.state;
    if (get(relativeDatePeriod, 'type') !== RELATIVE_FILTERS.CUSTOM) {
      return null;
    }

    // This is used to determine pluralization of labels only. It's ok if it's NaN.
    const count = Number.parseInt(relativeDatePeriod.value, 10);

    const fyFiltersEnabled = get(window, 'socrata.fiscalYearConfig.fy_filters_enabled', false);
    const options = [
      { label: I18n.t('duration_periods.day', { scope, count }), value: 'day' },
      { label: I18n.t('duration_periods.month', { scope, count }), value: 'month' },
      { label: I18n.t('duration_periods.quarter', { scope, count }), value: 'quarter' }
    ];

    // append appropriate "Year" options based on whether FY Filters are enabled
    if (fyFiltersEnabled) {
      options.push(
        { label: I18n.t('duration_periods.calendar_year', { scope, count }), value: 'calendar_year' },
        { label: I18n.t('duration_periods.fiscal_year', { scope, count }), value: 'fiscal_year' }
      );
    } else {
      options.push({ label: I18n.t('duration_periods.year', { scope, count }), value: 'year' });
    }

    const dropdownProps = {
      'aria-label': I18n.t('time_unit', { scope }),
      disabled: calendarDateFilterType !== FILTER_TYPES.RELATIVE,
      'on-change': (event: CustomEvent) => {
        this.onCustomDatePeriodChange({ value: event.detail });
      },
      options,
      popupClasses: 'forge-select__popup',
      value: relativeDatePeriod.period
    };

    return (
      <>
        <div className="relative-last-value">
          <ForgeTextField>
            <input
              id="custom-period-value"
              min={CUSTOM_PERIOD_INPUT_FIELD.MIN}
              disabled={calendarDateFilterType !== FILTER_TYPES.RELATIVE}
              step={CUSTOM_PERIOD_INPUT_FIELD.STEP}
              onKeyDown={this.onKeyDownInputBox}
              onChange={(event) => this.onCustomDateInputChange(event.target.value)}
              type="number"
              value={relativeDatePeriod.value}
            />
          </ForgeTextField>
          {/* this label is placed outside ForgeTextField to be not displayed */}
          <label className="screenreader-only" htmlFor="custom-period-value">
            {I18n.t('last_field_value_label', { scope })}
          </label>
        </div>
        <div className="relative-last-unit">
          <ForgeSelect {...dropdownProps} />
        </div>
      </>
    );
  };

  renderDateToTodayDatePicker() {
    const { column, isReadOnly } = this.props;
    const { relativeDatePeriod, value } = this.state;
    const dataProvider = this.getDataProvider(this.props);

    if (get(relativeDatePeriod, 'type') !== RELATIVE_FILTERS.DATE_TO_TODAY) {
      return null;
    }

    const startMoment = this.getMoment(value.start);
    const date = startMoment.format(DATE_FORMAT);
    // combination of dataset ID and column name should be unique in a global filter bar
    // this ID is used to associate input and label elements for accessibility
    const fieldId = `gfb-filter--${dataProvider?.datasetUid}--${column.fieldName}`;

    // common date picker element props
    const datePickerProps = {
      value: date,
      'on-forge-date-picker-change': (event: CustomEvent) => {
        this.onChangeDateRangeToToday(event.detail);
      },
      // show Today button only in View mode
      showToday: isReadOnly,
      valueMode: 'iso-string'
    };

    return (
      <div className="range-filter-date-to-today">
        <div>
          <ForgeDatePicker {...datePickerProps}>
            <ForgeTextField>
              <input type="text" id={fieldId} />
              <label htmlFor={fieldId}>{I18n.t('start_date', { scope })}</label>
            </ForgeTextField>
          </ForgeDatePicker>
        </div>
        <div>&ndash;</div>
        <div>{I18n.t('relative_periods.today', { scope })}</div>
      </div>
    );
  }

  renderDateRangeAndRelativeTimePeriod = () => {
    const { calendarDateFilterType, relativeDatePeriod } = this.state;
    const { column, relativeDateOptions } = this.props;
    const hideCustomTimePeriod = get(relativeDatePeriod, 'type') !== RELATIVE_FILTERS.CUSTOM;
    const dataProvider = this.getDataProvider(this.props);

    const fieldId = `gfb-filter--${dataProvider?.datasetUid}--${column.fieldName}-filter`;

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setCalenderDateFilterType(event.target.value as FILTER_TYPES);
    };

    return (
      <div className="range-filter-container">
        <form className="filter-options">
          <div
            className="radio-button-group"
            role="radiogroup"
            aria-label={I18n.t('shared.components.filter_bar.config.filter_selection')}
          >
            <ForgeRadio>
              <input
                type="radio"
                id={`${fieldId}-range`}
                onChange={handleRadioChange}
                checked={calendarDateFilterType !== FILTER_TYPES.RELATIVE}
                name={`${fieldId}-selector`}
                value={FILTER_TYPES.RANGE}
                aria-label={I18n.t('date_range_label', { scope })}
              />
              <div slot="label">{this.renderDateRangePicker()}</div>
            </ForgeRadio>
            {!relativeDateOptions && hideCustomTimePeriod ? null : (
              <ForgeRadio>
                <input
                  type="radio"
                  id={`${fieldId}-relative`}
                  onChange={handleRadioChange}
                  checked={calendarDateFilterType === FILTER_TYPES.RELATIVE}
                  name={`${fieldId}-selector`}
                  value={FILTER_TYPES.RELATIVE}
                  aria-label={I18n.t('relative_date_label', { scope })}
                />
                <div slot="label" className="range-filter-relative">
                  {this.renderRelativeTimePeriod()}
                  {this.renderCustomTimePeriod()}
                </div>
              </ForgeRadio>
            )}
          </div>
          {this.renderDateToTodayDatePicker()}
        </form>
      </div>
    );
  };

  render() {
    const { filter, isReadOnly, onRemove } = this.props;
    const footerProps = {
      disableApplyFilter: this.shouldDisableApply(),
      isDrilldown: filter.isDrilldown,
      isReadOnly,
      onClickApply: this.applyFilter,
      onClickRemove: onRemove,
      onClickReset: this.resetFilter
    };

    const singleSelectBy = get(filter, 'singleSelect.by');
    let controls;

    switch (singleSelectBy) {
      case SINGLE_SELECT_BY.YEAR:
      case SINGLE_SELECT_BY.FISCAL_YEAR: // Fiscal year has the exact same picker style as year
        set(footerProps, 'showApplyButton', false);
        controls = this.renderSingleSelectYearPicker();
        break;
      case SINGLE_SELECT_BY.MONTH:
        controls = this.renderSingleSelectMonthPicker();
        break;
      case SINGLE_SELECT_BY.DAY:
        controls = this.renderSingleSelectDayPicker();
        break;
      default:
        controls = this.renderDateRangeAndRelativeTimePeriod();
    }

    const containerClasses = 'filter-controls calendar-date-filter';

    const fiscalYearLabel = (
      <span className="single-select-fiscal-year-label">
        {I18n.t('shared.components.filter_bar.config.fiscal_year_label')}
      </span>
    );

    return (
      <div className={containerClasses} ref={(el: HTMLDivElement) => (this.dateFilter = el)}>
        {singleSelectBy === SINGLE_SELECT_BY.FISCAL_YEAR && fiscalYearLabel}
        {controls}
        <FilterFooter {...footerProps} />
      </div>
    );
  }
}

export default CalendarDateFilter;
