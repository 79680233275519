// Vendor Imports
import _ from 'lodash';
import React, { Component, HTMLAttributes } from 'react';
import { ForgePopup, ForgeTooltip } from '@tylertech/forge-react';
import { IPopupComponent } from '@tylertech/forge';
import classNames from 'classnames';

// Project Imports
import SocrataIcon, { IconName } from '../SocrataIcon';
import FilterEditor from './FilterEditor';
import FilterConfig from './FilterConfig';
import { getFilterHumanText } from './filters';
import I18n from 'common/i18n';
import { isInsideFlannels, defaultRelativeDateOptions } from './helpers';

// Constants
import { ViewColumn } from 'common/types/viewColumn';
import { SoqlFilter } from './SoqlFilter';
import {
  FilterBarColumn,
  DataProvider,
  RelativeDatePeriod
} from 'common/components/SingleSourceFilterBar/types';
import { SINGLE_SELECT_BY, RELATIVE_FILTERS, shouldOverrideFiscalYear } from 'common/dates';
import { Key } from 'common/types/keyboard/key';

export interface FilterItemProps {
  allFilters: SoqlFilter[];
  column: FilterBarColumn;
  constraints: any;

  disabled?: boolean;
  filter: SoqlFilter;
  relativeDateOptions?: RelativeDatePeriod[];
  isReadOnly?: boolean;
  dataProvider: DataProvider[];
  onRemove: () => void;
  onUpdate: (filter: SoqlFilter) => void;
  onToggleControl: (value: boolean) => void;
  showNullsAsFalse?: boolean;
}

interface FilterItemState {
  isControlOpen: boolean;
  isConfigOpen: boolean;
  shouldRemoveActiveClass: boolean;
  previouslyActiveEditBlockElements: NodeListOf<Element> | undefined;
  isLeftAligned: boolean;
  columnStat: any;
  uniqueId: string;
}

export class FilterItem extends Component<FilterItemProps, FilterItemState> {
  static defaultProps = {
    onToggleControl: _.noop
  };

  private _isMounted: boolean;
  private filterControlToggle = React.createRef<HTMLDivElement>();
  private filterControlPopupRef = React.createRef<IPopupComponent & HTMLElement>();
  private filterConfig = React.createRef<FilterConfig>();
  private filterConfigToggle = React.createRef<HTMLDivElement>();
  private controlContainer = React.createRef<HTMLDivElement>();
  private configContainer = React.createRef<HTMLDivElement>();

  bodyClickHandler: (e: MouseEvent) => void;
  bodyEscapeHandler: (e: KeyboardEvent) => void;

  getDataProvider(props: FilterItemProps) {
    return props.dataProvider[0];
  }

  constructor(props: FilterItemProps) {
    super(props);

    this.state = {
      isControlOpen: false,
      isConfigOpen: false,
      shouldRemoveActiveClass: false,
      previouslyActiveEditBlockElements: undefined,
      isLeftAligned: false,
      columnStat: null,
      uniqueId: _.uniqueId('filter-item-')
    };

    _.bindAll(this, [
      'preventScrollWithSpaceBar',
      'onKeyUpConfig',
      'onKeyUpControl',
      'onRemove',
      'onUpdate',
      'renderFilterConfig',
      'renderFilterConfigToggle',
      'renderFilterControlToggle',
      'toggleConfig',
      'toggleControl'
    ]);
  }

  componentDidMount() {
    // To prevent a race condition updating state on column stats when fetching is completed after the component is unmounted
    this._isMounted = true;
    this.getColumnStat();
    this.bodyClickHandler = (event) => {
      // Avoid closing flannels if the click is inside any of these refs.
      const flannelElements = [this.filterConfig.current, this.filterConfigToggle.current] as (
        | HTMLDivElement
        | FilterConfig
        | null
      )[];

      const clickInsideFlannels = isInsideFlannels(event, flannelElements);

      // If none of the flannelElements contain event.target, close all the flannels.
      if (!clickInsideFlannels) {
        this.closeConfig();
      }
    };

    this.bodyEscapeHandler = (event) => {
      const { isConfigOpen, isControlOpen } = this.state;

      if (event.key === Key.Escape) {
        if (isConfigOpen && this.filterConfigToggle.current) {
          this.closeConfig();
          this.filterConfigToggle.current.focus();
        }
        if (isControlOpen && this.filterControlToggle.current) {
          this.closeControl();
          this.filterControlToggle.current.focus();
        }
      }
    };

    document.body.addEventListener('click', this.bodyClickHandler);
    document.body.addEventListener('keyup', this.bodyEscapeHandler);
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.body.removeEventListener('click', this.bodyClickHandler);
    document.body.removeEventListener('keyup', this.bodyEscapeHandler);
  }

  UNSAFE_componentWillReceiveProps = (nextProps: FilterItemProps) => {
    // eslint-disable-line camelcase
    if (!_.isEqual(nextProps.column, this.props.column)) {
      this.getColumnStat(nextProps.column);
    }
  };

  requiresColumnStat = () => {
    // If you look at the impls for TextFilter, NumberFilter, DateFilter,  etc you'll see that only
    // numberfilter and datefilter use the columnStat we pass in here. This is an optimization to
    // not do a super expensive aggregate call that blocks rendering, which is then ignored by
    // the actual component
    // also not sure why we're using renderTypeName to power all the control flow here but
    // i will just keep with that pattern
    // the unfortunate thing here is that (well, even before this optimization) this is essentially
    // a lower order component since we have to have knowledge of the child component's implementations
    // to use them. ugh.
    return _.includes(['money', 'number', 'calendar_date', 'date'], this.props.column.renderTypeName);
  };

  columnStatLoaded = (): boolean => {
    if (this.requiresColumnStat()) {
      return !!this.state.columnStat;
    }
    return true;
  };

  getColumnStat = (column?: FilterBarColumn): void => {
    if (!this.requiresColumnStat()) return;

    const dataProvider = this.getDataProvider(this.props);
    column = _.isNil(column) ? this.props.column : column;

    dataProvider.soqlDataProvider
      .getColumnStats([column as ViewColumn], dataProvider.datasetUid)
      .then((columnStat) => {
        if (this._isMounted) {
          const columnStatValue = _.isNil(columnStat[0]) ? {} : columnStat[0];
          this.setState({ columnStat: columnStatValue });
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({ columnStat: null });
        }
        if (column) {
          console.error(
            `Soql like cachedContents failed for ${dataProvider.datasetUid} field ${column.fieldName}:`
          );
        }
        console.error(error);
      });
  };

  preventScrollWithSpaceBar(event: React.KeyboardEvent): void {
    if (event.key === Key.Space) {
      event.preventDefault(); // Prevents page scrolling with a space key
    }
  }

  onKeyUpControl(event: React.KeyboardEvent): void {
    if (event.key === Key.Space || event.key === Key.Enter) {
      event.stopPropagation();
      event.preventDefault();
      this.toggleControl();
    }
  }

  onKeyUpConfig(event: React.KeyboardEvent): void {
    if (event.key === Key.Space || event.key === Key.Enter) {
      event.stopPropagation();
      event.preventDefault();

      // When opening (current state.isConfig is false), make the parent block-edit div active.
      // This additional handler for making .block-edit active is only necessary for keyboard events,
      // since mouse click will have already triggered hover intent on .block-edit itself to make it active.
      if (!this.state.isConfigOpen) {
        // Remove all active classes from .block-edit elements
        const blockEditElements = document.querySelectorAll('.block-edit.active');
        this.setState({ previouslyActiveEditBlockElements: blockEditElements });
        blockEditElements.forEach((element) => {
          element.classList.remove('active');
        });

        // Add active class to the parent block-edit element
        const blockEditElement = this.configContainer.current?.closest('.block-edit');
        if (blockEditElement) {
          blockEditElement.classList.add('active');
          this.setState({ shouldRemoveActiveClass: true });
        }
      }

      this.toggleConfig();
    }
  }

  onUpdate(newFilter: SoqlFilter, { shouldCloseControl = true } = {}) {
    this.props.onUpdate(newFilter);
    if (shouldCloseControl && this.filterControlToggle.current) {
      this.filterControlToggle.current.focus();
    }
  }

  onRemove(): void {
    this.props.onRemove();
  }

  toggleControl(): void {
    const { onToggleControl } = this.props;
    onToggleControl(!this.state.isControlOpen);

    this.setState({
      isControlOpen: !this.state.isControlOpen,
      isConfigOpen: false,
      isLeftAligned:
        !!this.filterControlToggle.current &&
        this.filterControlToggle.current.getBoundingClientRect().left < window.innerWidth / 2
    });
  }

  toggleConfig(): void {
    const { onToggleControl } = this.props;
    onToggleControl(false);

    this.setState({
      isControlOpen: false,
      isConfigOpen: !this.state.isConfigOpen,
      isLeftAligned:
        !!this.filterConfigToggle.current &&
        this.filterConfigToggle.current.getBoundingClientRect().left < window.innerWidth / 2
    });
  }

  closeConfig(): void {
    const { shouldRemoveActiveClass, previouslyActiveEditBlockElements, isConfigOpen } = this.state;
    if (!isConfigOpen) return;

    this.setState({
      isConfigOpen: false
    });

    // If "active" class was added to block-edit parent by this class, remove it.
    if (shouldRemoveActiveClass) {
      const blockEditElement = this.configContainer.current?.closest('.block-edit');
      if (blockEditElement) {
        blockEditElement.classList.remove('active');
        this.setState({ shouldRemoveActiveClass: false });
      }

      // Restore active class to previously active block-edit elements
      if (previouslyActiveEditBlockElements) {
        previouslyActiveEditBlockElements.forEach((element) => {
          element.classList.add('active');
          this.setState({ previouslyActiveEditBlockElements: undefined });
        });
      }
    }
  }

  closeControl(): void {
    this.setState({
      isControlOpen: false
    });
  }

  renderFilterConfig(): JSX.Element | null {
    const { column, filter, onUpdate } = this.props;
    const { isConfigOpen } = this.state;

    if (!isConfigOpen) {
      return null;
    }

    const configProps = {
      column,
      filter,
      onUpdate
    };

    // Override fiscal year option to normal year if FYs have been disabled
    if (
      _.get(configProps, 'filter.singleSelect.by') === SINGLE_SELECT_BY.FISCAL_YEAR &&
      shouldOverrideFiscalYear()
    ) {
      _.set(configProps, 'filter.singleSelect.by', SINGLE_SELECT_BY.YEAR);
    }

    return <FilterConfig {...configProps} ref={this.filterConfig} />;
  }

  renderFilterConfigToggle(): JSX.Element | null {
    const { isReadOnly, disabled } = this.props;
    const { isLeftAligned, isConfigOpen } = this.state;

    if (isReadOnly || disabled) {
      return null;
    }

    const toggleProps: React.HTMLAttributes<HTMLDivElement> = {
      className: classNames('filter-config-toggle btn-default', {
        left: isLeftAligned,
        right: !isLeftAligned,
        active: isConfigOpen
      }),
      'aria-label': I18n.t('shared.components.filter_bar.configure_filter'),
      tabIndex: 0,
      role: 'button',
      onClick: this.toggleConfig,
      onKeyDown: this.preventScrollWithSpaceBar,
      onKeyUp: this.onKeyUpConfig
    };

    return (
      <div {...toggleProps} ref={this.filterConfigToggle}>
        <span className="kebab-icon">
          <SocrataIcon name={IconName.Kebab} />
        </span>
      </div>
    );
  }

  fiscalYearOverrideHelper(filter: SoqlFilter): void {
    // Override fiscal year option to normal year if FYs have been disabled
    const filterType = _.get(filter, 'arguments.type');
    if (
      (filterType === RELATIVE_FILTERS.THIS_FISCAL_YEAR ||
        filterType === RELATIVE_FILTERS.THIS_CALENDAR_YEAR) &&
      shouldOverrideFiscalYear()
    ) {
      _.set(filter, 'arguments.type', RELATIVE_FILTERS.THIS_YEAR);
    }

    const filterPeriod = _.get(filter, 'arguments.period');
    if (
      filterType === RELATIVE_FILTERS.CUSTOM &&
      (filterPeriod === 'calendar_year' || filterPeriod === 'fiscal_year') &&
      shouldOverrideFiscalYear()
    ) {
      _.set(filter, 'arguments.period', 'year');
    }
  }

  renderFilterControl(): JSX.Element {
    const { columnStat, isControlOpen, uniqueId } = this.state;
    const {
      column,
      constraints,
      filter,
      allFilters,
      relativeDateOptions,
      isReadOnly,
      showNullsAsFalse,
      dataProvider
    } = this.props;

    const isOpen = isControlOpen && this.columnStatLoaded();

    const relativeDateOptionsWithOverrides = relativeDateOptions
      ? relativeDateOptions
      : defaultRelativeDateOptions();

    const filterProps = {
      column: _.merge({}, column, columnStat),
      constraints,
      filter,
      allFilters,
      relativeDateOptions: relativeDateOptionsWithOverrides,
      isReadOnly,
      showNullsAsFalse,
      dataProvider,
      onRemove: this.onRemove,
      onUpdate: this.onUpdate,
      popupRef: this.filterControlPopupRef
    };

    this.fiscalYearOverrideHelper(filter);

    const popupOptions = {
      placement: 'bottom-end', // forge-popup will adjust placement if needed
      closeCallback: () => {
        this.closeControl();
        // Wrapped in setTimeout to wait for rerenders to complete
        setTimeout(() => {
          this.filterControlToggle.current?.focus();
        });
      }
    };

    return (
      <ForgePopup
        ref={this.filterControlPopupRef}
        open={isOpen}
        targetElementRef={this.filterControlToggle}
        options={popupOptions}
      >
        <div id={`${uniqueId}-control`}>
          <FilterEditor {...filterProps} />
        </div>
      </ForgePopup>
    );
  }

  renderFilterControlToggle(): JSX.Element {
    const { column, disabled, filter, showNullsAsFalse } = this.props;
    const { isControlOpen, uniqueId } = this.state;
    const isFilterDisabled = disabled || filter.isOverridden;
    const filterHumanText = getFilterHumanText(filter, column, showNullsAsFalse);

    const toggleProps: HTMLAttributes<HTMLDivElement> = {
      className: classNames('filter-control-toggle btn-default', {
        active: isControlOpen,
        disabled: isFilterDisabled
      }),
      'aria-label': `${I18n.t('shared.components.filter_bar.filter')} ${column.name} - ${filterHumanText}`,
      tabIndex: 0,
      'aria-owns': `${uniqueId}-control`
    };

    if (!isFilterDisabled) {
      toggleProps.role = 'button';
      toggleProps.onClick = this.toggleControl;
      toggleProps.onKeyDown = this.preventScrollWithSpaceBar;
      toggleProps.onKeyUp = this.onKeyUpControl;
    }

    this.fiscalYearOverrideHelper(filter);

    return (
      <div {...toggleProps} ref={this.filterControlToggle}>
        {filterHumanText}
        {isFilterDisabled && (
          <ForgeTooltip id="filter-tooltip" position={'bottom'}>
            {I18n.t('shared.components.filter_bar.filter_tooltip')}
          </ForgeTooltip>
        )}
        <span className="arrow-down-icon">
          <SocrataIcon name={IconName.ArrowDown} aria-hidden={true} />
        </span>
      </div>
    );
  }

  render(): JSX.Element {
    const CONFIG = 'config';
    const CONTROL = 'control';

    const closeIfBlurred = _.noop;
    const columnName = _.get(this.props, 'column.name');

    // NOTE: You can't use ref'd values right away.
    //       Using a string constant to ID the
    //       container. There is probably a better
    //       way to do this.
    return (
      <div className="filter-bar-filter">
        <label className="filter-control-label">
          {columnName}
          {!this.columnStatLoaded() && <span className="spinner-default column-stat-spinner" />}
        </label>
        <div
          className="filter-control-container"
          ref={this.controlContainer}
          onBlur={() => closeIfBlurred(CONTROL)}
        >
          {this.renderFilterControlToggle()}
          {this.renderFilterControl()}
        </div>
        <div
          className="filter-config-container"
          ref={this.configContainer}
          onBlur={() => closeIfBlurred(CONFIG)}
        >
          {/* Only rendered for editing the filter bar, this is the action menu for the filter item */}
          {this.renderFilterConfigToggle()}
          {this.renderFilterConfig()}
        </div>
      </div>
    );
  }
}

export default FilterItem;
