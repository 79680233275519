/* Imports */
/* ============================================================================= */
import _ from 'lodash';

import { coreUidFromAssetId } from 'common/cetera/catalog_id';
import {
  getDomain,
  userAndTeamAutocompleteUrl
} from 'common/components/AccessManager/Util';
import {
  APPROVALS,
  ApprovalState,
  ResubmissionSettings,
  SubmissionOutcomeStatus,
  WorkflowTargetAudience,
} from 'common/core/approvals_enums';
import { currentUserHasRight } from 'common/current_user';
import { fetchJsonWithDefaultHeaders } from 'common/http';
import { defaultHeaders } from 'common/http/index';
import { fetchJson } from 'common/http';
import {
  Workflow,
  WorkflowRecordListing,
  WorkflowTask,
} from 'common/types/approvals';
import DomainRights from 'common/types/domainRights';
import { AudienceScope } from 'common/types/view';

import {
  isWorkflowGuidance,
  isGuidanceSummary,
  isGuidanceObject,
  isValidGuidance,
  runCheckMethodForWorkflows
} from 'common/core/approvals/guidanceHelpers';
import * as Types from 'common/core/approvals/Types';





/* Exports */
/* ============================================================================= */
export {
  isWorkflowGuidance,
  isGuidanceSummary,
  isGuidanceObject,
  isValidGuidance
};
export * from 'common/core/approvals/guidanceV1';
export * from 'common/core/approvals/guidanceV2';





/* Public Methods */
/* ============================================================================= */
/** Sleep for the given number of milliseconds */
const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * Fetch approvals settings from core.
 *
 * This is actually a list of "Workflows" that are set up on the domain.
 * When assets are submitted for approval, they are submitted to one of these workflows.
 */
export const fetchSettings = (): Promise<Workflow[]> => {
  const apiPath = '/api/approvals';
  const options = {
    credentials: 'same-origin',
    headers: defaultHeaders
  };

  return fetchJson(apiPath, options);
};

/**
 * Fetch user roles from core; used when generating list of approvers on
 * admin/approvals/settings page.
 *
 */
export const fetchRoles = (): Promise<any[]> => {
  const apiPath = '/api/roles';
  const options = {
    credentials: 'same-origin',
    headers: defaultHeaders
  };

  return fetchJson(apiPath, options);
};

/**
 * Remove approval rights for a specific reviewer on a given workflow
 *
 * @param uid Four x four of user to remove rights from
 * @param workflowId Workflow to update
 */
export const removeReviewer = (uid: string, workflowId: number) => {
  const apiPath = `/api/approvals/${workflowId}?method=removeReviewer&userId=${uid}`;
  const options: RequestInit = {
    credentials: 'same-origin',
    headers: defaultHeaders,
    method: 'DELETE'
  };

  return fetch(apiPath, options);
};

/**
 * Add approval right for a specific reviewer on a given workflow
 *
 * @param uid Four x four of user to remove rights from
 * @param workflowId Workflow to update
 */
export const addReviewer = (uid: string, workflowId: number) => {
  const apiPath = `/api/approvals/${workflowId}?method=addReviewer&userId=${uid}`;
  const options: RequestInit = {
    credentials: 'same-origin',
    headers: defaultHeaders,
    method: 'POST'
  };

  return fetch(apiPath, options);
};

/**
 * Use catalog to get autocomplete results from user search. I know this isn't an
 * approvals endpoint but this method doesn't live anywhere else and we use it in the
 * settings page, so I'm putting it here ¯\_(ツ)_/¯
 *
 * @param userSearch A string from the search box (ex. a partial name of a user)
 */
export const fetchUsersForAutocomplete = (userSearch: string) => {
  const userRights: DomainRights[] = [DomainRights.view_others_datasets, DomainRights.edit_others_datasets];

  return fetchJsonWithDefaultHeaders(userAndTeamAutocompleteUrl(
    userSearch,
    getDomain(),
    {disabled: false, rights: userRights, includeTeams: false})
  );
};


/**
 * Will return the asset ID for the given View.
 *
 * This takes into account two special cases of assets that we have in our system...
 *
 * DSMAPI revisions are different from "working copies" in that they do not have a lens backing them and so do not
 * have a unique UID. Instead, they have a revision number that is appended to the base lens UID.
 *
 * Story drafts are unique in much the same way, except there can only be one draft per story, and this is signified
 * by appending `:draft` to the base lens UID.
 *
 * Note that core is _mostly_ unaware of these special cased UIDs, except in the case of the approvals guidance endpoint.
 * The catalog is also aware of these special UIDs.
 *
 * @param coreViewUId Base UID of a View from core, aka the 4x4
 * @param revisionSeq A revision sequence, used only for DSMAPI revisions
 * @param isStoryDraft Whether or not the asset is a story draft.
 */
export const assetIdFor = (coreViewUId: string, revisionSeq?: string | number, isStoryDraft?: boolean) => {
  const isRevision = _.isFinite(_.toNumber(revisionSeq));
  if (isRevision && isStoryDraft) {
    throw new Error('an asset cannot be both a revision and a story draft');
  }

  if (isRevision) {
    return `${coreViewUId}:${revisionSeq}`;
  } else if (isStoryDraft) {
    return `${coreViewUId}:draft`;
  } else {
    return coreViewUId;
  }
};

/**
 * Poll the approvals API to see if approval for an asset has completed yet.
 * This returns a promise the either resolves when approval is finished, or
 * rejects after a certain number of retries are hit.
 *
 * @param assetUid Asset to poll for
 * @param submissionId The ID of the submission to check for completion of
 * @param maxRetries Number of times to retry hitting the API (default: 30)
 * @param sleepMs How many milliseconds to wait between tries (default: 2000)
 */
export async function pollForCompletedApproval(
  assetUid: string,
  submissionId: number,
  maxRetries = 30,
  sleepMs = 2000
): Promise<SubmissionOutcomeStatus | undefined> {
  if (maxRetries === 0) {
    return Promise.reject('pollForCompletedApproval reached max retries');
  }

  // inclusion of this no-op param (t) is a workaround to get ATS to not cache
  // see EN-40081 for more details or EN-41712 for the bigger picture solution
  const t = new Date().getTime();

  const fourByFour = coreUidFromAssetId(assetUid);
  const apiPath = `/api/views/${fourByFour}/approvals?includeExpired=true&no_cache=true&t=${t}`;
  const resp = (await fetchJson(apiPath, { headers: defaultHeaders })) as WorkflowRecordListing;
  const submission = resp.summaries.find((summary) => summary.approvalWorkflowSubmissionId === submissionId);

  const { SUCCESS, FAILURE } = APPROVALS.SUBMISSION_OUTCOME_STATUS;
  const submissionComplete =
    submission?.submissionOutcomeStatus && [SUCCESS, FAILURE].includes(submission.submissionOutcomeStatus);

  if (submissionComplete) {
    return await submission?.submissionOutcomeStatus;
  } else {
    await sleep(sleepMs);
    return pollForCompletedApproval(assetUid, submissionId, maxRetries - 1);
  }
}

/**
 * Update a specific workflow task.
 *
 * @param taskId ID of the task to update
 * @param body Values to set for the task. This can be a partial task to only update some fields.
 * @return A promise that will resolve with the updated task
 */
const updateTask = (taskId: number, body: Partial<WorkflowTask>): Promise<WorkflowTask> => {
  const apiPath = `/api/approvals?method=updateTask&taskId=${taskId}`;

  const options = {
    body: JSON.stringify(body),
    credentials: 'same-origin',
    headers: defaultHeaders,
    method: 'PUT'
  };

  return fetchJson(apiPath, options);
};

/**
 * Update a specific workflow.
 *
 * @param workflowId ID of the workflow to update
 * @param body Values to set for the workflow. This can be a partial workflow to only update some fields.
 */
const updateWorkflow = (workflowId: number, body: Partial<Workflow>): Promise<Workflow> => {
  const apiPath = `/api/approvals/${workflowId}`;

  const options = {
    body: JSON.stringify(body),
    credentials: 'same-origin',
    headers: defaultHeaders,
    method: 'PUT'
  };

  return fetchJson(apiPath, options);
};

/**
 * Change the preset state for a specific approvals workflow task.
 *
 * The preset state controls what happens when an asset enters the approvals queue.
 *
 * @param taskId The task to update
 * @param value The new preset state to set for the task
 * @return A promise that will resolve with the updated approval worfklow task
 */
export const setPresetState = (taskId: number, presetState: ApprovalState): Promise<WorkflowTask> => {
  return updateTask(taskId, { presetState });
};

/**
 * Change the resubmission policy to a specific approvals workflow task.
 *
 * The resubmission policy is what happens when an asset is resubmitted after being approved
 * (such as a draft with data updates).
 *
 * @param workflowId The workflow to update
 * @param value The new resubmission policy for the task.
 */
export const setApprovedResubmissionPolicy = (
  workflowId: number,
  approvedResubmissionPolicy: ResubmissionSettings
): Promise<Workflow> => {
  return updateWorkflow(workflowId, { approvedResubmissionPolicy });
};

/**
 * Takes an Audience Scope Enum value and maps it to a WorkflowTargetAudience Enum value.
 * Returns undefined if the scope value can't be mapped.
 * @param scope (Optional) AudienceScope Enum value
 * @returns WorkflowTargetAudience value or undefined
 */
export const mapPermissionScopeToTargetAudience = (scope?: AudienceScope): WorkflowTargetAudience | undefined => {
  let response;

  switch (scope) {
    case AudienceScope.Public:
      response = WorkflowTargetAudience.PUBLIC;
      break;
    case AudienceScope.Site:
      response = WorkflowTargetAudience.INTERNAL;
      break;
    default:
      response = undefined;
      break;
  }

  return response;
};

/**
 * Returns a summary of whether the current user can review the public/internal workflows
 *
 * @returns GuidanceBooleanCheckSummary object containing the following attributes:
 * - public: Boolean indicating whether the user has the `review_public_approvals` right
 * - internal: Boolean indicating whether the user has the `review_internal_approvals` right
 * - length: The number of workflows the user is able to review. Named to allow array-like checks.
 * - totalWorkflows: The total number of workflows a reviewer could potentially have rights for.
 */
export const summarizeCanReviewWorkflow = (): Types.GuidanceBooleanCheckSummary => {
  return runCheckMethodForWorkflows<boolean>(
    (workflow: WorkflowTargetAudience) => currentUserHasRight(DomainRights[`review_${workflow}_approvals`]),
    false
  );
};
