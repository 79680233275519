// Vendor Imports
import _ from 'lodash';
import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classNames from 'classnames';

// Project Imports
import I18n from 'common/i18n';
import SocrataIcon, { IconName } from '../SocrataIcon';
import AddFilter from '../SingleSourceFilterBar/AddFilter';
// @ts-expect-error
import ResetFiltersButton from '../SingleSourceFilterBar/ResetFiltersButton';
import FilterItem from '../SingleSourceFilterBar/FilterItem';
import ParameterItem from '../SingleSourceFilterBar/ParameterItem';
import { getDefaultFilterForColumn } from './filters';
import SoqlDataProvider from 'common/visualizations/dataProviders/SoqlDataProvider';
import MetadataProvider from 'common/visualizations/dataProviders/MetadataProvider';

import { Filters, Filter, FilterBarColumn } from '../SingleSourceFilterBar/types';
import type { ClientContextVariable } from 'common/types/clientContextVariable';

import './index.scss';
import { SoQLType } from 'common/types/soql';
import { RelativeDatePeriod } from './types';

// Constants
// These are approximately the dimensions set by our CSS
// This needs to in sync with '$max-filter-width' in common/components/SingleSourceFilterBar/css/_filter-item.scss
// This var should be width + margin
const MAX_FILTER_WIDTH = 184;
const FILTER_CONFIG_TOGGLE_WIDTH = 30;

export interface DateFilterBarColumn extends FilterBarColumn {
  renderTypeName: SoQLType.SoQLFloatingTimestampAltT;
  /** the minimum time present in the column */
  rangeMax: string;
  /** the maximum time present in the column */
  rangeMin: string;
}

/** This can also encompass specially formatted numbers like money. */
export interface NumberFilterBarColumn extends FilterBarColumn {
  renderTypeName: SoQLType.SoQLNumberT;
  /** the minimum value present in the column */
  rangeMax: number;
  /** the maximum value present in the column */
  rangeMin: number;
}

export interface SingleSourceFilterBarProps {
  /** Whether the Add Filter button is disabled */
  addFilterDisabled?: boolean;
  className?: string;
  columns: FilterBarColumn[];
  computedColumns: FilterBarColumn[];
  editMode: boolean;
  /**
   * This disables all controls in the filter bar, including the Add Filter button,
   * even if addFilterDisabled is false.
   */
  disabled?: boolean;
  /**
   * The message to display in a flyout over the Add Filter button when the SingleSourceFilterBar is disabled.
   * This has no effect if the SingleSourceFilterBar is not disabled.
   */
  disabledMessage?: string;
  /**
   * The filters prop is an array of filter objects that will be rendered.  Each filter object is
   * structured according to the VIF specification.  The set of rendered controls will always
   * reflect the contents of this array.
   */
  filters: Filters;

  parameters?: ClientContextVariable[];

  /**
   * An array of relative date filter options to display in the relative date filter dropdown.
   * If no options are provided, the relative date filter dropdown will not be displayed.
   */
  relativeDateOptions?: RelativeDatePeriod[] | undefined;

  /**
   * Whether to display the filter bar's settings, including the option to add new filters and
   * individual filter settings. If this is set to true and none of the provided filters are
   * visible, the SingleSourceFilterBar will not render anything. Defaults to true.
   *
   * NOTE: Even if 'isReadOnly' is set to true, the parameters of individual, non-hidden filters
   * will still be changeable by users.
   */
  isReadOnly?: boolean;
  /**
   * The onUpdate prop is an optional function that will be called whenever the set of filters has
   * changed.  This may happen when a filter is added, a filter is removed, or the parameters of a
   * filter have changed.  The function is passed the new set of filters.  The consumer of this
   * component is expected to respond to the event by rerendering this component with the new
   * updated "filters" prop.  Any filters that do not have any criteria applied will have a filter
   * function of "noop".
   */
  onUpdate: (newFilters: Filters) => void;
  onParameterOverrideUpdate?: (updatedParameters: ClientContextVariable[]) => void;
  /**
  * Constraints constrain filtering/autocompletion in the filter items.
      - geoSearch: reduces the RadiusFilter to suggest values within the given geoSearch boundary.
    example: {
        geoSearch: { boundary: [0, 0, 10, 10] },
    }
  */
  constraints?: {
    geoSearch: {
      boundary: number[];
    };
  };
  /**
   * Called whenever
   * - a FilterItem is opened/closed
   * - a filter is updated via the opened FilterItem.
   *
   * This is used to show the radius filters as a circle on the map when the user opens the
   * radius filter item in the filter bar. Also to update the circle on the map, when the user
   * updates the radius or the center of the radius filter.
   */
  onInEditFilterChange: (openFilter: Filter | null) => void;
  /**
   * Indicates whether to show all filters or to show only the first x filters that fits into one
   * row of the SingleSourceFilterBar along with 'Show More filters' button.
   */
  showAllFilters?: boolean;
  /**
   * Indicates whether to show report parameters in SingleSourceFilterBar row
   */
  displayParameters?: boolean;
  /**
   * Indicates whether CheckboxFilter should display the null option with a display string of
   * "False", and omit the strictly false option. This is detailed in EN-32483.
   */
  showNullsAsFalse?: boolean;
  dataSource: {
    datasetUid: string;
    domain: string; // See EN-28544
  };
}

interface FilterBarState {
  isExpanded: boolean;
  maxVisibleFilters: number;
  newFilterAdded: boolean;
  maxFiltersToggleWidth: number;
}

/**
 * SingleSourceFilterBar
 * The SingleSourceFilterBar component renders a set of controls that are intended to allow users to apply
 * customized sets of filters to datasets and visualizations.  Eventually, if the user accessing the
 * component has an admin or publisher role, the SingleSourceFilterBar will expose additional functionality,
 * allowing the user to create new filters and add restrictions on how they are used.
 */
export class SingleSourceFilterBar extends Component<SingleSourceFilterBarProps, FilterBarState> {
  static defaultProps = {
    addFilterDisabled: false,
    disabled: false,
    filters: [],
    parameters: [],
    constraints: {},
    className: '',
    computedColumns: [],
    isReadOnly: true,
    onUpdate: _.noop,
    onParameterUpdate: _.noop,
    onInEditFilterChange: _.noop,
    showAllFilters: false,
    displayParameters: false,
    showNullsAsFalse: false
  };

  soqlDataProvider: any;
  metadataProvider: any;
  container: HTMLElement | null;
  addFilter: HTMLElement | null;
  leftSideControls: HTMLElement | null;
  filterIcon: HTMLElement | null;
  expandControl: HTMLElement | null;

  constructor(props: SingleSourceFilterBarProps) {
    super(props);

    this.state = {
      isExpanded: false,
      maxVisibleFilters: props.showAllFilters ? Infinity : 0,
      newFilterAdded: false,
      maxFiltersToggleWidth: 0
    };

    this.soqlDataProvider = new SoqlDataProvider(props.dataSource);
    this.metadataProvider = new MetadataProvider(props.dataSource, true);

    _.bindAll(this, [
      'onFilterAdd',
      'onFilterRemove',
      'onFilterUpdate',
      'onParameterUpdate',
      'onToggleFilterItemControl',
      'onToggleCollapsedFilters',
      'onWindowResize',
      'getContainerWidth',
      'getControlsWidth',
      'setMaxVisibleFilters',
      'renderAddFilter',
      'renderFilterCount',
      'renderFilterIcon',
      'renderExpandControl',
      'renderVisibleFilters',
      'renderCollapsedFilters'
    ]);
  }

  componentDidMount() {
    this.setMaxVisibleFilters();

    window.addEventListener('resize', this.onWindowResize);
  }

  UNSAFE_componentWillReceiveProps(nextProps: SingleSourceFilterBarProps) {
    // eslint-disable-line camelcase
    const { filters } = this.props;

    if (nextProps.isReadOnly !== this.props.isReadOnly) {
      this.setState({
        isExpanded: false
      });
    }

    // Track if a new filter was added
    if (filters.length < nextProps.filters.length) {
      this.setState({
        newFilterAdded: true
      });
    } else if (filters.length >= nextProps.filters.length) {
      this.setState({
        newFilterAdded: false
      });
    }
  }

  componentDidUpdate(prevProps: SingleSourceFilterBarProps) {
    this.setMaxVisibleFilters();

    const { filters, isReadOnly } = this.props;

    if (!isReadOnly) {
      // if we've added a filter, we need to focus on the new filter
      if (prevProps.filters.length < filters.length && this.container) {
        // the new filter should be the last rendered filter (there should be at least one rendered
        // filter if we get to this point)
        (_.last(this.container.querySelectorAll('.filter-control-toggle')) as HTMLElement).focus();

        // otherwise we should focus on the add filter button
      } else if (prevProps.filters.length > filters.length && this.addFilter) {
        // we should always have an Add Filter button when isReadOnly is false
        this.addFilter.querySelector('button')?.focus();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  onFilterAdd(filter: Filter) {
    const { filters, onUpdate, parameters, displayParameters, dataSource } = this.props;
    const { maxVisibleFilters } = this.state;
    // This clone is very important. See comment in onFilterUpdate.
    const newFilters = _.cloneDeep(filters);

    // Add `columns` so that we can reuse the same filter objects between
    // SingleSourceFilterBar and FilterBar.
    if (!filter.columns || filter.columns.length < 1) {
      filter.columns = [{ fieldName: filter.columnName, datasetUid: dataSource.datasetUid }];
    }

    // Number of visible filters and parameters
    let itemCount;

    newFilters.push(filter);
    onUpdate(newFilters);

    itemCount = _.size(newFilters);
    if (displayParameters && !_.isEmpty(parameters)) {
      itemCount = itemCount + _.size(parameters);
    }

    if (itemCount > maxVisibleFilters) {
      this.setState({
        isExpanded: true
      });
    }
  }

  onToggleFilterItemControl(filter: Filter, isOpened: boolean) {
    const { onInEditFilterChange } = this.props;

    onInEditFilterChange(isOpened ? filter : null);
  }

  onFilterRemove(index: number) {
    const { filters, onUpdate } = this.props;
    // This clone is very important. See comment in onFilterUpdate.
    const newFilters = _.cloneDeep(filters);

    newFilters.splice(index, 1);

    onUpdate(newFilters);
  }

  onFilterUpdate(filter: Filter, index: number) {
    const { filters, onInEditFilterChange, onUpdate, dataSource } = this.props;

    // This clone is _very important_. We obtained `filters`
    // from our parent (via props), and we don't want to mutate
    // their copy of `filters`. If we do, it makes tracking state
    // changes via Redux quite difficult.
    const newFilters = _.cloneDeep(filters);
    newFilters.splice(index, 1, filter);

    // Add `columns` so that we can reuse the same filter objects between
    // SingleSourceFilterBar and FilterBar.
    if (!filter.columns || filter.columns.length < 1) {
      filter.columns = [{ fieldName: filter.columnName, datasetUid: dataSource.datasetUid }];
    }

    onInEditFilterChange(filter);
    onUpdate(newFilters);
  }

  onParameterUpdate(updatedParameter: ClientContextVariable, index: number) {
    const { parameters, onParameterOverrideUpdate } = this.props;

    const updatedParameters = _.cloneDeep(parameters);
    updatedParameters?.splice(index, 1, updatedParameter);

    if (onParameterOverrideUpdate && updatedParameters) {
      onParameterOverrideUpdate(updatedParameters);
    }
  }

  onToggleCollapsedFilters() {
    this.setState({
      isExpanded: !this.state.isExpanded
    });
  }

  onWindowResize() {
    this.setMaxVisibleFilters();

    if (this.state.newFilterAdded) {
      this.setState({
        newFilterAdded: false
      });
    }
  }

  getContainerWidth() {
    if (!this.container) {
      return 0;
    }

    const styles = window.getComputedStyle(this.container);
    const containerPadding = _.parseInt(styles.paddingLeft) + _.parseInt(styles.paddingRight);

    // Note that clientWidth does not include borders or margin. The SingleSourceFilterBar currently doesn't
    // have borders, but this could potentially throw our calculations off in the future if a
    // border is added.
    return this.container.clientWidth - containerPadding;
  }

  getControlsWidth() {
    const { maxFiltersToggleWidth } = this.state;
    const addFilterWidth = this.addFilter ? this.addFilter.offsetWidth : 0;
    const filterIconWidth = this.filterIcon ? this.filterIcon.offsetWidth : 0;
    const currentToggleWidth = this.expandControl ? this.expandControl.offsetWidth : 0;
    const leftSideControlsWidth = this.leftSideControls ? this.leftSideControls.offsetWidth : 0;

    // Keeping track of the longer word used for the toggle - 'more' or 'less' (which may vary depending on
    // locale) so that the max number of visible filters doesn't jump back and forth when you toggle visibility.
    const maxToggleWidth = _.max([currentToggleWidth, maxFiltersToggleWidth])!;

    if (currentToggleWidth > maxFiltersToggleWidth) {
      this.setState({
        maxFiltersToggleWidth: currentToggleWidth
      });
    }

    const isLeftSideInline = this.leftSideControls
      ? window.getComputedStyle(this.leftSideControls).display === 'inline-block'
      : false;

    return isLeftSideInline
      ? addFilterWidth + filterIconWidth + maxToggleWidth + leftSideControlsWidth
      : addFilterWidth + filterIconWidth + maxToggleWidth;
  }

  setMaxVisibleFilters() {
    const { isReadOnly, showAllFilters } = this.props;

    if (showAllFilters) {
      if (this.state.maxVisibleFilters === Infinity) {
        return;
      }
      return this.setState({
        maxVisibleFilters: Infinity
      });
    }

    const { maxVisibleFilters } = this.state;

    const containerWidth = this.getContainerWidth();
    const spaceLeftForFilters = containerWidth - this.getControlsWidth();

    const filterWidth = isReadOnly ? MAX_FILTER_WIDTH : MAX_FILTER_WIDTH + FILTER_CONFIG_TOGGLE_WIDTH;
    const newMaxVisibleFilters = _.floor(spaceLeftForFilters / filterWidth);

    if (containerWidth > 0 && maxVisibleFilters !== newMaxVisibleFilters) {
      this.setState({
        maxVisibleFilters: newMaxVisibleFilters
      });
    }
  }

  renderAddFilter() {
    const { addFilterDisabled, columns, computedColumns, disabled, disabledMessage, filters, isReadOnly } =
      this.props;

    const availableColumns = _.reject(columns, (column) => {
      return !!_.find(filters, ['columnName', column.fieldName]);
    });

    const availableComputedColumns = _.reject(computedColumns, (column) => {
      return !!_.find(filters, ['columnName', column.fieldName]);
    });

    const props = {
      columns: availableColumns,
      computedColumns: availableComputedColumns,
      disabled: addFilterDisabled || disabled,
      disabledMessage,
      onClickColumn: (column: FilterBarColumn) => {
        this.onFilterAdd(getDefaultFilterForColumn(column, this.props.dataSource.datasetUid));
      }
    };

    // FIXME Put styles in the tests and make the span a div
    return (
      !isReadOnly && (
        <span className="add-filter-container" ref={(ref) => (this.addFilter = ref)}>
          <span className="add-filter-spacer"></span>
          <AddFilter {...props} />
        </span>
      )
    );
  }

  renderResetFiltersButton = () => {
    const { dataSource, filters, isReadOnly, onUpdate } = this.props;
    const resetFiltersButtonAttributes = {
      filters,
      datasetUid: dataSource.datasetUid,
      isReadOnly,
      onReset: onUpdate
    };

    return (
      <span className="reset-filter-container">
        <span className="reset-filter-spacer"></span>
        <ResetFiltersButton {...resetFiltersButtonAttributes} />
      </span>
    );
  };

  renderFilterIcon() {
    const { isReadOnly } = this.props;

    const icon = (
      <div className="filter-icon" ref={(ref) => (this.filterIcon = ref)}>
        <SocrataIcon name={IconName.Filter} />
      </div>
    );

    return isReadOnly ? icon : null;
  }

  renderExpandControl() {
    const { isReadOnly, filters, displayParameters, parameters } = this.props;
    const { isExpanded, maxVisibleFilters } = this.state;

    const renderableFilters = _.reject(filters, (filter) => isReadOnly && filter.isHidden);

    let totalRenderableItems = _.size(renderableFilters);

    if (displayParameters && !_.isEmpty(parameters)) {
      totalRenderableItems = totalRenderableItems + _.size(parameters);
    }

    const text = isExpanded
      ? I18n.t('shared.components.filter_bar.less')
      : I18n.t('shared.components.filter_bar.more');
    const classes = classNames('btn btn-transparent btn-expand-control', {
      'is-hidden': totalRenderableItems <= maxVisibleFilters
    });

    const iconName = isExpanded ? IconName.ArrowUp : IconName.ArrowDown;

    return (
      <button
        aria-expanded={isExpanded ? 'true' : 'false'}
        className={classes}
        onClick={this.onToggleCollapsedFilters}
        ref={(ref) => (this.expandControl = ref)}
      >
        {text}
        <SocrataIcon name={iconName} />
      </button>
    );
  }

  renderVisibleFilters(filterItems: JSX.Element[]) {
    const { maxVisibleFilters, newFilterAdded } = this.state;
    const filters = _.take(filterItems, maxVisibleFilters);

    const filterTransitions = filters.map((filter, i) => (
      <CSSTransition
        key={i}
        in={true}
        classNames="filters"
        timeout={{ enter: 1000, exit: 1 }}
        enter={newFilterAdded}
        leave={false}
      >
        {filter}
      </CSSTransition>
    ));

    return (
      <div className="visible-filters-container">
        <TransitionGroup>{filterTransitions}</TransitionGroup>
      </div>
    );
  }

  renderCollapsedFilters(filterItems: JSX.Element[]) {
    const { maxVisibleFilters, newFilterAdded } = this.state;

    const filters = _.drop(filterItems, maxVisibleFilters);

    const filterTransitions = filters.map((filter, i) => (
      <CSSTransition
        key={i}
        in={true}
        classNames="filters"
        timeout={{ enter: 1000, exit: 1 }}
        enter={newFilterAdded}
        leave={false}
      >
        {filter}
      </CSSTransition>
    ));

    return (
      <div className="collapsed-filters-container">
        <TransitionGroup>{filterTransitions}</TransitionGroup>
      </div>
    );
  }

  renderFilterCount(filterItems: JSX.Element[]) {
    const filtersWithArgumentsCount = _.chain(filterItems)
      .filter((filterItem) => !_.isEmpty(filterItem.props.filter.arguments))
      .size()
      .value();

    const filtersCount = filtersWithArgumentsCount === 0 ? '' : `(${filtersWithArgumentsCount})`;

    return (
      <span className="filter-bar-filter-count">
        {I18n.t('shared.components.filter_bar.title')} {filtersCount}
      </span>
    );
  }

  render() {
    const { isExpanded } = this.state;
    const {
      className,
      columns,
      computedColumns,
      constraints,
      disabled,
      dataSource,
      filters,
      parameters,
      displayParameters,
      relativeDateOptions,
      isReadOnly,
      showNullsAsFalse
    } = this.props;

    const parameterItems = _.chain(parameters)
      .map((parameter, index) => {
        const props = {
          editMode: this.props.editMode,
          parameter,
          disabled,
          onParameterUpdate: _.partialRight(this.onParameterUpdate, index)
        };

        return <ParameterItem key={parameter.name} {...props} />;
      })
      .compact()
      .value();

    // We are mapping and then compacting here, instead of first filtering out the filters we
    // wouldn't be rendering, because we need to keep track of the filter's actual index in the
    // filters array in order to properly update the filters.
    const filterItems = _.chain(filters)
      .map((filter, index) => {
        if (isReadOnly && filter.isHidden) {
          return null;
        }

        const column =
          _.find(columns, { fieldName: filter.columnName }) ||
          _.find(computedColumns, { fieldName: filter.columnName });

        if (_.isNil(column)) {
          return null;
        }

        const props = {
          column,
          constraints,
          disabled,
          filter,
          allFilters: filters,
          relativeDateOptions,
          isReadOnly,
          showNullsAsFalse,
          dataProvider: [
            {
              soqlDataProvider: this.soqlDataProvider,
              metadataProvider: this.metadataProvider,
              ...dataSource
            }
          ],
          onRemove: _.partial(this.onFilterRemove, index),
          onUpdate: _.partialRight(this.onFilterUpdate, index),
          onToggleControl: _.partial(this.onToggleFilterItemControl, filter)
        };

        return <FilterItem key={index} {...props} />;
      })
      .compact()
      .value();

    if (displayParameters) {
      if (isReadOnly && _.isEmpty(filterItems) && _.isEmpty(parameterItems)) {
        return null;
      }
    } else {
      if (isReadOnly && _.isEmpty(filterItems)) {
        return null;
      }
    }

    const allItems = displayParameters ? parameterItems.concat(filterItems) : filterItems;

    const containerProps = {
      className: classNames(
        'filter-bar-container',
        {
          'filter-bar-expanded': isExpanded,
          'filter-bar-read-only': isReadOnly
        },
        className
      ),
      ref: (ref: HTMLDivElement) => (this.container = ref)
    };

    return (
      <div {...containerProps}>
        {this.renderFilterIcon()}
        <div className="filters-controls-left-side" ref={(ref) => (this.leftSideControls = ref)}>
          {this.renderFilterCount(filterItems)}
          {this.renderResetFiltersButton()}
        </div>
        <div className="filters-controls-right-side">{this.renderAddFilter()}</div>
        {this.renderVisibleFilters(allItems)}
        {this.renderExpandControl()}
        {this.renderCollapsedFilters(allItems)}
      </div>
    );
  }
}

export default SingleSourceFilterBar;
