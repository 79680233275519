// Vendor Imports
import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';

// Project Imports
import Dropdown from '../Dropdown';
import ColorPalettePreview from 'common/authoring_workflow/components/shared/ColorPalettePreview';
import {
  COLOR_PALETTE_VALUES,
  COLOR_PALETTE_VALUES_FOR_MAPS,
  DEFAULT_COLOR_PALETTE,
  isCustomColorPalette
} from 'common/authoring_workflow/constants';
import Tabs from 'common/components/ColorAndCharmPicker/Tabs';
import I18n from 'common/i18n';
import { getCharmSvgSrc } from 'common/resources/charms';
import {
  getSiteAppearanceColorPalettes,
  isSiteAppearanceColorPalette,
  findPaletteCanonicalReference
} from 'common/visualizations/helpers/SiteAppearanceColors';
import { getColorPaletteOptions } from 'common/authoring_workflow/helpers';
import {
  getSiteAppearanceColors
} from 'common/visualizations/helpers/VifSelectors';
// Constants
const CHARM_NAME = 'map-marker-alt';

export class ColorPalettePicker extends Component {
  constructor(props) {
    super(props);

    const siteAppearancePalettes = getSiteAppearanceColorPalettes();
    const selectedTab = props.selectedTab ||
      isSiteAppearanceColorPalette(props.value) ?
        ColorPalettePicker.custom :
        ColorPalettePicker.default;

    this.state = {
      siteAppearancePalettes,
      selectedTab
    };
  }

  renderColorAndCharm = () => {
    return (
      <div className="custom-color-palette-option">
        {this.renderColorFrame()}
        {this.renderColorFrame(true)}
      </div>
    );
  }

  renderColorFrame = (displayCharm = false) => {
    const { options } = this.props;

    // default color to first color of first palette
    const colorOptions = getColorPaletteOptions(options);
    const colorPalette = colorOptions[0].value;
    const color = COLOR_PALETTE_VALUES[colorPalette][0];

    return (
      <div className={classNames('custom-palette-frame', { 'charm': displayCharm })}>
        <div className={classNames('custom-selected-color', { 'preview-icon': displayCharm })} style={{ backgroundColor: color}}>
        {displayCharm ? <img src={getCharmSvgSrc(CHARM_NAME)} key={CHARM_NAME} alt={CHARM_NAME} /> : ''}
        </div>
        <div className="palette-box" role="presentation">
          <span className="socrata-icon-arrow-down"></span>
        </div>
      </div>
    );
  };

  renderCustomPaletteOption = () => {
    return (option) => (
      <div className="color-palette-column-option">
        {this.renderColorAndCharm()}
        <span>{option.title}</span>
      </div>
    );
  };

  renderTabs() {
    const localScope = 'shared.components.color_picker';
    const tabsProps = {
      tabs: [{
        title: I18n.t('default', { scope: localScope }),
        value: ColorPalettePicker.default
      },{
        title: I18n.t('custom', { scope: localScope }),
        value: ColorPalettePicker.custom
      }],
      selectedTab: this.state.selectedTab,
      onTabChange: (selectedTab) => this.setState({ selectedTab })
    };
    return (
      <Tabs {...tabsProps} />
    );
  }

  isCustomTabSelected = () => {
    const { siteAppearancePalettes, selectedTab } = this.state;
    return !_.isEmpty(siteAppearancePalettes) &&
      (selectedTab === ColorPalettePicker.custom);
  }

  renderDropdown = () => {
    const { options, onSelectColorPalette, showCharm, value } = this.props;
    const { siteAppearancePalettes } = this.state;

    const colorOptions = getColorPaletteOptions(options);

    const listOptions = this.isCustomTabSelected() ?
      _.map(siteAppearancePalettes, (palette) => ({
          value: palette.id || palette.name,
          title: palette.name,
          render: this.renderSiteAppearanceColorPaletteOption(palette.name)
        })) :
      _.map(colorOptions, (option) => ({
          value: option.value,
          title: option.title,
          render: isCustomColorPalette(option.value) && showCharm ?
            this.renderCustomPaletteOption(option) :
            this.renderPaletteOption(option)
        }));
    const colorPaletteAttributes = {
      id: 'color-palette-picker',
      options: listOptions,
      value: findPaletteCanonicalReference(value),
      onSelection: (event) => { onSelectColorPalette(event.value); }
    };

    return <Dropdown {...colorPaletteAttributes} />;
  }

  renderSiteAppearanceColorPaletteOption = (paletteName) => {
    const colors = getSiteAppearanceColors(paletteName);

    const previewProps = { colors };

    return (option) => (
      <div className="color-palette-column-option">
        <ColorPalettePreview {...previewProps} />
        <span>{option.title}</span>
      </div>
    );
  }

  renderPaletteOption = (colorPaletteOption) => {
    const { value } = colorPaletteOption;
    const selectedColorPalette = isCustomColorPalette(value) ? DEFAULT_COLOR_PALETTE :
      value;

    return (option) => (
      <div className="color-palette-column-option">
        <ColorPalettePreview colors={COLOR_PALETTE_VALUES_FOR_MAPS[selectedColorPalette](5)} />
        <span>{option.title}</span>
      </div>
    );
  };

  renderPreview = () => {
    const { showCharm, value } = this.props;
    const paletteName = (showCharm || !isCustomColorPalette(value)) ?
      value :
      DEFAULT_COLOR_PALETTE;

    if (this.state.selectedTab === ColorPalettePicker.default) {
      if (isCustomColorPalette(paletteName)) {
        return this.renderColorAndCharm();
      } else if (!_.isNil(COLOR_PALETTE_VALUES_FOR_MAPS[paletteName])) { // built-in palette
        return <ColorPalettePreview colors={COLOR_PALETTE_VALUES_FOR_MAPS[paletteName](5)} />;
      } else {
        return null;
      }
    } else if (this.state.selectedTab === ColorPalettePicker.custom) {
      if (isSiteAppearanceColorPalette(paletteName)) {
        const colors = getSiteAppearanceColors(paletteName);
        return <ColorPalettePreview colors={colors} />;
      } else {
        return null;
      }
    } else {
      return <ColorPalettePreview colors={COLOR_PALETTE_VALUES_FOR_MAPS[DEFAULT_COLOR_PALETTE](5)} />; // default palette
    }
  }

  render() {
    const tabs = _.isEmpty(this.state.siteAppearancePalettes) ? null : this.renderTabs();
    return (
      <div>
        {tabs}
        <div className="color-scale-dropdown-container has-color-palette-preview">
          {this.renderDropdown()}
          {this.renderPreview()}
        </div>
      </div>
    );
  }
}

ColorPalettePicker.default = 'default';
ColorPalettePicker.custom = 'custom';

ColorPalettePicker.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      // Used to render the option title.
      title: PropTypes.string,
      // Used for value comparisons during selection.
      value: PropTypes.any,
      // Receives the relevant option and
      // must return a DOM-renderable value.
      render: PropTypes.func
    })
  ),
  // Sets the initial value when provided.
  value: PropTypes.string,
  // show color and charm section.
  showCharm: PropTypes.bool,
  // Calls a function after user selection.
  onSelectColorPalette: PropTypes.func
};

export default ColorPalettePicker;
