import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import I18n from 'common/i18n';
import { DOWN, ESCAPE, isolateEventByKeys } from 'common/dom_helpers/keycodes_deprecated';
import { EMPTY_TRANSPARENT_COLOR } from 'common/authoring_workflow/constants';
import ColorBuckets from 'common/components/ColorPicker/ColorBuckets';
import { ForgeIcon, ForgePopup } from '@tylertech/forge-react';

import './index.scss';

export class ColorPicker extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.observer = null;

    this.state = {
      selectedColor: this.props.value,
      showingBuckets: false,
      colorFrameWidth: 0
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {  // eslint-disable-line camelcase
    if (nextProps.value !== this.props.value) {
      this.setState({
        selectedColor: nextProps.value
      });
    }
  }

  onClickColorFrame = () => {
    this.setState({
      showingBuckets: !this.state.showingBuckets
    });
  }

  onClickBucket = (selectedColor) => {
    this.setState({
      showingBuckets: false,
      selectedColor
    });

    this.props.handleColorChange(selectedColor);
    // Remove focus in an iframe - layout narrative || expanded || large
    // to enable(.active) rich media editor right after clicking a color, then reselecting text.
    const activeElement = document.activeElement;
    if (activeElement.closest('.layout-narrative, .layout-expanded, .layout-large')) activeElement.blur();
    this.colorPickerRef.focus();
  }

  onClose = () => {
    this.setState({
      showingBuckets: false
    });
  }

  onKeyDownColorPicker = (event) => {
    isolateEventByKeys(event, [DOWN]);
  }

  onKeyUpColorPicker = (event) => {
    const { keyCode } = event;
    isolateEventByKeys(event, [DOWN, ESCAPE]);

    if (keyCode === DOWN) {
      this.setState({ showingBuckets: true });
    } else if (keyCode === ESCAPE) {
      this.onClose();
    }
  }

  renderColorFrame = () => {
    const { colorFrameWidth, selectedColor } = this.state;
    const openColorPicker = I18n.t('shared.components.color_picker.open_color_picker');
    const withCurrentSelection = I18n.t('shared.components.color_picker.with_currently_selected_color');
    const label = selectedColor ?
      `${openColorPicker} ${withCurrentSelection} ${selectedColor}` : openColorPicker;
    const colorFrameAttributes = {
      className: 'color-frame',
      onClick: this.onClickColorFrame,
      role: 'button',
      tabIndex: 0,
      onKeyUp: this.onKeyUpColorPicker,
      onKeyDown: this.onKeyDownColorPicker,
      ref: (ref) => this.colorPickerRef = ref,
      'aria-label': label
    };
    const selectedColorFrameAttributes = {
      className: classNames('selected-color-frame', {
        'color-none': selectedColor === EMPTY_TRANSPARENT_COLOR
      }),
      style: { backgroundColor: selectedColor },
      'data-testid': 'selected-color-frame'
    };

    return (
      <div {...colorFrameAttributes}>
        <div className="selected-color-frame-wrapper">
          <div {...selectedColorFrameAttributes} />
        </div>
        <div className="caret" role="presentation">
          <div className="arrow-down">
            <ForgeIcon name="arrow_drop_down" />
          </div>
        </div>
      </div>
    );
  }

  renderColorPickerOverlay = () => {
    const colorBucketsAttributes = {
      bucketRevealDirection: this.props.bucketRevealDirection,
      palette: this.props.palette,
      selectedColor: this.state.selectedColor,
      showingBuckets: this.state.showingBuckets,
      onClickBucket: this.onClickBucket,
      onClose: this.onClose,
      value: this.state.selectedColor
    };

    return (
      <ForgePopup
        targetElementRef={this.ref}
        open={this.state.showingBuckets}
        onDismiss={() => this.setState({
          showingBuckets: false
        })}
      >
        <div className="color-buckets-wrapper">
          <ColorBuckets {...colorBucketsAttributes} />
        </div>
      </ForgePopup>
    );
  }

  render() {
    const colorPickerAttributes = {
      className: 'color-picker',
      id: this.props.id,
      ref: this.ref
    };

    return (
      <div {...colorPickerAttributes}>
        {this.renderColorPickerOverlay()}
        {this.renderColorFrame()}
      </div>
    );
  }
}

ColorPicker.propTypes = {
  bucketRevealDirection: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  palette: PropTypes.array,
  handleColorChange: PropTypes.func
};

ColorPicker.defaultProps = {
  type: 'single',
  value: '#204490',
  handleColorChange: _.noop
};

export default ColorPicker;
