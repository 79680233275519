import React, { Component, PropsWithChildren, CSSProperties } from 'react';
import classNames from 'classnames';

import { ESCAPE, TAB, isolateEventByKeys, isOneOfKeys } from 'common/dom_helpers/keycodes_deprecated';
import {
  focusFirstActionableElement,
  getFirstActionableElement,
  getLastActionableElement
} from 'common/a11y';

interface ModalProps {
  /** Optional classname to add to the wrapping div of the modal */
  className?: string;

  /** Optional CSS properties to add to inner container of the modal */
  containerStyle?: CSSProperties;

  /**
   * Whether or not this modal should be rendered full screen.
   * Note that if the window size is small enough, the modal will be forced into fullscreen.
   * Default: false
   */
  fullScreen?: boolean;

  /**
   * Function to call when dismissing the modal.
   * This can happen in a few ways, such as hitting the ESC key, or clicking the X in the header.
   */
  onDismiss: () => void;

  /**
   * Whether this modal is an overlay or not; this renders a semi-transparent grey background under the modal that covers the whole page.
   * Default: true
   */
  overlay?: boolean;

  /** Optional styles to pass to the overlay; this actually is applied to the overall wrapping div of the modal */
  overlayStyle?: CSSProperties;

  /**
   * Whether or not to call the "onDismiss" function when the user clicks outside of the modal.
   * Default: true
   */
  dismissOnOutsideClick?: boolean;

  /** Optional test id to pass to the modal. This can be useful in i.e. cheetah for checking if a modal is being displayed. */
  'data-testid'?: string;

  /** Optional label ID string for modal parent */
  modalLabelId?: string;
}

interface ModalState {
  /**
   * Whenever the window changes size, the modal will check if it smaller than a specified breakpoint.
   * If it is, it will change to be full-screen.
   */
  forceFullScreen: boolean;
}

/**
 * @example
 * ```tsx
 * import { Modal, ModalContent, ModalFooter, ModalHeader } from 'common/components/Modal';
 *
 * // ...
 *
 * <Modal>
 *  <ModalHeader title="Your Modal" />
 *  <ModalContent>Your modal content</ModalContent>
 *  <ModalFooter>Your modal footer</ModalFooter>
 * </Modal>
 * ```
 */
export class Modal extends Component<PropsWithChildren<ModalProps>, ModalState> {
  static defaultProps = {
    className: null,
    fullScreen: false,
    overlay: true,
    dismissOnOutsideClick: true,
    modalLabelId: 'modal-header-title'
  };

  static MOBILE_BREAKPOINT = 420;

  /** @returns True if the modal should be fullscreen because the window is small enough */
  static shouldForceFullScreen = () => window.innerWidth <= Modal.MOBILE_BREAKPOINT;

  state = {
    forceFullScreen: Modal.shouldForceFullScreen()
  };

  /** This is used to track which component should be re-focused when the modal closes */
  previouslyFocusedElement: HTMLElement | null = null;

  /** Ref pointing to the wrapping modal div */
  modalContainer: Element | null = null;

  /** Ref pointing to the inner modal div */
  modalElement: Element | null = null;

  componentDidMount = () => {
    window.addEventListener('resize', this.checkDimensions);
    document.addEventListener('keyup', this.tryEscDismiss as any);
    document.documentElement.classList.add('modal-open');

    // Handle a11y focusing concerns
    this.previouslyFocusedElement = document.activeElement as HTMLElement;
    focusFirstActionableElement(this.modalContainer, '.modal-header-dismiss');
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.checkDimensions);
    document.removeEventListener('keyup', this.tryEscDismiss as any);
    document.documentElement.classList.remove('modal-open');

    // Handle a11y focusing concerns
    if (this.previouslyFocusedElement) {
      this.previouslyFocusedElement.focus();
    }
  };

  checkDimensions = () => {
    this.setState({ forceFullScreen: Modal.shouldForceFullScreen() });
  };

  tryFocusTrap = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (isOneOfKeys(event, [TAB])) {
      const firstActionableElement = getFirstActionableElement(this.modalContainer);
      const lastActionableElement = getLastActionableElement(this.modalContainer);

      // tab + shift means the user is tabbing to the previous focusable element
      if (event.target === firstActionableElement && event.shiftKey && lastActionableElement) {
        isolateEventByKeys(event, [TAB]);
        lastActionableElement.focus();
        // be careful to let users to tab + shift to the element before the last one
      } else if (event.target === lastActionableElement && !event.shiftKey && firstActionableElement) {
        isolateEventByKeys(event, [TAB]);
        firstActionableElement.focus();
      }
    }
  };

  tryEscDismiss = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const { onDismiss } = this.props;
    isolateEventByKeys(event, [ESCAPE]);

    if (isOneOfKeys(event, [ESCAPE])) {
      onDismiss();
    }
  };

  tryOverlayClickDismiss = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { onDismiss, dismissOnOutsideClick } = this.props;

    if (dismissOnOutsideClick && event.target === this.modalElement) {
      onDismiss();
    }
  };

  render() {
    const { children, className, containerStyle, fullScreen, overlay, overlayStyle, modalLabelId } = this.props;
    const { forceFullScreen } = this.state;

    const modalClasses = classNames(className, {
      modal: true,
      'modal-overlay': overlay !== false,
      'modal-full': fullScreen || forceFullScreen
    });

    return (
      <div
        ref={(ref) => (this.modalElement = ref)}
        className={modalClasses}
        style={overlayStyle}
        role="dialog"
        aria-modal={true}
        onKeyUp={this.tryEscDismiss}
        onKeyDown={this.tryFocusTrap}
        onClick={this.tryOverlayClickDismiss}
        data-testid={this.props['data-testid']}
        aria-labelledby={modalLabelId}
      >
        <div className="modal-container" style={containerStyle} ref={(ref) => (this.modalContainer = ref)}>
          {children}
        </div>
      </div>
    );
  }
}

export { default as ModalHeader } from './Header';
export { default as ModalContent } from './Content';
export { default as ModalFooter } from './Footer';

export default Modal;
