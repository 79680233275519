import React, { FunctionComponent } from 'react';
// @ts-expect-error
import ActionDropdown from './action_dropdown';
import RefreshButton from './refresh_button';
/**
 * A component which presents the user with a menu of generic actions applicable to a dataset being
 * displayed in a results list row. Examples of these actions include editing the dataset, deleting
 * the dataset, invoking ("refreshing") an associated Hosted Analysis job, or managing collaborators.
 */


interface DefaultResultListRowActionsProps {
  analysisId?: string;
  uid: string;
}

export const DefaultResultListRowActions: FunctionComponent<DefaultResultListRowActionsProps> = (props) => {
  // If the result has an analysisId associated, we've decided to hide every other action along with the entire
  // ActionDropdown and instead render a singular Refresh button. We'll likely revisit this if we iterate
  // further on Hosted Analysis.
  if (props.analysisId) {
    return <RefreshButton viewId={props.uid} />;
  } else {
    return <ActionDropdown {...props} />;
  }
};
