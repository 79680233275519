import React, { FunctionComponent } from 'react';

import I18n from 'common/i18n';
import { Modal, ModalHeader, ModalContent, ModalFooter } from 'common/components/AccessibleModal';
import Button, { VARIANTS } from 'common/components/Button';
import { ForgeDialog, ForgeToolbar, ForgeButton } from '@tylertech/forge-react';

interface ConfirmationModalProps {
  /** Function to call when clicking the confirm button */
  onAgree: () => void;

  /** Function to call when clicking the cancel button */
  onCancel: () => void;

  /** Text to put inside cancel button; defaults to "Cancel" */
  cancelButtonText?: string;

  /** Class to add to cancel button */
  cancelButtonClassName?: string;

  /** Variant to use for cancel button; defaults to DEFAULT */
  cancelButtonVariant?: VARIANTS;

  /** Whether or not to hide the cancel button. It is shown by default. */
  hideCancelButton?: boolean;

  /** Text to put inside agree button; defaults to "OK" */
  agreeButtonText?: string;

  /** Class name to add to agree button */
  agreeButtonClassName?: string;

  /** Variant to use for confirm button; defaults to PRIMARY. Forge version Only ERROR is used */
  agreeButtonVariant?: VARIANTS;

  /** Optional text to show in modal header */
  headerText?: string;

  /** Optional renders a forge version of this dialog */
  forgeVersion?: boolean;
}

const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
  children,
  onCancel,
  onAgree,
  headerText,
  cancelButtonClassName,
  agreeButtonClassName,
  cancelButtonText,
  agreeButtonText,
  cancelButtonVariant,
  agreeButtonVariant,
  hideCancelButton,
  forgeVersion
}) => {
  if (forgeVersion) {
    return (
      <ForgeDialog open={true} onDismiss={onCancel} >
        <div className='confirmation-dialog-container-forged'>
          <div className='container-body'>
            <h1 id="confirmation-modal-title" className='forge-typography--title forge-header-title'>
              {headerText}
            </h1>
            <p className="forge-typography--body2">
              {children}
            </p>
          </div>
          <ForgeToolbar inverted={true}>
            {!hideCancelButton &&
              <ForgeButton
                type="outlined"
                onClick={onCancel}
                className="confirmation-cancel"
                slot="end"
              >
                <button type="button" data-testid='confirmation-cancel-button'>
                  {cancelButtonText || I18n.t('shared.components.confirmation.cancel')}
                </button>
              </ForgeButton>
            }
            <ForgeButton
              type="raised"
              onClick={onAgree}
              className={`confirmation-accept ${agreeButtonVariant === VARIANTS.ERROR ? 'confirmation-forge-danger-style' : ''}`}
              slot="end"
            >
              <button type="button" data-testid='confirmation-accept-button'>
                {agreeButtonText || I18n.t('shared.components.confirmation.agree')}
              </button>
            </ForgeButton>
          </ForgeToolbar>
        </div>
      </ForgeDialog>
    );
  } else {
    return (
      <Modal className="confirmation-dialog-container" onDismiss={onCancel}>
        <ModalHeader showCloseButton={false} onDismiss={onCancel} title={headerText} />
        <ModalContent>{children}</ModalContent>
        <ModalFooter>
          {!hideCancelButton && (
            <Button
              variant={cancelButtonVariant || VARIANTS.DEFAULT}
              onClick={onCancel}
              className={`confirmation-cancel cancel-button${
                cancelButtonClassName && ` ${cancelButtonClassName}`
              }`}
            >
              {cancelButtonText || I18n.t('shared.components.confirmation.cancel')}
            </Button>
          )}
          <Button
            variant={agreeButtonVariant || VARIANTS.PRIMARY}
            onClick={onAgree}
            className={`confirmation-accept accept-button${agreeButtonClassName && ` ${agreeButtonClassName}`}`}
          >
            {agreeButtonText || I18n.t('shared.components.confirmation.agree')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
};

export default ConfirmationModal;
