import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

import Functions from '../Lang/Functions';
import FunctionDocs from './FunctionDocs';
import ColumnDocs from './ColumnDocs';

/**
 * Shows documentation or other info for the currently open documentation
 */
class DocumentationInfoFlannel extends Component {
  static propTypes = {
    docType: PropTypes.string,
    name: PropTypes.string,
    view: PropTypes.object.isRequired
  }

  getFunctionDocs = (name) => {
    const func = Functions[name];

    if (!func) {
      console.warn(`Unknown function ${name}`);
      return null;
    }

    return (
      <FunctionDocs func={func} />
    );
  }

  getColumnDocs = (columnFieldName) => {
    const { view } = this.props;

    const column = view.columns.find(col => col.fieldName === columnFieldName);

    if (!column) {
      console.warn(`Column ${columnFieldName} not found in view`);
      return null;
    }

    return (
      <ColumnDocs column={column} />
    );
  }

  render() {
    const { docType, name } = this.props;

    let docs = null;

    switch (docType) {
      case 'function':
        docs = this.getFunctionDocs(name);
        break;
      case 'column':
        docs = this.getColumnDocs(name);
        break;
      default:
        console.warn(`Unknown doc type ${docType}`);
        break;
    }

    return docs;
  }
}

const mapStateToProps = state => ({
  docType: get(state, 'editor.documentation.docType'),
  name: get(state, 'editor.documentation.name'),
  view: state.editor.view
});

export default connect(mapStateToProps)(DocumentationInfoFlannel);
