// Vendor Imports
import bindAll from 'lodash/bindAll';
import includes from 'lodash/includes';
import merge from 'lodash/merge';
import React, { Component, HTMLAttributes } from 'react';
import classNames from 'classnames';
import { ForgeCheckbox } from '@tylertech/forge-react';

// Project Imports
import SocrataIcon, { IconName } from '../SocrataIcon';
import ValueEditor from './ValueEditor';
import { getParameterHumanText } from './filters';
import I18n from 'common/i18n';

import { isInsideFlannels } from './helpers';
import { DEFAULT_ALIGN_TOP_AREA_HEIGHT } from 'common/authoring_workflow/constants';
import { Key } from 'common/types/keyboard/key';

import type { ClientContextVariable } from 'common/types/clientContextVariable';
import { SoQLType } from 'common/types/soql';

// TODO: Add Tests
export interface ParameterItemProps {
  editMode: boolean;
  parameter: ClientContextVariable;
  disabled?: boolean;
  onParameterUpdate: (parameter: ClientContextVariable) => void;
}

interface ParameterItemState {
  isControlOpen: boolean;
  isLeftAligned: boolean;
  isTopAligned: boolean;
}

export class ParameterItem extends Component<ParameterItemProps, ParameterItemState> {
  private parameterControl = React.createRef<HTMLSpanElement>();
  private parameterControlToggle = React.createRef<HTMLDivElement>();
  private controlContainer = React.createRef<HTMLDivElement>();

  bodyClickHandler: (e: MouseEvent) => void;
  bodyEscapeHandler: (e: KeyboardEvent) => void;

  constructor(props: ParameterItemProps) {
    super(props);

    this.state = {
      isControlOpen: false,
      isLeftAligned: false,
      isTopAligned: false
    };

    bindAll(this, [
      'toggleControl',
      'onKeyDownControl',
      'onKeyUpControl',
      'onUpdate',
      'renderParameterControl',
      'renderParameterControlToggle'
    ]);
  }

  componentDidMount() {
    this.bodyClickHandler = (event) => {
      // Avoid closing flannels if the click is inside any of these refs.
      const flannelElements = [this.parameterControl.current, this.parameterControlToggle.current];

      const clickInsideFlannels = isInsideFlannels(event, flannelElements);

      // If none of the flannelElements contain event.target, close all the flannels.
      if (!clickInsideFlannels) {
        this.closeAll();
      }
    };

    this.bodyEscapeHandler = (event) => {
      const { isControlOpen } = this.state;
      if (event.key === Key.Escape) {
        if (isControlOpen && this.parameterControlToggle.current) {
          this.closeAll();
          this.parameterControlToggle.current.focus();
        }
      }
    };

    document.body.addEventListener('click', this.bodyClickHandler);
    document.body.addEventListener('keyup', this.bodyEscapeHandler);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.bodyClickHandler);
    document.body.removeEventListener('keyup', this.bodyEscapeHandler);
  }

  isTopAlignedForParameterControl = (): boolean => {
    if (this.parameterControlToggle.current) {
      const parameterControlTopPosition =
        this.parameterControlToggle.current.getBoundingClientRect().top + window.scrollY;
      const bodyHeight = document.body.offsetHeight;

      return bodyHeight - DEFAULT_ALIGN_TOP_AREA_HEIGHT < parameterControlTopPosition;
    }

    return false;
  };

  toggleControl(): void {
    this.setState({
      isControlOpen: !this.state.isControlOpen,
      isTopAligned: this.isTopAlignedForParameterControl(),
      isLeftAligned:
        !!this.parameterControlToggle.current &&
        this.parameterControlToggle.current.getBoundingClientRect().left < window.innerWidth / 2
    });

    // When opening control (i.e., current isControlOpen value is false), focus on the first input element.
    // If we update this component to use ForgePopup in the future, we may need to use a solution similar to
    // FilterEditor/InputFocus.
    if (!this.state.isControlOpen) {
      setTimeout(() => {
        this.parameterControl.current?.querySelector('input')?.focus();
      });
    }
  }

  onKeyDownControl(event: React.KeyboardEvent): void {
    if (event.key === Key.Space) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  onKeyUpControl(event: React.KeyboardEvent): void {
    if (includes([Key.Enter, Key.Space], event.key)) {
      event.stopPropagation();
      event.preventDefault();
      this.toggleControl();
    }
  }

  onUpdate(parameterOverrideValue: string) {
    const { parameter } = this.props;
    const newParameter: ClientContextVariable = merge({}, parameter, {
      overrideValue: parameterOverrideValue
    });

    this.setState((prev) => ({ ...prev, isLoading: true }));
    this.props.onParameterUpdate(newParameter);
    this.setState((prev) => ({ ...prev, isLoading: false }));
    this.closeAll();
    if (this.parameterControlToggle.current) {
      this.parameterControlToggle.current.focus();
    }
  }

  renderDisplayName(): string {
    const { parameter } = this.props;

    if (parameter.dataType === SoQLType.SoQLBooleanT) return '';
    else return parameter.displayName;
  }

  renderParameterControlToggle(): JSX.Element {
    const { parameter, disabled, editMode } = this.props;
    const { isLeftAligned, isControlOpen } = this.state;

    if (parameter.dataType === SoQLType.SoQLBooleanT) {
      return (
        <div className={editMode ? 'filter-control-toggle boolean-parameter' : ''}>
          <ForgeCheckbox dense>
            <input
              type="checkbox"
              id={`parameter-${parameter.name}-checkbox`}
              checked={
                parameter.overrideValue
                  ? JSON.parse(parameter?.overrideValue)
                  : JSON.parse(parameter.defaultValue)
              }
              onChange={(e: any) => this.onUpdate(e.target.checked.toString())}
            />
            <label htmlFor={`parameter-${parameter.name}-checkbox`}>{getParameterHumanText(parameter)}</label>
          </ForgeCheckbox>
        </div>
      );
    } else {
      const toggleProps: HTMLAttributes<HTMLDivElement> = {
        className: classNames('filter-control-toggle btn-default', {
          left: isLeftAligned,
          right: !isLeftAligned,
          active: isControlOpen,
          disabled
        }),
        'aria-label': `${I18n.t('shared.components.filter_bar.parameter')} ${parameter.name}`,
        tabIndex: 0
      };

      if (!disabled) {
        toggleProps.role = 'button';
        toggleProps.onClick = this.toggleControl;
        toggleProps.onKeyDown = this.onKeyDownControl;
        toggleProps.onKeyUp = this.onKeyUpControl;
      }

      return (
        <div {...toggleProps} ref={this.parameterControlToggle}>
          {getParameterHumanText(parameter)}
          <span className="arrow-down-icon">
            <SocrataIcon name={IconName.ArrowDown} aria-hidden={true} />
          </span>
        </div>
      );
    }
  }

  renderParameterControl(): JSX.Element | null {
    const { isControlOpen, isLeftAligned, isTopAligned } = this.state;
    const { parameter } = this.props;

    if (!isControlOpen) {
      return null;
    }

    const valueEditorProps = {
      parameter,
      onUpdate: this.onUpdate
    };

    const spanProps: React.HTMLAttributes<HTMLSpanElement> = {
      className: classNames({
        left: isLeftAligned,
        right: !isLeftAligned,
        top: isTopAligned
      })
    };

    return (
      <span {...spanProps} ref={this.parameterControl}>
        <ValueEditor {...valueEditorProps} />
      </span>
    );
  }

  closeAll(): void {
    this.setState({
      isControlOpen: false
    });
  }

  render(): JSX.Element {
    return (
      <div className="filter-bar-filter parameter-item">
        <label className="filter-control-label">{this.renderDisplayName()}</label>
        <div className="filter-control-container" ref={this.controlContainer}>
          {this.renderParameterControlToggle()}
          {this.renderParameterControl()}
        </div>
      </div>
    );
  }
}

export default ParameterItem;
