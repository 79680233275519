import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import DOMPurify from 'dompurify';

import I18n from 'common/i18n';
import { Modal, ModalHeader, ModalContent } from 'common/components/Modal';
import { SocrataIcon } from 'common/components/SocrataIcon';

import TableVisualization from '../TableVisualization';
import './alert-data-grid-modal.scss';

const scope = 'shared.site_chrome.notifications.alert_data_grid_modal';

const TABS = {
  DATA: 'DATA',
  DETAILS: 'DETAILS'
};

/**
 * Renders the result of the soql query that triggered the alert in a table (grid).
 */
export class AlertDataGridModal extends Component {
  state = { activeTab: TABS.DATA }

  sanitizeHtml = (htmlString) => {
    if (!_.isString(htmlString) || htmlString.length === 0) {
      return htmlString;
    }

    return DOMPurify.sanitize(htmlString, {ADD_TAGS: ['iframe']});
  }

  renderAlertData = () => {
    const { domain, datasetUid, soqlQuery } = this.props;
    const { activeTab } = this.state;

    const isActive = activeTab === TABS.DATA;
    const tableVisProps = { domain, datasetUid, soqlQuery };

    return (
      <div className={classNames({ active: isActive }, 'alert-data-container')}>
        <TableVisualization {...tableVisProps} />
      </div>
    );
  }

  renderDescriptionRow = () => {
    const { description } = this.props;

    if (_.isEmpty(description)) {
      return;
    }

    return (
      <div className="description-section">
        <div>{I18n.t('about_tab.description', { scope })}: </div>
        <div className="content" dangerouslySetInnerHTML={{__html: this.sanitizeHtml(description)}} />
      </div>
    );
  }

  renderAlertDetails = () => {
    const { soqlQuery } = this.props;
    const { activeTab } = this.state;
    const isActive = activeTab === TABS.DETAILS;

    return (
      <div className={classNames({ active: isActive }, 'alert-details-container')}>
        <table className="alert-data-grid-modal-details-table">
          <tbody>
            <tr className="entry">
              <td className="name">{I18n.t('about_tab.query', { scope })}</td>
              <td className="value">{soqlQuery}</td>
            </tr>
            <tr className="entry">
              <td className="name">{I18n.t('about_tab.dataset', { scope })}</td>
              <td className="value">{this.renderDatasetLink()}</td>
            </tr>
            <tr className="entry">
              <td className="name">{I18n.t('about_tab.domain', { scope })}</td>
              <td className="value">{this.renderDomainLink()}</td>
            </tr>
          </tbody>
        </table>
        {this.renderDescriptionRow()}
      </div>
    );
  }

  renderDatasetLink = () => {
    const { datasetName, datasetUid, domain } = this.props;
    const href = `//${domain}/dataset/${datasetUid}`;

    return (<a href={href}>{datasetName}</a>);
  }

  renderDomainLink = () => {
    const { domain } = this.props;
    const href = `//${domain}`;

    return (<a href={href}>{domain}</a>);
  }

  renderSubTitle = () => {
    const { alertName } = this.props;
    const basedOn = I18n.t('based_on', { scope });

    return (
      <div className="sub-title">{alertName} | {basedOn} {this.renderDatasetLink()}</div>
    );
  }

  renderTab = (tabId, name, className) => {
    const { activeTab } = this.state;
    return (
      <div
        className={`${className} ${classNames({ active: activeTab === tabId }, 'tab-name')}`}
        onClick={() => this.setState({ activeTab: tabId })}>
        {name}
      </div>
    );
  }

  renderTabs = () => {
    const { datasetDownloadLink } = this.props;
    const dataTabName = I18n.t('data_tab.name', { scope });
    const detailsTabName = I18n.t('about_tab.name', { scope });

    return (
      <div className="tabs-list">
        {this.renderTab(TABS.DATA, dataTabName, 'alert-data-grid-modal-data-tab')}
        {this.renderTab(TABS.DETAILS, detailsTabName, 'alert-data-grid-modal-details-tab')}

        <div className="actions-pane">
          <a
            className="user-notification-download-icon"
            href={datasetDownloadLink}
            role="button"
            target="_blank" rel="noreferrer">
            <SocrataIcon name="download" />
          </a>
        </div>
      </div>
    );
  }

  render() {
    const { onClose } = this.props;

    const modalTitle = I18n.t('title', { scope });

    return (
      <Modal onDismiss={onClose} className="alert-data-grid-modal">
        <ModalHeader
          title={modalTitle}
          onDismiss={onClose} />

        <ModalContent>
          {this.renderSubTitle()}
          {this.renderTabs()}
          <div className="tab-content">
            {this.renderAlertData()}
            {this.renderAlertDetails()}
          </div>

        </ModalContent>
      </Modal>
    );
  }
}

AlertDataGridModal.propTypes = {
  domain: PropTypes.string.isRequired,
  datasetUid: PropTypes.string.isRequired,
  datasetName: PropTypes.string.isRequired,
  description: PropTypes.string,
  datasetDownloadLink: PropTypes.string.isRequired,
  alertName: PropTypes.string.isRequired, // Name of the alert
  soqlQuery: PropTypes.string.isRequired,
  onClose: PropTypes.func
};

AlertDataGridModal.defaultProps = {
  onClose: _.noop
};

export default AlertDataGridModal;
