import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import I18n from 'common/i18n';
import { canChangeVisibility } from 'common/core';

import { MODES } from 'common/components/AccessManager/Constants';
import { shouldUpdateViewOnPublish, getParentScope } from 'common/components/AccessManager/Util';
import {
  getCurrentScope,
  getCurrentView,
  getShowApprovalMessage,
  getUiMode,
  getVisualizationCanvasHasPublicDataSource
} from 'common/components/AccessManager/sagas/Selectors';

import PrivateButton from './AudienceScopeChooserRadioButton/PrivateButton';
import InternalButton from './AudienceScopeChooserRadioButton/InternalButton';
import PublicButton from './AudienceScopeChooserRadioButton/PublicButton';
import BottomSection from './BottomSection';

import FeatureFlags from 'common/feature_flags';

import './chooser.scss';
import { AudienceScope, View } from 'common/types/view';
import { isEnrolled } from 'common/core/archival';
import { useEffect } from 'react';
import { useState } from 'react';
import Alert, { AlertType } from 'common/components/Alert';

export const classNameScope = 'common--components--AccessManager--components--AudienceScopeChooser--chooser';

const isScopeExpanding = (existingScope: AudienceScope | undefined, proposedScope: AudienceScope | null) => {
  if (!existingScope) return false;
  // not sure yet
  if (proposedScope === null) return false;
  // it's already public. cannot expand further.
  if (existingScope === AudienceScope.Public) return false;

  return (
    // it's internal and we want to make it public
    (existingScope === AudienceScope.Site && proposedScope === AudienceScope.Public) ||
    // it's private and we want to make it internal or public
    (existingScope === AudienceScope.Private &&
      (proposedScope === AudienceScope.Site || proposedScope === AudienceScope.Public))
  );
};

const archivesExist = async (view: Partial<View>) =>
  FeatureFlags.valueOrDefault('enable_asset_archival', false) && (await isEnrolled(view));

const ScopeExpansionWarning = () => (
  <Alert className={`${classNameScope}--warning-message`} type={AlertType.Warning}>
    {I18n.t('shared.asset_browser.result_list_table.action_modal.change_visibility.archive_scope_warning')}
  </Alert>
);

/**
 * This will render buttons to choose the asset's audience,
 * as well as information about approvals and number of users it is shared to
 */
export const AudienceScopeChooser: FunctionComponent = () => {
  const showApprovalMessage = useSelector(getShowApprovalMessage);
  const mode = useSelector(getUiMode);
  const view = useSelector(getCurrentView);
  const visualizationCanvasHasPublicDataSource = useSelector(getVisualizationCanvasHasPublicDataSource);

  const existingScope = view.permissions?.scope;
  const proposedScope = useSelector(getCurrentScope);
  const [hasArchives, setHasArchives] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        setHasArchives(await archivesExist(view));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [view]);
  const scopeExpansionWarning =
    isScopeExpanding(existingScope, proposedScope) && hasArchives ? <ScopeExpansionWarning /> : null;

  const parentScope = getParentScope(view);
  const visibilityChangeAllowed = view && canChangeVisibility(view, parentScope);

  // if the user can't change visibility at all, we render a message instead of the buttons etc.
  if (!visibilityChangeAllowed && parentScope) {
    return (
      <div className={`alert warning ${classNameScope}--chooser--warning-message`}>
        {I18n.t(
          mode === MODES.PUBLISH
            ? `shared.site_chrome.access_manager.audience.legacy_permissions.will_be_${parentScope}`
            : 'shared.asset_browser.result_list_table.action_modal.change_visibility.can_not_change_visibility'
        )}
      </div>
    );
  }

  // if we're in publish mode and there are changes to the current dataset,
  // we need to update the dataset before publishing; show a message saying so
  const shouldRenderDatasetUpdateMessage = mode === MODES.PUBLISH && shouldUpdateViewOnPublish();

  return (
    <div className={`${classNameScope}--chooser`}>
      <div className={`${classNameScope}--chooser-buttons`}>
        <PrivateButton view={view} />
        <InternalButton view={view} />
        <PublicButton
          view={view}
          visualizationCanvasHasPublicDataSource={visualizationCanvasHasPublicDataSource}
        />
      </div>
      {scopeExpansionWarning}
      <BottomSection
        view={view}
        showApprovalMessage={showApprovalMessage}
        visualizationCanvasHasPublicDataSource={visualizationCanvasHasPublicDataSource}
      />
      {shouldRenderDatasetUpdateMessage && (
        <div className={`alert warning ${classNameScope}--warning-message`}>
          {I18n.t('shared.site_chrome.access_manager.unsaved_changes')}
        </div>
      )}
    </div>
  );
};

export default AudienceScopeChooser;
