import React, { FunctionComponent, useRef, useState } from 'react';
import I18n from 'common/i18n';
import { withGuidanceV2 } from 'common/core/approvals/index_new';
import {
  showToastNow,
  showToastOnPageReload,
  ToastType
} from 'common/components/ToastNotification/Toastmaster';
import type { GuidanceSummaryV2 } from 'common/types/approvals';
import { ForgeButton } from '@tylertech/forge-react';
import { WorkflowTargetAudience } from 'common/core/approvals_enums';
import { GuidanceBooleanCheckSummary, GuidanceSummaryV2Helper } from 'common/core/approvals/Types';
import { ForgePopup } from '@tylertech/forge-react';

const toastI18nScope = 'shared.components.asset_action_bar.publication_action';
const buttonI18nScope = `${toastI18nScope}.draft.primary_action_text`;

interface SubmitForApprovalButtonProps {
  approvalsGuidance: GuidanceSummaryV2;
  disabled: boolean;
  disabledReason?: string | JSX.Element;
}

const SubmitForApprovalButton: FunctionComponent<SubmitForApprovalButtonProps> = ({
  approvalsGuidance,
  disabled,
  disabledReason
}: SubmitForApprovalButtonProps) => {
  const targetRef = useRef<any>();
  const [flyoutOpen, setFlyoutOpen] = useState(false);
  const [busy, setBusy] = useState(false);
  const approvalsHelper: GuidanceSummaryV2Helper = withGuidanceV2(approvalsGuidance);
  const willEnterApprovalQueueSummary: GuidanceBooleanCheckSummary =
    approvalsHelper.summarizeWillEnterApprovalQueue();
  const onClick = () => {
    setBusy(true);

    // EN66455 FOLLOW UP: This is the same logic as before, but I don't think it
    // determines which queue we want to submit to the way we actually want.
    // Need to verify in further testing.
    let targetAudience: WorkflowTargetAudience;
    if (willEnterApprovalQueueSummary.length) {
      targetAudience = willEnterApprovalQueueSummary.internal
        ? WorkflowTargetAudience.INTERNAL
        : WorkflowTargetAudience.PUBLIC;
    }

    const redirectTo = willEnterApprovalQueueSummary.length
      ? window.location.pathname
      : `/d/${approvalsHelper.publishedUid()}`;

    const successToastContent = willEnterApprovalQueueSummary.length
      ? I18n.t('submitted_asset_for_approval', { scope: toastI18nScope })
      : I18n.t('shared.site_chrome.access_manager.publish.success_toast');

    // EN66455 FOLLOW UP: Need to verify targetAudience or handle when there isn't one
    approvalsHelper
      // @ts-ignore - Currently targetAudience could be undefined. We want it to error if it is.
      .submitForApproval(targetAudience)
      .then(() => {
        showToastOnPageReload({
          type: ToastType.SUCCESS,
          content: successToastContent
        });
        window.location.href = redirectTo;
      })
      .catch((err: any) => {
        console.error(err);
        showToastNow({
          type: ToastType.ERROR,
          content: I18n.t('unknown_error', { scope: toastI18nScope })
        });
      });
  };

  let title = '';
  if (willEnterApprovalQueueSummary.public) {
    title = I18n.t('updating_public_asset_requires_approval', { scope: buttonI18nScope });
  } else if (willEnterApprovalQueueSummary.internal) {
    title = I18n.t('updating_internal_asset_requires_approval', { scope: buttonI18nScope });
  }

  const buttonTextKey = willEnterApprovalQueueSummary.length ? 'submit_for_approval' : 'previously_published';

  return (
    <div onMouseEnter={() => setFlyoutOpen(true)} onMouseLeave={() => setFlyoutOpen(false)}>
      <ForgeButton type="raised" onClick={onClick}>
        <button
          data-testid="aab-submit-for-approval"
          ref={targetRef}
          type="button"
          disabled={disabled || busy}
          title={title}
        >
          {I18n.t(buttonTextKey, { scope: buttonI18nScope })}
        </button>
      </ForgeButton>
      {disabledReason && (
        <ForgePopup
          targetElementRef={targetRef}
          open={flyoutOpen}
          onDismiss={() => {}}
          options={{ placement: 'bottom-end' }}
        >
          <div className="forge-typography--body2">
            <div className="publish-button-flyout-content">{disabledReason}</div>
          </div>
        </ForgePopup>
      )}
    </div>
  );
};

export default SubmitForApprovalButton;
