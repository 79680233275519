import React, { ReactElement, PropsWithChildren, InputHTMLAttributes, useRef, useEffect } from 'react';
import cx from 'classnames';
import omit from 'lodash/omit';

import SocrataIcon, { IconName } from 'common/components/SocrataIcon';

import { CustomInputProps } from './types';
import RequiredStar from './RequiredStar';

interface CustomCheckboxProps {
  /**
   * Whether or not to hide the label for the checkbox,
   * Useful for rows of checkboxes that may have labels elsewhere
   * NOTE: For accessibility reasons, ALL checkboxes MUST have labels,
   * even if the labels are hidden
   */
  hideLabel?: boolean;

  /** Whether or not the checkbox is actually checked */
  checked: boolean | 'indeterminate';
}

export type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & PropsWithChildren<CustomCheckboxProps & CustomInputProps>;

const renderFakeCheckbox = (
  valid?: boolean,
  disabled?: boolean,
  checked?: true | false | 'indeterminate'
): ReactElement | null => {
  // if the input is invalid, add an error class to change the outline
  const fakeCheckboxClassName = cx('form-component-checkbox-pseudo-checkbox-container', {
    'form-component-checkbox-pseudo-checkbox-error': !valid && !disabled,
    'form-component-checkbox-disabled': disabled

  });

  const fakeCheckboxIcon = ((): ReactElement | null => {
    if (checked === 'indeterminate') {
      return <SocrataIcon name={IconName.IndeterminateStateCheckbox} />;
    } else if (checked === true) {
      return <SocrataIcon name={IconName.Check2} />;
    } else {
      return null;
    }
  })();

  return <span className={fakeCheckboxClassName}>{fakeCheckboxIcon}</span>;
};

/**
 * Generic controlled Checkbox component
 *
 * Renders with a label (and required * if it's required) and handles valid/disabled/etc.
 *
 * All props will be passed to the "real" <input type="checkbox" /> that is rendered.
 *
 * Children will be rendered next to the label. This is useful for i.e. buttons that open modals to show more
 * info about the checkbox.
 */
const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const {
    required,
    checked,
    id,
    errorMessage,
    valid,
    className,
    hideLabel,
    label,
    children,
    disabled
  } = props;

  // we want to also use the forwarded ref in this component
  // this means we have to track is separately, but also forward it
  // the useEffect below will forward this inner ref to the ref that is being passed in
  const innerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // sometimes, this component can be unmounted and then re-mounted
    // and we have to synchronize its indeterminate state
    if (innerRef.current) {
      innerRef.current.indeterminate = (checked as true | false | 'indeterminate') === 'indeterminate';
    }

    if (!ref) {
      return;
    }

    if (typeof ref === 'function') {
      ref(innerRef.current);
    } else {
      // we have to ts-ignore this because TS (rightly) claims that `ref.current` is not settable
      // but... in reality it is and just _shouldn't_ be set :)
      // @ts-ignore
      ref.current = innerRef.current;
    }
  });

  // since we need an id to link the label, we use the name if we're not passed an id
  const inputId = id || `form-checkbox-${name}`;

  // omit any "non-form" props that have been passed in
  // otherwise, pass the props to the input wholesale
  const filteredProps = omit(props, [
    'children',
    'valid',
    'errorMessage',
    'hideLabel',
    'className', // we actually pass this into the root div
    'key', // if rendering an array of inputs
    'defaultChecked', // potentially from the "uncontrolled" version
    'checked' // created above based on actual props
  ]);

  const inputProps: InputHTMLAttributes<HTMLInputElement> = {
    ...filteredProps,
    id: inputId,
    'aria-required': required,

    // if a checkbox is "indeterminate" it (mostly) behaves as if it us unchecked, in that it doesn't
    // get sent along with the form, so we just treat an indeterminate checkbox the same as an unchecked on
    checked: (checked as boolean | 'indeterminate') === 'indeterminate' ? false : checked
  };

  // also update our checkbox state, if we have a ref to it
  // Story time: 'indeterminate' is NOT a valid prop on an 'input' element (in the DOM and in React) and can only be set via javascript
  // We have to manually set the property on the DOM node here in order to sync the real checkbox with our fake checkbox
  if (innerRef.current) {
    innerRef.current.indeterminate = (checked as boolean | 'indeterminate') === 'indeterminate';
  }

  return (
    <div className={className}>
      <input
        className="form-component-checkbox-hidden-checkbox"
        type="checkbox"
        ref={innerRef}
        {...inputProps}
      />
      <div className="form-component-checkbox-label-container">
        <label className="form-component-checkbox-label" htmlFor={inputId}>
          <span className="form-component-checkbox-label-text-container" hidden={hideLabel}>
            {label} <RequiredStar required={required || false} />
          </span>
          {children}
          {renderFakeCheckbox(valid, disabled, checked)}
        </label>
        {!valid && (
          <div className="form-component-checkbox-error-message" hidden={hideLabel}>
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
});

Checkbox.defaultProps = {
  hideLabel: false,
  required: false,
  valid: true,
  disabled: false
};

Checkbox.displayName = 'Checkbox'; // forwardRef changes the name

export default Checkbox;
