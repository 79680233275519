import React, { Component } from 'react';

import I18n from 'common/i18n';

import Keywords from '../Lang/Keywords';
import Functions from '../Lang/Functions';
import ColumnList from './ColumnList';
import ListItem from './ListItem';

import { AccordionContainer, AccordionPane } from '../../Accordion';


/**
 * Shows on the right side of the query editor
 * Lists keywords, columns for the current view, and available SoQL functions
 */
class ActionList extends Component {

  renderKeywords = () =>
    Keywords.map(({ name }) =>
      <ListItem
        key={name}
        iconName="data"
        name={name} />
    )

  renderFunctions = () =>
    Object.keys(Functions).sort().map(name =>
      <ListItem
        key={name}
        iconName="embed"
        name={name}
        type="function"
        hasDocs />
    );

  render() {
    return (
      <div className="query-editor-action-list">
        <AccordionContainer>
          <AccordionPane
            className="query-editor-keyword-list"
            title={I18n.t('shared.query_editor.keywords')}
            isOpen>
              {this.renderKeywords()}
          </AccordionPane>

          <AccordionPane
            className="query-editor-column-list"
            title={I18n.t('shared.query_editor.columns')}
            isOpen>
            <ColumnList />
          </AccordionPane>

          <AccordionPane
            className="query-editor-function-list"
            title={I18n.t('shared.query_editor.functions')}
            isOpen>
              {this.renderFunctions()}
          </AccordionPane>
        </AccordionContainer>
      </div>
    );
  }
}

export default ActionList;
