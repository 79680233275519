import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';

import I18n from 'common/i18n';

import { ForgeIcon } from '@tylertech/forge-react';
import { ForgeButton } from '@tylertech/forge-react';
import { View } from 'common/types/view';

interface ViewOnSourceDomainButtonProps {
  view: View;
}

/**
 * Button to view the asset on its source domain.
 */
class ViewOnSourceDomainButton extends Component<ViewOnSourceDomainButtonProps> {
  render() {
    const { view } = this.props;
    const sourceDomainCName = view.sourceDomainCName;
    if (isEmpty(sourceDomainCName)) {
      return null;
    }

    let windowPathname = window.location.pathname;
    // IE drops leading slash.
    if (windowPathname[0] !== '/') {
      windowPathname = '/' + windowPathname;
    }

    return (
      <ForgeButton type="outlined" className="primary-action-button">
        <a
          data-testid="view-on-source-domain-link"
          target="_blank"
          href={`https://${view.sourceDomainCName}${windowPathname}`}
        >
          <ForgeIcon name="open_in_new"></ForgeIcon>
          {I18n.t('shared.components.asset_action_bar.go_to_source_asset')}
        </a>
      </ForgeButton>
    );
  }
}

export default ViewOnSourceDomainButton;
