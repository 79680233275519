import PropTypes from 'prop-types';
import React, { Component } from 'react';
import I18n from 'common/i18n';
import './index.scss';


// EN-41907
// About 15% of the time, the google preview request returns 204 empty content
// this component will load up to 10 times in an attempt to get contents
class ReloadingIframe extends Component {

  constructor(props) {
    super();
    this.loadAttempts = 0;
    this.bindActions();
  }

  bindActions() {
    this.updateIframeSrc = this.updateIframeSrc.bind(this);
    this.iframeLoaded = this.iframeLoaded.bind(this);
  }

  iframeLoaded() {
    clearInterval(this.iframeTimeoutId);
  }

  updateIframeSrc(loadAttempts) {
    if (loadAttempts < 10 && !!this.refs && !!this.refs.iframe) { // check prevents the interval from messing up unit tests
      this.refs.iframe.src = this.props.iframeSrc;
    } else {
      clearInterval(this.iframeTimeoutId);
    }
  }

  componentDidMount() {
    this.iframeTimeoutId = setInterval(
        () => {
          this.loadAttempts += 1;
          this.updateIframeSrc(this.loadAttempts);
        }, 1000 * 5
    );
  }

  render() {
    const { iframeSrc } = this.props;
    return (
      <iframe onLoad={this.iframeLoaded} ref="iframe" src={iframeSrc} />
    );
  }
}

ReloadingIframe.propTypes = {
  iframeSrc: PropTypes.string.isRequired
};

const PreviewElement = ({ previewUrl, previewType, blobName, blobNotPublic }) => {
  switch (previewType) {
    case 'image':
      return <img src={previewUrl} alt={blobName} />;

    case 'google_viewer':
      if (window.serverConfig.apiLockdown) {
        // This refers to staging_api_lockdown.
        return (
          <div className="alert default">
            {I18n.t('shared.components.blobs.locked_down')}
          </div>
        );
      } else if (blobNotPublic) {
        return (
          <div className="alert default">
            {I18n.t('shared.components.blobs.not_public')}
          </div>
        );
      } else {
        var location = document.location;
        var url = `${location.protocol}//${location.hostname}${previewUrl}`;
        return (
          <ReloadingIframe iframeSrc={`//docs.google.com/gview?url=${url}&embedded=true`} />
        );
      }

    default:
      return null;
  }
};

PreviewElement.propTypes = {
  previewUrl: PropTypes.string.isRequired,
  previewType: PropTypes.string.isRequired,
  blobName: PropTypes.string,
  blobNotPublic: PropTypes.bool
};

export class BlobPreview extends Component {
  render() {
    const { isPreviewable, previewUrl, previewType, blobName } = this.props;
    if (
      !isPreviewable ||
      (previewType !== 'image' && previewType !== 'google_viewer')
    ) {
      return null;
    }

    return (
      <section className="blob-preview">
        <h2>{I18n.t('shared.components.blobs.preview')}</h2>
        <PreviewElement
          previewUrl={previewUrl}
          previewType={previewType}
          blobName={blobName} />
      </section>
    );
  }
}

BlobPreview.propTypes = {
  isPreviewable: PropTypes.bool.isRequired,
  previewUrl: PropTypes.string,
  previewType: PropTypes.string,
  blobName: PropTypes.string
};

export default BlobPreview;
