import { fetchJsonWithDefaultHeaders, fetchWithDefaultHeaders } from 'common/http';

// A Collection of ContractProvisions
export interface ConfigurationContract {
  id?: number;
  key: string; // key used in the URL, like /api/contracts/<key>.json
  name: string; // human-friendly name
  description?: string;
  groups?: string[]; // groups to which this contract belongs
  metadata?: any; // arbitrary JSON
  provisions?: ContractProvision[];
  createdAt: number;
}

// A Provision is basically any specific config (feature flag, module, etc.)
export interface ContractProvision {
  id?: number;
  contractKey: string; // key used in the URL, like /api/contracts/<contractKey>.json
  type: string;
  desiredSetting: string;
  lookup?: string;
  dataJson?: any;
  metadata?: any;
  createdAt?: number;
  created?: TimestampMetadata;
  updated?: TimestampMetadata;
}

interface TimestampMetadata {
  at: number;
  by: any;
}

const BASE_URL = '/api/contracts';

export const getAllContracts = (): Promise<ConfigurationContract[]> => fetchJsonWithDefaultHeaders(BASE_URL);

export const createContract = (contract: ConfigurationContract): Promise<ConfigurationContract> => {
  const options = {
    body: JSON.stringify(contract),
    method: 'POST'
  };

  return fetchJsonWithDefaultHeaders(BASE_URL, options);
};

export const createContractProvision = (contractKey: string, provision: ContractProvision): Promise<ContractProvision> => {
  const apiPath = `${BASE_URL}/${contractKey}/provisions`;
  const options = {
    body: JSON.stringify(provision),
    method: 'POST'
  };

  return fetchJsonWithDefaultHeaders(apiPath, options);
};

export const updateContract = (contractKey: string, contract: ConfigurationContract): Promise<ConfigurationContract> => {
  const apiPath = `${BASE_URL}/${contractKey}.json`;

  const options = {
    body: JSON.stringify(contract),
    method: 'PUT'
  };

  return fetchJsonWithDefaultHeaders(apiPath, options);
};

export const replaceContractProvisions = (contractKey: string, provisions: ContractProvision[]): Promise<ContractProvision> => {
  const apiPath = `${BASE_URL}/${contractKey}/provisions?method=replace`;
  const options = {
    body: JSON.stringify({ provisions }),
    method: 'PUT'
  };

  return fetchWithDefaultHeaders(apiPath, options);
};
