import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import I18n from 'common/i18n';
import { Modal, ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';
import AssetBrowser from 'common/components/AssetBrowser';
import { ASSET_SELECTOR, RENDER_STYLE_CARD } from 'common/components/AssetBrowser/lib/constants';

import './index.scss';

export class AssetSelector extends Component {

  renderModalFooter() {
    if (this.props.modalFooterChildren) {
      return <ModalFooter>{this.props.modalFooterChildren}</ModalFooter>;
    }
  }

  render() {
    const {
      additionalTopbarComponents,
      baseFilters,
      closeOnSelect,
      columns,
      ids,
      showDerivedFromFilter,
      showIdsFilter,
      onClose,
      onAssetSelected,
      openInNewTab,
      parentIds,
      renderInModal,
      renderStyle,
      resultsPerPage,
      showBackButton,
      title,
      version,
      withWrapper
    } = this.props;

    const assetBrowserProps = {
      additionalTopbarComponents,
      assetSelector: true,
      closeOnSelect,
      columns,
      enableAssetInventoryActions: false,
      ids,
      showDerivedFromFilter,
      showIdsFilter,
      initialTab: ASSET_SELECTOR,
      onAssetSelected,
      onClose,
      openInNewTab: openInNewTab,
      pageSize: resultsPerPage,
      parentIds,
      renderStyle,
      selectMode: true,
      showAssetCounts: false,
      showBackButton,
      showFilters: false,
      showHeader: false,
      tabs: {
        [ASSET_SELECTOR]: {
          props: {
            baseFilters: {
              version,
              ...baseFilters
            }
          }
        }
      }
    };

    if (renderInModal) {
      const modalProps = {
        className: 'asset-selector',
        fullScreen: true,
        onDismiss: onClose,
        overlay: true
      };

      // EN-41254: this is a hack to fix scrolling issues
      // on the map layer asset selector on small screens
      if (withWrapper) {
        return (
          <Modal {...modalProps}>
            <div className="modal-container-internal-wrapper">
              <ModalHeader title={title} onDismiss={onClose} />
              <ModalContent>
                <AssetBrowser {...assetBrowserProps} />
              </ModalContent>
              {this.renderModalFooter()}
            </div>
          </Modal>
        );
      }

      return (
        <Modal {...modalProps}>
          <ModalHeader title={title} onDismiss={onClose} />
          <ModalContent>
            <AssetBrowser {...assetBrowserProps} />
          </ModalContent>
          {this.renderModalFooter()}
        </Modal>
      );
    } else {
      return (
        <div className="asset-selector">
          <AssetBrowser {...assetBrowserProps} />
        </div>
      );
    }
  }
}

AssetSelector.propTypes = {
  additionalTopbarComponents: PropTypes.array,
  baseFilters: PropTypes.object,
  closeOnSelect: PropTypes.bool,
  columns: PropTypes.array,
  ids: PropTypes.array,
  modalFooterChildren: PropTypes.node,
  onAssetSelected: PropTypes.func,
  onClose: PropTypes.func,
  openInNewTab: PropTypes.bool,
  parentIds: PropTypes.array,
  renderInModal: PropTypes.bool,
  renderStyle: PropTypes.string,
  resultsPerPage: PropTypes.number,
  showBackButton: PropTypes.bool,
  showDerivedFromFilter: PropTypes.bool,
  showIdsFilter: PropTypes.bool,
  title: PropTypes.string,
  version: PropTypes.string,
  withWrapper: PropTypes.bool
};

AssetSelector.defaultProps = {
  additionalTopbarComponents: [],
  assetSelector: true,
  baseFilters: {},
  closeOnSelect: true,
  ids: [],
  showDerivedFromFilter: false,
  showIdsFilter: false,
  onClose: _.noop,
  onAssetSelected: _.noop,
  parentIds: [],
  openInNewTab: false,
  renderInModal: true,
  renderStyle: RENDER_STYLE_CARD,
  resultsPerPage: 6,
  showBackButton: true,
  title: I18n.t('common.asset_selector.header_title'),
  version: 'published',
  withWrapper: false
};

export default AssetSelector;
