import _ from 'lodash';
import React, { FunctionComponent } from 'react';
import ViewCard, { ViewCardProps } from './ViewCard';
import I18n from 'common/i18n';

const ExternalViewCard: FunctionComponent<ViewCardProps> = function(props) {
  const linkProps = _.defaults({}, props.linkProps, {
    target: '_blank',
    rel: 'nofollow external'
  });

  return (
    <ViewCard
      // @ts-expect-error : `...props` spread below will overwrite this?
      icon="socrata-icon-external-square"

      // @ts-expect-error : `...props` spread below will overwrite this?
      metadataLeft={I18n.t('shared.components.view_card.external_content')}
      {...props}
      linkProps={linkProps}>
      {props.children}
    </ViewCard>
  );
};

export default ExternalViewCard;
