import { MetadataDiff } from 'common/types/revision';
import { pastOrPresentChangeLabel, Tense } from './util';
import React from 'react';
import ChangesWithDetails from './ChangesWithDetails';
import { IconName } from '../SocrataIcon';
import _ from 'lodash';
import { ClientContextVariableCreate } from 'common/types/clientContextVariable';
import formatString from 'common/js_utils/formatString';
const scope = 'shared.components.asset_changes.apply_parameters';
import { fetchTranslation } from 'common/locale';
import './AssetChanges.scss'; // TO DO STYLING
import { ForgeIcon, ForgeList, ForgeListItem, ForgeExpansionPanel, ForgeOpenIcon } from '@tylertech/forge-react';
import { getForgeIconNameForDataType } from 'common/views/dataTypeMetadata';

const t = (k: string) => fetchTranslation(k, scope);
const withTense = pastOrPresentChangeLabel(`${scope}.parameter_changes`);

interface ClientContextVariableOldAndNew {
  old: ClientContextVariableCreate;
  new: ClientContextVariableCreate
}

interface CRUDContextVariables {
  addedVariables: ClientContextVariableCreate[];
  updatedVariables: ClientContextVariableOldAndNew[];
  deletedVariables: ClientContextVariableCreate[];
}

interface ClientContextVariableUpdate {
  field: string,
  old: string,
  new: string
}

function getChanges(step: MetadataDiff, tense: Tense): CRUDContextVariables {

  const contextVariablesCRUD: CRUDContextVariables = {
    addedVariables: [],
    updatedVariables: [],
    deletedVariables: []
  };

  const oldContextVars = step.diff?.old.clientContext?.clientContextVariables || [];
  const newContextVars = step.diff?.new.clientContext?.clientContextVariables || [];

  if (!oldContextVars && !newContextVars) return contextVariablesCRUD;

  // New and updated context variables
  newContextVars.forEach((newVars) => {
    const oldVars = oldContextVars.find((oldVar) => oldVar.name === newVars.name);
    if (!oldVars) {
      contextVariablesCRUD.addedVariables.push(newVars);
    } else if (!_.isEqual(oldVars, newVars)) {
      contextVariablesCRUD.updatedVariables.push({old: oldVars, new: newVars});
    }
  });

  // Deleted context variables
  const newContextVarNames = newContextVars.map(newVar => newVar.name);
  contextVariablesCRUD.deletedVariables = oldContextVars.filter(oldVar => !newContextVarNames.includes(oldVar.name));

  return contextVariablesCRUD;
}

function getDetails(contextVariablesCrud: CRUDContextVariables): string {
  const details = [];
  if (contextVariablesCrud.addedVariables.length) {
    details.push(
      formatString(t(`parameter_additions.${contextVariablesCrud.addedVariables.length > 1 ? 'plural' : 'singular'}`),{
        count: contextVariablesCrud.addedVariables.length
      })
    );
  }
  if (contextVariablesCrud.deletedVariables.length) {
    details.push(
      formatString(t(`parameter_deletions.${contextVariablesCrud.deletedVariables.length > 1 ? 'plural' : 'singular'}`),{
        count: contextVariablesCrud.deletedVariables.length
      })
    );
  }
  if (contextVariablesCrud.updatedVariables.length) {
    details.push(
      formatString(t(`parameter_updates.${contextVariablesCrud.updatedVariables.length > 1 ? 'plural' : 'singular'}`),{
        count: contextVariablesCrud.updatedVariables.length
      })
    );
  }
  return details.join(', ');
}

function createContextUpdates(clientContextVar: ClientContextVariableOldAndNew): ClientContextVariableUpdate[] {
  const updates: ClientContextVariableUpdate[] = [];

  for (const property in clientContextVar.old) {
    if (clientContextVar.old[property] !== clientContextVar.new[property]) {
      updates.push({field: property, old: clientContextVar.old[property], new: clientContextVar.new[property]});
    }
  }
  return updates;
}

function ClientContextInfo({ contextVar }: { contextVar: ClientContextVariableCreate }) {
  return (
    <ForgeListItem className="column-creation">

      <ForgeIcon slot='leading' name={getForgeIconNameForDataType(contextVar.dataType)} />
      <span >
      {formatString(t('parameter_info'),
        {
          displayName: contextVar.displayName,
          fieldName: contextVar.name,
          defaultValue: contextVar.defaultValue
      })}
      </span>
    </ForgeListItem>
  );
}

function ClientContextUpdates({ contextVar, tense }: { contextVar: ClientContextVariableOldAndNew, tense: Tense }) {
  const updates = createContextUpdates(contextVar);
  return (
    <ForgeExpansionPanel className="change-list-panel" open={true}>
      <ForgeListItem slot="header">
        <ForgeIcon slot='leading' name={getForgeIconNameForDataType(contextVar.old.dataType)} />
        <div slot='title'>
          {formatString(t('changes_to_parameter'), { parameterName: contextVar.old.name})}
        </div>
        <ForgeOpenIcon slot="trailing"></ForgeOpenIcon>
      </ForgeListItem>

      <ForgeList dense={true} indented={true} static={true} wrap={true}>
        {updates.map((update, i) => (
          <ForgeListItem key={i}>
            {formatString(t('parameter_update'),
            {
                paramField: update.field,
                oldValue: update.old,
                newValue: update.new
            })}
          </ForgeListItem>
        ))}
      </ForgeList>
    </ForgeExpansionPanel>
  );
}

function getContents(contextVariablesCrud: CRUDContextVariables, tense: Tense) {
  return (
    <div className="column-operations">
      {contextVariablesCrud.addedVariables.length > 0 && <p>{withTense('additions', tense)}</p>}
      <ForgeList className="changes creation-list" dense={true} static={true} wrap={true}>
        {contextVariablesCrud.addedVariables.map((contextVar, i) => {
          return <ClientContextInfo contextVar={contextVar} key={i}/>;
        })}
      </ForgeList>
      {contextVariablesCrud.deletedVariables.length > 0 &&  <p>{withTense('deletions', tense)}</p>}
      <ForgeList className="changes deletion-list" dense={true} static={true} wrap={true}>
        {contextVariablesCrud.deletedVariables.map((contextVar, i) => {
          return <ClientContextInfo contextVar={contextVar} key={i}/>;
        })}
      </ForgeList>
      {contextVariablesCrud.updatedVariables.length > 0 && <p>{withTense('updates', tense)}</p>}
      <ForgeList className="changes change-list"  static={true} wrap={true} >
        {contextVariablesCrud.updatedVariables.map((contextVar, i) => {
          return <ClientContextUpdates contextVar={contextVar} key={i} tense={tense}/>;
        })}
      </ForgeList>
    </div>
  );
}

function hasChanges(contextVariables: CRUDContextVariables): boolean {
  return contextVariables.addedVariables.length > 0 || contextVariables.deletedVariables.length > 0 || contextVariables.updatedVariables.length > 0;
}

function ClientContextVariableChange({ step, tense }: { step: MetadataDiff; tense: Tense } ) {
  const changes = getChanges(step, tense);
  if (!hasChanges(changes)) return null;
  const contents = getContents(changes, tense);

  return (
    <div className="asset-change-step">
      <ChangesWithDetails
        icon={IconName.ColumnInfo}
        name={t('parameter_changes_title')}
        details={getDetails(changes)}
        contents={contents}
      />
    </div>
  );
}

export default ClientContextVariableChange;
