import {
  emptyTableAliases,
  QueryAnalysisFailed, QueryAnalysisSucceeded,
  QueryCompilationFailed, CompilationFailedDetails, CompilationStatus, QueryCompilationSucceeded,
  ViewContext
} from 'common/types/compiler';
import { PhxChannel } from 'common/types/dsmapi';
import {
  AnalyzedAst, BinaryTree, RunnableSoQLRendering, SoQLRendering, SoQLType, soqlRendering, usingNBEName, UnAnalyzedAst,
  TypedSelect
} from 'common/types/soql';
import { extractTableAliasesFromAst } from './binary-tree';
import { option, some } from 'ts-option';

interface PaginationParams {
  page_size: number;
  current_page: number;
}

export interface CompilerResponse {
  rendered: SoQLRendering;
  runnable: RunnableSoQLRendering;
  unanalyzed: BinaryTree<UnAnalyzedAst>;
  analyzed: BinaryTree<AnalyzedAst>;
  views: ViewContext;
  pagination_params: PaginationParams;
}

export interface AnalysisSucceeded {
  analyzed: BinaryTree<TypedSelect>;
  output_schema: {
    name: string;
    type: SoQLType | null;
    is_synthetic: boolean;
  }[];
  soql: SoQLRendering;
  views: ViewContext;
}

export interface AnalysisFailed {
  column: number; // TODO Deprecated.
  line: number; // TODO Deprecated
  reason: string; // TODO Deprecated
  soql_exception: SoQLException; // change to SoQLException?
}

export interface CompilationFailedForText {
  views?: ViewContext;
  reason: string;
  line: number;
  column: number;
  unanalyzed: BinaryTree<UnAnalyzedAst>;
  soql_exception: CompilationFailedDetails;
}
export type CompilationFailedForAST = CompilationFailedForText & {
  rendered: SoQLRendering;
  unanalyzed: BinaryTree<UnAnalyzedAst>;
};

const responseToSucceeded = (res: CompilerResponse): QueryCompilationSucceeded => (
  {
    type: CompilationStatus.Succeeded,
    text: some(soqlRendering.unwrap(res.rendered)),
    unanalyzed: res.unanalyzed,
    analyzed: res.analyzed,
    rendering: res.rendered, // TODO: naming
    runnable: res.runnable,
    tableAliases: extractTableAliasesFromAst(res.analyzed),
    views: res.views,
    currentPage: res.pagination_params.current_page,
    pageSize: res.pagination_params.page_size
  }
);

// TODO: This is a placeholder because I'm not sure how to handle errors in the type system yet.
export interface SoQLException {
  type: string;
  english: string;
  message: string;
  position: {
    column: number;
    row: number;
    text: string;
  }
}

const analysisResponseSucceeded = (res: AnalysisSucceeded): QueryAnalysisSucceeded => {
  return {
    type: CompilationStatus.Succeeded,
    text: soqlRendering.unwrap(res.soql),
    ast: res.analyzed,
    outputSchema: res.output_schema,
    tableAliases: extractTableAliasesFromAst(res.analyzed),
    views: res.views,
  };
};

export const compileAST = (channel: PhxChannel, ast: BinaryTree<UnAnalyzedAst>, pageSize = 100, currentPage = 0, pageable = true, clientContextInfo = {}): Promise<QueryCompilationSucceeded | QueryCompilationFailed> => {
  return new Promise((resolve) => {
    // TODO: when dsmapi no longer requires the ref, delete it here
    // this was an old thing, and we used to pass the ref through dsmapi to match up
    // compilation requests to responses, but we don't need to pass it through dsmapi
    // at all because phoenix handles that. we can pass it through the dispatch(compilationStated())
    // and dispatch(compilationEnded()) calls entirely client side for matching up interleaved
    // compilation requests. This compiler-api stuff doesn't need to care about that at all
    // because we're just exposing a promise based API to the caller.
    // unfortunately at the time of writing, dsmapi still does a pattern match on ref, so once that
    // is out in the wild, we can remove the ref: null bit here
    channel.push('compile_ast', { ast, ref: null, pageable, page_size: pageSize, current_page: currentPage, clientContext: clientContextInfo })
    .receive('ok', async (res: CompilerResponse) => {
      resolve(responseToSucceeded(res));
    })
    .receive('error', (e: CompilationFailedForAST) => {
      const failure: QueryCompilationFailed = {
        views: option(e.views),
        type: CompilationStatus.Failed,
        tableAliases: extractTableAliasesFromAst(ast),
        unanalyzed: some(ast),
        soql_exception: e.soql_exception,
        text: some(soqlRendering.unwrap(e.rendered))
      };
      resolve(failure);
    });
  });
};

export const compileText = (channel: PhxChannel, query: string, pageSize?: number, currentPage?: number, clientContextInfo = {}): Promise<QueryCompilationSucceeded | QueryCompilationFailed> => {
  return new Promise((resolve) => {
    channel.push('compile_text', { text: query, ref: null, pageable: true, page_size: pageSize, current_page: currentPage, clientContext: clientContextInfo })
    .receive('ok', async (res: CompilerResponse) => {
      resolve(responseToSucceeded(res));
    })
    .receive('error', (e: CompilationFailedForText) => {
      const ast = option(e.unanalyzed);
      const failure: QueryCompilationFailed = {
        views: option(e.views),
        type: CompilationStatus.Failed,
        tableAliases: ast.map(a => extractTableAliasesFromAst(a)).getOrElseValue(emptyTableAliases()),
        unanalyzed: ast,
        soql_exception: e.soql_exception,
        text: some(query)
      };
      resolve(failure);
    });
  });
};

export const analyzeAST = (channel: PhxChannel, ast: BinaryTree<UnAnalyzedAst>, clientContextInfo = {}): Promise<QueryAnalysisSucceeded | QueryAnalysisFailed> => {
  return new Promise((resolve) => {
    const message = { ast, ref: null, clientContext: {} }; // FIXME clientContext needs passing
    //console.log('analyzeAST', message);
    channel.push('analyze_ast', message)
    .receive('ok', async (res: AnalysisSucceeded) => {
      resolve(analysisResponseSucceeded(res));
    })
    .receive('error', (e: AnalysisFailed) => {
      const failure: QueryAnalysisFailed = {
        type: CompilationStatus.Failed,
        soql_exception: e.soql_exception,
      };
      resolve(failure);
    });
  });
};

export const analyzeText = (channel: PhxChannel, query: string, clientContextInfo = {}): Promise<QueryAnalysisSucceeded | QueryAnalysisFailed> => {
  return new Promise((resolve) => {
  //const clientContext = { variables: clientContextInfo.variables.map(variable => ({
  //  ...variable,
  //  dataType: usingNBEName(variable.dataType)
  //})) }
  //console.log({ clientContext });
    const message = { text: query, ref: null, clientContext: {} }; // FIXME clientContext needs passing
    //console.log('analyzeText', message);
    channel.push('analyze_text', message)
    .receive('ok', async (res: AnalysisSucceeded) => {
      resolve(analysisResponseSucceeded(res));
    })
    .receive('error', (e: AnalysisFailed) => {
      const failure: QueryAnalysisFailed = {
        type: CompilationStatus.Failed,
        soql_exception: e.soql_exception,
      };
      resolve(failure);
    });
  });
};
