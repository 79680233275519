// Vendor Imports
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import _ from 'lodash';
import React, { Component } from 'react';

// Project Imports
import Dropdown from 'common/components/Dropdown';
import FilterHeader from '../FilterHeader';
import FilterFooter from '../FilterFooter';
import { getDefaultFilterForColumn } from '../filters';
import { ENTER, isolateEventByKeys } from 'common/dom_helpers/keycodes_deprecated';
import I18n from 'common/i18n';
import { getPrecision, roundToPrecision } from 'common/numbers';
import { addPopupListener } from './InputFocus';
import { FilterEditorProps } from '../types';
import { NoopFilter, NumberSoqlFilter, FILTER_FUNCTION } from '../SoqlFilter';
import { PicklistOption } from 'common/components/Picklist';

const requiresRange = (func: string) => _.includes(['rangeInclusive', 'rangeExclusive'], func);

interface NumberFilterState {
  filter: NumberSoqlFilter | NoopFilter;
}

// For some reason this lint check is firing here, even though
// the render method absolutely has a return statement.
// eslint-disable-next-line react/require-render-return
class NumberFilter extends Component<FilterEditorProps, NumberFilterState> {
  numberFilter: HTMLDivElement;
  removePopupListener = () => {};
  popupListenerRemoved = false;

  constructor(props: FilterEditorProps) {
    super(props);

    this.state = this.getInitialState();
  }

  componentDidMount() {
    const { popupRef } = this.props;
    this.removePopupListener = addPopupListener(popupRef, this.numberFilter, () => {
      this.popupListenerRemoved = true;
    });
  }

  componentWillUnmount() {
    const { popupRef } = this.props;
    if (!this.popupListenerRemoved) {
      popupRef?.current?.removeEventListener('forge-popup-position', this.removePopupListener);
    }
  }

  getInitialState = () => {
    const { column, filter } = this.props;
    let newFilter = _.cloneDeep(filter);

    // This function is deprecated. See its entry in VIF.md.
    if (filter.function === 'valueRange') {
      // valueRange pretends to be a rangeInclusive, but it isn't actually (it's half inclusive, half exclusive).
      // However, the UI strongly suggests to the user that it's properly rangeInclusive. So, make that a reality
      // here.
      const step = _.min(_.map([column.rangeMin, column.rangeMax], getPrecision));
      // The result of this computation is what we show the user. It is reasonable
      // for them to assume we actually use the number we display as the end point (which
      // we don't, for valueRange).
      const fudgedEnd = roundToPrecision(_.get(filter, 'arguments.end', column.rangeMax), step);

      newFilter = {
        function: FILTER_FUNCTION.RANGE_INCLUSIVE,
        columnName: filter.columnName,
        arguments: {
          start: _.toString(filter.arguments.start),
          end: _.toString(fudgedEnd)
        }
      };

      if (_.has(this.props.filter.arguments, 'includeNullValues')) {
        newFilter.arguments.includeNullValues = filter.arguments.includeNullValues;
      }
    }

    const state = {
      filter: newFilter as NumberSoqlFilter | NoopFilter
    };

    // If filter is single value, ensure the function is '=' and that the arguments.value is set.
    if (state.filter.singleSelect) {
      _.set(state, 'filter.function', FILTER_FUNCTION.EQUALS);
      _.set(state, 'filter.arguments.includeNullValues', false);

      const didRequireRange = requiresRange(state.filter.function);

      if (didRequireRange) {
        const value = _.get(this.props, 'filter.arguments.start', _.toString(column.rangeMin));
        _.set(state, 'filter.arguments.value', value);
        _.unset(state, 'filter.arguments.start');
        _.unset(state, 'filter.arguments.end');
      } else {
        const value = _.get(this.props, 'filter.arguments.value', _.toString(column.rangeMin));
        _.set(state, 'filter.arguments.value', value);
      }
    }

    return state;
  };

  onDropdownChange = (newValue: PicklistOption) => {
    const { column } = this.props;
    const { filter } = this.state;
    const nowRequiresRange = requiresRange(newValue.value);
    const didRequireRange = requiresRange(this.state.filter.function);
    const newFilter = _.cloneDeep(filter);

    _.set(newFilter, 'function', newValue.value);

    if (nowRequiresRange && didRequireRange) {
      newFilter.arguments = this.state.filter.arguments;
    } else if (nowRequiresRange) {
      const start = _.get(this.state, 'filter.arguments.value', _.toString(column.rangeMin));
      _.set(newFilter, 'arguments.start', start);
      _.set(newFilter, 'arguments.end', _.toString(column.rangeMax));
    } else if (didRequireRange) {
      const value = _.get(this.state, 'filter.arguments.start', _.toString(column.rangeMin));
      _.set(newFilter, 'arguments.value', value);
    } else {
      const value = _.get(this.state, 'filter.arguments.value', _.toString(column.rangeMin));
      _.set(newFilter, 'arguments.value', value);
    }

    let includeNullValues;
    const filterFunction = newFilter.function;
    if (filterFunction === FILTER_FUNCTION.EQUALS) {
      includeNullValues = false;
    } else if (filterFunction === FILTER_FUNCTION.EXCLUDE_NULL) {
      _.set(newFilter, 'arguments', {});
      includeNullValues = false;
    } else {
      includeNullValues = _.get(filter, 'arguments.includeNullValues', true);
    }

    _.set(newFilter, 'arguments.includeNullValues', includeNullValues);

    this.setState({ filter: newFilter });
  };

  onNullCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = _.cloneDeep(this.state);
    _.set(newState, 'filter.arguments.includeNullValues', event.target.checked);
    this.setState(newState);
  };

  shouldDisableApply = () => {
    return (
      _.isEqual(this.state.filter, this.props.filter) || // Filter has not changed // Filter is single select and value is empty
      (_.get(this.props, 'filter.singleSelect') && _.isEmpty(_.get(this.state, 'filter.arguments.value')))
    );
  };

  applyFilter = () => {
    const { onUpdate } = this.props;
    const { filter } = this.state;

    // Swap the start and end if necessary to ensure the range is valid
    const { start, end } = filter.arguments || {};

    // Because the filter arguments are always strings, coerce strings to
    // numbers before doing this comparison. For example: start of '24000'
    // and end of '230000' would have caused these to flip.
    if (_.toNumber(start) > _.toNumber(end)) {
      filter.arguments = {
        start: end,
        end: start
      };
    }

    onUpdate(filter);
  };

  applyOnEnter = (event: React.KeyboardEvent) => {
    isolateEventByKeys(event, [ENTER]);

    if (event.keyCode === ENTER && !this.shouldDisableApply()) {
      this.applyFilter();
    }
  };

  getStepInterval = (): number | undefined => {
    const { rangeMin, rangeMax } = this.props.column;

    return _.min(_.map([rangeMin, rangeMax], getPrecision));
  };

  resetFilter = () => {
    const { column, onUpdate } = this.props;

    const filter = _.cloneDeep(getDefaultFilterForColumn(column, this.props.dataProvider[0].datasetUid)) as
      | NumberSoqlFilter
      | NoopFilter;
    filter.isHidden = _.get(this.props, 'filter.isHidden', false);
    filter.isDrilldown = _.get(this.props, 'filter.isDrilldown', false);
    filter.singleSelect = _.get(this.props, 'filter.singleSelect');
    this.setState({ filter });
    onUpdate(filter);
  };

  isPercentage = () => {
    return _.get(this.props.column, 'format.precisionStyle') === 'percentage';
  };

  formatValue = (value: string) => {
    // value is always a string
    if (!this.isPercentage()) {
      return value;
    }
    const scale = _.get(this.props.column, 'format.precisionStyle.percentScale', '1');
    return new BigNumber(value).mul(100).div(scale).toString();
  };

  unformatValue = (value: string) => {
    // value is always a string
    if (!this.isPercentage()) {
      return value;
    }
    const scale = _.get(this.props.column, 'format.precisionStyle.percentScale', '1');
    return new BigNumber(value).div(100).mul(scale).toString();
  };

  renderRangeInputFields = () => {
    const setArgument = (argument: string, value: string) => {
      const newState = _.cloneDeep(this.state);
      _.set(newState, ['filter', 'arguments', argument], this.unformatValue(value)); // String.
      this.setState(newState);
    };

    const inputProps = {
      className: 'range-input text-input',
      type: 'number',
      step: this.getStepInterval(),
      onKeyUp: this.applyOnEnter
    };

    const { column } = this.props;
    const start = this.formatValue(_.get(this.state, 'filter.arguments.start', column.rangeMin));
    const end = this.formatValue(_.get(this.state, 'filter.arguments.end', column.rangeMax));

    return (
      <div className="range-text-inputs-container input-group">
        <input
          id="start"
          value={start}
          onChange={(event) => {
            setArgument('start', event.target.value);
          }}
          aria-label={I18n.t('shared.components.filter_bar.from')}
          placeholder={I18n.t('shared.components.filter_bar.from')}
          {...inputProps}
        />
        <span className="range-separator">-</span>
        <input
          id="end"
          value={end}
          onChange={(event) => {
            setArgument('end', event.target.value);
          }}
          aria-label={I18n.t('shared.components.filter_bar.to')}
          placeholder={I18n.t('shared.components.filter_bar.to')}
          {...inputProps}
        />
      </div>
    );
  };

  renderSingleInputField = () => {
    const value = _.get(this.state, 'filter.arguments.value', this.props.column.rangeMin);

    const inputProps = {
      'aria-label': I18n.t('shared.components.filter_bar.range_filter.value'),
      className: 'range-input text-input',
      id: 'value',
      onKeyUp: this.applyOnEnter,
      placeholder: I18n.t('shared.components.filter_bar.range_filter.value'),
      step: this.getStepInterval(),
      type: 'number',
      value: this.formatValue(value),
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState(
          _.set(_.cloneDeep(this.state), 'filter.arguments.value', this.unformatValue(event.target.value))
        );
      }
    };

    return (
      <div className="range-text-inputs-container input-group">
        <input {...inputProps} />
      </div>
    );
  };

  shouldRenderInputFields = () => {
    if (_.get(this.state, 'filter.singleSelect', false)) {
      return true;
    }

    const filterFunction = this.state.filter.function;
    if (!filterFunction) {
      return false;
    }
    switch (filterFunction) {
      case FILTER_FUNCTION.NOOP:
      case FILTER_FUNCTION.EXCLUDE_NULL:
        return false;
      default:
        return true;
    }
  };

  renderInputFields = () => {
    if (_.get(this.state, 'filter.singleSelect', false)) {
      return this.renderSingleInputField();
    }

    return requiresRange(this.state.filter.function)
      ? this.renderRangeInputFields()
      : this.renderSingleInputField();
  };

  renderDropdown = () => {
    const operators = 'shared.components.filter_bar.range_filter.operators';
    const props = {
      onSelection: this.onDropdownChange,
      options: [
        {
          title: I18n.t(`${operators}.equal.title`),
          value: '=',
          symbol: I18n.t(`${operators}.equal.symbol`)
        },
        {
          title: I18n.t(`${operators}.not_equal.title`),
          value: '!=',
          symbol: I18n.t(`${operators}.not_equal.symbol`)
        },
        {
          title: I18n.t(`${operators}.less_than.title`),
          value: '<',
          symbol: I18n.t(`${operators}.less_than.symbol`)
        },
        {
          title: I18n.t(`${operators}.greater_than.title`),
          value: '>',
          symbol: I18n.t(`${operators}.greater_than.symbol`)
        },
        {
          title: I18n.t(`${operators}.less_than_or_equal.title`),
          value: '<=',
          symbol: I18n.t(`${operators}.less_than_or_equal.symbol`)
        },
        {
          title: I18n.t(`${operators}.greater_than_or_equal.title`),
          value: '>=',
          symbol: I18n.t(`${operators}.greater_than_or_equal.symbol`)
        },
        {
          title: I18n.t(`${operators}.range_exclusive`),
          value: 'rangeExclusive'
        },
        {
          title: I18n.t(`${operators}.range_inclusive`),
          value: 'rangeInclusive'
        },
        { title: I18n.t(`${operators}.exclude_null`), value: 'excludeNull' }
      ],
      size: 'small',
      value: _.get(this.state, 'filter.function'),
      alwaysCalculateHeight: true
    };
    return <Dropdown {...props} />;
  };

  shouldRenderDropdown = () => {
    return !_.get(this.state, 'filter.singleSelect', false);
  };

  shouldRenderNullValueCheckbox = () => {
    const { filter } = this.state;

    if (filter.singleSelect) {
      return false;
    }
    const filterFunction = filter.function;
    if (!filterFunction) {
      return false;
    }
    switch (filterFunction) {
      case FILTER_FUNCTION.NOOP:
      case FILTER_FUNCTION.EXCLUDE_NULL:
      case FILTER_FUNCTION.EQUALS:
        return false;
      default:
        return true;
    }
  };

  renderNullValueCheckbox = () => {
    const filterFunction = _.get(this.state, 'filter.function');
    const disabled = filterFunction === FILTER_FUNCTION.EQUALS;
    const className = classNames('checkbox', { disabled });

    const checked = _.get(this.state, 'filter.arguments.includeNullValues', true);
    const nullToggleId = _.uniqueId('include-nulls-');
    const inputAttributes = {
      id: nullToggleId,
      className: 'include-nulls-toggle',
      type: 'checkbox',
      onChange: this.onNullCheckboxChange,
      checked,
      disabled
    };

    return (
      <div className={className}>
        <input {...inputAttributes} />
        <label className="inline-label" htmlFor={nullToggleId}>
          <span className="fake-checkbox">
            <span className="icon-checkmark3" />
          </span>
          {I18n.t('shared.components.filter_bar.range_filter.include_null_values')}
        </label>
      </div>
    );
  };

  render = () => {
    const { column, filter, isReadOnly, onRemove } = this.props;
    const headerProps = {
      name: column.name || column.fieldName
    };

    const footerProps = {
      disableApplyFilter: this.shouldDisableApply(),
      isDrilldown: filter.isDrilldown,
      isReadOnly,
      onClickApply: this.applyFilter,
      onClickRemove: onRemove,
      onClickReset: this.resetFilter
    };

    return (
      <div className="filter-controls number-filter" ref={(el: HTMLDivElement) => (this.numberFilter = el)}>
        <div className="range-filter-container">
          <FilterHeader {...headerProps} />
          {this.shouldRenderDropdown() && this.renderDropdown()}
          {this.shouldRenderInputFields() && this.renderInputFields()}
          {this.shouldRenderNullValueCheckbox() && this.renderNullValueCheckbox()}
        </div>
        <FilterFooter {...footerProps} />
      </div>
    );
  };
}

export default NumberFilter;
