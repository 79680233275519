import React, { ReactElement } from 'react';
import { Store, createStore, compose } from 'redux';
import forOwn from 'lodash/forOwn';
import isNil from 'lodash/isNil';

import formReducer from './FormReducer';
import ReduxValidatedInput from './ReduxValidatedInput';
import ReduxValidatedCheckbox from './ReduxValidatedCheckbox';
import { InputState, Inputs, FormState, Validations } from './types';

/**
 * Given a set up inputs, this will generate a default redux state that
 * can be used with the form reducer.
 *
 * This should be set as the "inputs" key at the top level of the redux state.
 *
 * The key in the object must match the name of the input.
 *
 * @example
 * {
 *    firstInput: {
 *       label: I18n.t('my.input.label'),
 *       value: 'Default input value',
 *       required: true // default false
 *       // any other input values are splatted into the input's props
 *    }
 * }
 *
 * @param {array} inputs Definition of inputs
 * @param {boolean} includeRecaptcha Whether or not this form has a recaptcha
 */
export const createDefaultInputsState = (inputs: Inputs, includeRecaptcha = false): Inputs => {
  const inputState: Inputs = {};

  // basically, copy everything from the given inputs making sure we have
  // all the required bits that can't be undefined
  forOwn(
    inputs,
    (input: InputState, name: string): void => {
      inputState[name] = {
        ...input,
        valid: true, // every input starts valid
        errorMessage: '',
        required: isNil(input.required) ? false : input.required
      };

      // checkboxes use "checked" instead of "value"
      if (input.type === 'checkbox') {
        inputState[name].checked = isNil(input.checked) ? false : input.checked;
      } else {
        inputState[name].value = isNil(input.value) ? '' : input.value;
      }
    }
  );

  if (includeRecaptcha) {
    inputState.recaptcha = {
      // since the recaptcha doesn't have a real "value" (it's just valid or invalid)
      // we mark it as required false, which skips checking if the value is empty or not
      // a "null" valid here means the form has not been submitted
      name: 'recaptcha',
      valid: null,
      required: false,
      type: 'recaptcha',
      errorMessage: ''
    };
  }

  return inputState;
};

/**
 * Create a redux store using the default form reducer.
 *
 * Will also hook up to the Redux dev tools extension:
 * https://github.com/zalmoxisus/redux-devtools-extension
 *
 * "validations" should be an object with a map of input name -> validation function
 *
 * The validations are called automatically on blur and when the form is submitted.
 * If any inputs fail validation, the form does not submit.
 *
 * The validation functions must take in the current redux state, and return a changed (or the same) state.
 *
 * @param {object} defaultState State to start with; see createDefaultInputsState
 * @param {object} validations Map of input name -> validation function
 * @param {string} formName Name to give form in the Redux dev tools
 */
export const createStoreWithFormReducer = (
  defaultState: FormState,
  validations: Validations,
  formName = 'Form'
): Store<FormState> => {
  // use the redux devtool's composeEnhancers to keep them around
  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: formName })) ||
    compose;

  // @ts-ignore We don't need formReducer to accept an undefined.
  return createStore(formReducer(validations), defaultState, composeEnhancers());
};

/**
 * Given a list of inputs from the state, this will render a ReduxValidatedInput for each one
 * This makes it easy to store all of the input's props in the Redux store
 *
 * Note that ReduxValidatedInput just splats all of its properties as props of the input,
 * so _any_ prop (even children!) can be put in the store.
 *
 * @param {array} inputs List of inputs in the state
 */
export const renderInputsFromState = (inputs: Inputs): (ReactElement | null)[] =>
  Object.keys(inputs).map(
    (name): ReactElement | null => {
      if (name === 'recaptcha') {
        return null;
      }

      if (inputs[name].type === 'checkbox') {
        return <ReduxValidatedCheckbox name={name} key={name} />;
      }

      return <ReduxValidatedInput name={name} key={name} />;
    }
  );
