import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import I18n from 'common/i18n';
import formatString from 'common/js_utils/formatString';

import createStore from './redux/QueryEditorStore';
import QueryEditorModal from './QueryEditorModal';
import { fetchView, fetchRows, closeQueryEditor } from './redux/QueryEditorActions';

import './index.scss';

/**
 * Query editor wrapped in a Redux provider
 */
class QueryEditor extends Component {
  // if you change these props please also update the README for this component!
  static propTypes = {
    /**
     * View with this uid will be fetched when the modal opens. This may differ from
     * currentView on certain conditions. Refer to README.
     */
    assetUid: PropTypes.string.isRequired,
    /**
     * View (draft or published, derived or default) that is being worked on; inheritance
     * of metadata (name, position, formatting) will come from this view, if possible.
     */
    currentView: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    confirmButtonText: PropTypes.string,
    /**
     * Default query to open with; if nothing is supplied a SELECT with all columns is
     * generated in the QueryEditorReducer.
     */
    query: PropTypes.string
  }

  static defaultProps = {
    confirmButtonText: I18n.t('shared.query_editor.confirm'),
    busy: false
  }

  componentDidMount() {
    // fetch the view as soon as we mount; a spinner will be shown until the view is fetched,
    // or an error shown if it can't be fetched
    this.store.dispatch(fetchView());

    // On first load, the view won't have a query associated with it. However after the user
    // saves the query for the first time with "Update Query", subsequent Query Editor opens
    // will trigger a fetchRows() request. This will either show the  preview table right away
    // if the data can be fetched, OR show the collocation warning message if the current query returns a 404
    // which is the main signal that collocation is ongoing
    if (this.props.query) {
      this.store.dispatch(fetchRows());
    }
  }

  store = createStore(
    {
      editor: {
        columnBaseUid: this.props.currentView.id,
        assetUid: this.props.assetUid,
        query: this.props.query,
        fetchingRows: false,
        successCallbackCalled: false,
        documentation: null
      }
    }
  );

  render() {
    const {
      confirmButtonText,
      currentView,
      onCancel,
      onConfirm
    } = this.props;


    const cancelWrapper = () => {
      const title = formatString(
        I18n.t('shared.query_editor.confirm_close'),
        confirmButtonText
      );
      if (window.confirm(title)) {  // eslint-disable-line no-alert
        onCancel();
        this.store.dispatch(closeQueryEditor());
      }
    };

    const confirmWrapper = (newQuery) => {
      onConfirm(newQuery);
      this.store.dispatch(closeQueryEditor());
    };

    return (
      <Provider store={this.store}>
        <QueryEditorModal
          currentView={currentView}
          confirmButtonText={confirmButtonText}
          onCancel={cancelWrapper}
          onConfirm={confirmWrapper} />
      </Provider>
    );
  }
}

export default QueryEditor;
