import PropTypes from 'prop-types';
import React from 'react';
import Dropdown from 'common/components/Dropdown';
import I18n from 'common/i18n';
import { ColumnFormat } from 'common/types/viewColumn';
import { PicklistOption } from 'common/components/Picklist';
import { CURRENCY_FORMATS } from 'common/authoring_workflow/constants';

const scope = 'shared.dataset_management_ui.format_column';

const numberFormats = [
  {
    value: 'standard',
    title: I18n.t('number_format.standard', { scope })
  },
  {
    value: 'scientific',
    title: I18n.t('number_format.scientific', { scope })
  },
  {
    value: 'percentage',
    title: I18n.t('number_format.percentage', { scope })
  },
  {
    value: 'currency',
    title: I18n.t('number_format.currency', { scope })
  },
  {
    value: 'financial',
    title: I18n.t('number_format.financial', { scope })
  }
];

interface Props {
  onChange: (format: ColumnFormat, baseChange?: ColumnFormat) => void;
  format: ColumnFormat;
  defaultPrecision: string;
}

function PrecisionStyle({ onChange, format, defaultPrecision }: Props) {
  const dropdownProps = {
    onSelection: (e: PicklistOption) => {
      const ps = e.value === 'percentage' ? { percentScale: '1' } : {};
      onChange({ precisionStyle: e.value }, ps);
    },
    value: format.precisionStyle || defaultPrecision,
    options: numberFormats
  };

  let currencyFormatter;
  if (format.precisionStyle === 'currency') {
    const currencyProps = {
      onSelection: (e: PicklistOption) => onChange({ currencyStyle: e.value }),
      value: format.currencyStyle || 'USD',
      options: CURRENCY_FORMATS
    };

    currencyFormatter = (<div>
      <label>Currency Format</label>
      <Dropdown {...currencyProps} />
    </div>);
  }

  return (
    <div>
      <label>{I18n.t('display_format', { scope })}</label>
      <Dropdown {...dropdownProps} />
      {currencyFormatter}
    </div>
  );
}

export default PrecisionStyle;
