import { SoQLCellError } from 'common/components/DatasetTable/cell/TableCell';
import I18n from 'common/i18n';
import {
  FieldIdentifier,
  FieldT,
  MetadataTemplate,
  MetadataFieldComponents,
  MetadataType,
  SelectComponents,
  SelectWithParentComponents,
  MultiSelectComponents,
  MultiSelectWithParentComponents,
  OptionByParent,
  MetadataComponentsWithParent,
  SelectWithMultiSelectParentComponents,
  SelectWithSelectParentComponents
} from 'common/types/metadataTemplate';
import {
  ColumnRef,
  Expr,
  FunCall,
  isBooleanLiteral,
  isColumnRef,
  isColumnEqualIgnoringPosition,
  isExpressionEqualIgnoringPosition,
  isFunCall,
  isLet,
  isNullLiteral,
  isStringLiteral,
  Let,
  SoQLBooleanLiteral,
  SoQLStringLiteral,
  TableQualifier,
  traverseExpr,
  Binding
} from 'common/types/soql';
import _ from 'lodash';
import { none, option, Option, some } from 'ts-option';
const translateBuiltin = (k: string) => I18n.t(k, { scope: 'shared.metadata_template.builtins' });
const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'shared.metadata_template', ...options });

export const cellErrorMessageToString = (errorCell: SoQLCellError): string => {
  const message = errorCell.error.message;
  if (_.isString(message)) return message;
  return message.english;
};

// we're kicking the validation of some fields down the road for the first
// iteration of metadata templates, since they're a little weird
// they either:
// * have custom validation hardcoded in core (category, attribution, email)
// * are json fields (attachments, tags)
export const isMBI1ValidatableBuiltin = (fieldName: string) =>
  !_.includes(
    ['attachments', 'contact_email', 'attribution_link', 'attribution', 'category', 'license_id'],
    fieldName
  );

export const fieldDisplayName = (field: FieldT) => {
  if (field.is_builtin) {
    return translateBuiltin(field.field_name);
  }
  return field.display_name;
};

const groupFieldsByName = (fields: FieldT[], qualifier: TableQualifier): FieldIdentifier[] =>
  _.transform(
    _.groupBy(fields, (f) => f.field_name),
    (result, instances, fieldName) => {
      result.push({ qualifier, fieldName, instances, displayName: instances[0].display_name });
    },
    [] as FieldIdentifier[]
  );

export const getBuiltins = (templates: MetadataTemplate[]) =>
  groupFieldsByName(
    templates.flatMap((template) => template.builtin_fields),
    null
  );

export const getCustomFieldsets = (templates: MetadataTemplate[]) => {
  // templates can share fieldset names
  const customFieldsets = templates.flatMap((template) => template.custom_fields);
  return _.map(
    _.groupBy(customFieldsets, (fs) => fs.fieldset_qualifier),
    (fieldsets, qualifier) => {
      const fieldsetName = fieldsets[0].fieldset_name;
      return {
        fieldsetName,
        qualifier,
        fields: fieldsets.flatMap((fs) => groupFieldsByName(fs.fields, qualifier))
      };
    }
  );
};

export const getField = (
  template: MetadataTemplate,
  name: string,
  qualifier: string | null
): Option<FieldT> =>
  qualifier
    ? option(template.custom_fields.find((fs) => fs.fieldset_qualifier === qualifier)).flatMap((fs) =>
        option(fs.fields.find((f) => f.field_name === name))
      )
    : option(template.builtin_fields.find((b) => b.field_name === name));

const isFunCallOf = (node: Expr | null, funcName: string): boolean =>
  !!node && isFunCall(node) && node.function_name === funcName;

const isCaseFunCall = (node: Expr | null): node is FunCall =>
  !!node && isFunCall(node) && node.function_name === 'case';

const stripRequiredWrapping = (expr: Expr, fieldColumn: ColumnRef): Option<Expr> => {
  if (isLet(expr) && expr.clauses.length === 1 && isCaseFunCall(expr.body)) {
    switch (expr.clauses[0].name) {
      // Textfield, select, multiselect, "select with select parent"
      case 'required_field':
        const body = expr.body;
        /**
         We're looking for exprs that are the generated form of something like:

          case(
            is_empty(required_field)                         -- firstPredicate
            error('some_field cannot be empty'),             -- firstConsequent
            true,                                            -- defaultPredicate
            some_field                                       -- defaultConsequent
            )
          WITH
            required_field = some_expr_which_could_be_very_large

          If an expr looks like this, we consider it required and will do things like
          render the little red asterisk on the input field.
        */
        if (isCaseFunCall(body)) {
          const [firstPredicate, firstConsequent] = body.args.slice(0, 2);
          const [defaultPredicate, defaultConsequent] = body.args.slice(2, 4);

          const isRequired =
            isFunCallOf(firstPredicate, 'is_empty') &&
            isFunCallOf(firstConsequent, 'error') &&
            isBooleanLiteral(defaultPredicate) &&
            defaultPredicate.value === true &&
            isColumnRef(defaultConsequent);

          if (isRequired) {
            return some(expr.clauses[0].expr);
          }
        }
        return none;
      // Special case for a "multiselect with multiselect parent", "multiselect with select parent",
      // and "select with multiselect parent" field. It already has a LET wrapping it, so the
      // "requiredness" aspect needs to take another form, namely by having the empty case return an error.
      case 'valid_options':
        const multiSelectBodyCheckResults = checkMultiSelectWithParentBody(expr, fieldColumn);
        const selectWithMultiSelectParentBodyCheckResults = checkSelectWithMultiSelectParentBody(
          expr,
          fieldColumn
        );

        const isRequiredFieldWithParentBuilderClause =
          (multiSelectBodyCheckResults.isBodyValid && multiSelectBodyCheckResults.isRequired) ||
          (selectWithMultiSelectParentBodyCheckResults.isBodyValid &&
            selectWithMultiSelectParentBodyCheckResults.isRequired);

        if (isRequiredFieldWithParentBuilderClause) {
          const newExpression = _.cloneDeep(expr);
          (newExpression.body as FunCall).args[1] = {
            type: 'null_literal',
            value: null
          };

          return some(newExpression);
        }
        return none;
      default:
        return none;
    }
  }
  return none;
};

export const isRequiredExpr = (expr: Expr, fieldColumn: ColumnRef): boolean =>
  stripRequiredWrapping(expr, fieldColumn).isDefined;

export const isIdentityExpr = (expr: Expr, qualifier: TableQualifier, fieldName: string) =>
  isColumnRef(expr) && expr.value === fieldName;

export const wrapInRequiredness = (displayName: string, expr: Expr, fieldColumn: ColumnRef): Let => {
  // Special case for "multiselect with multiselect parent", "multiselect with select parent",
  // and "select with multiselect parent" fields. They already has a LET wrapping them, so the
  // "requiredness" aspect needs to take another form, namely by having the empty case return an error.
  if (
    isLet(expr) &&
    expr.clauses[0].name === 'valid_options' &&
    (checkMultiSelectWithParentBody(expr, fieldColumn).isBodyValid === true ||
      checkSelectWithMultiSelectParentBody(expr, fieldColumn).isBodyValid === true)
  ) {
    const newExpression = _.cloneDeep(expr);
    (newExpression.body as FunCall).args[1] = {
      type: 'funcall',
      function_name: 'error',
      window: null,
      args: [
        {
          type: 'string_literal',
          value: t('error_messages.field_is_required', {
            name: displayName
          })
        }
      ]
    };
    return newExpression;
  }
  // Every other field type uses a standardized LET wrapper to handle "requiredness".
  return {
    type: 'let',
    body: {
      window: null,
      type: 'funcall',
      function_name: 'case',
      args: [
        {
          window: null,
          type: 'funcall',
          function_name: 'is_empty',
          args: [
            {
              value: 'required_field',
              type: 'column_ref',
              qualifier: null
            }
          ]
        },
        {
          window: null,
          type: 'funcall',
          function_name: 'error',
          args: [
            {
              value: t('error_messages.field_is_required', {
                name: displayName
              }),
              type: 'string_literal'
            }
          ]
        },
        {
          value: true,
          type: 'boolean_literal'
        },
        {
          value: 'required_field',
          type: 'column_ref',
          qualifier: null
        }
      ]
    },
    clauses: [
      {
        type: 'binding',
        qualifier: null,
        name: 'required_field',
        expr: expr
      }
    ]
  };
};

export const unwrapRequiredness = (expr: Expr, fieldColumn: ColumnRef): Expr =>
  stripRequiredWrapping(expr, fieldColumn).getOrElseValue(expr);

export const buildDefaultParentlessSelect = (
  fieldName: string,
  displayName: string,
  qualifier: TableQualifier
): Expr => {
  const defaultOption: Expr[] = [
    {
      window: null,
      type: 'funcall',
      function_name: '#IN',
      args: [
        {
          value: fieldName,
          type: 'column_ref',
          qualifier
        },
        {
          value: '',
          type: 'string_literal'
        }
      ]
    },
    {
      type: 'column_ref',
      qualifier,
      value: fieldName
    }
  ];

  return {
    type: 'funcall',
    function_name: 'case',
    window: null,
    args: [
      {
        type: 'funcall',
        function_name: 'is_empty',
        window: null,
        args: [
          {
            type: 'column_ref',
            value: fieldName,
            qualifier
          }
        ]
      },
      { type: 'null_literal', value: null },
      ...defaultOption,
      { type: 'boolean_literal', value: true },
      {
        type: 'funcall',
        function_name: 'error',
        window: null,
        args: [
          {
            type: 'string_literal',
            value: t('error_messages.must_be_one_of_the_following', {
              name: displayName
            })
          }
        ]
      }
    ]
  };
};

export type FieldWithParentDefaultExpressionBuilder = (
  fieldName: string,
  displayName: string,
  qualifier: TableQualifier,
  parentFieldName: string,
  parentDisplayName: string,
  initialParentValue: string
) => Expr;

export const buildDefaultSelectWithSelectParent: FieldWithParentDefaultExpressionBuilder = (
  fieldName,
  displayName,
  qualifier,
  parentFieldName,
  parentDisplayName,
  initialParentValue
) => {
  return {
    type: 'funcall',
    function_name: 'case',
    window: null,
    args: [
      {
        type: 'funcall',
        function_name: 'is_empty',
        window: null,
        args: [
          {
            type: 'column_ref',
            value: fieldName,
            qualifier
          }
        ]
      },
      { type: 'null_literal', value: null },
      {
        type: 'funcall',
        function_name: 'is_empty',
        window: null,
        args: [
          {
            type: 'column_ref',
            value: parentFieldName,
            qualifier
          }
        ]
      },
      {
        type: 'funcall',
        function_name: 'error',
        window: null,
        args: [
          {
            type: 'string_literal',
            value: t('error_messages.parent_field_does_not_have_a_value_set', {
              name: parentDisplayName
            })
          }
        ]
      },
      {
        window: null,
        type: 'funcall',
        function_name: 'op$AND',
        args: [
          {
            window: null,
            type: 'funcall',
            function_name: 'op$==',
            args: [
              {
                value: parentFieldName,
                type: 'column_ref',
                qualifier
              },
              {
                value: initialParentValue,
                type: 'string_literal'
              }
            ]
          },
          {
            window: null,
            type: 'funcall',
            function_name: '#IN',
            args: [
              {
                value: fieldName,
                type: 'column_ref',
                qualifier
              },
              {
                value: '',
                type: 'string_literal'
              }
            ]
          }
        ]
      },
      {
        type: 'column_ref',
        qualifier,
        value: fieldName
      },
      { type: 'boolean_literal', value: true },
      {
        type: 'funcall',
        function_name: 'error',
        window: null,
        args: [
          {
            type: 'string_literal',
            value: t('error_messages.must_be_one_of_the_following', {
              name: displayName
            })
          }
        ]
      }
    ]
  };
};

export const buildDefaultSelectWithMultiSelectParent: FieldWithParentDefaultExpressionBuilder = (
  fieldName,
  displayName,
  qualifier,
  parentFieldName,
  parentDisplayName,
  initialParentValue
) => {
  const body: FunCall = {
    type: 'funcall',
    function_name: 'case',
    window: null,
    args: [
      {
        type: 'funcall',
        function_name: 'is_empty',
        window: null,
        args: [
          {
            type: 'column_ref',
            value: fieldName,
            qualifier
          }
        ]
      },
      { type: 'null_literal', value: null },
      {
        type: 'funcall',
        function_name: 'is_empty',
        window: null,
        args: [
          {
            type: 'column_ref',
            value: parentFieldName,
            qualifier
          }
        ]
      },
      {
        type: 'funcall',
        function_name: 'error',
        window: null,
        args: [
          {
            type: 'string_literal',
            value: t('error_messages.parent_field_does_not_contain_any_values', {
              name: parentDisplayName
            })
          }
        ]
      },
      {
        type: 'funcall',
        function_name: 'json_array_contains',
        window: null,
        args: [
          {
            type: 'column_ref',
            value: 'valid_options',
            qualifier: null
          },
          {
            type: 'column_ref',
            value: fieldName,
            qualifier
          }
        ]
      },
      {
        type: 'column_ref',
        value: fieldName,
        qualifier
      },
      {
        type: 'boolean_literal',
        value: true
      },
      {
        type: 'funcall',
        function_name: 'error',
        window: null,
        args: [
          {
            type: 'funcall',
            function_name: 'op$||',
            window: null,
            args: [
              {
                type: 'string_literal',
                value: t('error_messages.must_be_one_of_the_following', {
                  name: displayName
                })
              },
              {
                type: 'funcall',
                function_name: 'cast$text',
                window: null,
                args: [
                  {
                    type: 'column_ref',
                    value: 'valid_options',
                    qualifier: null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  };

  const clause: Binding = {
    type: 'binding',
    name: 'valid_options',
    qualifier: null,
    expr: {
      type: 'funcall',
      function_name: 'combine_json_arrays',
      window: null,
      args: [
        {
          type: 'funcall',
          function_name: 'case',
          window: null,
          args: [
            {
              type: 'funcall',
              function_name: 'json_array_contains',
              window: null,
              args: [
                {
                  type: 'column_ref',
                  value: parentFieldName,
                  qualifier
                },
                {
                  type: 'string_literal',
                  value: initialParentValue
                }
              ]
            },
            {
              type: 'funcall',
              function_name: 'cast$json',
              window: null,
              args: [
                {
                  type: 'string_literal',
                  value: '[""]'
                }
              ]
            },
            {
              type: 'boolean_literal',
              value: true
            },
            {
              type: 'funcall',
              function_name: 'cast$json',
              window: null,
              args: [
                {
                  type: 'string_literal',
                  value: '[]'
                }
              ]
            }
          ]
        },
        {
          type: 'funcall',
          function_name: 'cast$json',
          window: null,
          args: [
            {
              type: 'string_literal',
              value: '[]'
            }
          ]
        }
      ]
    }
  };

  return {
    type: 'let',
    body,
    clauses: [clause]
  };
};

export const buildDefaultMultiSelect = (fieldName: string, qualifier: TableQualifier): Expr => {
  return {
    type: 'funcall',
    function_name: 'ensure_json_array_contains_only',
    window: null,
    args: [
      {
        type: 'column_ref',
        value: fieldName,
        qualifier
      },
      {
        type: 'funcall',
        function_name: 'cast$json',
        window: null,
        args: [
          {
            type: 'string_literal',
            value: '[]'
          }
        ]
      }
    ]
  };
};

export const buildDefaultMultiSelectWithParent: FieldWithParentDefaultExpressionBuilder = (
  fieldName,
  displayName,
  qualifier,
  parentFieldName,
  parentDisplayName
) => {
  const body: FunCall = {
    type: 'funcall',
    function_name: 'case',
    window: null,
    args: [
      {
        type: 'funcall',
        function_name: 'is_empty',
        window: null,
        args: [
          {
            type: 'column_ref',
            value: fieldName,
            qualifier
          }
        ]
      },
      { type: 'null_literal', value: null },
      {
        type: 'funcall',
        function_name: 'is_empty',
        window: null,
        args: [
          {
            type: 'column_ref',
            value: parentFieldName,
            qualifier
          }
        ]
      },
      {
        type: 'funcall',
        function_name: 'error',
        window: null,
        args: [
          {
            type: 'string_literal',
            value: t('error_messages.parent_field_does_not_contain_any_values', {
              name: parentDisplayName
            })
          }
        ]
      },
      {
        type: 'funcall',
        function_name: 'is_empty',
        window: null,
        args: [
          {
            type: 'funcall',
            function_name: 'distinct_subtract_arrays',
            window: null,
            args: [
              {
                type: 'column_ref',
                value: fieldName,
                qualifier
              },
              {
                type: 'column_ref',
                value: 'valid_options',
                qualifier: null
              }
            ]
          }
        ]
      },
      {
        type: 'column_ref',
        value: fieldName,
        qualifier
      },
      {
        type: 'boolean_literal',
        value: true
      },
      {
        type: 'funcall',
        function_name: 'error',
        window: null,
        args: [
          {
            type: 'funcall',
            function_name: 'op$||',
            window: null,
            args: [
              {
                type: 'string_literal',
                value: t('error_messages.multi_select_error_message', {
                  name: displayName
                })
              },
              {
                type: 'funcall',
                function_name: 'cast$text',
                window: null,
                args: [
                  {
                    type: 'funcall',
                    function_name: 'distinct_subtract_arrays',
                    window: null,
                    args: [
                      {
                        type: 'column_ref',
                        value: fieldName,
                        qualifier
                      },
                      {
                        type: 'column_ref',
                        value: 'valid_options',
                        qualifier: null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  };

  const clause: Binding = {
    type: 'binding',
    name: 'valid_options',
    qualifier: null,
    expr: {
      type: 'funcall',
      function_name: 'combine_json_arrays',
      window: null,
      args: [
        {
          type: 'funcall',
          function_name: 'cast$json',
          window: null,
          args: [
            {
              type: 'string_literal',
              value: '[]'
            }
          ]
        }
      ]
    }
  };

  return {
    type: 'let',
    body,
    clauses: [clause]
  };
};

export const inPredicateBuilder = (field: ColumnRef, options: string[]): FunCall => ({
  type: 'funcall',
  function_name: '#IN',
  window: null,
  args: [field, ...options.map((opt) => ({ type: 'string_literal', value: opt } as SoQLStringLiteral))]
});

export const equalsPredicateBuilder = (
  qualifier: TableQualifier,
  fieldName: string,
  literal: SoQLStringLiteral
): FunCall => ({
  type: 'funcall',
  function_name: 'op$==',
  window: null,
  args: [{ type: 'column_ref', value: fieldName, qualifier }, literal]
});

export const andPredicateBuilder = (leftSide: FunCall, rightSide: FunCall): FunCall => ({
  type: 'funcall',
  function_name: 'op$AND',
  window: null,
  args: [leftSide, rightSide]
});

export const hasRequiredExprs = (instances: FieldT[], qualifier: TableQualifier) =>
  _.some(instances, (f) =>
    isRequiredExpr(f.parsed_expr, { type: 'column_ref', value: f.field_name, qualifier })
  );

export const isIdentifierPrivate = (identifier: FieldIdentifier): boolean =>
  _.some(identifier.instances, (i) => i.private);

export const isIdentifierRestricted = (identifier: FieldIdentifier): boolean =>
  _.some(identifier.instances, (i) => i.restricted);

export const isColumnRefEqualsStringLiteral = (f: FunCall, cref: ColumnRef) =>
  f.function_name === 'op$==' &&
  f.args.length === 2 &&
  isColumnRef(f.args[0]) &&
  f.args[0].qualifier === cref.qualifier &&
  f.args[0].value === cref.value &&
  isStringLiteral(f.args[1]);

// @fieldset.field IN ('cat', 'dog', 'snake', 'owl')
export const isColumnRefInListOfStringLiterals = (f: FunCall, cref: ColumnRef) =>
  f.function_name === '#IN' &&
  f.args.length > 1 &&
  isColumnRef(f.args[0]) &&
  f.args[0].qualifier === cref.qualifier &&
  f.args[0].value === cref.value &&
  f.args.slice(1).every((arg) => isStringLiteral(arg));

export const isEmptyFunCallOfExpr = (f: FunCall, expr: Expr) =>
  f.function_name === 'is_empty' && f.args.length === 1 && isExpressionEqualIgnoringPosition(f.args[0], expr);

const checkParentField = (
  template: MetadataTemplate,
  parentFieldReference: ColumnRef
): Option<
  [
    parentFieldComponents: MetadataFieldComponents,
    parentDisplayName: string,
    parentType: MetadataType,
    parentLabels: string[]
  ]
> => {
  const fieldset = _.find(
    template.custom_fields,
    ({ fieldset_qualifier }) => fieldset_qualifier === parentFieldReference.qualifier
  );

  if (!fieldset) {
    // If we reached here something is seriously wrong with this field.
    // The fieldset for the parent (which at this point in development is the same as the child) doesn't seem to exist.
    // At this point we should bail.
    return none;
  }

  const parentField = _.find(fieldset.fields, ({ field_name }) => field_name === parentFieldReference.value);

  if (!parentField) {
    // Same as above, if the parent field column reference we pulled out of the child is bad, something is wrong.
    return none;
  }
  return intoMetadataComponents(
    parentFieldReference.qualifier,
    parentField.field_name,
    parentField.parsed_expr,
    parentField.legacy_labels,
    template
  ).match({
    none: () => none,
    some: (metadataFieldComponents) => {
      const parentLabels = (() => {
        if (parentField.legacy_labels.length === metadataFieldComponents.options.length) {
          return parentField.legacy_labels;
        }

        return metadataFieldComponents.options.map((currentOption, optionIndex) => {
          return _.isUndefined(parentField.legacy_labels[optionIndex])
            ? currentOption
            : parentField.legacy_labels[optionIndex];
        });
      })();

      return some([
        metadataFieldComponents,
        parentField.display_name,
        metadataFieldComponents.type,
        parentLabels
      ]);
    }
  });
};

interface CheckParentValueResultsPositive {
  isCheckValid: true;
  columnFromParentSide: ColumnRef;
  parentValue: string;
}

interface CheckParentEqualsValueResultsNegative {
  isCheckValid: false;
}

type CheckParentEqualsValueResults = CheckParentValueResultsPositive | CheckParentEqualsValueResultsNegative;

// Helper that makes sure that the "parent column === 'some string'" side of a parent child predicate is valid,
// and if so returns the parent columnRef from within it
const checkParentEqualsStringValueFunction = (expression: Expr): CheckParentEqualsValueResults => {
  const isValidParentEqualsStringValueFunction =
    isFunCall(expression) &&
    expression.function_name === 'op$==' &&
    expression.args.length == 2 &&
    isColumnRef(expression.args[0]) &&
    isStringLiteral(expression.args[1]);

  if (isValidParentEqualsStringValueFunction) {
    const columnFromParentSide = expression.args[0] as ColumnRef;
    const parentValue = (expression.args[1] as SoQLStringLiteral).value;

    return { isCheckValid: true, columnFromParentSide, parentValue };
  }

  return { isCheckValid: false };
};

const checkParentContainsStringValueFunction = (
  parentValueCheck: Expr,
  parentField: ColumnRef
): CheckParentEqualsValueResults => {
  const isCheckValid =
    isFunCall(parentValueCheck) &&
    parentValueCheck.function_name === 'json_array_contains' &&
    isColumnRef(parentValueCheck.args[0]) &&
    parentValueCheck.args[0].qualifier === parentField.qualifier &&
    parentValueCheck.args[0].value === parentField.value &&
    isStringLiteral(parentValueCheck.args[1]);

  if (!isCheckValid) {
    return { isCheckValid };
  }

  const parentValue = (parentValueCheck.args[1] as SoQLStringLiteral).value;
  const columnFromParentSide = parentValueCheck.args[0] as ColumnRef;

  return { isCheckValid, columnFromParentSide, parentValue };
};

const checkForSelectWithSelectParent = (
  expr: Expr,
  qualifier: TableQualifier,
  fieldName: string,
  legacyLabels: string[],
  template?: MetadataTemplate
): Option<SelectWithSelectParentComponents> => {
  /**
   * Here's an example validation expression for a select with a parent-child relationship
   *  case(
   *    is_empty(@my_cool_fieldset.`child`), null,
   *    is_empty(@dependent_fieldset.`parent`), error("Parent field parent does not have a value set")
   *    @my_cool_fieldset.`parent` == "a" and @my_cool_fieldset.`child` IN ("cat"), @my_cool_fieldset.`child`,
   *    @my_cool_fieldset.`parent` == "c" and @my_cool_fieldset.`child` IN ("kitty"), @my_cool_fieldset.`child`,
   *    TRUE, error("child must be one of cat, kitty")
   *  )
   */

  const childField: ColumnRef = {
    qualifier,
    value: fieldName,
    type: 'column_ref'
  };

  // No expression, no parent
  if (!expr) return none;

  let potentialParent: ColumnRef | undefined;

  // If the expression is wrapped in a required statement, we need to remove it first
  const node = stripRequiredWrapping(expr, childField).getOrElseValue(expr);

  // If the expression isn't a function call at the top level, there's no parent
  if (!isCaseFunCall(node)) {
    return none;
  }

  // Strip off the empty and error conditions, then split the remaining ones into predicates and consequents
  const possibleConditions = node.args.slice(4, -2);

  const predicates = possibleConditions.filter((_expr, i) => i % 2 === 0);
  const consequents = possibleConditions.filter((_expr, i) => i % 2 === 1);
  const [emptyPredicate, emptyConsequent] = node.args.slice(0, 2);
  const [emptyParentPredicate, emptyParentConsequent] = node.args.slice(2, 4);
  const [defaultPredicate, defaultConsequent] = node.args.slice(-2);

  const emptyCaseIsValid =
    isFunCall(emptyPredicate) &&
    isEmptyFunCallOfExpr(emptyPredicate, childField) &&
    isNullLiteral(emptyConsequent);

  const defaultCaseIsError =
    isBooleanLiteral(defaultPredicate) &&
    defaultPredicate.value === true &&
    isFunCall(defaultConsequent) &&
    defaultConsequent.function_name === 'error';

  if (!defaultCaseIsError || !emptyCaseIsValid) return none;

  // In a parent-child expression, each top-level consequent will be a reference to the childField
  // If that's not the case, we can eliminate there being a parent to find in this expression right here.
  const allConsequentsAreValid = _.every(
    consequents,
    (consequent) => isColumnRef(consequent) && isColumnEqualIgnoringPosition(consequent, childField)
  );

  if (!allConsequentsAreValid) {
    return none;
  }

  // This helper function will be used to check each predicate and see if it matches the expected shape,
  // that shape being an "and" function operating on both a string literal check on the parent and a
  // string literal check on the child.
  // In addition, it makes sure each consequent is referencing the same field as the parent.
  const validatePredicate = (predicate: Expr): boolean => {
    // If the predicate is not an "and" function with two args, fail it
    if (!isFunCall(predicate) || predicate.function_name !== 'op$AND' || predicate.args.length !== 2) {
      return false;
    }

    // On the "right/child" side, we should see it checking if child field values in a list of string literals
    const isChildSideValid =
      isFunCall(predicate.args[1]) && isColumnRefInListOfStringLiterals(predicate.args[1], childField);

    if (!isChildSideValid) {
      return false;
    }

    // Next we want to check the "left/parent" side of the "and" function.
    // What we expect to see here is an "equals" function comparing the *same* column def against string literals.
    // However, for the first iteration we won't have a column def to compare against. In this case, we'll just grab
    // whatever column def is in the statement (if it meets the rest of our requirements) and save it to compare
    //  against the rest of the predicates afterwards.
    if (!potentialParent) {
      const parentEqualsResults = checkParentEqualsStringValueFunction(predicate.args[0]);

      if (!parentEqualsResults.isCheckValid) {
        return false;
      }

      const { columnFromParentSide } = parentEqualsResults;

      potentialParent = columnFromParentSide;
      return true;
    } else {
      return (
        isFunCall(predicate.args[0]) && isColumnRefEqualsStringLiteral(predicate.args[0], potentialParent)
      );
    }
  };

  // Check every predicate using the helper function above. If each predicate passes, we know we have our parent!
  if (!_.every(predicates, (predicate) => validatePredicate(predicate))) {
    return none;
  }

  // We've held off on validating that the predicate/consequent which should be checking the parent field has a value
  // until we've hopefully found the parent field. If we haven't, because there are no options yet, then we'll find it now.
  if (!isFunCall(emptyParentPredicate)) return none;
  if (!isFunCall(emptyParentConsequent) || emptyParentConsequent.function_name !== 'error') return none;
  if (potentialParent) {
    if (!isEmptyFunCallOfExpr(emptyParentPredicate, potentialParent)) return none;
  } else {
    if (!isColumnRef(emptyParentPredicate.args[0])) return none;
    potentialParent = emptyParentPredicate.args[0];
  }

  // Generating this up front, though duplicative, lets us rely on labels being always available for each option
  const allOptions = (predicates as FunCall[]).flatMap((andFn) => {
    const equalsFn = andFn.args[0];
    const childInFn = andFn.args[1];
    if (isFunCall(equalsFn) && isFunCall(childInFn)) {
      return childInFn.args.slice(1).flatMap((opt) => (isStringLiteral(opt) ? [opt.value] : []));
    } else {
      return [];
    }
  });

  let paddedLabels = allOptions.map((opt, i) => legacyLabels[i] || opt);
  const optionsByParent = (predicates as FunCall[]).flatMap((andFn) => {
    const parentEqualsFn = andFn.args[0] as FunCall;
    const childInFn = andFn.args[1] as FunCall;
    if (isFunCall(andFn.args[0]) && isFunCall(andFn.args[1])) {
      const parentValue = parentEqualsFn.args[1];
      const options = childInFn.args.slice(1).flatMap((opt) => (isStringLiteral(opt) ? [opt.value] : []));
      const optionCount = options.length;
      const labels = paddedLabels.slice(0, optionCount);
      paddedLabels = _.drop(paddedLabels, optionCount);
      return [
        {
          parentValue: (parentValue as ColumnRef).value,
          options: options,
          labels: labels
        }
      ];
    }
    return [];
  });

  const resultingSelectComponents: SelectWithParentComponents = {
    inputCref: childField,
    staticCases: [emptyPredicate, emptyConsequent, emptyParentPredicate, emptyParentConsequent],
    defaultPredicate: defaultPredicate as SoQLBooleanLiteral,
    defaultConsequent: defaultConsequent as FunCall,
    type: MetadataType.dependentSelectWithSelectParent,
    options: allOptions,
    parentField: potentialParent,
    optionsByParent
  };

  // If the metadata template was passed in,
  // we're doing these actions under the context of editing the template fields themselves.
  // In this case we should go deeper and validate the parent/grab its consequents before continuing.
  if (template) {
    return checkParentField(template, potentialParent).match({
      none: () => none,
      some: ([{ options: parentOptions, type }, parentDisplayName, parentType, parentLabels]) => {
        // TODO: For now, only select fields can be parents
        if (type === MetadataType.select || type === MetadataType.dependentSelectWithSelectParent) {
          // The parent is valid, and we have the list of options/consequents from it.
          // We'll need those when editing this field, so attach them to the case components and return.
          // resultingSelectComponents.parentOptions = options;
          return some({
            ...resultingSelectComponents,
            parentOptions,
            parentDisplayName,
            parentType,
            parentLabels
          });
        }
        return none;
      }
    });
  }

  return some(resultingSelectComponents);
};

const checkForSelectWithMultiSelectParent = (
  expr: Let,
  qualifier: TableQualifier,
  fieldName: string,
  legacyLabels: string[],
  template?: MetadataTemplate
): Option<SelectWithMultiSelectParentComponents> => {
  const childField: ColumnRef = {
    type: 'column_ref',
    value: fieldName,
    qualifier
  };

  const bodyCheckResults = checkSelectWithMultiSelectParentBody(expr, childField);

  if (bodyCheckResults.isBodyValid === false) {
    return none;
  }

  const { staticCases, defaultConsequent, defaultPredicate, parentField } = bodyCheckResults;

  const clauseCheckResults = checkParentValueBuilderClause(
    expr,
    checkParentContainsStringValueFunction,
    parentField
  );

  if (clauseCheckResults.isClauseValid === false) {
    return none;
  }

  const { baseOptionsByParent } = clauseCheckResults;

  return addMetadataFieldWithParentValueBuilderClauseCommonInfo(
    MetadataType.dependentSelectWithMultiSelectParent,
    baseOptionsByParent,
    legacyLabels,
    parentField,
    childField,
    staticCases,
    defaultPredicate,
    defaultConsequent,
    template
  );
};

const checkForSelectWithParent = (
  expr: Expr,
  qualifier: TableQualifier,
  fieldName: string,
  legacyLabels: string[],
  template?: MetadataTemplate
): Option<SelectWithParentComponents> => {
  return checkForSelectWithSelectParent(expr, qualifier, fieldName, legacyLabels, template).match<
    Option<SelectWithParentComponents>
  >({
    none: () => {
      if (isLet(expr)) {
        return checkForSelectWithMultiSelectParent(expr, qualifier, fieldName, legacyLabels, template).match({
          none: () => none,
          some: (selectWithMultiSelectParentComponents) => some(selectWithMultiSelectParentComponents)
        });
      }
      return none;
    },
    some: (selectWithSelectParentComponents) => some(selectWithSelectParentComponents)
  });
};

type PredAndCon = {
  predicates: FunCall[];
  consequents: ColumnRef[];
};

// Transforms v1 style enumeration into v2 style
// turns a series of Functions like: [@field = 'cat', @field = 'dog', @field = 'chinchilla'] into [@field IN ('cat', 'dog', 'chinchilla)]
// the consequent will always be [@field] -- in v1 the consequent was always the string being compared in the equality check.
const v1IntoV2Enumeration = (v1Consequents: SoQLStringLiteral[], cref: ColumnRef): PredAndCon => {
  // if there are no options, we produce no predicate + consequent because 'x IN ()' is invalid, so we'll wait for someone to add an option
  const inPredicate: FunCall[] =
    v1Consequents.length > 0
      ? [
          {
            type: 'funcall',
            function_name: '#IN',
            args: [cref, ...v1Consequents],
            window: null
          }
        ]
      : [];
  const inConsequent: ColumnRef[] = v1Consequents.length > 0 ? [cref] : [];
  return {
    predicates: inPredicate,
    consequents: inConsequent
  };
};

const isValidV1Enumeration = (predicates: Expr[], consequents: Expr[], cref: ColumnRef) => {
  const allPredicatesAreValid = _.every(
    predicates,
    (pred) => isFunCall(pred) && isColumnRefEqualsStringLiteral(pred, cref)
  );
  const allConsequentsAreValid = _.every(consequents, (cons) => isStringLiteral(cons));
  return allPredicatesAreValid && allConsequentsAreValid;
};

const isValidV2Enumeration = (predicates: Expr[], consequents: Expr[], cref: ColumnRef) => {
  const allPredicatesAreValid = _.every(
    predicates,
    (pred) => isFunCall(pred) && isColumnRefInListOfStringLiterals(pred, cref)
  );
  const allConsequentsAreValid = _.every(
    consequents,
    (c) => isColumnRef(c) && isColumnEqualIgnoringPosition(c, cref)
  );
  return allPredicatesAreValid && allConsequentsAreValid;
};

const getValidV2Enumeration = (
  predicates: Expr[],
  consequents: Expr[],
  cref: ColumnRef
): Option<PredAndCon> => {
  if (isValidV1Enumeration(predicates, consequents, cref)) {
    // type casting because the method above checks this
    return some(v1IntoV2Enumeration(consequents as SoQLStringLiteral[], cref));
  } else if (isValidV2Enumeration(predicates, consequents, cref)) {
    return some({ predicates: predicates, consequents: consequents } as PredAndCon);
  } else {
    return none;
  }
};

const checkForParentlessSelect = (
  expr: Expr,
  qualifier: TableQualifier,
  fieldName: string
): Option<SelectComponents> => {
  // it's possible that this expr is something like
  //
  // case(
  //   required_field is null, error('animal is required'),
  //   required_field == '', error('animal is required'),
  //   true, required_field
  // )
  // WITH
  //   required_field = <the case statement we care about>
  //   )
  //
  // which is just a case expr wrapped in a requiredness checker.
  // so we want to strip the required wrapper off and see if theres
  // a case enumeration in there

  const node = stripRequiredWrapping(expr, {
    type: 'column_ref',
    qualifier,
    value: fieldName
  }).getOrElseValue(expr);
  if (!isCaseFunCall(node)) return none;
  const possibleConditions = node.args.slice(2, -2);

  const predicates = possibleConditions.filter((_expr, i) => i % 2 === 0);
  const consequents = possibleConditions.filter((_expr, i) => i % 2 === 1);
  const [emptyPredicate, emptyConsequent] = node.args.slice(0, 2);
  const [defaultPredicate, defaultConsequent] = node.args.slice(-2);
  const cref: ColumnRef = {
    qualifier,
    value: fieldName,
    type: 'column_ref'
  };

  const emptyCaseIsEmpty =
    isFunCall(emptyPredicate) && isEmptyFunCallOfExpr(emptyPredicate, cref) && isNullLiteral(emptyConsequent);
  const defaultCaseIsError =
    isBooleanLiteral(defaultPredicate) &&
    defaultPredicate.value === true &&
    isFunCall(defaultConsequent) &&
    defaultConsequent.function_name === 'error';
  if (!emptyCaseIsEmpty || !defaultCaseIsError) return none;

  // okay, there's two possible shapes the enumeration might be in:
  // version 1, the original:
  // case(
  //  @cool_field_set.`animal` == 'dog', 'dog',
  //  @cool_field_set.`animal` == 'cat', 'cat',
  //  @cool_field_set.`animal` == 'rabbit', 'rabbit',
  //  true, error('animal must be one of dog, cat, rabbit')
  // )

  // version 2, to condense the statement due to character & memory limits:
  // case(
  //   @cool_field_set.`animal` IN ('dog', 'cat', 'rabbit'),  @cool_field_set.`animal`,
  //   true, error('animal must be one of dog, cat, rabbit')
  // )

  // if its version 1, we'll convert it to version 2 so that we only have to handle one shape
  return getValidV2Enumeration(predicates, consequents, cref).match({
    none: () => none,
    some: (predAndCon) => {
      const options = predAndCon.predicates.flatMap((pred) => {
        return pred.args.slice(1).flatMap((x) => (isStringLiteral(x) ? [x.value] : []));
      });
      return some({
        inputCref: cref,
        staticCases: [emptyPredicate, emptyConsequent],
        options: options,
        defaultPredicate: defaultPredicate as SoQLBooleanLiteral,
        defaultConsequent: defaultConsequent as FunCall,
        type: MetadataType.select
      });
    }
  });
};

export const checkForParentChildRelationship = (
  expr: Expr,
  qualifier: TableQualifier,
  fieldName: string,
  legacyLabels: string[],
  template?: MetadataTemplate
): Option<MetadataComponentsWithParent> => {
  return checkForSelectWithParent(expr, qualifier, fieldName, legacyLabels, template).match<
    Option<MetadataComponentsWithParent>
  >({
    some: (selectWithParentComponents) => {
      return some(selectWithParentComponents);
    },
    none: () => {
      return checkForMultiSelectWithParent(expr, qualifier, fieldName, legacyLabels, template).match({
        none: () => none,
        some: (multiSelectWithParentComponents) => some(multiSelectWithParentComponents)
      });
    }
  });
};

export const intoMetadataComponents = (
  qualifier: TableQualifier,
  fieldName: string,
  expr: Expr | null,
  legacyLabels: string[],
  template?: MetadataTemplate
): Option<MetadataFieldComponents> => {
  if (!expr) return none;
  return checkForParentChildRelationship(expr, qualifier, fieldName, legacyLabels, template).match<
    Option<MetadataFieldComponents>
  >({
    some: (enumComponentsWithParent) => some(enumComponentsWithParent),
    none: () => {
      return checkForParentlessSelect(expr, qualifier, fieldName).match<Option<MetadataFieldComponents>>({
        some: (parentlessSelectComponents) => some(parentlessSelectComponents),
        none: () => {
          return intoMultiSelect(qualifier, fieldName, expr).match({
            none: () => none,
            some: (multiSelectComponents) => some(multiSelectComponents)
          });
        }
      });
    }
  });
};

export const deriveEnumerationFromField = (qualifier: string | null, field: FieldT): Option<string[]> =>
  traverseExpr(field.parsed_expr, none as Option<string[]>, (node) =>
    intoMetadataComponents(qualifier, field.field_name, node, field.legacy_labels).map(
      ({ options }) => options
    )
  );

export const deriveEnumerationFromFieldIdentifier = (field: FieldIdentifier): Option<string[]> => {
  return field.instances.reduce((set: Option<string[]>, instance: FieldT) => {
    // Combine this enumeration with all the other ones from other templates
    // that the user is subjecting themselves to.
    // so if they have
    //    template A that requires value be {1,2,3}
    //    template B that requires value be {3,4,5}
    // we'd take the intersection of those and end up with {3}
    return deriveEnumerationFromField(field.qualifier, instance)
      .flatMap((enumeration) =>
        some(
          set.match({
            some: (otherEnumerations) => _.intersection(otherEnumerations, enumeration),
            none: () => enumeration
          })
        )
      )
      .orElseValue(set);
  }, none as Option<string[]>);
};

export const intoMultiSelect = (
  qualifier: TableQualifier,
  fieldName: string,
  expr: Expr | null
): Option<MultiSelectComponents> => {
  if (!expr) return none;

  // Remove the required wrapper if present, then move on to validating the expression
  const baseExpression = stripRequiredWrapping(expr, {
    type: 'column_ref',
    qualifier,
    value: fieldName
  }).getOrElseValue(expr);

  /*
    We're looking for expressions that look like:

      ensure_json_array_contains_only(
        @common_core.`program_code`,
        '["026:001", "026:002", "026:053"]'::json
      )
  */
  if (
    isFunCall(baseExpression) &&
    baseExpression.function_name === 'ensure_json_array_contains_only' &&
    isColumnRef(baseExpression.args[0]) &&
    baseExpression.args[0].qualifier === qualifier &&
    baseExpression.args[0].value === fieldName &&
    isFunCall(baseExpression.args[1]) &&
    baseExpression.args[1].function_name === 'cast$json' &&
    baseExpression.args[1].args[0] &&
    isStringLiteral(baseExpression.args[1].args[0])
  ) {
    try {
      const js: JSON = JSON.parse(baseExpression.args[1].args[0].value);
      if (_.every(js, _.isString)) {
        return some({
          options: js as any as string[],
          optionsByParent: null,
          parentField: null,
          inputCref: baseExpression.args[0],
          type: MetadataType.multiSelect
        });
      }
    } catch {
      return none;
    }
  }

  return none;
};

const addLabelsToMetadataFieldwithParentValueBuilderClause = (
  optionsByParent: OptionByParent[],
  legacyLabels: string[]
): [OptionByParent[], string[]] => {
  // We need to add the labels to all of the optionsByParent objects.
  // Labels are stored as one big array on the field and that array can not match the length of the number of options,
  // so we need to pad it out first.
  const allOptions = optionsByParent.flatMap(({ options }) => options);
  let paddedLabels = allOptions.map((actualOption, index) => legacyLabels[index] || actualOption);

  const optionsByParentWithLabels = optionsByParent.map((optionByParent) => {
    const optionCount = optionByParent.options.length;
    const labels = paddedLabels.slice(0, optionCount);
    paddedLabels = _.drop(paddedLabels, optionCount);

    return {
      ...optionByParent,
      labels
    };
  });

  return [optionsByParentWithLabels, allOptions];
};

type MetadataTypesWithParentValueBuilderClause =
  | MetadataType.dependentMultiSelect
  | MetadataType.dependentSelectWithMultiSelectParent;

type CommonMetadataFieldReturnType<T extends MetadataTypesWithParentValueBuilderClause> =
  T extends MetadataType.dependentMultiSelect
    ? MultiSelectWithParentComponents
    : T extends MetadataType.dependentSelectWithMultiSelectParent
    ? SelectWithMultiSelectParentComponents
    : never;

const addMetadataFieldWithParentValueBuilderClauseCommonInfo = <
  T extends MetadataTypesWithParentValueBuilderClause
>(
  type: T,
  baseOptionsByParent: OptionByParent[],
  legacyLabels: string[],
  potentialParent: ColumnRef,
  childField: ColumnRef,
  staticCases: Expr[],
  defaultPredicate: SoQLBooleanLiteral,
  defaultConsequent: FunCall,
  template?: MetadataTemplate
): Option<CommonMetadataFieldReturnType<T>> => {
  // Last thing to do is to add the labels to all of the optionsByParent objects.
  const [optionsByParent, allOptions] = addLabelsToMetadataFieldwithParentValueBuilderClause(
    baseOptionsByParent,
    legacyLabels
  );

  const resultingMetadataComponents = {
    type,
    optionsByParent,
    parentField: potentialParent,
    inputCref: childField,
    staticCases,
    defaultPredicate,
    defaultConsequent,
    options: allOptions
  } as CommonMetadataFieldReturnType<T>;

  // If we have the template, the caller function's context is likely something to do with editing the template fields.
  // We need to validate the parent field and grab all of its options (not just the ones already used in optionsByParent).
  if (template) {
    return checkParentField(template, potentialParent).match({
      none: () => none,
      some: ([{ options: parentOptions }, parentDisplayName, parentType, parentLabels]) => {
        return some({
          ...resultingMetadataComponents,
          parentOptions,
          parentDisplayName,
          parentType,
          parentLabels
        });
      }
    });
  }
  return some(resultingMetadataComponents);
};

interface MultiSelectBodyCheckResultsPositive {
  isBodyValid: true;
  staticCases: Expr[];
  defaultPredicate: SoQLBooleanLiteral;
  defaultConsequent: FunCall;
  parentField: ColumnRef;
  isRequired: boolean;
}

interface MultiSelectBodyCheckResultsNegative {
  isBodyValid: false;
}

type MultiSelectBodyCheckResults = MultiSelectBodyCheckResultsNegative | MultiSelectBodyCheckResultsPositive;

const checkMultiSelectWithParentBody = (expr: Let, childField: ColumnRef): MultiSelectBodyCheckResults => {
  // A multiselect with a parent will consist of a let with a clause that builds a JSON array of all possible
  // values for the child, and a body that consists of an empty case that returns null when the child is empty,
  // an error case for when the parent is empty, a "distinct_subtract_arrays" function wrapped in an "is_empty" function
  // that returns the field as its consequent if the values in the field are only those allowed by the array made in the
  // clause, and finally an error case that uses "distinct_subtract_arrays" again to print out any invalid values found.
  // We'll start by checking the body.
  if (!isCaseFunCall(expr.body) || expr.body.args.length !== 8) {
    // The case function in the body should have 8 args
    return { isBodyValid: false };
  }

  const [emptyPredicate, emptyConsequent] = expr.body.args.slice(0, 2);
  const [emptyParentPredicate, emptyParentConsequent] = expr.body.args.slice(2, 4);
  const [checkValidOptionsPredicate, checkValidOptionsConsequent] = expr.body.args.slice(4, 6);
  const [defaultPredicate, defaultConsequent] = expr.body.args.slice(-2);

  const isEmptyPredicateValid = isFunCall(emptyPredicate) && isEmptyFunCallOfExpr(emptyPredicate, childField);

  let isRequired: boolean;
  let isEmptyConsequentValid: boolean;

  if (isNullLiteral(emptyConsequent)) {
    isRequired = false;
    isEmptyConsequentValid = true;
  } else if (isFunCall(emptyConsequent) && emptyConsequent.function_name === 'error') {
    isRequired = true;
    isEmptyConsequentValid = true;
  } else {
    isRequired = false;
    isEmptyConsequentValid = false;
  }

  const emptyCaseIsValid = isEmptyPredicateValid && isEmptyConsequentValid;

  const emptyParentCaseIsValid =
    isFunCall(emptyParentPredicate) &&
    emptyParentPredicate.function_name === 'is_empty' &&
    isFunCall(emptyParentConsequent) &&
    emptyParentConsequent.function_name === 'error' &&
    isStringLiteral(emptyParentConsequent.args[0]);

  const checkValidOptionsCaseIsValid =
    isFunCall(checkValidOptionsPredicate) &&
    checkValidOptionsPredicate.function_name === 'is_empty' &&
    isFunCall(checkValidOptionsPredicate.args[0]) &&
    checkValidOptionsPredicate.args[0].function_name === 'distinct_subtract_arrays' &&
    isColumnRef(checkValidOptionsPredicate.args[0].args[0]) &&
    isColumnEqualIgnoringPosition(checkValidOptionsPredicate.args[0].args[0], childField) &&
    isColumnRef(checkValidOptionsPredicate.args[0].args[1]) &&
    isColumnEqualIgnoringPosition(checkValidOptionsPredicate.args[0].args[1], {
      type: 'column_ref',
      value: 'valid_options',
      qualifier: null
    }) &&
    isColumnRef(checkValidOptionsConsequent) &&
    isColumnEqualIgnoringPosition(checkValidOptionsConsequent, childField);

  let checkDefaultCaseIsValid = false;
  // The checkDefaultCaseIsValid logic is nasty, so I'm breaking it up into chunks here.
  // Still looks nasty, but the breaks should make it easier to follow compared to a giant "&&"" chain.
  // To start, we're looking for an error function with an arg of a "||" function.
  if (
    isFunCall(defaultConsequent) &&
    defaultConsequent.function_name === 'error' &&
    isFunCall(defaultConsequent.args[0]) &&
    defaultConsequent.args[0].function_name === 'op$||' &&
    defaultConsequent.args[0].args.length === 2
  ) {
    const [concatLeft, concatRight] = defaultConsequent.args[0].args;

    // Next we expect the left argument to be a string literal and the right to be a "cast$text" function.
    if (concatLeft && isFunCall(concatRight) && concatRight.function_name === 'cast$text') {
      // The "cast$text" function should have one arg, and that arg should be a "distinct_subtract_arrays" function.
      const distinctSubtractArraysFunction = concatRight.args[0];

      if (
        isFunCall(distinctSubtractArraysFunction) &&
        distinctSubtractArraysFunction.function_name === 'distinct_subtract_arrays' &&
        distinctSubtractArraysFunction.args.length === 2
      ) {
        // The only remaining thing is checking the two args of the "distinct_subtract_arrays" function.
        // The left should be the child field and the right should be our "valid_options" array from the clause.
        const [leftColumnRef, rightColumnRef] = distinctSubtractArraysFunction.args;

        if (
          isColumnRef(leftColumnRef) &&
          isColumnRef(rightColumnRef) &&
          isColumnEqualIgnoringPosition(leftColumnRef, childField) &&
          isColumnEqualIgnoringPosition(rightColumnRef, {
            type: 'column_ref',
            value: 'valid_options',
            qualifier: null
          })
        ) {
          // The consequent looks good!
          // But we still need to make sure the predicate is correct.
          // It should be a boolean literal with a value of true.
          if (isBooleanLiteral(defaultPredicate) && defaultPredicate.value === true) {
            // Finally, we can say that the default case is valid.
            checkDefaultCaseIsValid = true;
          }
        }
      }
    }
  }

  if (
    !emptyCaseIsValid ||
    !emptyParentCaseIsValid ||
    !checkValidOptionsCaseIsValid ||
    !checkDefaultCaseIsValid
  ) {
    return { isBodyValid: false };
  }

  // Whatever column is referenced as an arg in emptyParentConsequent's "is_empty" function *should* be our parent.
  if (!isColumnRef(emptyParentPredicate.args[0])) {
    return { isBodyValid: false };
  }

  // We've verified the body and it's valid! Return the results.
  const parentField = emptyParentPredicate.args[0];
  const staticCases = [
    emptyPredicate,
    emptyConsequent,
    emptyParentPredicate,
    emptyParentConsequent,
    checkValidOptionsPredicate,
    checkValidOptionsConsequent
  ];

  return {
    isBodyValid: true,
    staticCases,
    defaultPredicate: defaultPredicate as SoQLBooleanLiteral,
    defaultConsequent: defaultConsequent as FunCall,
    parentField,
    isRequired
  };
};

const checkSelectWithMultiSelectParentBody = (
  expr: Let,
  childField: ColumnRef
  // The child isn't a multiselect, but the overall shape is very similar, so we'll reuse the return type
): MultiSelectBodyCheckResults => {
  if (!isCaseFunCall(expr.body) || expr.body.args.length !== 8) {
    return { isBodyValid: false };
  }

  const [emptyPredicate, emptyConsequent] = expr.body.args.slice(0, 2);
  const [emptyParentPredicate, emptyParentConsequent] = expr.body.args.slice(2, 4);
  const [checkValidOptionsPredicate, checkValidOptionsConsequent] = expr.body.args.slice(4, 6);
  const [defaultPredicate, defaultConsequent] = expr.body.args.slice(-2);

  const isEmptyPredicateValid = isFunCall(emptyPredicate) && isEmptyFunCallOfExpr(emptyPredicate, childField);

  let isRequired = false;
  let isEmptyConsequentValid = false;

  if (isNullLiteral(emptyConsequent)) {
    isEmptyConsequentValid = true;
  } else if (isFunCall(emptyConsequent) && emptyConsequent.function_name === 'error') {
    isRequired = true;
    isEmptyConsequentValid = true;
  }

  const emptyCaseIsValid = isEmptyPredicateValid && isEmptyConsequentValid;

  const emptyParentCaseIsValid =
    isFunCall(emptyParentPredicate) &&
    emptyParentPredicate.function_name === 'is_empty' &&
    isColumnRef(emptyParentPredicate.args[0]) &&
    isFunCall(emptyParentConsequent) &&
    emptyParentConsequent.function_name === 'error' &&
    isStringLiteral(emptyParentConsequent.args[0]);

  const checkValidOptionsCaseIsValid =
    isFunCall(checkValidOptionsPredicate) &&
    checkValidOptionsPredicate.function_name === 'json_array_contains' &&
    isColumnRef(checkValidOptionsPredicate.args[0]) &&
    isColumnRef(checkValidOptionsPredicate.args[1]) &&
    isColumnEqualIgnoringPosition(checkValidOptionsPredicate.args[0], {
      type: 'column_ref',
      value: 'valid_options',
      qualifier: null
    }) &&
    isColumnEqualIgnoringPosition(checkValidOptionsPredicate.args[1], childField) &&
    isColumnRef(checkValidOptionsConsequent) &&
    isColumnEqualIgnoringPosition(checkValidOptionsConsequent, childField);

  const checkDefaultCaseValid =
    isBooleanLiteral(defaultPredicate) &&
    defaultPredicate.value === true &&
    isFunCall(defaultConsequent) &&
    defaultConsequent.function_name === 'error' &&
    isFunCall(defaultConsequent.args[0]) &&
    defaultConsequent.args[0].function_name === 'op$||' &&
    defaultConsequent.args[0].args.length === 2 &&
    isStringLiteral(defaultConsequent.args[0].args[0]) &&
    isFunCall(defaultConsequent.args[0].args[1]) &&
    defaultConsequent.args[0].args[1].function_name === 'cast$text' &&
    isColumnRef(defaultConsequent.args[0].args[1].args[0]) &&
    isColumnEqualIgnoringPosition(defaultConsequent.args[0].args[1].args[0], {
      type: 'column_ref',
      value: 'valid_options',
      qualifier: null
    });

  if (
    !emptyCaseIsValid ||
    !emptyParentCaseIsValid ||
    !checkValidOptionsCaseIsValid ||
    !checkDefaultCaseValid
  ) {
    return { isBodyValid: false };
  }

  const parentField = (emptyParentPredicate as FunCall).args[0] as ColumnRef;
  const staticCases = [
    emptyPredicate,
    emptyConsequent,
    emptyParentPredicate,
    emptyParentConsequent,
    checkValidOptionsPredicate,
    checkValidOptionsConsequent
  ];

  return {
    isBodyValid: true,
    staticCases,
    defaultPredicate: defaultPredicate as SoQLBooleanLiteral,
    defaultConsequent: defaultConsequent as FunCall,
    parentField,
    isRequired
  };
};

interface ParentValueBuilderClauseCheckResultsPositive {
  isClauseValid: true;
  baseOptionsByParent: OptionByParent[];
}

interface ParentValueBuilderClauseCheckResultsNegative {
  isClauseValid: false;
}

type ParentValueBuilderClauseCheckResults =
  | ParentValueBuilderClauseCheckResultsNegative
  | ParentValueBuilderClauseCheckResultsPositive;

const checkParentValueBuilderClause = (
  expr: Let,
  validateParentValueCheck: (parentValueCheck: Expr, parentField: ColumnRef) => CheckParentEqualsValueResults,
  parentField: ColumnRef
): ParentValueBuilderClauseCheckResults => {
  const baseOptionsByParent: Array<OptionByParent> = [];

  // Next we need to check the clause that builds "valid_options". It should consist of a "combine_json_arrays" function
  // with a list of arguments made up of a case function for each potential valid parent option.
  // First step is to make sure that clause exists.
  if (
    expr.clauses.length !== 1 ||
    expr.clauses[0].type !== 'binding' ||
    expr.clauses[0].name !== 'valid_options'
  ) {
    return { isClauseValid: false };
  }

  const clauseExpression = expr.clauses[0].expr;

  // Next let's make sure that the expression in the clause is an instance of the "combine_json_arrays" function.
  if (!isFunCall(clauseExpression) || clauseExpression.function_name !== 'combine_json_arrays') {
    return { isClauseValid: false };
  }

  /**
   * Each case function will have two predicate/consquent pairs, with the first one checking to see the parent option
   * is in the parent value via "json_array_contains" and returning a list of valid child field options if so, and the
   * second pair being a default case that just returns an empty array. Whichever is returned will be concatinated into
   * the 'valid_options' array by "combine_json_arrays".
   * However, to make sure that "combine_json_arrays" is never without at least one argument, there should be one
   * "default" value in there alongside the case functions, that being just an empty array in a "cast$json" function,
   * so we need to account for that, too.
   **/
  const checkValidOptionArrayBuilderSubExpression = (validOptionArrayBuilderSubExpression: Expr): boolean => {
    if (!isFunCall(validOptionArrayBuilderSubExpression)) {
      return false;
    }

    // First check if the sub expression is the default case. The default case can just be ignored.
    const isDefaultEmptyArrayOption =
      validOptionArrayBuilderSubExpression.function_name === 'cast$json' &&
      isStringLiteral(validOptionArrayBuilderSubExpression.args[0]) &&
      validOptionArrayBuilderSubExpression.args[0].value === '[]';

    if (isDefaultEmptyArrayOption) {
      return true;
    }

    // Otherwise, we need to verify if this is an actual case function mapping a parent value to values for the child.
    // Obviously if it's not a case function, we can rule that out. Additionally, it should have exactly 4 args.
    if (
      validOptionArrayBuilderSubExpression.function_name !== 'case' ||
      validOptionArrayBuilderSubExpression.args.length !== 4
    ) {
      return false;
    }

    /**
     * As mentioned above, there should be 4 args.
     * parentValueCheck: a "json_array_contains" function that checks if a string-literal/option is in the parent
     * validChildOptionsForParentValue: an array of values that child field can have if the above is true
     * noMatchPredicate: A boolean literal set to true that catches the case where the parent doesn't contain this cases value
     * noMatchConsequent: An empty array, gives "combine_json_arrays" something to combine even if parentValueCheck isn't true
     **/
    const [parentValueCheck, validChildOptionsForParentValue, noMatchPredicate, noMatchConsequent] =
      validOptionArrayBuilderSubExpression.args;

    // While validating the parentValueCheck function, we'll also double check the parent field matches the one we saw
    // in emptyParentConsequent up above.
    const parentValueCheckResults = validateParentValueCheck(parentValueCheck, parentField);

    const { isCheckValid: isParentValueCheckValid } = parentValueCheckResults;

    const isValidChildOptionsForParentValueValid =
      isFunCall(validChildOptionsForParentValue) &&
      validChildOptionsForParentValue.function_name === 'cast$json' &&
      isStringLiteral(validChildOptionsForParentValue.args[0]);

    const isNoMatchPredicateValid = isBooleanLiteral(noMatchPredicate) && noMatchPredicate.value === true;

    const isNoMatchConsequentValid =
      isFunCall(noMatchConsequent) &&
      noMatchConsequent.function_name === 'cast$json' &&
      isStringLiteral(noMatchConsequent.args[0]) &&
      noMatchConsequent.args[0].value === '[]';

    if (
      !isParentValueCheckValid ||
      !isValidChildOptionsForParentValueValid ||
      !isNoMatchPredicateValid ||
      !isNoMatchConsequentValid
    ) {
      return false;
    }

    // Now that we know this is a valid sub expression, we can pull the parent value and matching child values out of it
    // and use them to build an "optionsByParent" object to represent the grouping in the UI.
    const { parentValue } = parentValueCheckResults;

    try {
      const options = JSON.parse((validChildOptionsForParentValue.args[0] as SoQLStringLiteral).value);
      baseOptionsByParent.push({
        parentValue,
        options,
        labels: []
      });
      return true;
    } catch {
      // Looks like the child values string wasn't actually JSON, which means the field is invalid.
      return false;
    }
  };

  // Using the function above, validate each potential group of options
  if (
    !_.every(clauseExpression.args, (validOptionArrayBuilderSubExpression) =>
      checkValidOptionArrayBuilderSubExpression(validOptionArrayBuilderSubExpression)
    )
  ) {
    return { isClauseValid: false };
  }

  // If we reached this point, the clause is valid!
  return { isClauseValid: true, baseOptionsByParent };
};

const checkForMultiSelectWithSelectParent = (
  expr: Let,
  childField: ColumnRef,
  legacyLabels: string[],
  template?: MetadataTemplate
): Option<MultiSelectWithParentComponents> => {
  const bodyCheckResults = checkMultiSelectWithParentBody(expr, childField);

  if (bodyCheckResults.isBodyValid === false) {
    return none;
  }

  const { staticCases, defaultConsequent, defaultPredicate, parentField } = bodyCheckResults;

  const clauseCheckResults = checkParentValueBuilderClause(
    expr,
    checkParentEqualsStringValueFunction,
    parentField
  );

  if (clauseCheckResults.isClauseValid === false) {
    return none;
  }

  const { baseOptionsByParent } = clauseCheckResults;

  return addMetadataFieldWithParentValueBuilderClauseCommonInfo(
    MetadataType.dependentMultiSelect,
    baseOptionsByParent,
    legacyLabels,
    parentField,
    childField,
    staticCases,
    defaultPredicate,
    defaultConsequent,
    template
  );
};

const checkForMultiSelectWithMultiSelectParent = (
  expr: Let,
  childField: ColumnRef,
  legacyLabels: string[],
  template?: MetadataTemplate
): Option<MultiSelectWithParentComponents> => {
  const bodyCheckResults = checkMultiSelectWithParentBody(expr, childField);

  if (bodyCheckResults.isBodyValid === false) {
    return none;
  }

  const { staticCases, defaultPredicate, defaultConsequent, parentField } = bodyCheckResults;

  const clauseCheckResults = checkParentValueBuilderClause(
    expr,
    checkParentContainsStringValueFunction,
    parentField
  );

  if (clauseCheckResults.isClauseValid === false) {
    return none;
  }

  const { baseOptionsByParent } = clauseCheckResults;

  return addMetadataFieldWithParentValueBuilderClauseCommonInfo(
    MetadataType.dependentMultiSelect,
    baseOptionsByParent,
    legacyLabels,
    parentField,
    childField,
    staticCases,
    defaultPredicate as SoQLBooleanLiteral,
    defaultConsequent as FunCall,
    template
  );
};

const checkForMultiSelectWithParent = (
  expr: Expr,
  qualifier: TableQualifier,
  fieldName: string,
  legacyLabels: string[],
  template?: MetadataTemplate
): Option<MultiSelectWithParentComponents> => {
  if (!expr || !isLet(expr)) return none;

  const childField: ColumnRef = {
    qualifier,
    value: fieldName,
    type: 'column_ref'
  };

  // Annoyingly, multiselect fields with a parent child child relationship can have two very different
  // shapes based on the type of the parent field. We have to check for both shapes in here.
  return checkForMultiSelectWithSelectParent(expr, childField, legacyLabels, template).match({
    none: () => {
      return checkForMultiSelectWithMultiSelectParent(expr, childField, legacyLabels, template).match({
        none: () => none,
        some: (multiSelectWithParentComponents) => some(multiSelectWithParentComponents)
      });
    },
    some: (multiSelectWithParentComponents) => some(multiSelectWithParentComponents)
  });
};
