import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import I18n from 'common/i18n';
import { ENTER, ESCAPE, SPACE, isolateEventByKeys } from 'common/dom_helpers/keycodes_deprecated';
import { getCharmSvgSrc, getAvailableCharms } from 'common/resources/charms';
import { DEFAULT_CHARMS_COUNT, NULL_CHARM_NAME } from 'common/authoring_workflow/constants';

import './charmBuckets.scss';

export class CharmBuckets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredCharms: this.getCharms(),
      searchTerm: this.props.selectedCharmName
    };
  }

  getCharms = (charms = getAvailableCharms()) => {
    return _.take(charms, DEFAULT_CHARMS_COUNT);
  }

  onChangeSearchTerm = (e) => {
    const charmSearchTerm = e.target.value;
    if (!_.isEmpty(charmSearchTerm)) {
      const filteredCharms = _.filter(getAvailableCharms(), (charmName) =>
        _.includes(charmName, charmSearchTerm)
      );
      this.setState({
        filteredCharms: this.getCharms(filteredCharms),
        searchTerm: charmSearchTerm
      });
    } else {
      this.setState({
        filteredCharms: this.getCharms(),
        searchTerm: NULL_CHARM_NAME
      });
    }
  }

  onKeyDownCharmBucket = (event) => {
    isolateEventByKeys(event, [ENTER, SPACE]);
  }

  onKeyUpCharmBucket = (charmName, event) => {
    const { keyCode } = event;
    isolateEventByKeys(event, [ENTER, SPACE, ESCAPE]);

    if (keyCode === ENTER || keyCode === SPACE) {
      this.props.onClickBucket(charmName);
    } else if (keyCode === ESCAPE) {
      this.props.onClose();
    }
  }

  onKeyUpHexInput = (event) => {
    const { keyCode } = event;
    isolateEventByKeys(event, [ENTER, ESCAPE]);

    if (keyCode === ENTER) {
      this.onChangeSearchTerm(event);
    } else if (keyCode === ESCAPE) {
      this.props.onClose();
    }
  }

  renderCharmBucket = (charmName, key = -1) => {
    const isSelectedCharmName = charmName === this.props.selectedCharmName;
    const attributes = {
      key,
      id: charmName,
      tabIndex: 0,
      role: 'option',
      onClick: () => this.props.onClickBucket(charmName),
      onKeyUp: (event) => this.onKeyUpCharmBucket(charmName, event),
      onKeyDown: this.onKeyDownCharmBucket,
      'aria-selected': isSelectedCharmName,
      'aria-label': `${charmName}`,
      className: classNames('charm-bucket',
        { 'selected-charm': isSelectedCharmName },
        { 'charm-none': charmName === NULL_CHARM_NAME }
      ),
      'data-testid': `charm-bucket-${key}`
    };

    return (
      <div {...attributes}>
        {charmName === NULL_CHARM_NAME ? '' : <img src={getCharmSvgSrc(charmName)} key={charmName} alt={charmName} />}
      </div>
    );
  }

  render() {
    const { bucketRevealDirection, showingCharmName } = this.props;
    const { filteredCharms, searchTerm } = this.state;
    const charmBuckets = [
      this.renderCharmBucket(NULL_CHARM_NAME)
    ].concat(_.map(filteredCharms, this.renderCharmBucket));
    const bucketContainerClassName = classNames('charm-buckets-container', {
      'hidden': !showingCharmName,
      'reveal-from-top': bucketRevealDirection === 'top'
    });

    const charmBucketsAttributes = {
      className: `charm-buckets color-${filteredCharms.length}`,
      ref: ref => this.charmBucketsRef = ref,
      role: 'listbox',
      tabIndex: 0,
      'aria-activedescendant': searchTerm
    };
    const hexInputAttributes = {
      type: 'text',
      placeholder: I18n.t('shared.components.color_and_charm_picker.input.placeholder'),
      value: _.isEmpty(searchTerm) ? NULL_CHARM_NAME : searchTerm,
      onChange: this.onChangeSearchTerm,
      onKeyUp: this.onKeyUpHexInput
    };

    return (
      <div className={bucketContainerClassName}>
        <input {...hexInputAttributes} />
        <div {...charmBucketsAttributes}>
          {charmBuckets}
        </div>
      </div>
    );
  }
}

CharmBuckets.propTypes = {
  bucketRevealDirection: PropTypes.string,
  onClickBucket: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  selectedCharmName: PropTypes.string,
  showingCharmName: PropTypes.bool
};

export default CharmBuckets;
