import React, { FunctionComponent } from 'react';
import { ForgeIcon } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import './index.scss';

const i18nScope = 'shared.components.asset_action_bar.publication_action.fix_metadata_message';
const t = (key: string) => I18n.t(key, { scope: i18nScope });
interface Props {
  fixMetadataLink: string; // DSMUI may want an onClick instead
  fetchValidityStatus: 'success' | 'error' | 'loading' | 'idle';
}

export const FixMetadataMessage: FunctionComponent<Props> = ({ fixMetadataLink, fetchValidityStatus }) => {
  if (fetchValidityStatus === 'loading') return t('loading');
  if (fetchValidityStatus === 'error' || fetchValidityStatus === 'idle') return t('error_validating');

  return (
    <span className="fix-metadata-message">
      {t('cant_publish')}
      <br />
      <span className="publish-to-do">
        <ForgeIcon slot="leading" name="circle_edit_outline" /> {t('filled_metadata')}
      </span>
      <a className="fix-metadata-link" data-test-id="fix-metadata-link" href={fixMetadataLink}>
        {t('fix_metadata')}
      </a>
    </span>
  );
};

export default FixMetadataMessage;
