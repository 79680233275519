import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isNil } from 'lodash';

import fixedEncodeURIComponent from 'common/js_utils/fixedEncodeURIComponent';
import I18n from 'common/i18n';
import { Modal, ModalContent, ModalHeader } from 'common/components/Modal';
import SocrataIcon from 'common/components/SocrataIcon';
import { getCurrentDomain } from 'common/currentDomain';

import Editor from './Editor';
import ActionList from './ActionList';
import Results from './Results';
import DocumentationInfoFlannel from './DocumentationInfoFlannel';
import QueryEditorModalFooter from './QueryEditorModalFooter';
import { ExploreEntry, canRenderExploreEntry } from 'common/exploreEntryHelper';
import { MAX_FRONTEND_URL_SIZE } from 'common/utilities/Constants';

const t = (k) => I18n.t(k, { scope: 'shared.explore_banner'});

/**
 * Actual modal component
 *
 * Will render a spinner if the view is being fetched,
 * an error if there was an error fetching the view,
 * or the contents of the editor after the view has been fetched
 */
class QueryEditorModal extends Component {
  static propTypes = {
    confirmButtonText: PropTypes.string.isRequired,
    currentView: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    showingDocumentationFlannel: PropTypes.bool,
    query: PropTypes.string,
    view: PropTypes.object,
    viewFetchError: PropTypes.string
  }

  state = {
    canShowExploreEntry: false
  };

  componentDidMount() {
    this.checkIfExploreEntryEnabled();
  }

  async checkIfExploreEntryEnabled() {
    try {
      this.setState({ canShowExploreEntry: await canRenderExploreEntry(this.props.currentView) });
    } catch (err) {
      this.setState({ canShowExploreEntry: false });
    }
  }

  renderSpinnerOrContent = () => {
    const {
      currentView,
      onCancel,
      query,
      showingDocumentationFlannel,
      view,
      viewFetchError
    } = this.props;

    const { canShowExploreEntry } = this.state;

    if (viewFetchError) {
      return (
        <ModalContent className="query-editor-modal-content-error">
          <div className="alert error query-editor-view-fetch-error">{viewFetchError}</div>
        </ModalContent>
      );
    }

    // show a spinner while we're fetching the view
    if (!view) {
      return (<div className="query-editor-loading-spinner spinner-default spinner-large" />);
    }

    let uri = `/d/${currentView.id}/explore/query/${fixedEncodeURIComponent(query)}/page/code`;
    const urlBase = 'https://' + (getCurrentDomain() || '');
    if ((urlBase.length + uri.length) > MAX_FRONTEND_URL_SIZE ) {
      uri = `/d/${currentView.id}/explore/`;
    }

    return (
      <ModalContent>
        {/* The header is rendered in the content because we want the ActionList to go above it */}
        <ModalHeader title="Query Editor" onDismiss={onCancel}>
          <div className="query-editor-modal-details">
            <SocrataIcon name="dataset" className="query-editor-modal-details-icon" />
            <div className="query-editor-modal-details-name">{view.name}</div>
          </div>
          {canShowExploreEntry &&
            <div className="query-editor-vqe-entry-point">
              <ExploreEntry intro={t('use_new_query_editor')} url={uri} />
            </div>}
        </ModalHeader>
        <div className="query-editor-main">
          <Editor />
          <Results />
        </div>
        <ActionList />
        {showingDocumentationFlannel && <DocumentationInfoFlannel />}
      </ModalContent>
    );
  }

  render() {
    const {
      confirmButtonText,
      onCancel,
      onConfirm
    } = this.props;

    return (
      <Modal className="query-editor-modal" onDismiss={() => {}}>
        {this.renderSpinnerOrContent()}
        <QueryEditorModalFooter
          onCancel={onCancel}
          onConfirm={onConfirm}
          confirmButtonText={confirmButtonText} />
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  showingDocumentationFlannel: !isNil(state.editor.documentation),
  view: state.editor.view,
  query: state.editor.query,
  viewFetchError: state.editor.viewFetchError
});

export default connect(mapStateToProps, {})(QueryEditorModal);
