import React, { Component } from 'react';
import SocrataIcon, { IconName } from '../SocrataIcon';

interface Props {
  contents: JSX.Element | null;
  details: any;
  icon: IconName;
  name: string;
  derivedViewCount?: number;
  warnings?: JSX.Element | null;
}

interface State {
  expanded: boolean;
}

class ChangesWithDetails extends Component<Props, State> {
state = {
    expanded: false
  };

  toggle = () => {
    this.setState({
      expanded: !this.state.expanded
    });
  };

  render() {
    const details = this.props.contents ? (
      <span className="socrata-icon-arrow-down" onClick={this.toggle}></span>
    ) : (
      null
    );

    return (
      <div>
        <div className="step-title">
          <div className="marker">
            <SocrataIcon name={this.props.icon} className="step-icon" />
          </div>
          <div className="step-content">
            <p className="step-title">{this.props.name}</p>
            <span className="step-details">{this.props.details}</span>
            {details}
            {this.state.expanded && this.props.contents && (
              <div>{this.props.contents}</div>
            )}
            {this.props.warnings || null}
          </div>
        </div>
      </div>
    );
  }
}

export default ChangesWithDetails;
