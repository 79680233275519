// Vendor Imports
import _ from 'lodash';
import moment from 'moment';

// Project Imports
import I18n from 'common/i18n';
import { getPrecision, roundToPrecision } from 'common/numbers';
import { assertIsNil, assertIsString } from 'common/assertions';
import formatString from 'common/js_utils/formatString';
import { NUMERIC_COLUMN_TYPES, POINT_COLUMN_TYPES } from 'common/authoring_workflow/constants';
import {
  DATE_FORMAT,
  FILTER_TYPES,
  formatDate,
  RELATIVE_FILTERS,
  SINGLE_SELECT_BY,
  getFiscalEndYear
} from 'common/dates';
import { Filter, FilterBarColumn } from './types';
import DataTypeFormatter from 'common/DataTypeFormatter';
import { SoQLType } from 'common/types/soql';
import { ClientContextVariable } from 'common/types/clientContextVariable';
import {
  BinaryOperator,
  DateFilterArgument,
  FilterValue,
  FILTER_FUNCTION,
  NumberSoqlFilter,
  OPERATOR,
  RadiusFilter,
  RelativeDateFilter,
  TimeRangeFilter,
  JOIN_FUNCTION
} from './SoqlFilter';
import { PicklistOption } from '../Picklist';

export function getDefaultFilterForColumn(
  column: Pick<FilterBarColumn, 'fieldName'>,
  datasetUid: string,
  isDrilldown = false
): Filter {
  return {
    function: FILTER_FUNCTION.NOOP,
    columnName: column.fieldName,
    arguments: null,
    isDrilldown,
    isHidden: false,
    columns: [{ fieldName: column.fieldName, datasetUid: datasetUid }],
    singleSelect: undefined,
    orderBy: undefined
  };
}

export function getContainsTextFilter(
  column: FilterBarColumn,
  filter: BinaryOperator,
  operator: OPERATOR,
  operand: FilterValue,
  datasetUid: string
) {
  if (_.isEmpty(operand)) {
    return applyDefaultFilterAndGetFilter(filter, column, datasetUid);
  } else {
    return _.chain({})
      .assign(filter, {
        function: FILTER_FUNCTION.BINARY_OPERATOR,
        arguments: [{ operator, operand }]
      })
      .omit('joinOn')
      .value();
  }
}

export function getEqualityTextFilter(
  column: FilterBarColumn,
  filter: BinaryOperator,
  values: FilterValue[],
  isNegated: boolean,
  datasetUid: string
) {
  if (_.isEmpty(values)) {
    return applyDefaultFilterAndGetFilter(filter, column, datasetUid);
  } else {
    const toArgument = (value: FilterValue) => {
      if (_.isNull(value)) {
        return {
          operator: isNegated ? OPERATOR.NOT_NULL : OPERATOR.NULL
        };
      } else {
        return {
          operator: isNegated ? OPERATOR.NOT_EQUAL : OPERATOR.EQUALS,
          operand: value
        };
      }
    };

    return _.assign({}, filter, {
      function: FILTER_FUNCTION.BINARY_OPERATOR,
      joinOn: isNegated ? 'AND' : 'OR',
      arguments: _.map(values, toArgument)
    });
  }
}

export function getComputedColumnFilter(
  column: FilterBarColumn,
  filter: Filter,
  values: (PicklistOption | null)[],
  datasetUid: string,
  isNegated: boolean
) {
  if (_.isEmpty(values)) {
    const { isHidden } = filter;
    return _.merge({}, getDefaultFilterForColumn(column, datasetUid), { isHidden });
  } else {
    const toArgument = (value: PicklistOption | null) => {
      if (_.isNull(value)) {
        return {
          operator: isNegated ? OPERATOR.NOT_NULL : OPERATOR.NULL
        };
      } else {
        return {
          operator: isNegated ? OPERATOR.NOT_EQUAL : OPERATOR.EQUALS,
          operand: value.value,
          operandLabel: value.title
        };
      }
    };

    return _.assign({}, filter, {
      function: FILTER_FUNCTION.BINARY_OPERATOR,
      joinOn: isNegated ? 'AND' : 'OR',
      arguments: _.map(values, toArgument)
    });
  }
}

export function getCheckboxFilter(
  column: FilterBarColumn,
  filter: BinaryOperator,
  values: FilterValue[],
  datasetUid: string
) {
  if (_.isEmpty(values)) {
    return applyDefaultFilterAndGetFilter(filter, column, datasetUid);
  } else {
    const toArgument = (value: FilterValue) => {
      if (_.isNull(value)) {
        return {
          operator: OPERATOR.NULL
        };
      } else {
        return {
          operator: OPERATOR.EQUALS,
          operand: value
        };
      }
    };

    return _.assign({}, filter, {
      function: FILTER_FUNCTION.BINARY_OPERATOR,
      joinOn: JOIN_FUNCTION.OR,
      arguments: _.map(values, toArgument)
    });
  }
}

export function getFilterHumanText(filter: Filter, column: FilterBarColumn, showNullsAsFalse?: boolean) {
  if (filter.function === FILTER_FUNCTION.NOOP) {
    return I18n.t('shared.components.filter_bar.select');
  }

  if (_.includes(['calendar_date', 'date'], column.renderTypeName)) {
    if (filter.singleSelect) {
      // It may be possible to simply reverse the order of first 2 if..else if conditions,
      // but this is a special condition and wanted to make sure there were no side effect.
      if (
        _.get(filter, 'singleSelect.by') === SINGLE_SELECT_BY.DAY &&
        _.get(filter, 'arguments.calendarDateFilterType') === FILTER_TYPES.RELATIVE
      ) {
        return getRelativeDateFilterText(filter as RelativeDateFilter);
      }
      return getSingleSelectDateFilterText(filter as TimeRangeFilter, column);
    } else if (_.get(filter.arguments, 'calendarDateFilterType') === FILTER_TYPES.RELATIVE) {
      return getRelativeDateFilterText(filter as RelativeDateFilter);
    } else {
      return getDateRangeFilterText(filter as TimeRangeFilter, column);
    }
  } else if (_.includes(['money', 'number'], column.renderTypeName)) {
    if (filter.function === FILTER_FUNCTION.BINARY_OPERATOR) {
      return getTextFilterText(filter as BinaryOperator);
    } else {
      return getNumberFilterHumanText(filter as NumberSoqlFilter, column);
    }
  } else if (column.renderTypeName === 'text') {
    return getTextFilterText(filter as BinaryOperator);
  } else if (column.renderTypeName === 'checkbox') {
    return getCheckboxFilterText(filter as BinaryOperator, showNullsAsFalse);
  } else if (_.includes(POINT_COLUMN_TYPES, column.renderTypeName)) {
    return getRadiusFilterText(filter as RadiusFilter);
  } else {
    console.error(`Unsupported column type "${column.renderTypeName}"`); // eslint-disable-line no-console
  }
}

export function getParameterHumanText(parameter: ClientContextVariable) {
  switch (_.get(parameter, 'dataType')) {
    case SoQLType.SoQLFloatingTimestampT:
    case SoQLType.SoQLFixedTimestampT:
      const parameterStartDate = parameter.overrideValue || parameter.defaultValue;

      // if parameterStartDate is a relative value
      if (
        parameterStartDate === RELATIVE_FILTERS.TODAY ||
        parameterStartDate === RELATIVE_FILTERS.YESTERDAY
      ) {
        const scope = 'shared.components.filter_bar.calendar_date_filter';
        return I18n.t(`relative_periods.${parameterStartDate}`, { scope });
      }
      return formatDate(parameterStartDate, 'l');
    case SoQLType.SoQLTextT:
    case SoQLType.SoQLNumberT:
      return parameter.overrideValue || parameter.defaultValue;
    case 'checkbox':
      return parameter.displayName;
  }
}

export function isTodayOrYesterday(value: any) {
  return value === RELATIVE_FILTERS.TODAY || value === RELATIVE_FILTERS.YESTERDAY;
}

// Private functions

function getNumberFilterHumanText(filter: NumberSoqlFilter, column: FilterBarColumn) {
  const filterFunction = filter.function;
  assertIsString(filterFunction, `Expected 'function' property to be a string, was: ${filter.function}`);

  // Apart from valueRange, these filters use strings in their arguments for arbitrary numerical precision.
  const { start, end, value } = _.defaultTo(filter.arguments, {});
  const rangeFilter = 'shared.components.filter_bar.range_filter';

  if (filterFunction === FILTER_FUNCTION.VALUE_RANGE) {
    // Old-style ambiguous filter. We won't generate new ones, but we still need
    // to display them. They're migrated when the user opens the dropdown.

    const step = _.min(_.map([column.rangeMin, column.rangeMax], getPrecision));
    const startLabel = roundToPrecision(start, step);
    const endLabel = roundToPrecision(end, step);

    const hasMinValue = _.isFinite(start) && !_.isEqual(column.rangeMin, startLabel);
    const hasMaxValue = _.isFinite(end) && !_.isEqual(column.rangeMax, endLabel);

    if (hasMinValue || hasMaxValue) {
      return formatString(I18n.t(`${rangeFilter}.range_label`), startLabel, endLabel);
    } else {
      return column.name;
    }
  }

  // Note early return above.
  let key;
  switch (filterFunction) {
    case FILTER_FUNCTION.RANGE_INCLUSIVE:
      assertIsString(start);
      assertIsString(end);
      key = `${rangeFilter}.range_inclusive_label`;
      break;
    case FILTER_FUNCTION.RANGE_EXCLUSIVE:
      assertIsString(start);
      assertIsString(end);
      key = `${rangeFilter}.range_exclusive_label`;
      break;
    case FILTER_FUNCTION.EQUALS:
      assertIsString(value);
      key = `${rangeFilter}.equals_label`;
      break;
    case FILTER_FUNCTION.NOT_EQUAL:
      assertIsString(value);
      key = `${rangeFilter}.not_equals_label`;
      break;
    case FILTER_FUNCTION.GREATER_THAN:
      assertIsString(value);
      key = `${rangeFilter}.above_label`;
      break;
    case FILTER_FUNCTION.GREATER_THAN_EQUAL_TO:
      assertIsString(value);
      key = `${rangeFilter}.at_least_label`;
      break;
    case FILTER_FUNCTION.LESS_THAN:
      assertIsString(value);
      key = `${rangeFilter}.below_label`;
      break;
    case FILTER_FUNCTION.LESS_THAN_EQUAL_TO:
      assertIsString(value);
      key = `${rangeFilter}.at_most_label`;
      break;
    case FILTER_FUNCTION.EXCLUDE_NULL:
      assertIsNil(value);
      key = `${rangeFilter}.exclude_null_label`;
      break;
    default:
      console.error(`Unknown function: ${filterFunction}`);
      key = `${rangeFilter}.invalid_value`;
      break;
  }

  const formattedArgs = _.mapValues(_.pick(filter.arguments || {}, ['start', 'end', 'value']), (val) =>
    DataTypeFormatter.renderCellHTML(val, column)
  );

  return formatString(I18n.t(key), formattedArgs);
}

export function getRadiusFilter(
  column: FilterBarColumn,
  filter: RadiusFilter,
  center: number[],
  radius: number,
  humanReadableLocation: unknown,
  datasetUid: string
) {
  if (_.isUndefined(center) || _.isEmpty(center)) {
    return applyDefaultFilterAndGetFilter(filter, column, datasetUid);
  } else {
    return _.assign({}, filter, {
      function: FILTER_FUNCTION.WITHIN_CIRCLE,
      arguments: [
        {
          center,
          humanReadableLocation,
          radius,
          units: 'miles'
        }
      ]
    });
  }
}

export function getCalendarDateRangeFilter(
  filter: RelativeDateFilter | TimeRangeFilter,
  calendarDateFilterType: FILTER_TYPES,
  value: Partial<DateFilterArgument>
) {
  const filterFunction =
    calendarDateFilterType === FILTER_TYPES.RANGE
      ? FILTER_FUNCTION.TIME_RANGE
      : FILTER_FUNCTION.RELATIVE_DATE_RANGE;

  return _.merge({}, filter, {
    function: filterFunction,
    arguments: {
      calendarDateFilterType,
      ...value
    }
  });
}

/**
 * @param dimensionAndDrilldownColumns Array of column field names
 * @param existingFilters
 */
export function getNewFilters(
  dimensionAndDrilldownColumns: string[],
  existingFilters: Filter[],
  datasetUid: string
): Filter[] {
  const dimensionAndDrilldownFilters = _.map(dimensionAndDrilldownColumns, (column) => {
    const existingFilter = _.find(existingFilters, { columnName: column });
    if (existingFilter) {
      _.set(existingFilter, 'isDrilldown', true);
      return existingFilter;
    } else {
      return getDefaultFilterForColumn({ fieldName: column }, datasetUid, true);
    }
  });

  const otherFilters = _.reject(existingFilters, (filter) => {
    return _.includes(dimensionAndDrilldownColumns, filter.columnName) || filter.isDrilldown;
  }) as Filter[];

  return [...dimensionAndDrilldownFilters, ...otherFilters];
}

export function atLeastOneDrilldownFilter(filters: Filter[]) {
  return _.some(filters, ['isDrilldown', true]);
}

/**
 *
 * @param drilldownDimensions Array of all column field names used as dimensions for drilldowns
 * @param currentDrilldownDimension The column field name for the current drilldown dimension
 * @returns The column field name for the next drilldown dimension to be used
 */
export function getNextDrilldownDimensionColumnName(
  drilldownDimensions: string[],
  currentDrilldownDimension: string
) {
  const currentDrilldownIndex = _.indexOf(drilldownDimensions, currentDrilldownDimension);

  return _.get(drilldownDimensions, [currentDrilldownIndex + 1], _.last(drilldownDimensions));
}

// Given an array of filters, this function returns filters with
// the filter object of the given column filtering on the given value.
export function applyFilterForColumnNameAndGetFilters({
  columnDetails,
  columnName,
  columnValue,
  filters,
  period,
  datasetUid
}: {
  columnDetails: FilterBarColumn;
  columnName: string;
  columnValue: FilterValue;
  filters: Filter[];
  period: moment.unitOfTime.StartOf;
  datasetUid: string;
}) {
  return _.map(filters, (filterItem) => {
    if (filterItem.columnName === columnName) {
      if (_.includes(NUMERIC_COLUMN_TYPES, columnDetails.renderTypeName)) {
        return getNumberFilter(filterItem as NumberSoqlFilter, columnValue);
      } else if (_.includes(['calendar_date', 'date'], columnDetails.renderTypeName)) {
        const value = {
          start: moment(columnValue as string, DATE_FORMAT)
            .startOf(period)
            .format(DATE_FORMAT),
          end: moment(columnValue as string, DATE_FORMAT)
            .endOf(period)
            .format(DATE_FORMAT)
        };

        return getCalendarDateRangeFilter(
          filterItem as RelativeDateFilter | TimeRangeFilter,
          FILTER_TYPES.RANGE,
          value
        );
      } else {
        return getCheckboxFilter(columnDetails, filterItem as BinaryOperator, [columnValue], datasetUid);
      }
    }

    return filterItem;
  });
}

export function getNonHiddenFilters(filters: Filter[], isReadOnly = true) {
  return _.chain(filters)
    .map((filterItem) => {
      if (isReadOnly && filterItem.isHidden) {
        return null;
      }
      return filterItem;
    })
    .compact()
    .value();
}

export function resetFiltersToDefaults(
  filters: Filter[],
  datasetUid: string,
  resetOnlyVisibleFilters: boolean
) {
  return _.map(filters, (filterItem) => {
    const { isHidden, isDrilldown, columnName, isOverridden } = filterItem;
    const orderBy = _.get(filterItem, 'orderBy');
    const singleSelect = _.get(filterItem, 'singleSelect');
    if ((resetOnlyVisibleFilters && isHidden) || isOverridden) {
      return filterItem;
    }

    const defaultFilterForColumn = getDefaultFilterForColumn({ fieldName: columnName }, datasetUid);

    return _.merge({}, defaultFilterForColumn, {
      isHidden,
      isDrilldown,
      singleSelect: singleSelect ?? undefined,
      orderBy: orderBy ?? undefined
    });
  });
}

function applyDefaultFilterAndGetFilter(filter: Filter, column: FilterBarColumn, datasetUid: string) {
  const isHidden = _.get(filter, 'isHidden');
  const isDrilldown = _.get(filter, 'isDrilldown', false);
  const orderBy = _.get(filter, 'orderBy', {});

  return _.merge({}, getDefaultFilterForColumn(column, datasetUid), { isHidden, isDrilldown, orderBy });
}

function getRadiusFilterText(filter: RadiusFilter) {
  const center = _.get(filter, 'arguments[0].center');
  const humanReadableLocation = _.get(filter, 'arguments[0].humanReadableLocation');
  const radius = _.get(filter, 'arguments[0].radius');
  const units = _.get(filter, 'arguments[0].units');

  const allArgumentsDefined = _.compact([center, humanReadableLocation, radius, units]).length === 4;

  if (allArgumentsDefined) {
    return formatString(
      I18n.t('shared.components.filter_bar.radius_filter.filter_text'),
      radius,
      units,
      humanReadableLocation
    );
  } else {
    return I18n.t('shared.components.filter_bar.radius_filter.no_value');
  }
}

function getTextFilterText(filter: BinaryOperator): string {
  const valueCount = _.size(filter.arguments);
  const firstArgument = _.first(filter.arguments);
  const firstValue = firstArgument?.operandLabel || firstArgument?.operand;
  const operators = _.map(filter.arguments, 'operator');
  const firstOperator = _.first(operators);
  const isNegated = _.toLower(filter.joinOn) === 'and';
  const scope = 'shared.components.filter_bar.text_filter';

  if (isNegated) {
    if (valueCount > 1) {
      return formatString(I18n.t('n_values_negated', { scope }), valueCount);
    } else if (_.isString(firstValue)) {
      return formatString(I18n.t('single_value_negated', { scope }), firstValue);
    } else {
      return I18n.t('no_value_negated', { scope });
    }
  } else if (valueCount > 1) {
    return formatString(I18n.t('n_values', { scope }), valueCount);
  } else if (_.isString(firstValue)) {
    if (firstOperator === 'contains') {
      return formatString(I18n.t('contains_value', { scope }), firstValue);
    } else if (firstOperator === 'does_not_contain') {
      return formatString(I18n.t('does_not_contain_value', { scope }), firstValue);
    } else if (firstOperator === 'starts_with') {
      return formatString(I18n.t('starts_with_value', { scope }), firstValue);
    } else {
      return firstValue;
    }
  } else {
    return I18n.t('no_value', { scope });
  }
}

function getCheckboxFilterText(filter: BinaryOperator, showNullsAsFalse?: boolean): string {
  const values = _.map(filter.arguments, 'operand');
  const valueCount = _.size(values);
  const firstValue = _.first(values);
  const scope = 'shared.components.filter_bar.checkbox_filter';

  if (valueCount > 1) {
    return formatString(I18n.t('n_values', { scope }), valueCount);
  } else if (_.isBoolean(firstValue)) {
    return boolToString(firstValue);
  } else {
    return showNullsAsFalse ? I18n.t('false_value', { scope }) : I18n.t('no_value', { scope });
  }
}

function boolToString(value: any): string {
  const scope = 'shared.components.filter_bar.checkbox_filter';
  if (value === true) {
    return I18n.t('true_value', { scope });
  } else if (value === false) {
    return I18n.t('false_value', { scope });
  } else {
    return I18n.t('no_value', { scope });
  }
}

function getSingleSelectDateFilterText(filter: TimeRangeFilter, column: FilterBarColumn): string {
  const { start, end } = filter.arguments;
  const singleSelectBy = _.get(filter, 'singleSelect.by');
  const scope = 'shared.components.filter_bar.calendar_date_filter.relative_periods';

  if (start) {
    if (isTodayOrYesterday(start)) {
      return I18n.t(start, { scope });
    } else if (singleSelectBy === SINGLE_SELECT_BY.YEAR) {
      return formatDate(start, 'YYYY');
    } else if (singleSelectBy === SINGLE_SELECT_BY.FISCAL_YEAR) {
      return formatDate(getFiscalEndYear(end), 'YYYY');
    } else if (singleSelectBy === SINGLE_SELECT_BY.MONTH) {
      return formatDate(start, 'MMMM YYYY');
    } else {
      // default to include the day
      return formatDate(start, 'l');
    }
  } else {
    return column.name || column.fieldName;
  }
}

function getDateRangeFilterText(filter: TimeRangeFilter, column: FilterBarColumn): string {
  const { start, end } = filter.arguments;
  const scope = 'shared.components.filter_bar.calendar_date_filter.relative_periods';

  if (start && end) {
    const startLabel = isTodayOrYesterday(start) ? I18n.t(start, { scope }) : formatDate(start, 'l');
    const endLabel = isTodayOrYesterday(end) ? I18n.t(end, { scope }) : formatDate(end, 'l');

    return formatString(
      I18n.t('shared.components.filter_bar.range_filter.range_label'),
      startLabel,
      endLabel
    );
  } else {
    return column.name || column.fieldName;
  }
}

function getRelativeDateFilterText(filter: RelativeDateFilter): string {
  const { type, period, value } = filter.arguments;
  const relativeDateFilterScope = 'shared.components.filter_bar.calendar_date_filter';
  const lastFieldLabel = I18n.t(`${relativeDateFilterScope}.last_field_label`);

  if (type === RELATIVE_FILTERS.CUSTOM) {
    const unit = value === '1' ? 'singular' : 'plural';
    const customPeriod = I18n.t(`${relativeDateFilterScope}.custom_periods.${period}.${unit}`);
    return `${lastFieldLabel} ${value} ${customPeriod}`;
  } else {
    return I18n.t(`${relativeDateFilterScope}.relative_periods.${type}`);
  }
}

function getNumberFilter(filterOptions: NumberSoqlFilter, columnValue: FilterValue): NumberSoqlFilter {
  const newFilterOptions = {
    function: FILTER_FUNCTION.EQUALS,
    arguments: {
      value: columnValue,
      includeNullValues: false
    }
  };

  return _.merge({}, filterOptions, newFilterOptions);
}
