import { currentUserIsSuperAdmin } from 'common/current_user';
import { FeatureFlags } from 'common/feature_flags';
import { defaultHeaders, fetchJson } from 'common/http';

import { showInfoToastNow, showErrorToastNow } from 'common/components/ToastNotification/Toastmaster';
import ToastNotification from 'common/components/ToastNotification';

import { analysisIdType } from 'common/types/programAnalytics';

import I18n from 'common/i18n';

// TODO: EN-47792
// export for tests
export const fetchOptions = {
  credentials: 'same-origin',
  headers: defaultHeaders
};

export const isProgramAnalyticsEnabled = () => FeatureFlags.value('use_program_analytics');

export async function getAnalysisId(fxf: string): Promise<analysisIdType> {
  if (!isProgramAnalyticsEnabled()) {
    return null;
  }

  const analysisIdUrl = `/api/views/${fxf}/analysis`;
  const analysisResponse = await fetchJson(analysisIdUrl, fetchOptions);
  return analysisResponse.analysisId;
}


export async function refreshAnalysis(fxf: string): Promise<void> {
  const scope = 'shared.asset_browser.result_list_table';
  const analysisRefreshUrl = `/api/views/${fxf}/analysis?method=run`;
  const options = {
    'method': 'PUT',
    ...fetchOptions
  };

  fetchJson(analysisRefreshUrl, options).then( () => {
    showInfoToastNow(I18n.t('program_analytics_datasets.refresh_success', { scope }));
  }).catch( () => {
    showErrorToastNow(I18n.t('program_analytics_datasets.refresh_error', { scope }));
  });
}
