import classNames from 'classnames';
import React, { Component } from 'react';
import I18n from 'common/i18n';
import { handleKeyEvent } from 'common/dom_helpers/keyPressHelpers';
import { ENTER, SPACE } from 'common/dom_helpers/keycodes_deprecated';
import { ForgeBadge, ForgeIconButton, ForgeIcon, ForgeTooltip } from '@tylertech/forge-react';

interface BellProps {
  unreadUserNotificationCount: number;
  toggleNotificationPanel: () => void;
}

class Bell extends Component<BellProps> {
  render() {
    const {
      unreadUserNotificationCount,
      toggleNotificationPanel
    } = this.props;
    const scope = 'shared.site_chrome.notifications';
    const totalUnreadNotifications = unreadUserNotificationCount;
    const hasUnreadNotifications = totalUnreadNotifications > 0;
    const notificationsClassName = classNames('notifications-bell', {
      'has-unread-notifications': hasUnreadNotifications,
      'unread': hasUnreadNotifications
    });

    let tipsyText;

    if (hasUnreadNotifications) {
      tipsyText = I18n.t('has_unread_notifications', { scope });
    } else {
      tipsyText = I18n.t('no_unread_notifications', { scope });
    }

    return (
      <div>
        <ForgeIconButton
          aria-haspopup="true"
          aria-label={tipsyText}
          className={notificationsClassName}
          role='tooltip'
          onClick={toggleNotificationPanel}
          onKeyPress={handleKeyEvent([ENTER, SPACE], toggleNotificationPanel)}
          // In Firefox, using the spacebar fires the onClick event when the key is released, in addition to the onKeyDown
          // event. This will make toggleNotificationPanel() be called twice. Therefore we make onKeyUp a noop.
          // We're using handleKeyEvent() to preventDefault.
          // ref: https://stackoverflow.com/questions/45168846/double-event-on-firefox-on-keydown-event
          //
          //
          title={tipsyText}
        >
        <button type="button" aria-label="View notifications">
          <ForgeIcon name="notifications"></ForgeIcon>
        </button>
        {totalUnreadNotifications > 0 && <ForgeBadge positioned>{totalUnreadNotifications}</ForgeBadge>}
        </ForgeIconButton>
        <ForgeTooltip position='bottom'>{I18n.t('shared.site_chrome.forge_omnibar.notifications.notifications_and_alerts')}</ForgeTooltip>
      </div>
    );
  }
}

export default Bell;
