import React, { Component } from 'react';
import _ from 'lodash';
import I18n from 'common/i18n';
import CreateAlertModal from 'common/components/CreateAlertModal';

class CreateAlertButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showCreateAlertModal: false
    };

    _.bindAll(this,
      'closeModal',
      'renderCreateAlertModal',
      'onCreateAlertButtonClick'
    );
  }


  onCreateAlertButtonClick() {
    const showCreateAlertModal = true;

    this.setState({ showCreateAlertModal });
  }

  closeModal(options) {
    const { onAlertSaveSuccess } = this.props;
    const showCreateAlertModal = false;

    if (options.isSaved) {
      onAlertSaveSuccess();
    }
    this.setState({ showCreateAlertModal });
  }

  renderCreateAlertModal() {
    const { showCreateAlertModal } = this.state;
    if (showCreateAlertModal) {
      return <CreateAlertModal onClose={this.closeModal} />;
    }
  }
  render() {
    const createAlertButtonText = I18n.t('title', { scope: 'shared.components.create_alert' });

    return (
      <div className="create-alert-button">
        <label
          onClick={(event) => this.onCreateAlertButtonClick(event)}
          className="inline-label manage-prompt-button btn btn-sm btn-default">
          <span className="checkbox-with-icon-label">{createAlertButtonText}</span>
        </label>
        {this.renderCreateAlertModal()}
      </div>
    );
  }
}

export default CreateAlertButton;
