import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { ForgeCard, ForgeDivider, ForgeIcon } from '@tylertech/forge-react';
import classNames from 'classnames';
import AccordionPane from './AccordionPane';
import './ForgeAccordionPane-stylesheet.scss';
import FeatureFlags from 'common/feature_flags';
import Scrolls from 'common/components/Accordion/Scrolls';

interface IForgeAccordionPaneProps extends PropsWithChildren<any> {
  className?: string;
  title: string;
  onToggle?: (paneId: string) => void;
  onOpen?: () => void;
  isOpen?: boolean;
  paneId?: string;
  toggleOnCaretOnly?: boolean;
}

const DEFAULT_TRANSITION_DURATION = 400;
const DEFAULT_CONTENT_TRANSITION = `height ${DEFAULT_TRANSITION_DURATION}ms ease 0s, opacity ${DEFAULT_TRANSITION_DURATION}ms ease 0s`;
const DEFAULT_BUTTON_TRANSITION = `transform ${DEFAULT_TRANSITION_DURATION}ms ease 0s`;

function setTransitionIfNeeded(styleRef: CSSStyleDeclaration, animate: boolean, defaultTransition: string) {
  const transition = styleRef.transition;
  if (animate) {
    if (transition !== defaultTransition) styleRef.transition = defaultTransition;
  } else styleRef.transition = '';
}

const togglePane = (contentRef: HTMLDivElement | null, isOpen: boolean, needsAnimation: boolean) => {
  const contentHeight = contentRef?.scrollHeight;
  const styleRef = contentRef?.style;
  if (styleRef) {
    if (isOpen) {
      styleRef.height = `${contentHeight}px`;
      styleRef.overflow = 'hidden';
      setTransitionIfNeeded(styleRef, needsAnimation, DEFAULT_CONTENT_TRANSITION);
      setTimeout(() => {
        styleRef.height = '0px';
      }, 0);
    } else {
      styleRef.height = `${contentHeight}px`;
      setTransitionIfNeeded(styleRef, needsAnimation, DEFAULT_CONTENT_TRANSITION);
      setTimeout(() => {
        contentRef?.style?.removeProperty('height');
        contentRef?.style?.removeProperty('overflow');
        contentRef?.style?.removeProperty('opacity');
      }, DEFAULT_TRANSITION_DURATION);
    }
  }
};

const rotateCaret = (headerRef: HTMLButtonElement | null, isOpen: boolean, needsAnimation: boolean) => {
  const styleRef = headerRef?.style;
  if (styleRef) {
    if (isOpen) {
      styleRef.transform = 'rotate(180deg)';
      setTransitionIfNeeded(styleRef, needsAnimation, DEFAULT_BUTTON_TRANSITION);
    } else {
      styleRef.transform = 'rotate(0deg)';
      setTransitionIfNeeded(styleRef, needsAnimation, DEFAULT_BUTTON_TRANSITION);
    }
  }
};

function focusCurrentCustomizationTab() {
  const tab = document.querySelector('.tab-link.current a') as HTMLAnchorElement;
  tab?.focus?.();
}

const InternalAccordionPane = (props: IForgeAccordionPaneProps) => {
  const { title, className, toggleOnCaretOnly, isOpen, paneId, onOpen, onToggle } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const paneTitleElement = useRef<HTMLDivElement>(null);
  const needsAnimation = useRef(false);

  const toggle = () => {
    if (!isOpen) onOpen?.call(this);
    needsAnimation.current = true;
    onToggle?.call(this, [paneId]);
  };

  useEffect(() => {
    const animate = needsAnimation.current;
    rotateCaret(buttonRef.current, !isOpen, animate);
    togglePane(contentRef.current, !isOpen, animate);
    needsAnimation.current = false;
  }, [isOpen]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const firstPaneTitle = document.querySelector(
      '.customization-tab-pane:not(.customization-tab-pane_hidden) .paneSubHeader'
    );
    const isFirstPaneTitle = paneTitleElement.current === firstPaneTitle;
    const isShiftTab = event.shiftKey && event.keyCode === 9;

    if (event.keyCode == 13 || event.keyCode == 32) {
      onToggle?.(paneId!);
      event.preventDefault();
    } else if (isShiftTab && isFirstPaneTitle) {
      focusCurrentCustomizationTab();
      event.preventDefault();
    }
  };

  const handleClick = () => {
    if (!toggleOnCaretOnly) {
      toggle();
    }
  };

  const handleCaretClick = () => {
    if (toggleOnCaretOnly) {
      toggle();
    }
  };

  return (
    <>
      <ForgeCard className="paneRoot" style={{ '--forge-card-padding': '16px' }} outlined>
        <div className={classNames(className, paneId, isOpen && 'open', 'socrata-accordion-pane')}>
          <div
            ref={paneTitleElement}
            slot="header"
            onClick={handleClick}
            role="button"
            aria-expanded={isOpen ? 'true' : 'false'}
            aria-label={props['aria-label'] || title}
            onKeyDown={handleKeyDown}
            className={'paneSubHeader forge-typography--body1'}
          >
            <span className="paneTitle">{title}</span>
            <button
              ref={buttonRef}
              onClick={handleCaretClick}
              className={classNames('paneButton')}
              type="button"
              style={{ color: 'var(--forge-theme-icon-color,#757575)' }}
            >
              <ForgeIcon external external-type="standard" name="keyboard_arrow_up" />
            </button>
          </div>
          <div ref={contentRef} className={'socrata-accordion-pane-content'}>
            <div>{props.children}</div>
          </div>
        </div>
      </ForgeCard>
      <ForgeDivider />
    </>
  );
};

const ScrolledAccordionPane = Scrolls(InternalAccordionPane);

export const ForgeAccordionPane = (props: IForgeAccordionPaneProps) => {
  const enableForgeTabs = FeatureFlags.valueOrDefault('enable_forge_authoring_tabs', false);

  if (enableForgeTabs) return <ScrolledAccordionPane {...props} />;
  return <AccordionPane {...props} />;
};
