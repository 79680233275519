import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Picklist from 'common/components/Picklist';
import { positionPicklist } from 'common/components/Dropdown/helper';
import { picklistSizingStrategy } from 'common/components/Dropdown/picklistSizingStrategy';

import './index.scss';

/**
  InputDropDown.
  Displays a input text box and a dropdown on textbox focus. On input textbox
  value change, it fires 'onInputChange' callback to be handled by parent components,
  to retrieve search results/... for displaying as typeahead options in the picklist

  Props:
  @prop options       - to list the values in dropdown
  @prop onSelect      - called when a value is selected from the picklist
  @prop value         - currently selected value
  @prop isLoading     - true/false. If true, shows a loading spinner next the input text box.
  @prop onInputChange - called when the input in the textbox changes.
  @prop placeholder   - placeholder for the textbox.
  @prop listId        - id for picklist
*/
class InputDropDownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      selectedOption: this.getSelectedOption(props)
    };
  }

  componentDidMount() {
    // Adding listeners to check dropdown focus
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('scroll', this.handleClickOutside);
    document.addEventListener('wheel', this.handleClickOutside);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    // eslint-disable-line camelcase
    this.setState({
      selectedOption: this.getSelectedOption(nextProps)
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    // eslint-disable-line camelcase
    if (nextState.showDropDown && !nextProps.isLoading) {
      // Setting dropdown position on open
      const picklistOptions = {
        picklistSizingStrategy: picklistSizingStrategy.EXPAND_TO_WIDEST_ITEM,
        showOptionsBelowHandle: true
      };
      positionPicklist(this.optionsRef, this.dropDownRef, picklistOptions);
    }
  }

  componentWillUnmount() {
    // Removing listeners
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('scroll', this.handleClickOutside);
    document.removeEventListener('wheel', this.handleClickOutside);
  }

  onBlur = () => {
    this.setState({ showDropDown: false });
  };

  onInputClick = () => {
    this.setState({ showDropDown: true });
  };

  onOptionSelect = (option) => {
    this.props.onSelect(option);
    this.setState({ showDropDown: false });
  };

  onInputValueChange = (value) => {
    this.props.onInputChange(value);
    this.setState({ showDropDown: true });
  };

  getSelectedOption = (props) => {
    const { options, value } = props;
    return _.find(options, { value }) || null;
  };

  handleClickOutside = (e) => {
    // ignore clicks on the component itself
    if (!this.dropDownRef.contains(e.target)) {
      this.setState({ showDropDown: false });
    }
  };

  renderInputType() {
    const { placeholder, value } = this.props;
    const { selectedOption } = this.state;
    const inputValue = _.get(selectedOption, 'title', value);
    return (
      <input
        placeholder={placeholder}
        className="small"
        type="text"
        value={inputValue}
        onClick={this.onInputClick}
        onChange={(event) => this.onInputValueChange(event.target.value)}
        onBlur={this.onBlur}
      />
    );
  }

  renderPickList() {
    const { listId, options } = this.props;
    const { selectedOption, showDropDown } = this.state;
    const picklistProps = {
      id: listId,
      options: options,
      onSelection: this.onOptionSelect,
      ref: (ref) => (this.picklistRef = ref),
      size: 'small',
      value: selectedOption
    };

    const classes = classNames('onclick-dropdown-menu small', {
      hidden: _.isEmpty(options) || !showDropDown
    });

    return (
      <div ref={(ref) => (this.optionsRef = ref)} className={classes}>
        <Picklist {...picklistProps} />
      </div>
    );
  }

  render() {
    const { isLoading } = this.props;

    return (
      <div
        className="input-dropdown-button input-drop-down"
        ref={(ref) => (this.dropDownRef = ref)}
      >
        <div className="input-container">
          {this.renderInputType()}
          {isLoading ? <span className="input-loading" /> : null}
        </div>
        {this.renderPickList()}
      </div>
    );
  }
}

InputDropDownMenu.defaultProps = {
  isLoading: false,
  options: [],
  placeholder: '',
  value: ''
};

InputDropDownMenu.propTypes = {
  isLoading: PropTypes.bool,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onInputChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default InputDropDownMenu;
