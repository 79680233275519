import React, { KeyboardEvent, useEffect, useRef } from 'react';
import { ForgeTextField } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import { UserInputField } from '../../types';
import PrivateFieldIcon from 'common/FormatColumn/PrivateFieldIcon/PrivateFieldIcon';

const t = (key: string, scope = 'shared.dataset_management_ui.metadata_manage.dataset_tab') =>
  I18n.t(key, { scope });

export type TextUserInputField = UserInputField<string | undefined>;

export interface TextInputProps {
  id?: string;
  field: TextUserInputField;
  inErrorState: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: () => void;
  handleFocus?: () => void;
  handleKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  focusOnMount?: boolean;
  isRequired?: boolean;
  isRestrictedForUser?: boolean;
  isPrivate?: boolean;
  useForge?: boolean;
}

const TextInput: React.FunctionComponent<TextInputProps> = ({
  handleChange,
  handleFocus,
  handleBlur,
  handleKeyDown,
  field,
  focusOnMount,
  id,
  inErrorState,
  isRequired,
  isPrivate,
  isRestrictedForUser
}) => {
  const ref = useRef<HTMLInputElement>(null);

  let classes = ['metadata-component-text-input'];

  useEffect(() => {
    if (focusOnMount && ref.current) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ref.current!.focus(); // TSC is very mean and does not believe it's not actually null even though there's a check.
    }
  }, []);

  if (inErrorState) {
    classes = [...classes, 'text-input-error', 'metadata-component-text-input-error'];
  }

  const baseInput = (
    <input
      type="text"
      ref={ref}
      id={id ?? field.name}
      name={field.name}
      value={field.value}
      placeholder={field.placeholder}
      aria-label={field.label || field.name}
      aria-required={field.isRequired}
      className={classes.join(' ')}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      disabled={field.disabled}
    />
  );

  return (
    <ForgeTextField invalid={inErrorState} floatLabelType="always" required={isRequired}>
      {isPrivate && <PrivateFieldIcon />}
      {baseInput}
      <label htmlFor={id ?? field.name} slot="label">
        {field.label || field.name}
      </label>
      {isRestrictedForUser && <span slot="helper-text">{t('subtitles.restricted_field')}</span>}
    </ForgeTextField>
  );
};

export default TextInput;
