import React, { FunctionComponent } from 'react';
import isEmpty from 'lodash/isEmpty';

import type { View } from 'common/types/view';
import { ViewRight } from 'common/types/view';

import I18n from 'common/i18n';

import ActionItem from '../action_item';
import { showToastNow, showToastOnPageReload, ToastType } from 'common/components/ToastNotification/Toastmaster';
import { assetIsDraft } from 'common/views/helpers';
import { hasRights } from 'common/views/has_rights';
import { setLockedOnAsset } from 'common/views/helpers';


interface LockAssetActionItemProps {
  view: View;
  revisionInfo?: object;
  assetIsStoryDraft?: boolean;
}

export const onLockAsset = (view: View): void => {
  const messageType: string = view.locked ? 'unlock' : 'lock';
  const toast = I18n.t(`shared.components.asset_action_bar.publication_action.${messageType}_success`,
      { name: view.name });
  const error = I18n.t(`shared.components.asset_action_bar.publication_action.${messageType}_error`);

  setLockedOnAsset(view.id, !view.locked)
      .then(() => {
        showToastOnPageReload({
          type: ToastType.SUCCESS,
          content: toast
        });

        window.location.reload();
      })
      .catch((err: ErrorEvent) => {
        showToastNow({
          type: ToastType.ERROR,
          content: error
        });

        console.error(`Error updating locked state for ${view.id}`, err);
      });
};


const LockAssetActionItem: FunctionComponent<LockAssetActionItemProps> = ({view, revisionInfo, assetIsStoryDraft}) => {
  const shouldRender = () => {
    if (isEmpty(view)) {
      return false;
    }

    return hasRights(view, ViewRight.LockView) && !assetIsDraft({
      coreView: view,
      isRevision: revisionInfo != null,
      isDraftStory: assetIsStoryDraft
    });
  };

  if (!shouldRender()) {
    return null;
  }

  let label: string;
  if (view.locked) {
    label = I18n.t('shared.asset_browser.result_list_table.action_dropdown.unlock_asset');
  } else {
    label = I18n.t('shared.asset_browser.result_list_table.action_dropdown.lock_asset');
  }

  return (
      <ActionItem
          label={label}
          onClick={() => {
            onLockAsset(view);
          }}
      />
  );
};

export default LockAssetActionItem;
