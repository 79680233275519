// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

// Project Imports
import Dropdown from 'common/components/Dropdown';
import { getFirstActionableElement } from 'common/a11y';
import I18n from 'common/i18n';

// Constants
import {
  getSelectByFiscalYearStartAndEndDates,
  getSelectByYearStartAndEndDates,
  SINGLE_SELECT_BY
} from 'common/dates';
import { FilterOrderConfig, FilterSortOption, FILTER_SORTING } from 'common/authoring_workflow/constants';
import { PicklistOption } from '../Picklist/index';
import { SoqlFilter } from './SoqlFilter';
import { FilterBarColumn } from './types';
import moment from 'moment';

const scope = 'shared.components.filter_bar.config';

export interface FilterConfigProps {
  column: FilterBarColumn;
  filter: SoqlFilter;
  onUpdate: (filter: SoqlFilter) => void;
}

interface FilterConfigState {
  singleSelectBy: string;
  orderBy: FilterOrderConfig;
}

class FilterConfig extends Component<FilterConfigProps, FilterConfigState> {
  static propTypes = {
    column: PropTypes.object.isRequired,
    filter: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired
  };

  configElement = React.createRef<HTMLDivElement>();

  constructor(props: FilterConfigProps) {
    super(props);

    _.bindAll(this, ['onChangeSingleSelectBy', 'setFilterHidden', 'setFilterSingleValue']);
    const defaultFilterOrderBy = _.get(FILTER_SORTING[0], 'orderBy');
    this.state = {
      singleSelectBy: _.get(props, 'filter.singleSelect.by', 'day'),
      orderBy: _.get(props, 'filter.orderBy', defaultFilterOrderBy)
    };
  }

  componentDidMount(): void {
    const actionableElement = getFirstActionableElement(this.configElement.current);
    if (actionableElement) {
      actionableElement.focus();
    }
  }

  setFilterHidden(isHidden: boolean): () => void {
    return () => {
      const { filter, onUpdate } = this.props;
      const newFilter = _.merge({}, filter, {
        isHidden
      });

      onUpdate(newFilter);
    };
  }

  setFilterSingleValue(bool: boolean): () => void {
    return () => {
      const { filter, onUpdate } = this.props;
      let newFilter;

      if (bool && this.isCalendarDateFilter()) {
        newFilter = _.merge({}, filter, {
          singleSelect: {
            by: this.state.singleSelectBy
          }
        });
      } else {
        newFilter = _.merge({}, filter);

        if (bool) {
          _.set(newFilter, 'singleSelect', true);
        } else {
          _.unset(newFilter, 'singleSelect');
        }
      }

      onUpdate(newFilter);
    };
  }

  onChangeSingleSelectBy(newValue: PicklistOption): void {
    const { filter, onUpdate } = this.props;
    const filterUpdate : { arguments?: { start: string, end: string }, singleSelect: { by: string }} = {
      singleSelect: {
        by: newValue.value
      }
    };

    // Update start and end arguments
    if (filter.arguments && !Array.isArray(filter.arguments)) {
      let start, end;
      if (newValue.value === SINGLE_SELECT_BY.FISCAL_YEAR) {
        const endMoment = moment(filter.arguments.end);
        [start, end] = getSelectByFiscalYearStartAndEndDates(endMoment);
      } else {
        const startMoment = moment(filter.arguments.start).month(0).date(1);
        [start, end] = getSelectByYearStartAndEndDates(startMoment);
      }

      filterUpdate.arguments = { start, end };
    }
    const newFilter = _.merge({}, filter, filterUpdate);

    this.setState({
      singleSelectBy: newValue.value
    });

    onUpdate(newFilter);
  }

  onChangeFilterSortBy = (newValue: PicklistOption & FilterSortOption): void => {
    const { filter, onUpdate } = this.props;
    const newFilter = _.merge({}, filter, { orderBy: newValue.orderBy });

    this.setState({ orderBy: newValue.orderBy });

    onUpdate(newFilter);
  };

  isCalendarDateFilter(): boolean {
    return this.props.column.dataTypeName === 'calendar_date';
  }

  renderDateTypeDropdown(): JSX.Element | null {
    if (!this.isCalendarDateFilter()) {
      return null;
    }

    const dropdownProps = {
      disabled: !this.props.filter.singleSelect,
      onSelection: this.onChangeSingleSelectBy,
      options: [
        { title: I18n.t('single_select_by_day', { scope }), value: SINGLE_SELECT_BY.DAY },
        { title: I18n.t('single_select_by_month', { scope }), value: SINGLE_SELECT_BY.MONTH },
        { title: I18n.t('single_select_by_year', { scope }), value: SINGLE_SELECT_BY.YEAR },
        { title: I18n.t('single_select_by_fiscal_year', { scope }), value: SINGLE_SELECT_BY.FISCAL_YEAR }
      ],
      size: 'small',
      value: this.state.singleSelectBy
    };

    const fiscalYearWindow = _.get(window, 'socrata.fiscalYearConfig.fy_filters_enabled', false);
    if (!fiscalYearWindow) {
      // Remove fiscal year option if disabled by feature flag or admin panel config
      // Pushing it doesn't work because of typescript's aggressive implicit typing
      dropdownProps.options.pop();
    }

    return <Dropdown {...dropdownProps} />;
  }

  shouldRenderFilterSelection(): boolean {
    const { column } = this.props;

    return (
      column.dataTypeName === 'number' ||
      column.dataTypeName === 'calendar_date' ||
      column.dataTypeName === 'text'
    );
  }

  shouldRenderFilterSortSelection(): boolean {
    return _.get(this.props, 'column.dataTypeName') === 'text';
  }

  renderFilterSelection(): JSX.Element {
    const { filter } = this.props;
    const singleValueContainerProps = {
      className: classNames(null, {
        'filter-single-value-date-container': this.isCalendarDateFilter()
      })
    };

    return (
      <form className="filter-options">
        <label>{I18n.t('filter_selection', { scope })}</label>
        <div className="radiobutton">
          <div>
            <input
              checked={!filter.singleSelect}
              id="filter-multiple-values"
              onChange={this.setFilterSingleValue(false)}
              type="radio"
            />
            <label htmlFor="filter-multiple-values">
              <span className="fake-radiobutton" />
              <span className="option-label">{I18n.t('multiple_values', { scope })}</span>
            </label>
          </div>
          <div {...singleValueContainerProps}>
            <input
              checked={!!filter.singleSelect}
              id="filter-single-value"
              onChange={this.setFilterSingleValue(true)}
              type="radio"
            />
            <label htmlFor="filter-single-value">
              <span className="fake-radiobutton" />
              <span className="option-label">{I18n.t('single_value', { scope })}</span>
            </label>
            {this.renderDateTypeDropdown()}
          </div>
        </div>
      </form>
    );
  }

  renderFilterSortingOption(option: PicklistOption): JSX.Element {
    return (
      <div>
        <span className={option.icon as string}></span> {option.title}
      </div>
    );
  }

  renderFilterSortSelection = (): JSX.Element => {
    const { orderBy } = this.state;
    const options = _.map(FILTER_SORTING, (option) =>
      _.extend({}, option, {
        render: this.renderFilterSortingOption,
        value: `${option.orderBy.parameter}-${option.orderBy.sort}`
      })
    );

    const filterSortDropdownAttributes = {
      id: 'filter-sorting-order-by-selection',
      onSelection: this.onChangeFilterSortBy,
      options,
      value: `${orderBy.parameter}-${orderBy.sort}`
    };

    return (
      <form className="filter-options">
        <label>{I18n.t('filter_sorting.title', { scope })}</label>
        <div>
          <Dropdown {...filterSortDropdownAttributes} />
          <div>{I18n.t('filter_sorting.description', { scope })}</div>
        </div>
      </form>
    );
  };

  render() {
    const { filter } = this.props;
    const filterSelection = this.shouldRenderFilterSelection() ? this.renderFilterSelection() : null;
    const filterSort = this.shouldRenderFilterSortSelection() ? this.renderFilterSortSelection() : null;

    return (
      <div className="filter-config" ref={this.configElement}>
        {filterSelection}
        <form className="filter-options">
          <label>{I18n.t('filter_visibility', { scope })}</label>
          <div className="radiobutton">
            <div>
              <input
                checked={!filter.isHidden}
                id="filter-interactive"
                onChange={this.setFilterHidden(false)}
                type="radio"
              />
              <label htmlFor="filter-interactive">
                <span className="fake-radiobutton" />
                <span className="option-label">{I18n.t('interactive_label', { scope })}</span>
                <div className="setting-description">{I18n.t('interactive_description', { scope })}</div>
              </label>
            </div>
            <div>
              <input
                checked={filter.isHidden}
                id="filter-hidden"
                onChange={this.setFilterHidden(true)}
                type="radio"
              />
              <label htmlFor="filter-hidden">
                <span className="fake-radiobutton" />
                <span className="option-label">{I18n.t('hidden_label', { scope })}</span>
                <div className="setting-description">{I18n.t('hidden_description', { scope })}</div>
              </label>
            </div>
          </div>
        </form>
        {filterSort}
      </div>
    );
  }
}

export default FilterConfig;
