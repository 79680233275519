import React, { Component } from 'react';
import PropTypes from 'prop-types';

import I18n from 'common/i18n';

class FunctionDocsParameterTable extends Component {
  static propTypes = {
    params: PropTypes.object.isRequired
  }

  render() {
    const { params } = this.props;

    return (
      <table className="query-editor-info-flannel-function-parameter-table">
        <thead>
          <tr>
            <th>{I18n.t('shared.query_editor.parameter')}</th>
            <th>{I18n.t('shared.query_editor.types')}</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(params).map(parameterName => (
            <tr key={parameterName} className="query-editor-info-flannel-function-signature-param">
              {/* Actual parameter name */}
              <td className="query-editor-info-flannel-function-parameter-name">{parameterName}</td>

              {/* Each parameter can be of multiple types */}
              <td>
                {params[parameterName].map(type => (
                  <div className="query-editor-info-flannel-function-parameter-type" key={`${parameterName}-${type}`}>
                    {type}
                  </div>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default FunctionDocsParameterTable;
