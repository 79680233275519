import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { AudienceScope, View } from 'common/types/view';
import I18n from 'common/i18n';

import {
  internalSharingEnabled,
  viewIsVizCanWithNonPublicDataSource,
  getParentScope
} from 'common/components/AccessManager/Util';
import { getCurrentScope } from 'common/components/AccessManager/sagas/Selectors';

import { classNameScope } from './index';
import ManagePublishedTo from './ManagePublishedTo';

interface BottomSectionProps {
  view: Partial<View>;

  visualizationCanvasHasPublicDataSource?: boolean;

  /** Whether or not to show the "Publishing this asset will go through approvals" message */
  showApprovalMessage: boolean;
}

const BottomSection: FunctionComponent<BottomSectionProps> = ({
  view,
  showApprovalMessage,
  visualizationCanvasHasPublicDataSource
}) => {
  const proposedScope = useSelector(getCurrentScope);
  const scope = proposedScope === AudienceScope.Public ? 'public' : 'internal';
  return (
    <>
      {internalSharingEnabled() && (
        <>
          {/* this lists users with the "published viewer" grant, which only exists when strict_permissions is on */}
          <ManagePublishedTo />
          <div
            className={`alert info ${
              // if the approval message isn't shown, we still want to take up some space
              showApprovalMessage
                ? `${classNameScope}--warning-message`
                : `${classNameScope}--warning-message-placeholder`
            }`}
          >
            {I18n.t(`shared.site_chrome.access_manager.audience.${scope}_approval_notice`)}
          </div>
        </>
      )}
      {viewIsVizCanWithNonPublicDataSource(view, visualizationCanvasHasPublicDataSource) && (
        <div
          className={`alert warning ${classNameScope}--warning-message`}
          dangerouslySetInnerHTML={{
            __html: I18n.t(
              `shared.site_chrome.access_manager.audience.warning_about_parent.${getParentScope(view)}`
            )
          }}
        />
      )}
    </>
  );
};

export default BottomSection;
