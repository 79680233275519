import { fetchJsonWithParsedError } from 'common/http';
import { View } from 'common/types/view';
import { isDefaultView } from 'common/views/view_types';
import { find, filter } from 'lodash';

export const enrollDataset = async (view: View): Promise<void> => {
  const url = `/api/archival?${new URLSearchParams({
    id: view.id,
    method: 'enroll'
  }).toString()}`;

  await fetchJsonWithParsedError(url, {
    method: 'POST'
  });

  // replication endpoint is not synchronous? this is a hack...
  return new Promise((resolve, reject) => {
    let i = 0;
    const attempts = 10;
    const check = async () => {
      const done = await isEnrolled(view);
      if (done) {
        resolve();
      } else {
        i = i + 1;
        if (i < attempts) {
          setTimeout(check, 1000);
        } else {
          reject();
        }
      }
    };
    check();
  });
};

export const unenrollDataset = async (view: View): Promise<void> => {
  const url = `/api/archival?${new URLSearchParams({
    id: view.id,
    method: 'unenroll'
  }).toString()}`;

  // consider polling until dataset is fully removed from the secondary

  return await fetchJsonWithParsedError(url, {
    method: 'DELETE'
  });
};

export const isEnrolled = async (view: Partial<View>): Promise<boolean> => {
  if (!view.id || !isDefaultView(view)) return Promise.resolve(false);
  const url = `/api/views/${view.id}/replication.json`;
  const resp = await fetchJsonWithParsedError(url);
  const archivalEntry = filter(resp?.secondary_versions || [], (dv) => dv.name === 'archival');
  const inSecondary = find(archivalEntry, (dv) => (dv.pending_drop == undefined) ? true : !dv.pending_drop);
  return !!inSecondary;
};

export interface ArchivalStatus {
  type: 'done' | 'running' | 'failed' | 'not_started';
}
type ArchivePoller = () => Promise<ArchivalStatus>;

const archiveStatus = (view: View, dataVersion: number): Promise<ArchivalStatus> => {
  const url = `/api/archival?${new URLSearchParams({
    id: view.id,
    version: `${dataVersion}`,
    method: 'status'
  }).toString()}`;

  return fetchJsonWithParsedError(url, {
    method: 'GET'
  });
};

export const createArchive = async (view: View, dataVersion: number): Promise<ArchivePoller> => {
  const url = `/api/archival?${new URLSearchParams({
    id: view.id,
    method: 'createArchive',
    version: `${dataVersion}`
  }).toString()}`;

  await fetchJsonWithParsedError(url, {
    method: 'PUT'
  });

  return () => archiveStatus(view, dataVersion);
};

export const archiveCSVLink = (view: View, version: number): string => {
  return `/api/archival.csv?${new URLSearchParams({
    id: view.id,
    version: `${version}`,
    method: 'export'
  }).toString()}`;
};



export const changeArchiveVisibility = (view: View, version: number, visible: boolean): Promise<never> => {
  const url = `/api/archival?${new URLSearchParams({
    id: view.id,
    version: `${version}`,
    method: 'setVisibility',
    visible: `${visible}`
  }).toString()}`;

  return fetchJsonWithParsedError(url, {
    method: 'PUT'
  });
};
