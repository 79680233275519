/* eslint react/jsx-boolean-value: 0 */
import React from 'react';
import AceEditor from 'react-ace';

import * as ace from 'ace-builds';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/ext-language_tools';
import { Option, none, some, option } from 'ts-option';
import { fetchTranslation } from 'common/locale';
import { Scope } from 'common/types/soql';
import { SimpleCompilationResult, CompilationStatus } from 'common/types/compiler';
import { Matcher, Match } from 'common/components/SoQLDocs';
import './SoQLEditor.scss';
// @ts-ignore SoQLMode implicitly has type any, SoQLAceMode should be converted to ts
import SoQLMode from './SoQLAceMode';
import _ from 'lodash';
const t = (k: string) => fetchTranslation(k, 'dataset_management_ui.soql_editor');

const { Range } = ace.require('ace/range');
const langTools = ace.require('ace/ext/language_tools');
const { TokenIterator } = ace.require('ace/token_iterator');


export interface Props {
  scope: Scope;
  compilationResult: Option<SimpleCompilationResult>;
  soql: string;
  matcher: Matcher;
  selectedFunction: Option<Match>;
  onChange: (soql: string) => void;
  onChangeSelectedFunction: (match: Option<Match>) => void;
  height: string;
  soqlMode: boolean | undefined;
  needsResize?: boolean;
  onResizeComplete?: () => void;
  onLoad?: (editor: Editor) => void;
  isDisabled?: boolean;
}

interface Position {
  row: number;
  column: number;
}

export type Editor = any;

interface State {
  selectedPosition: Option<Position>;
}

interface Token {
  type: string;
}

const tokenHasDocs = (token: Token) => token.type === 'identifier' || token.type === 'keyword.operator';

export class SoQLEditor extends React.Component<Props, State> {
  marker: Option<number> = none;
  popup: Option<any> = none;
  editor: Option<Editor> = none;
  tooltipMarkers: number[] = [];
  state: State = {
    selectedPosition: none
  };

  componentDidMount = () => {
    document.addEventListener('keyup', this.captureEscKey, true);
  };

  componentWillUnmount = () => {
    this.maybeRemovePopupListener();
    document.removeEventListener('keyup', this.captureEscKey, true);
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.compilationResult !== this.props.compilationResult) {
      this.addCompilationFailureMarker(nextProps.compilationResult);
    }
  }

  componentDidUpdate = (prevProps: Props) => {
    /* Update the tooltip markers when the soql string has changes. It can change when:
     *   The editor has loaded but the compilation process has not finished.
     *   The user edits via the editor. */
    if (prevProps.soql !== this.props.soql) {
      this.addTooltipMarkers();
    }

    if (this.props.needsResize) {
      const waitUntilEditorReady = () => {
        if (this.editorCanBeResized()) {
          this.editor.match({ some: (e: Editor) => e.resize(), none: _.noop });
          this.props.onResizeComplete ? this.props.onResizeComplete() : _.noop();
        } else {
          setTimeout(waitUntilEditorReady, 500);
        }
      };
      waitUntilEditorReady();
    }
  };

  // Running editor.resize() appears to do nothing when the editor.renderer.container
  // hasn't been rendered at least once and thus acquired a width/height. This function
  // basically determines whether or not that's happened.
  editorCanBeResized = () => this.editor.match({
    some: (editor) => {
      const { clientWidth, clientHeight } = editor.renderer?.container;
      return clientWidth > 0 && clientHeight > 0;
    },
    none: () => false
  });

  onEditorChange = (newsoql: string) => {
    this.props.onChange(newsoql);
  };

  onChangeSelection = () => {
    this.popup.forEach((popup) => {
      const row = popup.getData(popup.getRow());
      this.props.onChangeSelectedFunction(some(row));
    });
  };

  getSignatureTooltip = (): JSX.Element | null => {
    if (this.props.selectedFunction.isEmpty || this.state.selectedPosition.isEmpty) return null;
    // why are we jumping through all these hoops to compute the left offset?
    // i'd like the tooltip to be anchored to the token start, not to the absolute position of
    // the mouse.  this will make it look less shitty; as you move the mouse over a token, the tooltip
    // will stay anchored to the beginning of the token, rather than following your mouse.
    const hoveredPosition = this.state.selectedPosition.get;
    const hoveredFunction = this.props.selectedFunction.get;

    return this.editor
      .flatMap<JSX.Element | null>((editor) => {
        const token = editor.session.getTokenAt(hoveredPosition.row, hoveredPosition.column);

        if (!token) return none;

        const { start: column } = token;

        const offsets = editor.renderer.$cursorLayer.getPixelPosition({ row: hoveredPosition.row, column });

        const top = offsets.top + 32;
        const left = offsets.left + 32;

        return some(
          <div className="signature-tooltip" style={{ top, left }}>
            <div className="arrow-up"></div>
            {hoveredFunction.doc(true)}
          </div>
        );
      })
      .getOrElseValue(null);
  };

  // Called once when mounting AceEditor, not called again when props change.
  bootstrapEditor = () => {
    const { matcher: completer, soqlMode } = this.props;

    return (editor: Editor) => {
      this.editor = some(editor);

      if (this.props.onLoad) {
        // used to give external components editor instance access, for cursor position info
        this.props.onLoad(editor);
      }

      // when we updated react-ace to 9.2.0 it started applying themes to the autocomplete popup
      // this is us undoing that change, as the colors are not as accessible
      const theme = ace.require('ace/theme/monokai');
      theme.isDark = false;
      editor.setTheme(theme);

      if (soqlMode) {
        editor.getSession().setMode(new SoQLMode());
      }

      this.addTooltipMarkers();
      this.addCompilationFailureMarker(this.props.compilationResult);

      editor.on('mousemove', (e: any) => {
        const { row, column } = e.getDocumentPosition();
        const token = editor.session.getTokenAt(row, column);
        if (token && tokenHasDocs(token)) {
          this.maybeDisplayDocsForToken(token.value, some({ row, column }));
        } else if (this.state.selectedPosition.isDefined) {
          // clear the selected position if we have one
          this.setState({ selectedPosition: none });
        }
      });

      const aceCompleter = {
        getCompletions: function (
          this: any,
          theEditor: Editor,
          session: any,
          pos: Position,
          prefix: string,
          callback: any
        ) {
          const matches = completer(prefix, false);

          if (theEditor.completer && theEditor.completer.popup) {
            const popup = theEditor.completer.popup;
            this.popup = some(popup);
            popup.container.style.width = '500px';
            popup.resize();
            this.maybeRemovePopupListener();
            popup.on('changeSelection', this.onChangeSelection);
          }

          callback(null, matches);
        }.bind(this)
      };
      langTools.setCompleters([]);
      langTools.addCompleter(aceCompleter);
    };
  };

  // argh this is a disgusting mess, idk why ace won't let me remove it properly
  // somehow the event is firing on an unmounted component
  maybeRemovePopupListener = () => {
    this.popup.forEach((popup) => popup.removeAllListeners('changeSelection'));
  };

  maybeDisplayDocsForToken = (token: string, maybePosition: Option<Position>) => {
    const completions = this.props.matcher(token, true);
    if (completions.length) {
      this.setState({
        selectedPosition: maybePosition
      });
      this.props.onChangeSelectedFunction(option(completions[0]));
    }
  };

  addTooltipMarkers = () => {
    this.editor.forEach((editor) => {
      const session = editor.session;

      this.tooltipMarkers.forEach((marker) => session.removeMarker(marker));

      const it = new TokenIterator(session, 0, 0);
      let token = it.getCurrentToken();

      while (token) {
        if (tokenHasDocs(token)) {
          const row = it.getCurrentTokenRow();
          const col = it.getCurrentTokenColumn();

          const marker = session.addMarker(
            new Range(row, col, row, col + token.value.length),
            'identifier-with-docs',
            'text',
            true
          );

          this.tooltipMarkers.push(marker);
        }
        token = it.stepForward();
      }
    });
  };

  captureEscKey = (event: any) => {
    const escapeKeyCode = 27;
    this.editor.forEach((editor) => {
      if (event.keyCode === escapeKeyCode && editor.$isFocused) {
        // we need to stop the event from propagating so that the parent modal doesn't also close
        event.stopPropagation();
      }
    });
  };

  addCompilationFailureMarker = (compilationResult: Option<SimpleCompilationResult>) => {
    this.editor.forEach((editor) => {
      this.marker.forEach((marker) => editor.session.removeMarker(marker));
      /* eslint @typescript-eslint/no-shadow: "warn" */
      compilationResult.forEach((compilationResult) => {
        if (compilationResult.type == CompilationStatus.Failed) {
          const { row, column } = compilationResult.soql_exception.position;
          this.marker = some(
            editor.session.addMarker(
              new Range(
                row - 1,
                column,
                row - 1,
                column + 1
              ),
              'compiler-error-underline',
              'fullLine',
              true
            )
          );
        }
      });
    });
  };

  render() {
    return (
      <div className="editor-wrapper" data-testid="soql-ace-editor">
        {this.props.isDisabled && <div className="editor-overlay"></div>}
        {this.getSignatureTooltip()}
        {this.props.scope.length > 0 && (
          <AceEditor
            theme="monokai"
            mode="sql"
            width="100%"
            height={this.props.height}
            fontSize={20}
            tabSize={2}
            showGutter={true}
            onChange={this.onEditorChange}
            name={'column-editor'}
            editorProps={{ $blockScrolling: true }}
            enableBasicAutocompletion={true}
            enableLiveAutocompletion={true}
            defaultValue={this.props.soql}
            value={this.props.soql}
            onLoad={this.bootstrapEditor()}
          />
        )}
      </div>
    );
  }
}

export default SoQLEditor;
