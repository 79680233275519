import React, { Component } from 'react';
import PropTypes from 'prop-types';

import I18n from 'common/i18n';
import SocrataIcon from 'common/components/SocrataIcon';

import Docs from './Docs';
import FunctionDocsParameterTable from './FunctionDocsParameterTable';

/**
 * Given a function, will return a description of its parameters and return type
 */
class FunctionDocs extends Component {
  static propTypes = {
    func: PropTypes.shape({
      description: PropTypes.string.isRequired,
      fullpath: PropTypes.string.isRequired,
      /* eslint-disable dot-notation */
      'function': PropTypes.string.isRequired,
      /* eslint-enable dot-notation */
      params: PropTypes.object,
      returns: PropTypes.string,
      title: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  }

  renderFooter = () => {
    const { func } = this.props;
    const { fullpath } = func;

    return (
      <div className="query-editor-info-flannel-footer">
        <span>{I18n.t('shared.query_editor.view_full_function_docs')}</span>
        <a
          className="query-editor-info-flannel-external-link"
          href={`https://dev.socrata.com${fullpath}`}
          target="_blank" rel="noreferrer">
          {I18n.t('shared.query_editor.link_text')}
          <SocrataIcon
            className="query-editor-info-flannel-external-icon"
            name="external" />
        </a>
      </div>
    );
  }
  render() {
    const { func } = this.props;
    const { description, params, returns, title, name } = func;
    const signature = func['function']; // eslint-disable-line

    return (
      <Docs title={title} description={description} name={name} renderFooter={this.renderFooter}>
        <div className="query-editor-info-flannel-function-doc">
          <span><strong>{I18n.t('shared.query_editor.signature')}</strong> {signature}</span>
          <FunctionDocsParameterTable params={params} />
          <span><strong>{I18n.t('shared.query_editor.returns')}</strong> {returns}</span>
        </div>
      </Docs>
    );
  }
}

export default FunctionDocs;
