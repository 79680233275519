import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AceEditor from 'react-ace';
import * as ace from 'ace-builds';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-sql'; // we're actually using a custom mode but this is the 'default' one before that gets set
import SoQLMode from './SoQLAceMode';
import Functions from '../Lang/Functions';
import Keywords from '../Lang/Keywords';
import { queryChanged, showDocumentation, fetchRows, editorLoaded } from '../redux/QueryEditorActions';
import { filterLegacyAndHiddenColumns } from '../Util';

const langTools = ace.require('ace/ext/language_tools');

/**
 * Basic Ace editor with SoQL additions
 */
export class Editor extends Component {
  static propTypes = {
    query: PropTypes.string.isRequired,
    onQueryChanged: PropTypes.func.isRequired
  };

  static defaultEditorProps = {
    theme: 'monokai',
    width: '100%',
    height: '50%',
    fontSize: 15,
    tabSize: 2,
    showGutter: false,
    showPrintMargin: false,
    name: 'query-editor-ace-editor',
    editorProps: { $blockScrolling: true },
    enableBasicAutocompletion: true,
    enableLiveAutocompletion: true,
    focus: true,

    // Default mode is required here, but we actually use our custom SoQL mode
    // (see componentDidMount)
    mode: 'sql'
  };

  onEditorLoad = (editor) => {
    const { onRunQuery, onLoad } = this.props;
    this.editorInstance = editor;

    // Hold onto the instance of the editor for things like calculating and moving the cursor
    onLoad(editor);

    // when we updated react-ace to 9.2.0 it started applying themes to the autocomplete popup
    // this is us undoing that change, as the colors are not as accessible
    const theme = ace.require('ace/theme/monokai');
    theme.isDark = false;
    editor.setTheme(theme);

    // set the mode to our custom SoQL mode
    editor.getSession().setMode(new SoQLMode());

    // add shift+enter command to run query
    editor.commands.addCommand({
      name: 'Run Query',
      exec: onRunQuery,
      bindKey: { mac: 'shift+enter', win: 'shift+enter' }
    });

    // we want to remove the "local" completer (at least) and add our own
    // this way we get columns etc. in the completion and proper casing
    langTools.setCompleters([]);
    langTools.addCompleter({ getCompletions: this.getCompletions });
  }

  getCompletions = (editor, session, pos, prefix, callback) => {
    const { view } = this.props;
    const columns = filterLegacyAndHiddenColumns(view.columns);

    // build up an array of column, functions, and keywords
    const completions = columns.map(column => (
      {
        caption: column.fieldName,
        value: column.fieldName,
        meta: `${column.dataTypeName} column`
      })
    ).concat(
      Object.keys(Functions).map(func => (
        {
          caption: func,
          value: func,
          meta: 'function'
        })
      )
    ).concat(
      Keywords.map(keyword => (
        {
          caption: keyword.name,
          value: keyword.name,
          meta: 'keyword'
        })
      )
    );

    callback(null, completions);
  }

  render() {
    const { query, onQueryChanged } = this.props;

    return (
      <AceEditor
        {...Editor.defaultEditorProps}
        onChange={onQueryChanged}
        defaultValue={query}
        value={query}
        onLoad={this.onEditorLoad} />
    );
  }
}

const mapStateToProps = state => ({
  query: state.editor.query,
  view: state.editor.view
});

const mapDispatchToProps = dispatch => ({
  onQueryChanged: query => dispatch(queryChanged(query)),
  onShowDocumentation: (docType, name) => dispatch(showDocumentation(docType, name)),
  onRunQuery: () => dispatch(fetchRows()),
  onLoad: editor => dispatch(editorLoaded(editor))
});

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
