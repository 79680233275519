import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';

import I18n from 'common/i18n';
import DeleteAlertModal from 'common/components/DeleteAlertModal/DeleteAlertModal';
import { Modal, ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';

import AdvancedAlert from './AdvancedAlert';
import AlertInfo from './AlertInfo';
import AlertFooter from './AlertFooter';
import AlertTriggerPage from './components/AlertTriggerPage';
import AlertMetaPage from './components/AlertMetaPage';
import CreateCustomAlert from './CustomAlert';
import {
  ALERT_MODAL_PAGES,
  ALERT_QUERY_TYPE,
  ALERT_SNOOZE_PERIODIC,
  ALERT_TYPES,
  CUSTOM_ALERT_TRIGGER_TYPE,
  CUSTOM_ALERT_TYPES,
  DEFAULT_ALERT_TIME_ZONE,
  DEFAULT_TIME_BASED_ALERT_SENT_TIME,
  DEFAULT_TIME_BASED_ALERT_SENT_DAYS,
  FREQUENCY_OPTIONS,
  PERIODIC_TYPE
} from './constants';
import CreateAlertApi from './api/CreateAlertApi';
import './index.scss';
import { formatAlertParams } from './helpers/AlertHelpers';

/**
  CreateAlertModal - Surface to create/edit alerts
  Alerts types:
     Custom alert   - Alert created using the Soql builder
     Advanced alert - Alert created by typing the raw soql query

 @prop alert              - Alert object with alert params. (to be saved/got from the 'notifications
                            and alerts' service. We use camel case for all the keys in the object to
                            meet notifications and alert service's api.)
 @prop editAlertType      - 'custom'|'abstract'
 @prop editMode           - enable or disable edit mode (in edit mode, we show delete button and ...)
 @pops onClose            - called when the modal is closed
*/
class CreateAlertModal extends Component {
  state = {
    alertDescription: '',
    alertName: '',
    alerPeriodicFrequency: FREQUENCY_OPTIONS.DAILY,
    alertSentDays: DEFAULT_TIME_BASED_ALERT_SENT_DAYS,
    alertSentTime: DEFAULT_TIME_BASED_ALERT_SENT_TIME,
    alertSnoozeEnabled: false,
    alertSnoozeTime: '1',
    alertSnoozePeriodic: ALERT_SNOOZE_PERIODIC.DAYS,
    alertTimeZone: DEFAULT_ALERT_TIME_ZONE,
    alertType: ALERT_TYPES.CUSTOM_ALERT,
    currentAlertModalPage: ALERT_MODAL_PAGES.ALERT_TYPE_PAGE,
    customAlert: [],
    customAlertTriggerType: CUSTOM_ALERT_TRIGGER_TYPE.ROLLING,
    customAlertType: CUSTOM_ALERT_TYPES.THRESHOLD,
    enableSaveButton: false,
    enableValidationInfo: false,
    isInvalidQuery: false,
    isInvalidWebhook: false,
    isLoading: false,
    rawSoqlQuery: '',
    showDeleteAlertModal: false,
    showInfoText: false,
    viewId: '',
    webhookUrl: ''
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    // eslint-disable-line camelcase
    const { alert, editAlertType, editMode } = this.props;
    // customAlertType will be changed based on alert periodic type
    // if periodicType is time based then customAlertType will be time based alert
    // other wise it will be Threshold/Incidents
    const periodicType = _.get(alert, 'alertTriggerType');
    const customAlertType =
      periodicType === PERIODIC_TYPE.TIME_BASED ? CUSTOM_ALERT_TYPES.TIME_BASED : _.get(alert, 'changesOn');
    const alertSentDays = _.get(alert, 'alertSendingDays', []);

    if (editMode) {
      // setting alert value in edit mode
      this.setState({
        alertType:
          editAlertType === ALERT_QUERY_TYPE.CUSTOM_ALERT
            ? ALERT_TYPES.CUSTOM_ALERT
            : ALERT_TYPES.ADVANCED_ALERT,
        alertName: _.get(alert, 'name'),
        alertDescription: _.get(alert, 'description'),
        alerPeriodicFrequency: _.get(alert, 'periodicFrequency'),
        alertSentDays: _.isEmpty(alertSentDays) ? DEFAULT_TIME_BASED_ALERT_SENT_DAYS : alertSentDays,
        alertSentTime: alert.alertSendingTime || DEFAULT_TIME_BASED_ALERT_SENT_TIME,
        alertSnoozeEnabled: _.get(alert, 'isSnoozeEnabled'),
        alertSnoozeTime: _.get(alert, 'snoozeTimePeriod'),
        alertSnoozePeriodic: _.get(alert, 'snoozeFrequency'),
        alertTimeZone: alert.timeZone || DEFAULT_ALERT_TIME_ZONE,
        rawSoqlQuery: _.get(alert, 'queryString'),
        viewId: _.get(alert, 'datasetUid'),
        customAlert: _.get(alert, 'abstractParams', []),
        customAlertTriggerType: _.get(alert, 'recurrence'),
        currentAlertModalPage: ALERT_MODAL_PAGES.PARAMETERS_PAGE,
        customAlertType,
        webhookUrl: _.get(alert, 'webhookUrl')
      });
    } else {
      // using sessionData view id in create alert mode
      this.setState({ viewId: _.get(window, 'sessionData.viewId') });
    }
  }

  onWebhookUrlChange = (webhookUrl) => {
    this.setState({ webhookUrl });
  };

  onAlertTypeChange = (alertType) => {
    this.setState({ alertType });
  };

  onAlertPeriodicFrequencyChange = (alerPeriodicFrequency) => {
    this.setState({ alerPeriodicFrequency });
  };

  onAlertSentDaysChange = (alertSentDays) => {
    this.setState({ alertSentDays });
  };

  onAlertTimeZoneChange = (alertTimeZone) => {
    this.setState({ alertTimeZone });
  };

  onAlertSentTimeChange = (alertSentTime) => {
    this.setState({ alertSentTime });
  };

  onCustomAlertTypeChange = (customAlertType) => {
    this.setState({ customAlertType });
  };

  onTriggerTypeChange = (customAlertTriggerType) => {
    this.setState({ customAlertTriggerType });
  };

  onAlertSnoozeTimeChange = (alertSnoozeTime) => {
    this.setState({ alertSnoozeTime });
  };

  onAlertSnoozePeriodicChange = (alertSnoozePeriodic) => {
    this.setState({ alertSnoozePeriodic });
  };

  onToggleAlertSnooze = () => {
    const { alertSnoozeEnabled } = this.state;
    this.setState({ alertSnoozeEnabled: !alertSnoozeEnabled });
  };

  onDeleteButtonClick = () => {
    this.setState({ showDeleteAlertModal: true });
  };

  onDeleteModalClose = () => {
    this.setState({ showDeleteAlertModal: false });
  };

  onDeleteSuccess = () => {
    this.props.onClose({ isDeleted: true });
  };

  onAlertNameChange = (event) => {
    this.setState({
      alertName: event.target.value,
      enableSaveButton: false,
      enableValidationInfo: false
    });
  };

  onAlertDescriptionChange = (event) => {
    this.setState({ alertDescription: event.target.value });
  };

  onRawSoqlQueryChange = (event) => {
    this.setState({
      enableSaveButton: false,
      enableValidationInfo: false,
      rawSoqlQuery: event.target.value
    });
  };

  onCreateAlertClick = () => {
    const { alertName } = this.state;
    const { alert, editMode, onClose } = this.props;
    let alertPromise = null;

    if (editMode) {
      alertPromise = CreateAlertApi.update(this.getAlertParams(), alert.id);
    } else {
      alertPromise = CreateAlertApi.create(this.getAlertParams());
    }

    if (_.isEmpty(alertName)) {
      this.setState({ enableValidationInfo: true });
    } else {
      this.setState({ isLoading: true });
      alertPromise
        .then(() => {
          this.setState({ isLoading: false });
          if (editMode) {
            onClose();
          } else {
            onClose({ isSaved: true });
          }
        })
        .catch((error) => {
          error.response.json().then((e) => {
            if (_.get(e, 'errors.webhook_url')) {
              this.setState({ isInvalidWebhook: true, isLoading: false, enableValidationInfo: true });
            } else {
              this.setState({ isInvalidQuery: true, isLoading: false, enableValidationInfo: true });
            }
          });
        });
    }
  };

  onCurrentAlertModalPageChange = (page) => {
    this.setState({
      currentAlertModalPage: page,
      enableSaveButton: false,
      enableValidationInfo: false
    });
  };

  onValidateAlert = () => {
    const { alertType } = this.state;
    const params = this.getAlertParams();
    let promise;

    this.setState({ enableValidationInfo: true, isLoading: true });

    if (alertType === ALERT_TYPES.CUSTOM_ALERT) {
      promise = CreateAlertApi.validateCustomAlert(params);
    } else {
      promise = CreateAlertApi.validate(params);
    }
    promise
      .then((response) => {
        if (_.get(response, 'valid', false)) {
          this.setState({ isInvalidQuery: false, enableSaveButton: true, isLoading: false });
        } else {
          this.setState({ isInvalidQuery: true, isLoading: false, enableSaveButton: false });
        }
      })
      .catch((error) => {
        this.setState({ isInvalidQuery: true, isLoading: false, enableSaveButton: false });
        console.log(error);
      });
  };

  onCustomAlertChange = (customAlert) => {
    this.setState({
      customAlert: customAlert,
      enableSaveButton: false,
      enableValidationInfo: false
    });
  };

  getAlertParams = () => {
    const { alert } = this.props;
    return formatAlertParams(this.state, alert);
  };

  translationScope = 'shared.components.create_alert_modal';

  renderAlertTypePage() {
    const { alertType } = this.state;
    const alertTypeOptions = [
      {
        id: 'custom-alert-option',
        type: ALERT_TYPES.CUSTOM_ALERT,
        title: I18n.t('custom_alert_title', { scope: this.translationScope }),
        description: I18n.t('custom_alert_description', { scope: this.translationScope })
      },
      {
        id: 'advanced-alert-option',
        type: ALERT_TYPES.ADVANCED_ALERT,
        title: I18n.t('advanced_alert_title', { scope: this.translationScope }),
        description: I18n.t('advanced_alert_description', { scope: this.translationScope })
      }
    ];

    const alertTypeContent = alertTypeOptions.map((alert, index) => (
      <li className="alert-type-option" key={index}>
        <input
          type="radio"
          id={alert.id}
          checked={alertType === alert.type}
          onChange={() => this.onAlertTypeChange(alert.type)}
          name="selector"
        />
        <label htmlFor={alert.id} className="alert-type-title">
          {alert.title}
        </label>
        <p>{alert.description}</p>
        <div className="custom-radio-button"></div>
      </li>
    ));

    return (
      <div>
        <div className="alert-type-title">
          {I18n.t('alert_type_page_title', { scope: this.translationScope })}
        </div>
        <ul className="alert-type-options">{alertTypeContent}</ul>
      </div>
    );
  }

  renderDeleteAlertModal() {
    const { showDeleteAlertModal } = this.state;
    const { alert } = this.props;
    if (showDeleteAlertModal) {
      return (
        <DeleteAlertModal
          onCancel={this.onDeleteModalClose}
          onDismiss={this.onDeleteModalClose}
          onDeleteSuccess={this.onDeleteSuccess}
          selectedAlertIds={[alert.id]}
        />
      );
    }

    return null;
  }

  renderAlertInfo() {
    const {
      alertName,
      currentAlertModalPage,
      enableValidationInfo,
      isInvalidQuery,
      isInvalidWebhook,
      isLoading
    } = this.state;
    return (
      <AlertInfo
        alertName={alertName}
        alertModalPage={currentAlertModalPage}
        enableValidationInfo={enableValidationInfo}
        isInvalidQuery={isInvalidQuery}
        isInvalidWebhook={isInvalidWebhook}
        isLoading={isLoading}
      />
    );
  }

  renderAlertParameterPage() {
    const { alertType, customAlert, viewId, rawSoqlQuery } = this.state;

    if (alertType === ALERT_TYPES.CUSTOM_ALERT) {
      return (
        <CreateCustomAlert
          customAlert={customAlert}
          viewId={viewId}
          onAlertPageOptionChange={this.onAlertPageOptionChange}
          onCustomAlertChange={this.onCustomAlertChange}
          onValidateClick={this.onValidateAlert}
        />
      );
    } else {
      return (
        <AdvancedAlert
          onRawSoqlQueryChange={this.onRawSoqlQueryChange}
          onValidateClick={this.onValidateAlert}
          rawSoqlQuery={rawSoqlQuery}
        />
      );
    }
  }

  renderModalFooter() {
    const { editMode, onClose } = this.props;
    const { alertName, currentAlertModalPage, enableSaveButton } = this.state;
    return (
      <ModalFooter className="footer-section">
        <AlertFooter
          alertName={alertName}
          currentAlertPage={currentAlertModalPage}
          editMode={editMode}
          enableSaveButton={enableSaveButton}
          onDeleteClick={this.onDeleteButtonClick}
          onPageCancel={() => onClose({})}
          onPageChange={this.onCurrentAlertModalPageChange}
          onSaveClick={this.onCreateAlertClick}
        />
      </ModalFooter>
    );
  }

  renderAlerPageContent() {
    const {
      alertName,
      alertType,
      alertDescription,
      alerPeriodicFrequency,
      alertSentDays,
      alertSentTime,
      alertSnoozeEnabled,
      alertSnoozeTime,
      alertSnoozePeriodic,
      alertTimeZone,
      customAlertType,
      currentAlertModalPage,
      customAlertTriggerType,
      webhookUrl
    } = this.state;

    if (currentAlertModalPage === ALERT_MODAL_PAGES.ALERT_TYPE_PAGE) {
      return this.renderAlertTypePage();
    } else if (currentAlertModalPage === ALERT_MODAL_PAGES.PARAMETERS_PAGE) {
      return this.renderAlertParameterPage();
    } else if (currentAlertModalPage === ALERT_MODAL_PAGES.TRIGGER_PAGE) {
      return (
        <AlertTriggerPage
          alertType={alertType}
          alertSentDays={alertSentDays}
          alertSentTime={alertSentTime}
          alertTimeZone={alertTimeZone}
          alertSnoozeEnabled={alertSnoozeEnabled}
          alertSnoozeTime={alertSnoozeTime}
          alertSnoozePeriodic={alertSnoozePeriodic}
          customAlertType={customAlertType}
          customAlertTriggerType={customAlertTriggerType}
          onAlertSentDaysChange={this.onAlertSentDaysChange}
          onAlertSentTimeChange={this.onAlertSentTimeChange}
          onAlertSnoozePeriodicChange={this.onAlertSnoozePeriodicChange}
          onAlertSnoozeTimeChange={this.onAlertSnoozeTimeChange}
          onAlertTimeZoneChange={this.onAlertTimeZoneChange}
          onCustomAlertTypeChange={this.onCustomAlertTypeChange}
          onTriggerTypeChange={this.onTriggerTypeChange}
          onAlertFrequencyChange={this.onAlertPeriodicFrequencyChange}
          onToggleAlertSnooze={this.onToggleAlertSnooze}
          onWebhookUrlChange={this.onWebhookUrlChange}
          periodicFrequency={alerPeriodicFrequency}
          webhookUrl={webhookUrl}
        />
      );
    } else if (currentAlertModalPage === ALERT_MODAL_PAGES.META_PAGE) {
      return (
        <AlertMetaPage
          alertDescription={alertDescription}
          alertName={alertName}
          onAlertDescriptionChange={this.onAlertDescriptionChange}
          onAlertNameChange={this.onAlertNameChange}
        />
      );
    }

    return null;
  }

  render() {
    const { editMode, onClose } = this.props;
    const containerStyle = {
      bottom: 'auto',
      margin: '40px auto',
      maxHeight: 'calc(100vh - 40px)',
      maxWidth: '800px',
      minHeight: '400px',
      overflow: ' auto',
      padding: '20px',
      paddingBottom: '0',
      position: 'relative'
    };
    let alertModalTitle;
    let modalContent;

    if (editMode) {
      alertModalTitle = I18n.t('edit_mode_title', { scope: this.translationScope });
    } else {
      alertModalTitle = I18n.t('title', { scope: this.translationScope });
    }

    modalContent = (
      <div>
        {this.renderAlertInfo()}
        {this.renderAlerPageContent()}
      </div>
    );

    return (
      <div className="create-alert-modal create-alert-modal-container">
        <Modal
          className="alert-setting-modal"
          onDismiss={onClose}
          containerStyle={containerStyle}>
          <ModalHeader className="modal-header-title" title={alertModalTitle} onDismiss={onClose} />

          <ModalContent className="modal-content">{modalContent}</ModalContent>

          {this.renderModalFooter()}
        </Modal>
        {this.renderDeleteAlertModal()}
      </div>
    );
  }
}

CreateAlertModal.defaultProps = {
  alert: {},
  editMode: false
};

CreateAlertModal.propTypes = {
  alert: PropTypes.shape({
    abstractParams: PropTypes.array,
    changesOn: PropTypes.string,
    datasetUid: PropTypes.string,
    name: PropTypes.string,
    queryString: PropTypes.string,
    recurrence: PropTypes.string
  }),
  editAlertType: PropTypes.string,
  editMode: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

export default CreateAlertModal;
