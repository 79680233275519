import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import AccordionContainer from './AccordionContainer';
import { ForgeAccordion } from '@tylertech/forge-react';
import _ from 'lodash';
import FeatureFlags from 'common/feature_flags';

interface IAccordionContainerProps extends PropsWithChildren<any> {}

const getDefaultPaneState = (children: any) => {
  const paneState: { [key: string]: boolean } = {};

  React.Children.forEach(children, (pane, index) => {
    if (pane) {
      const isOpen = (index === 0 && !pane.props.isFirstPaneClosedByDefault) || pane.props.isOpen;
      paneState[`pane-${index}`] = isOpen;
    }
  });

  return paneState;
};

const InternalAccordionContainer = (props: IAccordionContainerProps) => {
  const { children } = props;

  const prevState = useRef({});
  const [paneState, setPaneState] = useState({});
  const [firstRun, setFirstRun] = useState(true);

  const defaultPaneState = getDefaultPaneState(children);
  useEffect(() => {
    // When AccordionContainer is constructed, not all its AccordionPanes
    // are loaded.  So when the component updates we want to re-read in
    // the default open/collapsed state from any new panes, then apply the
    // current paneState over the top of the structure.
    const newPaneState = { ...defaultPaneState, ...prevState.current };
    if (!_.isEqual(prevState.current, newPaneState)) {
      prevState.current = newPaneState;
      setPaneState(newPaneState);
    }
  }, [defaultPaneState]);

  const handlePaneToggle = (paneId: string) => {
    const newPaneState = { ...paneState };
    newPaneState[paneId] = !newPaneState[paneId];
    setPaneState(newPaneState);
  };

  const renderPanes = () => {
    return React.Children.map(children, (pane, index) => {
      const onToggle = handlePaneToggle;
      const paneId = firstRun ? `pane-${index}` : pane.props.paneId;
      const isOpen = _.get(paneState, paneId, false);

      if (!pane) {
        return pane;
      }

      return React.cloneElement(pane, {
        isOpen,
        onToggle,
        paneId
      });
    });
  };

  return <ForgeAccordion>{renderPanes()}</ForgeAccordion>;
};

export const ForgeAccordionContainer = (props: PropsWithChildren<any>) => {
  const enableForgeTabs = FeatureFlags.valueOrDefault('enable_forge_authoring_tabs', false);
  if (enableForgeTabs) return <InternalAccordionContainer {...props} />;
  return <AccordionContainer {...props} />;
};
