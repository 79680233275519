import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import { getIconNameForDataType } from 'common/views/dataTypeMetadata';

import ListItem from './ListItem';
import { filterLegacyAndHiddenColumns } from '../Util';

/**
 * Lists all of the columns in the current view
 */
class ColumnList extends Component {
  static propTypes = {
    view: PropTypes.object
  }

  renderColumns = () => {
    const { view } = this.props;

    if (!view.columns || view.columns.length === 0) {
      return (
        <div className="no-column-message">{I18n.t('shared.query_editor.no_columns')}</div>
      );
    }

    const columns = filterLegacyAndHiddenColumns(view.columns);

    return columns.map(column =>
      (<ListItem
        key={column.fieldName}
        name={column.fieldName}
        hasDocs
        type="column"
        iconName={getIconNameForDataType(column.dataTypeName)} />)
    );
  }

  render() {
    return (
      <div>
        {this.renderColumns()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  view: state.editor.view
});

export default connect(mapStateToProps)(ColumnList);
