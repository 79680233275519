import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';

import { AudienceScope, View, ViewPermissions } from 'common/types/view';
import type { GuidanceSummaryV2 } from 'common/types/approvals';

import I18n from 'common/i18n';
import { fetchJsonWithParsedError } from 'common/http';
import { PUBLICATION_STAGE } from 'common/views/constants';
import { isStoryDraft, isVisualizationCanvas } from 'common/views/view_types';
import { fetchVisualizationCanvasHasPublicDataSource } from 'common/views/helpers';
import {
  assetIdFor,
  fetchApprovalsGuidanceV2,
  withGuidanceV2,
  mapPermissionScopeToTargetAudience
} from 'common/core/approvals/index_new';
import getRevisionSeq from 'common/js_utils/getRevisionSeq';
import { showToastOnPageReload, ToastType } from 'common/components/ToastNotification/Toastmaster';

import {
  checkWillEnterApprovalQueue,
  getDefaultScope,
  permissionsUrl
} from 'common/components/AccessManager/Util';

import { MODES, PUBLISHED_VIEWER_ACCESS_LEVEL } from 'common/components/AccessManager/Constants';

import {
  addUsers,
  fetchPermissionsFail,
  fetchPermissionsSuccess,
  getScheduleCount,
  getScheduleCountFail,
  getScheduleCountSuccess,
  PermissionsActionsTypes,
  removeUserAccess,
  saveFail
} from 'common/components/AccessManager/actions/PermissionsActions';
import { UiActionTypes } from 'common/components/AccessManager/actions/UiActions';

import * as selectors from './Selectors';
import { WorkflowTargetAudience } from 'common/core/approvals_enums';

// fetch all the permissions from the api
export function* onFetchPermissions() {
  const view: View = yield select(selectors.getCurrentView);
  const mode: MODES = yield select(selectors.getUiMode);
  let approvalsGuidance: GuidanceSummaryV2 = yield select(selectors.getApprovalsGuidance);

  try {
    const permissions: ViewPermissions = yield call(fetchJsonWithParsedError, permissionsUrl(view.id));
    let visualizationCanvasHasPublicDataSource: boolean | undefined;

    // sometimes core replies back with a 200...
    // but an "error" boolean means there's _really_ an error
    if ((permissions as any).error) {
      yield put(fetchPermissionsFail(permissions));
    } else {
      if (!approvalsGuidance) {
        approvalsGuidance = yield call(
          fetchApprovalsGuidanceV2,
          assetIdFor(view.id, getRevisionSeq(), isStoryDraft(view))
        );
      }

      // need to check if the asset will enter the approval queue
      if (mode === MODES.CHANGE_AUDIENCE || mode === MODES.PUBLISH) {
        yield checkWillEnterApprovalQueue(approvalsGuidance, permissions.scope, view);
      }

      if (isVisualizationCanvas(view)) {
        visualizationCanvasHasPublicDataSource = yield call(
          fetchVisualizationCanvasHasPublicDataSource,
          view.id
        );
      }

      yield put(
        fetchPermissionsSuccess({
          permissions: {
            ...permissions,

            // it's possible that this view now has an "invalid" scope... (i.e. if the user's role has changed)
            // the default here will be the current permissions scope if it's still valid,
            // otherwise it will default to the "least permissive" scope that the user has available to them
            scope: getDefaultScope(view, visualizationCanvasHasPublicDataSource)
          },
          approvalsGuidance,
          visualizationCanvasHasPublicDataSource,
          mode
        })
      );
    }
  } catch (error) {
    yield put(fetchPermissionsFail(error));
  }
}

export function* addSelectedUsers(mode: MODES) {
  // need to check if any users have been selected but not added yet, and then add them
  let selectedUsers;
  let selectedAccessLevel;
  if (mode === MODES.PUBLISH || mode === MODES.CHANGE_AUDIENCE) {
    // if we're in a mode where we're changing who the asset is published to,
    // grab the users from the proper state tree and set the access level to the default of published viewer
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error
    selectedUsers = yield select(selectors.getSelectedPublishTo);
    selectedAccessLevel = PUBLISHED_VIEWER_ACCESS_LEVEL;
  } else {
    // else just grant the users and access level from the state
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error
    selectedUsers = yield select(selectors.getSelectedUsers);
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error
    selectedAccessLevel = yield select(selectors.getAccessLevel);
  }

  // add any users that have been selected, but not added
  if (selectedUsers.length > 0) {
    yield put(addUsers(selectedUsers, selectedAccessLevel, mode));
  }
}

/**
 * Will submit the current view for an audience change request
 */
export function* onSubmitApprovalChangeAudienceRequest(guidance: GuidanceSummaryV2) {
  try {
    const currentScope: AudienceScope | null = yield select(selectors.getCurrentScope);
    const targetAudience = mapPermissionScopeToTargetAudience(currentScope || undefined);

    yield call(
      withGuidanceV2(guidance).submitChangeAudienceRequest,
      targetAudience as WorkflowTargetAudience
    );

    const toastI18nScope = 'shared.components.asset_action_bar.publication_action';
    showToastOnPageReload({
      type: ToastType.SUCCESS,
      content: I18n.t('submitted_asset_for_approval', { scope: toastI18nScope })
    });
    window.location.reload();
  } catch (error) {
    yield put(saveFail(error));
  }
}

// persist all the permissions to the database
export function* onSaveClicked() {
  try {
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error
    const currentView = yield select(selectors.getCurrentView);
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error
    const showingApprovalMessage = yield select(selectors.getShowApprovalMessage);
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error
    const mode = yield select(selectors.getUiMode);
    // @ts-expect-error
    const targetScope = yield select(selectors.getTargetAudience);

    let targetAudience: WorkflowTargetAudience | undefined = undefined;
    if (targetScope === AudienceScope.Public) {
      targetAudience = WorkflowTargetAudience.PUBLIC;
    } else if (targetScope === AudienceScope.Site) {
      targetAudience = WorkflowTargetAudience.INTERNAL;
    }

    // in some cases, we want to submit for approval instead of hitting the permissions api
    // Essentially if...
    // - We're going from private/site scope to public (this is currently detected by the state.ui.showingApprovalMessage boolean)
    // - We're in PUBLISH or CHANGE_AUDIENCE mode (the only modes where you can change the scope)
    // - Approvals is on (this depends on a variety of factors, including feature flags and site config)
    // - The guidance endpoint tells us to go through approvals
    const checkForApprovalsGuidance =
      showingApprovalMessage &&
      currentView.publicationStage === PUBLICATION_STAGE.PUBLISHED &&
      [MODES.PUBLISH, MODES.CHANGE_AUDIENCE].includes(mode);
    if (targetAudience !== undefined && checkForApprovalsGuidance) {
      // @ts-expect-error - TODO: Add appropriate return type to generator so yield doesn't return any
      const guidance = yield select(selectors.getApprovalsGuidance);

      if (withGuidanceV2(guidance).canSubmitChangeAudienceRequest(targetAudience)) {
        yield call(onSubmitApprovalChangeAudienceRequest, guidance);
        return;
      }
    }

    // add any selected users
    yield call(addSelectedUsers, mode);

    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error
    const onConfirm = yield select(selectors.getOnConfirm);
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error
    const permissions = yield select(selectors.getPermissions);

    // call our confirm function, which returns a promise.
    // usually, this just hits the permissions endpoint
    yield onConfirm(mode, currentView.id, permissions);
  } catch (error) {
    yield put(saveFail(error));
  }
}

export function* onGetScheduleCount(action: ReturnType<typeof getScheduleCount>) {
  try {
    const { namespace, user, removeUserIfNoSchedules } = action.payload;

    if (!get(namespace, 'name') || !get(namespace, 'type')) {
      throw new Error('Invalid namespace provided, (missing name and/or type) cannot get schedules.');
    }

    // Make sure user id is present and the right lenght for a 4x4
    if (!user.id || user.id.length !== 9) {
      throw new Error(`Invalid user ID provided: ${user.id}, cannot get schedules.`);
    }

    const apiPath = `/api/publishing/v1/schedule/count?plugin_name=${namespace.name}&plugin_type=${namespace.type}&user_id=${user.id}`;

    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error
    const scheduleCounts = yield call(fetchJsonWithParsedError, apiPath);
    if (scheduleCounts.total === 0 && removeUserIfNoSchedules) {
      yield put(removeUserAccess(user));
    }

    yield put(getScheduleCountSuccess(scheduleCounts, user));
  } catch (err) {
    if (err.response) {
      // if we have a `response` we actually want to grab its JSON and pass that along
      // this will be handled by the UiReducer and shown by the Errors component
      // TODO: Add appropriate return type to generator so yield doesn't return any
      // @ts-expect-error
      const errorBody = yield err.response.json();
      yield put(getScheduleCountFail(errorBody));
    } else {
      yield put(getScheduleCountFail(err));
    }
  }
}

export default [
  takeEvery(PermissionsActionsTypes.FETCH_PERMISSIONS, onFetchPermissions),
  takeLatest(PermissionsActionsTypes.GET_SCHEDULE_COUNT, onGetScheduleCount),
  takeLatest(UiActionTypes.SAVE_BUTTON_CLICKED, onSaveClicked)
];
