import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

import I18n from 'common/i18n';

interface HeaderProps {
  className?: string;

  /** Text to be read by screenreader for close button */
  closeButtonLabel?: string;

  /**
   * Whether or not to show the close button in the header
   * Default: true
   */
  showCloseButton?: boolean;

  /** A title for the modal; this is rendered above the children that are passed in */
  title?: string;
  onDismiss?: () => void;

  /** Optional ID string for title (used by parent to identify label-by element) */
  modalLabelId?: string;
}

const Header = React.forwardRef<HTMLElement, PropsWithChildren<HeaderProps>>(({
  children,
  className,
  closeButtonLabel,
  showCloseButton,
  title,
  onDismiss,
  modalLabelId = 'modal-header-title'
}, ref) => {
  const headerClasses = classNames({
    'modal-header': true,
    [className as any]: !!className
  });

  const renderCloseButton = (show?: boolean) => {
    if (show === false) {
      return null;
    }

    return (
      <button
        type="button"
        className="btn btn-transparent modal-header-dismiss"
        onClick={onDismiss}
        aria-label={closeButtonLabel || I18n.t('shared.components.modal.aria_close')}
      >
        <span className="socrata-icon-close-2" />
      </button>
    );
  };

  return (
    <header ref={ref} className={headerClasses}>
      {title && <h1 className="h5 modal-header-title" id={modalLabelId}>{title}</h1>}
      {children}
      {renderCloseButton(showCloseButton)}
    </header>
  );
});

Header.defaultProps = {
  showCloseButton: true,
  onDismiss: () => {}
};

Header.displayName = 'Header'; // forwardRef alters the name.

export default Header;
