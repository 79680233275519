import _ from 'lodash';
import { defaultHeaders } from 'common/http/index';
import { fetchJson } from 'common/http';
import { coreUidFromAssetId } from 'common/cetera/catalog_id';
import {
  APPROVALS,
  ApprovalState,
  Status,
  SubmissionObject,
  WorkflowTaskScope,
  ResubmissionSettings,
} from 'common/core/approvals_enums';
import { AudienceScope, ViewPermissions } from 'common/types/view';
import {
  Guidance,
  GuidanceSummary,
  Workflow,
  WorkflowTask
} from 'common/types/approvals';

/**
 * PUBLIC API
 */

/**
 * @param assetId: catalog asset id; is one of:
 *    - 4x4 (all published assets, working copies)
 *    - 4x4:<revision seq> (revisions)
 *    - 4x4:draft (story draft)
 * @returns guidanceResponse:  see
 *    https://approvalsapi1.docs.apiary.io/#reference/0/view-approvals/get-submission-guidance-for-an-asset
 */
export const fetchApprovalsGuidance = (assetId: string): Promise<GuidanceSummary> => {
  const coreUid = coreUidFromAssetId(assetId);
  const apiPath = `/api/views/${coreUid}/approvals?method=guidance&assetId=${assetId}`;
  const options = {
    credentials: 'same-origin',
    headers: defaultHeaders
  };

  return fetchJson(apiPath, options);
};

/**
 * Example usage:
 *   fetchApprovalsGuidance(coreViewId, revision).then(guidance => {
 *     withGuidance(guidance).withdraw();
 *   });
 *
 * Why like this? Set guidance in component state where necessary,
 * then use this method to make approvals requests using that guidance.
 */
export const withGuidance = (guidanceResponse: GuidanceSummary) => {
  return {
    // Async
    approve: (notes = '') => approveAsset(guidanceResponse, notes),
    reject: (notes = '') => rejectAsset(guidanceResponse, notes),
    withdraw: () => withdrawAsset(guidanceResponse),
    submitForApproval: () => submitAnyAvailableRequest(guidanceResponse),
    submitChangeAudienceRequest: () => changeAudienceRequest(guidanceResponse),
    submitUpdatePublishedAssetRequest: () => updatedPublishedAssetRequest(guidanceResponse),
    // Boolean (synchronous)
    willEnterApprovalQueue: () => willEnterApprovalQueue(guidanceResponse),
    sitesThatWillBeFederatedToIfMadePublic: () => sitesThatWillBeFederatedToIfMadePublic(guidanceResponse),
    sitesThatWillBeFederatedToIfApprovedToPublic: () =>
      sitesThatWillBeFederatedToIfApprovedToPublic(guidanceResponse),
    canSubmitForApproval: () => canSubmit(guidanceResponse),
    canSubmitChangeAudienceRequest: () => canSubmitChangeAudience(guidanceResponse),
    canSubmitUpdatePublishedAssetRequest: () => canSubmitUpdatePublished(guidanceResponse),
    isPending: () => assetIsPending(guidanceResponse),
    // Helper
    publishedUid: () => publishedUid(guidanceResponse)
  };
};

/**
 * Returns whether or not the given provenance will result in requiring manual approval.
 *
 * @param settingsResponse The current workflow that approvals is using
 * @param provenance The desired provenance of the asset
 */
export const provenanceRequiresManualApproval = (settingsResponse: Workflow, provenance: WorkflowTaskScope) =>
  _.get(getProvenanceTask(settingsResponse, provenance), 'presetState') === APPROVALS.STATUS.PENDING;

/**
 * willEnterApprovalQueue is the result of withGuidance(approvalsGuidance).willEnterApprovalQueue()
 * this is needed because withGuidance's willEnterApprovalQueue only tells you
 * whether or not the asset is considered pending and could enter the queue
 * if 'require manual approval' is enabled
 */
export const isPublicAssetThatRequiresApproval = (
  willEnterApprovalQueue: boolean,
  permissions: ViewPermissions
) => {
  const wouldBePublic = permissions.scope == AudienceScope.Public;
  return willEnterApprovalQueue && wouldBePublic;
};

/**
 * Get the approval workflow task for the given provenance.
 *
 * Note: Scope is the same as provenance; it is called 'scope' in the API response
 *
 * @param apiResponse The approvals workflow to get the provenance task for
 * @param scope The provenance (scope) to get the task for
 */
export const getProvenanceTask = (apiResponse: Workflow, scope: WorkflowTaskScope) => {
  const task = _.find(_.get(apiResponse, '[0].steps[0].tasks'), _.matches({ scope }));
  if (task) {
    return { taskId: task.id, presetState: task.presetState };
  } else {
    return null;
  }
};

/**
 * Change the preset state for a specific approvals workflow task.
 *
 * The preset state controls what happens when an asset enters the approvals queue.
 *
 * @param taskId The task to update
 * @param value The new preset state to set for the task
 * @return A promise that will resolve with the updated approval worfklow task
 */
export const setPresetState = (taskId: number, presetState: ApprovalState) =>
  updateTask(taskId, { presetState });

/**
 * Change the resubmission police to a specific approvals workflow task.
 *
 * The resubmission policy is what happens when an asset is resubmitted after being approved
 * (this can currently happen with data updates or visibility changes, depending on other settings)
 *
 * @param workflowId The workflow to update
 * @param value The new resubmission policy for the task.
 */
export const setApprovedResubmissionPolicy = (
  workflowId: number,
  approvedResubmissionPolicy: ResubmissionSettings
) => updateWorkflow(workflowId, { approvedResubmissionPolicy });

/**
 * PRIVATE METHODS
 */

/**
 * These are keys in the `GuidanceSummary` object that can be used to
 * get guidance responses that can be used to submit an asset.
 *
 * We need this type because we need to tell TypeScript exactly _what_ values we expect to get out of
 * `GuidanceSummary` when checking against `SUBMISSION_KEYS`.
 */
export enum SubmissionKey {
  toChangeAudience = 'toChangeAudience',
  toUpdatePublishedAsset = 'toUpdatePublishedAsset',
  toPublishToPublic = 'toPublishToPublic'
}

/**
 * This array contains the keys into a `GuidanceSummary` object that can be used to submit an asset.
 *
 * "Submit" here means that the asset is being submitted to an approvals workflow.
 */
export const SUBMISSION_KEYS: SubmissionKey[] = [
  SubmissionKey.toChangeAudience,
  SubmissionKey.toUpdatePublishedAsset,
  SubmissionKey.toPublishToPublic
];

// Settings
/**
 * Update a specific workflow task.
 *
 * @param taskId ID of the task to update
 * @param body Values to set for the task. This can be a partial task to only update some fields.
 * @return A promise that will resolve with the updated task
 */
const updateTask = (taskId: number, body: Partial<WorkflowTask>): Promise<WorkflowTask> => {
  const apiPath = `/api/approvals?method=updateTask&taskId=${taskId}`;

  const options = {
    body: JSON.stringify(body),
    credentials: 'same-origin',
    headers: defaultHeaders,
    method: 'PUT'
  };

  return fetchJson(apiPath, options);
};

/**
 * Update a specific workflow.
 *
 * @param workflowId ID of the workflow to update
 * @param body Values to set for the workflow. This can be a partial workflow to only update some fields.
 */
const updateWorkflow = (workflowId: number, body: Partial<Workflow>): Promise<Workflow> => {
  const apiPath = `/api/approvals/${workflowId}`;

  const options = {
    body: JSON.stringify(body),
    credentials: 'same-origin',
    headers: defaultHeaders,
    method: 'PUT'
  };

  return fetchJson(apiPath, options);
};

// Helpers to check for ability to be submitted for various approvals

/**
 * Checks if an asset *could* enter the queue
 * e.g., if you are updating a public asset with 'require manual approval' set to true
 *
 * @param guidanceResponse Guidance summary passed to `withGuidance`
 */
const willEnterApprovalQueue = (guidanceResponse: GuidanceSummary) =>
  guidanceResponse &&
  SUBMISSION_KEYS.some((key) => guidanceResponse[key]?.expectedState === APPROVALS.STATUS.PENDING);

/**
 * Returns a list of other sites that this asset will end up federating to if its visibility is changed.
 *
 * Currently, only public assets are federated so this only handles making something public
 * (either during publish or an audience change after making it public)
 *
 * This is mainly used by the AccessManager to show a message when making an asset public.
 *
 * @param guidanceResponse Guidance summary passed to `withGuidance`
 */
const sitesThatWillBeFederatedToIfMadePublic = (guidanceResponse: GuidanceSummary) => {
  // this will return the "toChangeAudience" or "toPublishToPublic" object
  // which includes a submission which has an "object"
  const viewerChangeDetails = guidanceResponse?.toChangeAudience || guidanceResponse?.toPublishToPublic;

  // this will contain a "submission object" of which we only care about "public_audience_request"
  // once we need to add internal approvals, this will be an adventure
  const submissionObject: SubmissionObject | undefined = viewerChangeDetails?.submission?.object;

  if (submissionObject === SubmissionObject.PUBLIC_AUDIENCE_REQUEST) {
    return viewerChangeDetails?.expectedFederatedTo || [];
  }

  return [];
};

/**
 * Returns a list of domains that this asset will end up federating to if it is approved for public visibility.
 *
 * This is mainly used in the approvals queue UI to show where an asset will federate to when it is approved.
 *
 * @param guidanceResponse Guidance summary passed to `withGuidance`
 */
const sitesThatWillBeFederatedToIfApprovedToPublic = (guidanceResponse: GuidanceSummary) => {
  return guidanceResponse?.ifApproved?.expectedFederatedTo || [];
};

/**
 * Returns whether or not an asset can be submitted _in some way_ to approvals.
 *
 * See the SUBMISSION_KEYS array for possible parts of the guidance summary that can be considered "submissions".
 * To check for specific submission availabilities, see `canSubmitChangeAudience` and `canSubmitUpdatePublished`
 *
 * NOTE: If this is not passed a guidance summary, it will throw an error!
 *
 * @param guidanceResponse Guidance summary passed to `withGuidance`
 */
const canSubmit = (guidanceResponse: GuidanceSummary) => {
  assertHasGuidance(guidanceResponse);
  return SUBMISSION_KEYS.some((key) => guidanceResponse[key]);
};

/**
 * Returns whether or not an asset can be submitted for an audience change
 * (this is also called "visibility" or "scope", which is _not_ to be confused with the "scope" that is also used in this same file to mean "provenance").
 *
 * NOTE: If this is not passed a guidance summary, it will throw an error!
 *
 * @param guidanceResponse Guidance summary passed to `withGuidance`
 */
const canSubmitChangeAudience = (guidanceResponse: GuidanceSummary) => {
  assertHasGuidance(guidanceResponse);
  return !!guidanceResponse.toChangeAudience;
};

/**
 * Returns whether or not an asset can be submitted to be published.
 * This is only relevant for assets that have already been published and are being updated.
 *
 * NOTE: If this is not passed a guidance summary, it will throw an error!
 *
 * @param guidanceResponse Guidance summary passed to `withGuidance`
 */
const canSubmitUpdatePublished = (guidanceResponse: GuidanceSummary) => {
  assertHasGuidance(guidanceResponse);
  return !!guidanceResponse.toUpdatePublishedAsset;
};

/**
 * Returns whether an asset is currently pending approval.
 *
 * @param guidanceResponse Guidance summary passed to `withGuidance`
 */
const assetIsPending = (guidanceResponse: GuidanceSummary) => {
  assertHasGuidance(guidanceResponse);
  return guidanceResponse.currentState === APPROVALS.STATUS.PENDING;
};

/**
 * Get the published UID of a draft asset.
 *
 * @param guidanceResponse Guidance summary passed to `withGuidance`
 */
const publishedUid = (guidanceResponse: GuidanceSummary) => {
  if (!canSubmit(guidanceResponse)) {
    throw new Error(
      `guidanceResponse has no submission object to parse: ${JSON.stringify(guidanceResponse)}`
    );
  }

  const targetKey = SUBMISSION_KEYS.find((key) => guidanceResponse[key]);
  return targetKey && guidanceResponse[targetKey]?.publishedViewUid;
};

/**
 * Throws an error if passed an empty object (`undefined`, `null`, or `{}`)
 *
 * This is used in many other functions in this file to asset that we are given a guidance summary.
 *
 * @param guidanceResponse Guidance summary passed to `withGuidance`
 */
const assertHasGuidance = (guidanceResponse?: GuidanceSummary) => {
  if (_.isEmpty(guidanceResponse)) {
    throw new Error('no guidanceResponse provided to withGuidance helper');
  }
};

// Approve/Reject, a.k.a. Updating Approval Records

/** Used when approving/rejecting an asset that is in the approvals queue */
interface UpdateApprovalRequestBody {
  /** New state to set for asset */
  state: Status;

  /** Any notes to add to the asset when approving/rejecting it */
  notes: string;
}

/**
 * Approve an asset (for visibility change, updating published version, etc.)
 *
 * @param guidanceResponse Guidance summary passed to `withGuidance`
 * @param notes Notes to add when approving.
 */
const approveAsset = (guidanceResponse: GuidanceSummary, notes = '') =>
  updateApproval(guidanceResponse, {
    state: APPROVALS.STATUS.APPROVED,
    notes
  });

/**
 * Reject an asset (for visibility change, updating published version, etc.)
 *
 * @param guidanceResponse Guidance summary passed to `withGuidance`
 * @param notes Notes to add when rejecting.
 */
const rejectAsset = (guidanceResponse: GuidanceSummary, notes = '') =>
  updateApproval(guidanceResponse, {
    state: APPROVALS.STATUS.REJECTED,
    notes
  });

/**
 * Update an asset's approval status.
 * This can be used to either approve or reject assets.
 *
 * See also: `approveAsset` and `rejectAsset`
 *
 * @param guidanceResponse Guidance summary passed to `withGuidance`
 * @param putBody Parameters to use to update an asset's approval status
 */
const updateApproval = (guidanceResponse: GuidanceSummary, putBody: UpdateApprovalRequestBody) => {
  if (_.isEmpty(guidanceResponse)) {
    return Promise.reject('no guidanceResponse provided to withGuidance helper');
  }

  if (!guidanceResponse.updateUrl) {
    return Promise.reject('updateUrl not found in guidance response');
  }

  const apiPath = guidanceResponse.updateUrl;
  const options: RequestInit = {
    body: JSON.stringify(putBody),
    credentials: 'same-origin',
    headers: defaultHeaders,
    method: 'PUT'
  };

  return fetch(apiPath, options);
};

/**
 * Withdraw (a.k.a. cancelWorkflowSubmission) an approval request for an asset.
 * This will remove it from the approvals queue.
 *
 * @param guidanceResponse Guidance summary passed to `withGuidance`
 */
const withdrawAsset = (guidanceResponse: GuidanceSummary) => {
  if (_.isEmpty(guidanceResponse)) {
    return Promise.reject('no guidanceResponse provided to withGuidance helper');
  }

  if (!guidanceResponse.withdrawUrl) {
    return Promise.reject('withdrawUrl not found in guidance response');
  }

  const apiPath = guidanceResponse.withdrawUrl;
  const options: RequestInit = {
    credentials: 'same-origin',
    headers: defaultHeaders,
    method: 'DELETE'
  };

  return fetch(apiPath, options);
};

// Approvals requests, a.k.a. startWorkflowSubmission

/**
 * Given a guidance summary, this will find the proper submission details and then will
 * submit an asset for approval.
 *
 * This can result in any of the keys in `SUBMISSION_KEYS` being used to submit the asset.
 * At the time of writing, this uses one of:
 * - toChangeAudience: Changing the audience (a.k.a. visibility) of an asset. For example, making it public or site-scoped (a.k.a. internal)
 * - toUpdatePublishedAsset: Making an update to an already published asset.
 * - toPublishToPublic: Publishing an asset for the first time _and_ making it public.
 *
 * @param guidanceResponse Guidance summary passed to `withGuidance`
 */
const submitAnyAvailableRequest = (guidanceResponse: GuidanceSummary) => {
  // It should be the case that only one submission key is present in a guidanceResponse
  const targetKey = SUBMISSION_KEYS.find((key) => guidanceResponse[key]);
  const guidance = targetKey && guidanceResponse[targetKey];

  if (!guidance) {
    return Promise.reject(
      `no submissions available for guidanceResponse: ${JSON.stringify(guidanceResponse)}`
    );
  }

  return executeApprovalsRequest(guidanceResponse, guidance);
};

/**
 * Executes a change audience request using the given guidance summary's `toChangeAudience` guidance
 *
 * @param guidanceResponse Guidance summary passed to `withGuidance`
 */
const changeAudienceRequest = (guidanceResponse: GuidanceSummary) => {
  if (!guidanceResponse.toChangeAudience) {
    return Promise.reject("key 'toChangeAudience' not found in guidance response");
  }

  return executeApprovalsRequest(guidanceResponse, guidanceResponse.toChangeAudience);
};

/**
 * Executes an update published asset request using the given guidance summary's `toUpdatePublishedAsset` guidance
 *
 * @param guidanceResponse Guidance summary passed to `withGuidance`
 */
const updatedPublishedAssetRequest = (guidanceResponse: GuidanceSummary) => {
  if (!guidanceResponse.toUpdatePublishedAsset) {
    return Promise.reject("key 'toUpdatePublishedAsset' not found in guidance response");
  }

  return executeApprovalsRequest(guidanceResponse, guidanceResponse.toUpdatePublishedAsset);
};

/**
 * Given a guidance summary and a guidance object, this will use the guiance object's submission URL to
 * make an actual submission to the approvals API.
 *
 * @param guidanceResponse Guidance summary passed to `withGuidance`
 * @param guidance Guidance object to get submission URL from
 */
const executeApprovalsRequest = (guidanceResponse: GuidanceSummary, guidance: Guidance) => {
  if (_.isEmpty(guidanceResponse)) {
    return Promise.reject('no guidanceResponse provided to withGuidance helper');
  }

  return submitFromGuidance(guidance);
};

/**
 * Returns a promise from fetch that will hit the approvals API to submit an asset to an approvals workflow.
 *
 * @param guidance Guidance object to get submission URL from
 */
const submitFromGuidance = (guidanceObject: Guidance) => {
  const apiPath = guidanceObject.submissionUrl;
  const options: RequestInit = {
    body: JSON.stringify(guidanceObject.submission),
    credentials: 'same-origin',
    headers: defaultHeaders,
    method: 'POST'
  };

  return fetch(apiPath, options);
};
