import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import I18n from 'common/i18n';
import { hasOneOfRights } from 'common/views/has_rights';
import { editDatasetAsRevision, findWorkingCopyFor } from 'common/views/helpers';
import airbrake from 'common/airbrake';
import { showToastNow, ToastType } from 'common/components/ToastNotification/Toastmaster';
import { assign } from 'common/window_location';
import { containsLegacyDataTypes } from 'common/views/contains_legacy_data_types';
import GuidanceSummaryV2PropType from 'common/propTypes/GuidanceSummaryV2PropType';
import ActionItem from '../action_item';
import { requireApprovalRequestWithdrawalBeforeAction } from 'common/components/AssetActionBar/components/publication_action';
import optionallyLocalizeUrls from 'common/site_chrome/app/assets/javascripts/socrata_site_chrome/utils/optionally_localize_urls';

const isDsmpSupportedAssetType = (assetType) => {
  return ['dataset', 'href', 'file'].includes(assetType);
};

// assumes no working copies
const qualifiesForDSMPEdit = async (assetType, view) => {
  if (!isDsmpSupportedAssetType(assetType)) {
    return false;
  }

  if (view.publicationStage !== 'published') {
    return false;
  }

  return !containsLegacyDataTypes(view);
};

class EditMetadataActionItem extends Component {
  static propTypes = {
    approvalsGuidance: GuidanceSummaryV2PropType,
    assetType: PropTypes.string.isRequired,
    // null means it is not a revision
    revisionInfo: PropTypes.object,
    view: PropTypes.object,
    assetIsStoryDraft: PropTypes.bool
  };

  canSetUpOnClickEdit() {
    const { assetType, approvalsGuidance, view } = this.props;

    if (isEmpty(view) || isEmpty(approvalsGuidance) || view.locked) {
      return false;
    }

    return hasOneOfRights(view, 'write', 'update_view');
  }

  shouldRender(onClickEdit) {
    return this.canSetUpOnClickEdit() && onClickEdit !== null;
  }

  constructor(props) {
    super(props);
    this.editAsRevision = this.editAsRevision.bind(this);
    if (props.revisionInfo) {
      this.state = {
        editKey: 'edit',
        onClickEdit: () => assign(props.revisionInfo.link)
      };
    } else {
      // initialize to null to indicate unfinished API call
      this.state = {
        editKey: null,
        onClickEdit: null
      };
    }
  }

  UNSAFE_componentWillMount() { // eslint-disable-line camelcase
    if (!this.canSetUpOnClickEdit()) {
      return null;
    }

    if (this.state.onClickEdit === null) {
      try {
        qualifiesForDSMPEdit(this.props.assetType, this.props.view).then((dsmpEdit) => {
          if (dsmpEdit) {
            // check for working copy
            findWorkingCopyFor(this.props.view.id).then((workingCopy) => {
              if (workingCopy) {
                this.setState({
                  editKey: 'edit',
                  onClickEdit: () => assign(`/d/${workingCopy.id}/data`)
                });
              } else {
                this.setState({
                  editKey: 'edit',
                  onClickEdit: () => this.editAsRevision(this.props.view.id, this.props.assetType)
                });
              }
            });
          } else if (this.props.view.publicationStage === 'published' && this.props.assetType != 'story') {
            // EN-34337 Metadata cannot be edited from published measures, visualizations and filtered views.
            this.setState({
              editKey: 'edit_metadata',
              onClickEdit: () => null,
              toolTipText: I18n.t('shared.asset_browser.result_list_table.action_dropdown.disabled_tooltip')
            });
          } else {
            // not a dsmp situation, use /edit_metadata
            this.setState({
              editKey: 'edit_metadata',
              onClickEdit: () => assign(optionallyLocalizeUrls(`/d/${this.props.view.id}/edit_metadata`))
            });
          }
        });
      } catch (err) {
        // edit button will fail to resolve
        airbrake.notify({
          error: err,
          context: { component: 'EditMetadataActionItem#componentWillMount' }
        });
      }
    }
  }

  async editAsRevision(uid, assetType) {
    try {
      await editDatasetAsRevision(uid, assetType === 'dataset');
    } catch (err) {
      airbrake.notify({
        error: err,
        context: { component: 'EditMetadataActionItem#onClick' }
      });
      this.setState({ inProgress: false });
      showToastNow({
        type: ToastType.ERROR,
        content: I18n.t('shared.site_chrome.asset_action_bar.edit_error')
      });
    }
  }

  render() {
    const { editKey, onClickEdit, toolTipText } = this.state;

    if (!this.shouldRender(onClickEdit)) {
      return null;
    }

    return (<ActionItem
      toolTipText={toolTipText}
      label={I18n.t(`shared.asset_browser.result_list_table.action_dropdown.${editKey}`)}
      onClick={() => requireApprovalRequestWithdrawalBeforeAction(this.props.approvalsGuidance, onClickEdit)} />);
  }
}

export default EditMetadataActionItem;
