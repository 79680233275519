import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { components as SocrataVisualizations } from 'common/visualizations';
import { getInlineTableVif } from 'common/visualizations/helpers/VifHelpers';

import { NUMBER_OF_ROWS } from '../Constants';
import { fetchRowsFail } from '../redux/QueryEditorActions';

/**
 * Given a list of rows and columns, uses Socrata's visualization library to render a table
 */
class ResultsTable extends Component {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    onRenderError: PropTypes.func.isRequired
  }

  getVif = () => {
    const { columns, rows } = this.props;
    const startIndex = 0;

    return getInlineTableVif(rows, columns, startIndex, NUMBER_OF_ROWS);
  }

  render() {
    const { onRenderError } = this.props;
    const options = { pagingEnabled: false };
    return (
      <div className="query-editor-table-container">
        <SocrataVisualizations.Visualization
          vif={this.getVif()}
          onVisualizationRenderError={onRenderError}
          options={options}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onRenderError: error => dispatch(fetchRowsFail(error))
});

export default connect(null, mapDispatchToProps)(ResultsTable);
