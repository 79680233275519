import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import I18n from 'common/i18n';
import UserSearch from 'common/components/UserSearch';
import { fetchJsonWithDefaultHeaders } from 'common/http';
import {
  getDomain,
  userAndTeamAutocompleteUrl
} from 'common/components/AccessManager/Util';

const scope = 'shared.site_chrome.notifications.alert_share_modal';

class AddUser extends Component {

  state = {
    selectedUsers: [],
    userList: [],
    userSearchString: ''
  }

  onUserSelect = (result) => {
    const { onSelectedUserChange } = this.props;
    const { selectedUsers } = this.state;
    this.setState({
      selectedUsers: [...selectedUsers, result.user],
      userSearchString: ''
    },
      () => onSelectedUserChange(this.state.selectedUsers)
    );
  }

  fetchUserList = () => {
    const { userSearchString } = this.state;

    fetchJsonWithDefaultHeaders(userAndTeamAutocompleteUrl(userSearchString, getDomain())).then((response) => {
      const filteredResults = _.filter(response.results, (result) => _.isEmpty(result.team));
      const userList = _.map(filteredResults, (result) => (
        { ...result, user: { ...result.user, type: 'interactive' } })
      );
      this.setState({ userList });
    });
  }

  removeFromSelectedUsers = (user) => {
    const { onSelectedUserChange } = this.props;
    const { selectedUsers } = this.state;
    const filteredUsers = _.reject(selectedUsers, (u) => _.isEqual(u, user));
    this.setState(
      { selectedUsers: filteredUsers },
      () => onSelectedUserChange(this.state.selectedUsers)
    );
  }

  userSearchQueryChanged = (e) => {
    this.setState({ userSearchString: e.target.value }, () => {
      this.fetchUserList();
    });
  }


  render() {
    const { selectedUsers, userSearchString, userList } = this.state;

    const userSearchProps = {
      addToSelectedUsers: this.onUserSelect,
      currentQuery: userSearchString,
      noResultsMessage: I18n.t('no_results', { scope }),
      placeHolderText: I18n.t('placeholder', { scope }),
      unregisteredUserText: I18n.t('unregistered_user_text', { scope }),
      removeFromSelectedUsers: this.removeFromSelectedUsers,
      results: userList,
      selectedUsers,
      userSearchQueryChanged: this.userSearchQueryChanged
    };
    return (
      <div className="add-user-section">
        <UserSearch {...userSearchProps} />
      </div>
    );
  }
}

AddUser.propTypes = {
  onSelectedUserChange: PropTypes.func.isRequired
};

export default AddUser;
