import isEmpty from 'lodash/isEmpty';

import sift from 'common/js_utils/sift';

import type InteractiveUser from 'common/types/users/interactiveUser';
import DomainRights from 'common/types/domainRights';
import UserFlags from 'common/types/users/flags';
import UserSegment from 'common/types/users/userSegment';

/**
 * Get the current user, checking in as many places as possible to be app-agnostic.
 * Note that this can return `null` if a value of `null` has been explicitly set;
 * for this reason, we force `null` into `undefined`. (We also force `false`, but
 * it will never be `false`, right?)
 *
 * Note! There's a test utility available, see common/spec/helpers/current_user.js
 */
export const getCurrentUser = (): InteractiveUser | undefined =>
  sift(
    window,
    'socrata.currentUser',
    'currentUser',
    'blist.currentUser',
    'serverConfig.currentUser',
    'CURRENT_USER'
  ) || undefined;

/**
 * @param flag Flag to check
 * @returns Whether or not the current user has the given flag in their `flags` array
 */
export const currentUserHasFlag = (flag: UserFlags) => (getCurrentUser()?.flags || []).includes(flag);

/**
 * Generally, this means the user has a role on the domain.
 *
 * NOTE! Superadmin users are not site members, so you may need to also check currentUserIsSuperAdmin()
 *
 * @returns Whether the current user is a site member (they have the `siteMember` flag)
 */
export const currentUserIsSiteMember = () => getCurrentUser()?.userSegment === UserSegment.SiteMember;

/**
 * NOTE! Superadmin users are not site members, so currentUserIsSiteMember() returns `false`
 *
 * @returns Whether or not the current user is a super-admin (they have the `admin` flag)
 */
export const currentUserIsSuperAdmin = () => getCurrentUser()?.userSegment === UserSegment.SuperAdmin;

/**
 * Convenience method for checking with whether the current user is an "internal" user.
 *
 * @returns Whether or not the current user is a either a site member or a super-admin
 */
export const currentUserIsSiteMemberOrSuperAdmin = () => currentUserIsSiteMember() || currentUserIsSuperAdmin();

/**
 * Generally, this means they do not have a role on the domain but they are logged in.
 * If they are not logged in, they are considered "anonymous".
 *
 * @returns Whether or not the current user is a community member (they have the `communityMember` flag)
 */
export const currentUserIsCommunity = () => getCurrentUser()?.userSegment === UserSegment.CommunityMember;

/**
 * @returns Whether or not there is a current user logged in
 */
export const currentUserIsLoggedIn = () => !isEmpty(getCurrentUser());

/**
 * Check if the currently logged in user has the given domain right
 * @param right Right to check for
 * @returns Whether or not the current user has the given right
 */
export const currentUserHasRight = (right: DomainRights) => (getCurrentUser()?.rights || []).includes(right);
