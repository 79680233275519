import React from 'react';
import confirmation from 'common/components/ConfirmationDialog';
import I18n from 'common/i18n';

const scope = 'shared.components.asset_action_bar.publication_action';
const translate = (key: string, options = {}) => I18n.t(key, { ...options, scope });

export const explicitWithdrawApprovalModal = () =>
  confirmation(
    <p>{translate('explicit_withdraw_approval_request_confirm')}</p>,
    'asset-action-bar-modal-target',
    { agree: { text: translate('confirm') }, header: translate('withdraw_approval_request') }
  );

export const requireApprovalRequestWithdrawalModal = () =>
  confirmation(
    <p>{translate('implicit_withdraw_approval_request_confirm')}</p>,
    'asset-action-bar-modal-target',
    {
      agree: { text: translate('confirm') },
      header: translate('implicit_withdraw_approval_request')
    }
  );
