import React, { Component } from 'react';
import { ForgeButton } from '@tylertech/forge-react';
import get from 'lodash/get';
import I18n from 'common/i18n';
import airbrake from 'common/airbrake';
import { assign as windowLocationAssign } from 'common/window_location';
import { showToastNow, ToastType } from 'common/components/ToastNotification/Toastmaster';
import type { View } from 'common/types/view';
import { DisplayType } from 'common/types/view';
import type { Revision } from 'common/types/revision';
import { shouldCreateViewViaRevision } from 'common/views/helpers';
import { generateSelectAllColumnsQuery } from 'common/components/QueryEditor/Util';
import { deleteView } from 'common/views/delete_helpers';

/**
 * Button to create a child view; should pretty much only show up on the grid view...?
 */
class CreateViewButton extends Component {
  state = {
    busy: false
  };

  static getGridViewDataset = () => {
    const gridViewDataset = get(window, 'blist.dataset');

    if (!gridViewDataset) {
      throw new Error('CreateViewButton exists but no window.blist.dataset is available');
    }

    return gridViewDataset;
  };

  onRevisionCreateSuccess = (errorCleanup: () => void) => async (createResult: Revision) => {
    if (!createResult.fourfour || (createResult.revision_seq !== 0 && !createResult.revision_seq)) {
      airbrake.notify({
        error:
          'Got success creating child view and revision, but there was no fourfour or revision_seq to redirect to',
        context: { component: 'CreateViewButton#onRevisionCreateSuccess' },
        createResult
      });
      errorCleanup();
    } else {
      windowLocationAssign(`/d/${createResult.fourfour}/revisions/${createResult.revision_seq}/soql`);
    }
  };

  onViewCreateSuccess = async (createResult: View) => {
    if (!createResult.id) {
      airbrake.notify({
        error: 'Got success creating child view, but there was no id to redirect to',
        context: { component: 'CreateViewButton#onViewCreateSuccess' },
        createResult
      });
    } else {
      windowLocationAssign(`/d/${createResult.id}/data`);
    }
  };

  onViewCreateError = (error: any) => {
    showToastNow({
      type: ToastType.ERROR,
      content: I18n.t('shared.components.asset_action_bar.create_view_failed')
    });
    this.setState({ busy: false });
    airbrake.notify({
      error: error,
      context: { component: 'CreateViewButton#onViewCreateError' }
    });
  };

  onRevisionMissingData = (id: string) => () => {
    deleteView(id);
  };

  onRevisionCreateError = (id: string) => (error: any) => {
    deleteView(id);
    this.onViewCreateError(error);
  };

  onViewCreateSuccessCreateRevision = (dataset: any) => async (createResult: View) => {
    dataset.createRevision(
      createResult.id,
      generateSelectAllColumnsQuery(dataset),
      this.onRevisionCreateSuccess(this.onRevisionMissingData(createResult.id)),
      this.onRevisionCreateError(createResult.id)
    );
  };

  onClick = () => {
    this.setState({ busy: true });
    const gridViewDataset = CreateViewButton.getGridViewDataset();
    if (shouldCreateViewViaRevision(gridViewDataset)) {
      gridViewDataset.createView(
        this.onViewCreateSuccessCreateRevision(gridViewDataset),
        this.onViewCreateError,
        { displayType: DisplayType.Draft }
      );
    } else {
      gridViewDataset.createView(this.onViewCreateSuccess, this.onViewCreateError);
    }
  };

  render() {
    const { busy } = this.state;

    return (
      <ForgeButton type="raised" onClick={this.onClick}>
        <button type="button" disabled={busy} className="edit-button primary-action-button">
          {I18n.t('shared.components.asset_action_bar.create_view')}
        </button>
      </ForgeButton>
    );
  }
}

export default CreateViewButton;
