import { SoQLCell } from 'common/components/DatasetTable/cell/TableCell';
import { Option, none, some } from 'ts-option';
import { Expr, TableQualifier, SoQLType, ColumnRef, FunCall, SoQLBooleanLiteral } from './soql';

export interface FieldT {
  field_name: string;
  display_name: string;
  expr: string;
  private: boolean;
  restricted?: boolean;
  is_builtin: boolean;
  parsed_expr: Expr;
  input_soql_type: SoQLType;
  // ;_; :( :( :( ;_;
  legacy_labels: string[];
  default_value?: string;
}

export interface FieldSetT {
  fieldset_name: string;
  fieldset_qualifier: string;
  fields: FieldT[];
}

export interface FieldInput {
  /*
    In the context of metadata, TableQualifier represents the custom fieldset name.
    So builtin metadata fields (things like dataset name, description, license_id, etc)
    are unqualified, so in a metadata expression you can reference them like `name`.
    (ie: TableQualifier is null).

    But custom fields can have collisions between fieldsets. So if you have
    Fieldset A and Fieldset B, they can have fields within them called Foo
    and that's a perfectly valid domain configuration. So they need to be qualified
    in order to be referenced in an expression. So to reference Fieldset A's Foo
    field in a SoQL expression, you'd write @fieldset_a.foo. In this case,
    the TableQualifier is fieldset_a.
  */
  qualifier: TableQualifier;
  field_name: string;
  value: any;
}

export interface FieldValue {
  inputs: FieldInput[];
  errors: string[];
  output: Option<any | null>; // TODO: soql-cell-generics
}

export interface FieldIdentifier {
  qualifier: TableQualifier;
  fieldName: string;
  displayName: string;
  instances: FieldT[];
}

export interface FieldsetIdentifier {
  qualifier: TableQualifier;
  fieldsetName: string;
  fields: FieldIdentifier[];
}

export interface MetadataTemplate {
  name: string;
  is_required: boolean;
  builtin_fields: FieldT[];
  custom_fields: FieldSetT[];
}

interface TemplateFieldCellErrorMessage {
  english: string;
}
interface CellError {
  message: string | TemplateFieldCellErrorMessage;
}
interface TemplateFieldCellOk {
  ok: any;
}
interface TemplateFieldCellError {
  error: CellError;
}
type TemplateFieldCell = TemplateFieldCellOk | TemplateFieldCellError;

export interface TemplateFieldResult {
  name: {
    qualifier: TableQualifier;
    field_name: string;
  };
  results: TemplateFieldCell[]; // TODO: soql-cell-generics
}

export interface TemplateResult {
  template_name: string;
  result: TemplateFieldResult[];
}

export enum MetadataType {
  text = 'text-field',
  select = 'select',
  dependentSelectWithSelectParent = 'dependent-select-with-select-parent',
  dependentSelectWithMultiSelectParent = 'dependent-select-with-multi-select-parent',
  multiSelect = 'multi-select',
  dependentMultiSelect = 'dependent-multi-select'
}

export const asDependentMetadataType = (metadataComp: MetadataFieldComponents): Option<MetadataComponentsWithParent> => {
  if (
    metadataComp.type === MetadataType.dependentSelectWithSelectParent ||
    metadataComp.type === MetadataType.dependentSelectWithMultiSelectParent ||
    metadataComp.type === MetadataType.dependentMultiSelect) {
    return some(metadataComp);
  } else {
    return none;
  }
};

export interface OptionByParent {
  parentValue: string;
  options: string[];
  labels: string[];
}

interface BaseMetadataFieldComponents {
  type: MetadataType;
  inputCref: ColumnRef;
  options: string[];
}

interface BaseMetadataCaseFunctionComponents extends BaseMetadataFieldComponents {
  staticCases: Expr[];
  defaultPredicate: SoQLBooleanLiteral;
  defaultConsequent: FunCall;
}

export interface SelectComponents extends BaseMetadataCaseFunctionComponents {
  type: MetadataType.select;
}

export interface MultiSelectComponents extends BaseMetadataFieldComponents {
  type: MetadataType.multiSelect;
  optionsByParent: null;
  parentField: null;
}

interface BaseMetadataWithParentComponents extends BaseMetadataCaseFunctionComponents {
  parentField: ColumnRef;
  optionsByParent: OptionByParent[];
  parentOptions?: string[];
  parentLabels?: string[];
  parentDisplayName?: string;
  parentType?: MetadataType;
}

export interface SelectWithMultiSelectParentComponents extends BaseMetadataWithParentComponents {
  type: MetadataType.dependentSelectWithMultiSelectParent;
}

export interface SelectWithSelectParentComponents extends BaseMetadataWithParentComponents {
  type: MetadataType.dependentSelectWithSelectParent;
}

export interface MultiSelectWithParentComponents extends BaseMetadataWithParentComponents {
  type: MetadataType.dependentMultiSelect;
}

export type SelectWithParentComponents =
  | SelectWithMultiSelectParentComponents
  | SelectWithSelectParentComponents;

export type MetadataComponentsWithParent = SelectWithParentComponents | MultiSelectWithParentComponents;

export type MetadataFieldComponents = SelectComponents | MultiSelectComponents | MetadataComponentsWithParent;

export enum ChangeTemplateType {
  addField = 'add-field',
  base = 'base',
  deleteField = 'delete-field'
}
interface BaseFieldSetPayload {
  template_name: string,
  fieldset_name: string,
  template_state: MetadataTemplate,
}

interface FieldSetPayload extends BaseFieldSetPayload {
  type: ChangeTemplateType.base
}

export interface AddFieldPayload extends BaseFieldSetPayload {
  display_name: string,
  type: ChangeTemplateType.addField
}

interface DeleteFieldPayload extends BaseFieldSetPayload {
  field_name: string
  type: ChangeTemplateType.deleteField
}

export type ChangeTemplatePayload = FieldSetPayload |
AddFieldPayload | DeleteFieldPayload;
