import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { uniqueId as _uniqueId, cloneDeep as _cloneDeep } from 'lodash';
import I18n from 'common/i18n';
import { PhxChannel, socket } from 'common/types/dsmapi';
import { RootState } from '../store';
import { FieldInput, MetadataTemplate, TemplateResult } from 'common/types/metadataTemplate';
import { AssetMetadata, InnerMetadataObject } from '../../types';
import { RevisionMetadata } from 'common/types/revision';
import { FormActions, setFormErrors, setTemplateErrors } from './forms';
import { FlashMessageActions, hideFlashMessage, showFlashMessage } from './flashMessage';
import {
  evaluateMetadata as makeEvaluationCall,
  EvaluationPayload,
  EvaluationResult as RevisionBasedEvaluationResult
} from '../../components/utils/publicHelpers';

const t = (key: string, scope = 'shared.dataset_management_ui.metadata_manage.connection_status') =>
  I18n.t(key, { scope });

const DSMAPI_METADATA_CHANNEL_TOPIC = 'metadata_templates';
const SOCKET_DISCONNECTED = 'socket_disconnected';
const SOCKET_RECONNECTED = 'socket_reconnected';

export enum MetadataChannelActionType {
  METADATA_CHANNEL_JOINED = 'METADATA_CHANNEL_JOINED',
  METADATA_CHANNEL_NEW_EVALUATION_REQUEST = 'METADATA_CHANNEL_NEW_EVALUATION_REQUEST',
  METADATA_CHANNEL_CLEAR_EVALUATION_REQUEST = 'METADATA_CHANNEL_CLEAR_EVALUATION_REQUEST',
  METADATA_CHANNEL_RECEIVED_EVALUATION_RESULTS = 'METADATA_CHANNEL_RECEIVED_EVALUATION_RESULTS',
  METADATA_CHANNEL_SOCKET_CONNECTED = 'METADATA_CHANNEL_SOCKET_CONNECTED',
  METADATA_CHANNEL_SOCKET_ERROR = 'METADATA_CHANNEL_SOCKET_ERROR'
}

interface EvaluationResult {
  results: TemplateResult[];
  metadata: AssetMetadata;
  errors: string[];
}

export interface MetadataChannelJoinedAction {
  type: MetadataChannelActionType.METADATA_CHANNEL_JOINED;
  channel: PhxChannel;
  templates: MetadataTemplate[];
}

export interface MetadataChannelSocketConnectedAction {
  type: MetadataChannelActionType.METADATA_CHANNEL_SOCKET_CONNECTED;
}

export interface MetadataChannelSocketErrorAction {
  type: MetadataChannelActionType.METADATA_CHANNEL_SOCKET_ERROR;
}

export interface MetadataChannelNewEvaluationRequestAction {
  type: MetadataChannelActionType.METADATA_CHANNEL_NEW_EVALUATION_REQUEST;
  requestId: string;
}

export interface MetadataChannelClearEvaluationRequestAction {
  type: MetadataChannelActionType.METADATA_CHANNEL_CLEAR_EVALUATION_REQUEST;
}

export interface MetadataChannelReceivedEvaluationResultsAction {
  type: MetadataChannelActionType.METADATA_CHANNEL_RECEIVED_EVALUATION_RESULTS;
  templateResults: TemplateResult[];
}

export type MetadataChannelActions =
  | MetadataChannelJoinedAction
  | MetadataChannelSocketConnectedAction
  | MetadataChannelSocketErrorAction
  | MetadataChannelNewEvaluationRequestAction
  | MetadataChannelClearEvaluationRequestAction
  | MetadataChannelReceivedEvaluationResultsAction;

type Dispatch = ThunkDispatch<RootState, void, MetadataChannelActions | FormActions | FlashMessageActions>;

export const metadataChannelJoined = (
  channel: PhxChannel,
  templates: MetadataTemplate[]
): MetadataChannelJoinedAction => ({
  type: MetadataChannelActionType.METADATA_CHANNEL_JOINED,
  channel,
  templates
});

export const metadataChannelSocketConnected = (): MetadataChannelSocketConnectedAction => ({
  type: MetadataChannelActionType.METADATA_CHANNEL_SOCKET_CONNECTED
});

export const metadataChannelSocketError = (): MetadataChannelSocketErrorAction => ({
  type: MetadataChannelActionType.METADATA_CHANNEL_SOCKET_ERROR
});

export const metadataChannelNewEvaluationRequest = (
  requestId: string
): MetadataChannelNewEvaluationRequestAction => ({
  type: MetadataChannelActionType.METADATA_CHANNEL_NEW_EVALUATION_REQUEST,
  requestId
});

export const metadataChannelClearEvaluationRequest = (): MetadataChannelClearEvaluationRequestAction => ({
  type: MetadataChannelActionType.METADATA_CHANNEL_CLEAR_EVALUATION_REQUEST
});

export const metadataChannelReceivedEvaluationResults = (
  templateResults: TemplateResult[]
): MetadataChannelReceivedEvaluationResultsAction => ({
  type: MetadataChannelActionType.METADATA_CHANNEL_RECEIVED_EVALUATION_RESULTS,
  templateResults
});

const hideSocketFlashMessages = (dispatch: Dispatch) => {
  dispatch(hideFlashMessage(SOCKET_RECONNECTED));
  dispatch(hideFlashMessage(SOCKET_DISCONNECTED));
};

export const showReconnectionSuccess =
  (): ThunkAction<void, RootState, void, MetadataChannelActions> => (dispatch: Dispatch, getState) => {
    const { connectionEstablishedOnce } = getState().metadataChannel.socketInfo;

    if (!connectionEstablishedOnce) return;

    hideSocketFlashMessages(dispatch);

    dispatch(
      showFlashMessage({
        kind: 'success',
        id: SOCKET_RECONNECTED,
        message: t('reconnected'),
        hideAfterMS: 3000
      })
    );
  };

export const showDisconnectionError =
  (): ThunkAction<void, RootState, void, MetadataChannelActions> => (dispatch: Dispatch, getState) => {
    const { connectionEstablishedOnce, connectionErrorCount } = getState().metadataChannel.socketInfo;

    hideSocketFlashMessages(dispatch);

    if (connectionEstablishedOnce || connectionErrorCount > 1) {
      dispatch(
        showFlashMessage({
          kind: 'warning',
          id: SOCKET_DISCONNECTED,
          message: t('disconnected')
        })
      );
    }
  };

export const joinMetadataChannel =
  (): ThunkAction<void, RootState, void, MetadataChannelActions> => (dispatch: Dispatch) => {
    const dsmapiSocket = socket();

    dsmapiSocket.onOpen(() => {
      dispatch(showReconnectionSuccess());
      dispatch(metadataChannelSocketConnected());
    });

    dsmapiSocket.onError(() => {
      dispatch(metadataChannelSocketError());
      dispatch(showDisconnectionError());
    });

    const channel = dsmapiSocket.channel(DSMAPI_METADATA_CHANNEL_TOPIC);
    channel.join().receive('ok', ({ templates }: { templates: MetadataTemplate[] }) => {
      dispatch(metadataChannelSocketConnected());
      dispatch(metadataChannelJoined(channel, templates));
    });
  };

const buildEvaluationRequestPayload = (
  inputs: FieldInput[],
  metadata: AssetMetadata,
  metadataTemplates: MetadataTemplate[]
): EvaluationPayload => {
  const applicableTemplateNames: string[] = metadataTemplates
    .filter((template) => template.is_required)
    .map((template) => template.name);

  const {
    builtIn: {
      name,
      rowLabel,
      rdfSubject,
      rdfClass,
      resourceName,
      description,
      attachments,
      tags,
      license,
      attribution,
      category
    },
    privateMetadata,
    customFields
  } = metadata;

  const revisionMetadata: RevisionMetadata = {
    name,
    resourceName,
    description,
    attachments,
    tags,
    licenseId: license.licenseId,
    license: {
      termsLink: license.termsLink,
      name: license.name
    },
    attribution: attribution.name,
    attributionLink: attribution.link,
    category,
    metadata: {
      rowLabel,
      rdfSubject,
      rdfClass,
      custom_fields: customFields
    },
    privateMetadata: {
      ...privateMetadata,
      custom_fields: privateMetadata.customFields
    }
  };

  return {
    inputs,
    metadata: revisionMetadata,
    templates: applicableTemplateNames
  };
};

const handleEvaluationResponse = (evaulationResult: RevisionBasedEvaluationResult): EvaluationResult => {
  const { results, metadata, errors } = evaulationResult;
  const {
    rowLabel,
    rdfSubject,
    rdfClass,
    custom_fields: customFields
  } = metadata.metadata as InnerMetadataObject;

  const formattedMetadata: AssetMetadata = {
    builtIn: {
      name: metadata.name,
      rowLabel,
      rdfSubject,
      rdfClass,
      tags: metadata.tags,
      resourceName: metadata.resourceName,
      attachments: metadata.attachments,
      license: {
        ...metadata.license,
        licenseId: metadata.licenseId
      },
      attribution: {
        name: metadata.attribution,
        link: metadata.attributionLink
      },
      description: metadata.description,
      category: metadata.category
    },
    privateMetadata: {
      contactEmail: metadata.privateMetadata.contactEmail,
      customFields: _cloneDeep(metadata.privateMetadata.custom_fields)
    },
    customFields: _cloneDeep(customFields)
  };

  return {
    results,
    metadata: formattedMetadata,
    errors
  };
};

export const evaluateMetadata =
  (inputs: FieldInput[]): ThunkAction<Promise<AssetMetadata[]>, RootState, void, MetadataChannelActions> =>
  async (dispatch: Dispatch, getState) => {
    const {
      metadataChannel: { channel, metadataTemplates },
      forms: {
        metadataForm: { state: metadata }
      }
    } = getState();

    if (!channel || !metadata) {
      return [];
    }

    const requestId = _uniqueId();
    dispatch(metadataChannelNewEvaluationRequest(requestId));

    // There can be multiple evaluations happening at once. While they return in the order that
    // they were received, we don't want to clear the isInProgress state if there are outstanding
    // evaluations. So we'll use an ID and only clear it if it was our request.
    const maybeClearRequest = () => {
      const {
        metadataChannel: { latestRequestId }
      } = getState();
      if (latestRequestId === requestId) {
        dispatch(metadataChannelClearEvaluationRequest());
      }
    };

    try {
      const payload = buildEvaluationRequestPayload(inputs, metadata, metadataTemplates);
      const rawResult: RevisionBasedEvaluationResult = await makeEvaluationCall(channel, payload);
      const { results, errors, metadata: updatedMetadata } = handleEvaluationResponse(rawResult);
      updatedMetadata.results = {...metadata.results, inputs};

      dispatch(metadataChannelReceivedEvaluationResults(results));
      dispatch(setFormErrors(errors));
      dispatch(setTemplateErrors(errors));
      return [updatedMetadata];
    } catch (error) {
      console.error(error);
    } finally {
      maybeClearRequest();
    }
    return [];
  };
