// Vendor Imports
import _ from 'lodash';
import React, { Component } from 'react';

// Project Imports
import Autocomplete from 'common/components/Autocomplete';
import I18n from 'common/i18n';
import Slider from 'common/components/Slider';
import { geoSearch } from 'common/mapbox';
import FilterFooter from '../FilterFooter';
import { getRadiusFilter, getDefaultFilterForColumn } from '../filters';
import { addPopupListener } from './InputFocus';
import { FilterEditorProps } from '../types';
import { RadiusFilter as RadiusFilterType } from '../SoqlFilter';

const DEFAULT_RADIUS = 2;
const SLIDER_MIN_RADIUS = 0.01;
const MAX_RADIUS = 10;
const RADIUS_STEP = 1;
const DEBOUNCE_WAIT_TIME = 400; // in milliseconds
const INPUT_FIELD_MIN_RADIUS = 0;

const sanitizeRadius = (radius: string | number): number => {
  const roundedNumber = _.round(Number(radius), 2);

  return _.clamp(roundedNumber, SLIDER_MIN_RADIUS, MAX_RADIUS);
};

const scope = 'shared.components.filter_bar.radius_filter';

interface RadiusFilterState {
  center: number[];
  radius: number;
  humanReadableLocation: string;
}

class RadiusFilter extends Component<FilterEditorProps, RadiusFilterState> {
  radiusFilter: HTMLDivElement;
  removePopupListener = () => {};
  popupListenerRemoved = false;

  constructor(props: FilterEditorProps) {
    super(props);

    const { filter } = this.props;
    const center = _.get(filter, 'arguments[0].center', []);
    const humanReadableLocation = _.get(filter, 'arguments[0].humanReadableLocation', '');
    const radius = _.get(filter, 'arguments[0].radius', DEFAULT_RADIUS);

    this.state = { humanReadableLocation, center, radius: sanitizeRadius(radius) };
  }

  componentDidMount() {
    const { popupRef } = this.props;
    this.removePopupListener = addPopupListener(popupRef, this.radiusFilter, () => {
      this.popupListenerRemoved = true;
    });
  }

  componentWillUnmount() {
    const { popupRef } = this.props;
    if (!this.popupListenerRemoved) {
      popupRef?.current?.removeEventListener('forge-popup-position', this.removePopupListener);
    }
  }

  getSearchResults = (searchTerm: string) => {
    const { constraints } = this.props;
    const geoSearchOptions = _.get(constraints, 'geoSearch');

    return geoSearch(searchTerm, geoSearchOptions).then((results: { title: string; geometry: unknown }[]) => {
      const formattedResults = _.map(results, (result) => {
        return {
          title: result.title,
          matches: [],
          geometry: result.geometry
        };
      });
      return { results: formattedResults };
    });
  };

  // Without a debounce, whenever the user changes the radius either using the text box or
  // the increment/decrement buttons in the text box or using the slider, it immediately updates
  // the filter in the vif, resulting in soql queries. If the user continuously clicks on the
  // increment button in the text box, without debounce, it will fire a lot of soql queries.
  onChange = _.debounce(() => {
    const { column, dataProvider, filter } = this.props;
    const { center, radius, humanReadableLocation } = this.state;

    this.props.onUpdate(
      getRadiusFilter(
        column,
        filter as RadiusFilterType,
        center,
        radius,
        humanReadableLocation,
        dataProvider[0].datasetUid
      ),
      {
        shouldCloseControl: false
      }
    );
  }, DEBOUNCE_WAIT_TIME);

  onLocationSelection = (title: string, selection: { geometry: { coordinates: number[] } }) => {
    this.setState(
      {
        humanReadableLocation: _.get(selection, 'title'),
        center: _.get(selection, 'geometry.coordinates')
      },
      this.onChange
    );
  };

  onRadiusChange = (radius: string | number) => {
    this.setState({ radius: sanitizeRadius(radius) }, this.onChange);
  };

  renderFooter = () => {
    const { isReadOnly, onRemove } = this.props;

    const footerProps = {
      isReadOnly,
      showApplyButton: false,
      onClickRemove: onRemove,
      onClickReset: this.resetFilter
    };

    return <FilterFooter {...footerProps} />;
  };

  renderRadiusInputField = () => {
    return (
      <div className="radius-input-field-container">
        <label>{I18n.t('radius_field_label', { scope })}</label>
        <input
          max={MAX_RADIUS}
          min={INPUT_FIELD_MIN_RADIUS}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.onRadiusChange(event.target.value)}
          step={RADIUS_STEP}
          type="number"
          value={this.state.radius}
        />
      </div>
    );
  };

  renderRadiusSliderField = () => {
    const sliderProps = {
      // If you pass in value as a number, then onChange should take a number
      onChange: this.onRadiusChange as any,
      rangeMax: MAX_RADIUS,
      rangeMin: SLIDER_MIN_RADIUS,
      step: RADIUS_STEP,
      value: this.state.radius,
      formatLabel: (label: number) => _.round(label, 2).toString()
    };

    return <Slider {...sliderProps} />;
  };

  renderSearchField = () => {
    const { humanReadableLocation } = this.state;
    const placeholder = I18n.t('location_input_placeholder', { scope });

    const autocompleteProps = {
      className: 'geocode-autocomplete',
      getSearchResults: this.getSearchResults,
      focusFirstResult: true,
      millisecondsBeforeSearch: DEBOUNCE_WAIT_TIME,
      onChooseResult: this.onLocationSelection,
      placeholder,
      query: humanReadableLocation
    };

    return <Autocomplete {...autocompleteProps} />;
  };

  resetFilter = () => {
    const { column, onUpdate } = this.props;

    const filter = _.cloneDeep(getDefaultFilterForColumn(column, this.props.dataProvider[0].datasetUid));
    filter.isHidden = _.get(this.props, 'filter.isHidden', false);
    onUpdate(filter);
  };

  render() {
    return (
      <div className="filter-controls radius-filter" ref={(el: HTMLDivElement) => (this.radiusFilter = el)}>
        <div className="radius-filter-container">
          {this.renderSearchField()}
          {this.renderRadiusInputField()}
          {this.renderRadiusSliderField()}
        </div>
        {this.renderFooter()}
      </div>
    );
  }
}

export default RadiusFilter;
