import classnames from 'classnames';
import Dropdown from 'common/components/Dropdown';
import { picklistSizingStrategy } from 'common/components/Dropdown/picklistSizingStrategy';
import { replaceAt } from 'common/util';
import _, { isArray, isBoolean, isNull, isNumber, isObject, isString } from 'lodash';
import React, { Component, useState } from 'react';
import DragDropContainer, {
  DragDropContainerType,
  makeCustomDragDropElementWrapper
} from '../DragDropContainer';
import { Checkbox } from '../Forms';
import Input from '../Forms/Input';
import SocrataIcon, { IconName } from '../SocrataIcon';
import './style.scss';
import I18n from 'common/i18n';
import { IOption } from '@tylertech/forge';
import { ForgeMenu, ForgeIconButton, ForgeIcon } from '@tylertech/forge-react';

const t = (key: string, params?: Record<string, string | number>) =>
  I18n.t(key, { ...(params || {}), scope: 'shared.components.object_editor' });

const restrictedText = I18n.t('shared.dataset_management_ui.metadata_manage.dataset_tab.subtitles.restricted_field');

export type Json = string | number | boolean | null | Json[] | { [key: string]: Json };

interface Props<T> {
  id: string;
  thing: T;
  onChange: (value: T | null) => void;
  forgeVersion?: boolean; // This will swap a couple of things to forge components so that it displays nicely with them.
  isRestrictedForUser: boolean;
}

enum Adding {
  Text,
  Number,
  Boolean,
  List,
  Object
}
interface Option {
  text: string;
  value: Adding;
}

function AddChild({ onAdd, forgeVersion, isRestrictedForUser }: { onAdd: (a: Json) => void, forgeVersion?: boolean, isRestrictedForUser: boolean }) {
  if (isRestrictedForUser) return null;
  const onSelection = (op: Option | IOption) => {
    if (op.value === Adding.Text) {
      onAdd('');
    }
    if (op.value === Adding.Number) {
      onAdd(0);
    }
    if (op.value === Adding.Boolean) {
      onAdd(true);
    }
    if (op.value === Adding.List) {
      onAdd([]);
    }
    if (op.value === Adding.Object) {
      onAdd({});
    }
  };

  if (forgeVersion) {
    const options = [
      { label: t('add_text'), value: Adding.Text },
      { label: t('add_number'), value: Adding.Number },
      { label: t('add_boolean'), value: Adding.Boolean },
      { label: t('add_list'), value: Adding.List },
      { label: t('add_object'), value: Adding.Object }
    ];
    return (
      <ForgeMenu
        options={options}
        on-forge-menu-select={({ detail }: CustomEvent) => onSelection(detail)}
      >
        <ForgeIconButton >
          <button type="button" aria-label={t('add_value')} data-testid="object-editor-add-value-button">
            <ForgeIcon name="add"/>
          </button>
        </ForgeIconButton>
      </ForgeMenu>
    );
  } else {
    const options = [
      { title: t('add_text'), value: Adding.Text },
      { title: t('add_number'), value: Adding.Number },
      { title: t('add_boolean'), value: Adding.Boolean },
      { title: t('add_list'), value: Adding.List },
      { title: t('add_object'), value: Adding.Object }
    ];
    return (
      <Dropdown
        placeholder={() => (
          <a aria-label={t('add_value')} className="btn-muted add-btn">
            <SocrataIcon name={IconName.Add} />
          </a>
        )}
        size="small"
        label={t('add_value_dropdown')}
        picklistSizingStrategy={picklistSizingStrategy.EXPAND_TO_WIDEST_ITEM}
        options={options}
        onSelection={onSelection}
      />
    );
  }
}

function AddKVPair({ onAdd, forgeVersion, isRestrictedForUser }: { onAdd: (k: string, o: Json) => void, forgeVersion?: boolean, isRestrictedForUser: boolean }) {
  const [name, setName] = useState('');
  const [isAdding, setIsAdding] = useState(false);
  if (isRestrictedForUser) return null;

  const onClick = () => {
    setIsAdding(true);
  };
  if (isAdding) {
    return (
      <div className="add-key-value">
        <Input
          containerClassName="add-key-value-name"
          placeholder={t('property_name')}
          label={t('add_property')}
          onChange={(e) => setName(e.currentTarget.value)}
        />
        <AddChild
          onAdd={(value) => {
            onAdd(name, value);
            setIsAdding(false);
          }}
          forgeVersion={forgeVersion}
          isRestrictedForUser={isRestrictedForUser}
        />
        { forgeVersion ? (
           <ForgeIconButton >
            <button type="button" aria-label={t('nullify')} onClick={() => setIsAdding(false)} data-testid="object-editor-nullify-button">
              <ForgeIcon name="close" />
            </button>
          </ForgeIconButton>
        ) : (
          <a className="btn-muted" onClick={() => setIsAdding(false)}>
            <SocrataIcon name={IconName.Cross2} />
          </a>
        )}
      </div>
    );
  }

  if (forgeVersion) {
    return (
      <div className="add-kv forged">
        <ForgeIconButton>
          <button type="button" aria-label={t('add_key')} onClick={onClick} data-testid="object-editor-add-button">
            <ForgeIcon name="add"/>
          </button>
        </ForgeIconButton>
      </div>
    );
  } else {
    return (
      <div className="add-kv">
        <a aria-label={t('add_key')} className="btn-muted add-btn" onClick={onClick}>
          <SocrataIcon name={IconName.Add} />
        </a>
      </div>
    );
  }
}

function RemoveChild({ onRemove, label, forgeVersion, isRestrictedForUser}: { onRemove: () => void; label: string, forgeVersion?: boolean, isRestrictedForUser: boolean }) {
  if (isRestrictedForUser) return null;
  if (forgeVersion) {
    return (
      <ForgeIconButton dense={true} data-testid="object-editor-remove-child">
        <button type="button" aria-label={label} onClick={onRemove}>
          <ForgeIcon name="trash_can" />
        </button>
      </ForgeIconButton>
    );
  } else {
    return (
      <a aria-label={label} onClick={onRemove} className="btn-muted delete-btn">
        <SocrataIcon name={IconName.Delete} />
      </a>
    );
  }
}

function Nullify({ onNullify, forgeVersion, isRestrictedForUser}: { onNullify: () => void, forgeVersion?: boolean, isRestrictedForUser: boolean }) {
  if (isRestrictedForUser) return null;
  if (forgeVersion) {
    return (
      <ForgeIconButton dense={true}>
        <button type="button" aria-label={t('nullify')} onClick={onNullify} data-testid="object-editor-nullify-close" >
          <ForgeIcon name="close" />
        </button>
      </ForgeIconButton>
    );
  } else {
    return (
      <a className="btn-muted nullify-btn" onClick={onNullify} title={t('nullify')} aria-label={t('nullify')}>
        <SocrataIcon name={IconName.Cross2} />
      </a>
    );
  }
}

function EmptyComposite({ label, onNullify, forgeVersion, isRestrictedForUser}: { label: string; onNullify: () => void , forgeVersion?: boolean, isRestrictedForUser: boolean}) {
  return (
    <div className="empty-composite">
      {label}
      <Nullify onNullify={onNullify} forgeVersion={forgeVersion} isRestrictedForUser={isRestrictedForUser} />
    </div>
  );
}

function ArrayEditor(props: Props<Json[]>) {
  const { id, thing, isRestrictedForUser, forgeVersion, onChange } = props;
  return (
    <div className="json-array-editor">
      {props.thing.length > 0 ? (
        <DragDropContainer
          type={DragDropContainerType.LIST}
          items={thing}
          onDrop={onChange}
          childElements={makeCustomDragDropElementWrapper(
            thing.map((subThing, index) => {
              const subKey = `${id}.${index}`;
              return (
                <div className="json-array-item" key={index}>
                  <ObjectEditor
                    key={subKey}
                    thing={subThing}
                    id={subKey}
                    onChange={(newSubThing) => onChange(replaceAt(thing, newSubThing, index))}
                    forgeVersion={forgeVersion}
                    isRestrictedForUser={isRestrictedForUser}
                  />
                   <RemoveChild
                      label={t('remove_index', { index })}
                      onRemove={() => onChange(thing.filter((_unused, i) => i !== index))}
                      forgeVersion={forgeVersion}
                      isRestrictedForUser={isRestrictedForUser}
                    />
                </div>
              );
            }),
            () => !isRestrictedForUser
          )}
        />
      ) : (
        <EmptyComposite label={t('empty_list')} onNullify={() => onChange(null)} forgeVersion={forgeVersion} isRestrictedForUser={isRestrictedForUser} />
      )}
      <div className={`add-child ${props.forgeVersion ? 'forged' : ''}`}  >
        <AddChild onAdd={(newSubThing) => props.onChange([...thing, newSubThing])} forgeVersion={forgeVersion} isRestrictedForUser={isRestrictedForUser}/>
      </div>
    </div>
  );
}

function StringEditor({ id, thing, onChange, forgeVersion, isRestrictedForUser }: Props<string>) {
  return (
    <div className="json-string-editor">
      <TextField id={id} onChange={onChange} value={thing} isRestrictedForUser={isRestrictedForUser}/>
      <Nullify onNullify={() => onChange(null)} forgeVersion={forgeVersion} isRestrictedForUser={isRestrictedForUser} />
    </div>
  );
}

function NumberEditor({ id, thing, onChange, forgeVersion, isRestrictedForUser }: Props<number>) {
  const [error, setError] = useState<string | null>(null);
  const [current, setValue] = useState<string | null>(thing.toString());
  const onChangeTextField = (value: string) => {
    setError(null);
    setValue(value);

    // yes, this lops off trailing characters. oh well.
    const int = parseInt(value);
    const float = parseFloat(value);

    if (!isNaN(int)) {
      return onChange(int);
    }
    if (!isNaN(float)) {
      return onChange(float);
    }

    setError(t('invalid_number'));
  };

  return (
    <div className="json-number-editor">
      {error ? <label>{error}</label> : null}
      <TextField id={id} onChange={onChangeTextField} value={current} isRestrictedForUser={isRestrictedForUser}/>
      <Nullify onNullify={() => onChange(null)} forgeVersion={forgeVersion} isRestrictedForUser={isRestrictedForUser}/>
    </div>
  );
}

function BooleanEditor({ thing, id, onChange, forgeVersion, isRestrictedForUser }: Props<boolean>) {
  return (
    <div className="json-boolean-editor">
      <Checkbox disabled={isRestrictedForUser} label="" id={id} checked={thing} onChange={(e) => onChange(e.currentTarget.checked)} />
      <Nullify onNullify={() => onChange(null)} forgeVersion={forgeVersion} isRestrictedForUser={isRestrictedForUser} />
    </div>
  );
}

function NullEditor({ onChange, forgeVersion, isRestrictedForUser }: Props<Json>) {
  return (
    <div className="json-null-editor">
      <span className="text-muted">{t('null_value')}</span>
      <AddChild onAdd={onChange} forgeVersion={forgeVersion} isRestrictedForUser={isRestrictedForUser} />
    </div>
  );
}

class ObjectEditor extends Component<Props<Json>> {
  render() {
    const { thing, onChange, id, forgeVersion, isRestrictedForUser } = this.props;
    if (isString(thing)) {
      return <StringEditor id={id} thing={thing} onChange={onChange} forgeVersion={forgeVersion} isRestrictedForUser={isRestrictedForUser}/>;
    }
    if (isNumber(thing)) {
      return <NumberEditor id={id} thing={thing} onChange={onChange} forgeVersion={forgeVersion} isRestrictedForUser={isRestrictedForUser}/>;
    }
    if (isBoolean(thing)) {
      return <BooleanEditor id={id} thing={thing} onChange={onChange} forgeVersion={forgeVersion} isRestrictedForUser={isRestrictedForUser}/>;
    }
    if (isNull(thing)) {
      return <NullEditor id={id} thing={thing} onChange={onChange} forgeVersion={forgeVersion} isRestrictedForUser={isRestrictedForUser}/>;
    }

    if (isArray(thing)) {
      return <ArrayEditor id={id} thing={thing} onChange={onChange} forgeVersion={forgeVersion} isRestrictedForUser={isRestrictedForUser}/>;
    }
    if (isObject(thing)) {
      return (
        // we'll try our best to keep object keys in the same order, so the object doesn't totally
        // jump around when someone changes an object key. obviously not perfect.
        <div className="json-object-editor">
          {_.keys(thing).length === 0 ? (
            <EmptyComposite label={t('empty_object')} onNullify={() => onChange(null)} forgeVersion={forgeVersion} isRestrictedForUser={isRestrictedForUser}/>
          ) : null}

          {_.sortBy(_.keys(thing), (k) => k.toLowerCase()).map((key, idx) => {
            const value = thing[key];
            const isPrimitive = isString(value) || isNumber(value) || isBoolean(value) || isNull(value);
            const isComposite = isArray(value) || isObject(value);
            const removeKV = (
              <RemoveChild label={t('remove_key', { key })} onRemove={() => onChange(_.omit(thing, key))} forgeVersion={forgeVersion} isRestrictedForUser={isRestrictedForUser} />
            );

            const onChangeChild = (newChild: Json) => {
              onChange({ ...thing, [key]: newChild });
            };
            // Not using the key here since it's subject to change
            const subId = `${this.props.id}.${idx}`;
            return (
              <div
                key={subId}
                className={classnames({
                  'object-kv': true,
                  'object-kv-primitive': isPrimitive,
                  'object-kv-composite': isComposite
                })}
              >
                <div className="object-key">
                  <TextField
                    key={`${subId}-key-editor`}
                    id={`${subId}-key-editor`}
                    onChange={(newKey) => onChange({ ..._.omit(thing, key), [newKey]: thing[key] })}
                    value={key}
                    isRestrictedForUser={isRestrictedForUser}
                  />
                  {isComposite ? <div>{removeKV}</div> : null}
                </div>
                <div className="object-value">
                  <ObjectEditor thing={value} id={subId} onChange={onChangeChild} forgeVersion={forgeVersion} isRestrictedForUser={isRestrictedForUser}/>
                </div>
                {isPrimitive ? <div>{removeKV}</div> : null}
              </div>
            );
          })}

          <AddKVPair onAdd={(newKey, newSubThing) => onChange({ ...thing, [newKey]: newSubThing })} forgeVersion={forgeVersion} isRestrictedForUser={isRestrictedForUser}/>
        </div>
      );
    }
  }
}

function TextField({
  id,
  onChange,
  value, isRestrictedForUser
}: {
  id: string;
  onChange: (s: string) => void;
  value: string | null;
  isRestrictedForUser: boolean;
}) {
  return (
    <input
      disabled={isRestrictedForUser}
      id={id}
      className="text-input"
      type="text"
      value={value || ''}
      onChange={(e) => {
        e.preventDefault();
        onChange(e.currentTarget.value);
        return false;
      }}
    />
  );
}

function ObjectEditorWrapper(props: Props<Json> & {displayName?: string}) {
  return (
    <div className="object-editor-wrapper">
      {props.forgeVersion && props.displayName && <label className="object-field-label" aria-label={props.displayName}>
        {props.displayName}
      </label>}
      <ObjectEditor {...props} />
      {props.isRestrictedForUser && <span slot="helper-text" className="object-field-subtitle">{restrictedText}</span>}
    </div>
  );
}


// Because react-dnd is so awful, we need to make this a lower order component (ie: all callers
// of this component must know details about the implementation in order to use it) and the caller
// must know to do DragDropContext(HTML5Backend)(WhateverComponent) at the highest level of the component
// tree where all DND components on the page are used or else there will be a runtime error of "cannot
// have two html5 backends at the same time"
// ugh
export default ObjectEditorWrapper;
