/* Imports */
/* ============================================================================= */
import { extend } from 'lodash';

import { WorkflowTargetAudience, Status } from 'common/core/approvals_enums';
import { GuidanceSummaryV2 } from 'common/types/approvals';

import { BaseGuidanceSummaryV2Helper } from 'common/core/approvals/Types';
import {
  hasTruthyWorkflow,
  checkForWorkflowValueMatch,
  checkForWorkflowValueOtherThan,
  getWorkflowGuidanceHelper,
  summarizeAssetStatus,
  summarizeCanSubmitChangeAudienceRequest,
  summarizeCanSubmitForApproval,
  summarizeCanSubmitUpdatePublishedAssetRequest,
  summarizeIsPending,
  summarizePublishedUid,
  summarizeWillEnterApprovalQueue,
  summarizeWorkflowsWithGuidance,
  whichIsPending,
  WORKFLOWS
} from '.';





/* Method */
/* ============================================================================= */
/**
 * Get a BaseGuidanceSummaryV2Helper object for a given GuidanceSummaryV2 object
 * @param guidance GuidanceSummaryV2 object
 * @returns BaseGuidanceSummaryV2Helper object
 */
export const getBaseGuidanceSummaryV2Helper = (guidance: GuidanceSummaryV2): BaseGuidanceSummaryV2Helper => {
  const guidanceHelper = {} as BaseGuidanceSummaryV2Helper;

  /* Add attributes specific to GuidanceSummaryV2Helper */
  /* ----------------------------------------------- */
  // Add workflow helper attributes
  WORKFLOWS.forEach((targetAudience) => {
    guidanceHelper[targetAudience] = guidance[targetAudience]
      ? getWorkflowGuidanceHelper(guidance[targetAudience])
      : undefined;
  });

  // Add other attributes
  extend(guidanceHelper, {
    workflowsWithGuidance: summarizeWorkflowsWithGuidance(guidance),
  });


  /* Add methods specific GuidanceSummaryV2Helper */
  /* ----------------------------------------------- */
  extend(guidanceHelper, {
    hasGuidance: (workflow?: WorkflowTargetAudience) =>
      hasTruthyWorkflow(guidanceHelper.workflowsWithGuidance, workflow) ? guidanceHelper.workflowsWithGuidance : false,
    hasStatus: (status: Status, workflow?: WorkflowTargetAudience) =>
      checkForWorkflowValueMatch(summarizeAssetStatus(guidance), status, workflow),
    hasStatusOtherThan: (status: Status, workflow?: WorkflowTargetAudience) =>
      checkForWorkflowValueOtherThan(summarizeAssetStatus(guidance), status, workflow),
    summarizeAssetStatus: () => summarizeAssetStatus(guidance),
    summarizeCanSubmitChangeAudienceRequest: () => summarizeCanSubmitChangeAudienceRequest(guidance, guidanceHelper),
    summarizeCanSubmitForApproval: () => summarizeCanSubmitForApproval(guidance, guidanceHelper),
    summarizeCanSubmitUpdatePublishedAssetRequest: () => summarizeCanSubmitUpdatePublishedAssetRequest(guidance, guidanceHelper),
    summarizeIsPending: () => summarizeIsPending(guidance, guidanceHelper),
    summarizePublishedUid: () => summarizePublishedUid(guidance, guidanceHelper),
    summarizeWillEnterApprovalQueue: () => summarizeWillEnterApprovalQueue(guidance, guidanceHelper),
    whichIsPending: () => whichIsPending(guidanceHelper),
  });

  return guidanceHelper;
};

export default getBaseGuidanceSummaryV2Helper;
