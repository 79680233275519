import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import I18n from 'common/i18n';

import Functions from '../Lang/Functions';
import { showDocumentation } from '../redux/QueryEditorActions';
import FunctionDocsParameterTable from './FunctionDocsParameterTable';

/**
 * For the given data type, this will list all the functions that relate to it
 */
class ColumnDocsRelatedFunctions extends Component {
  static propTypes = {
    dataTypeName: PropTypes.string.isRequired,
    onFunctionDetailsClick: PropTypes.func.isRequired
  }

  toggleFunctionDetails(event, functionName) {
    event.preventDefault();

    const functionDetailsDiv = document.getElementById(`func-${functionName}`);
    functionDetailsDiv.classList.toggle('expanded');
  }

  render() {
    const { dataTypeName } = this.props;

    return (
      <div>
        {Object.keys(Functions).map(functionName => {
          const func = Functions[functionName];
          const { params, returns } = func;
          const signature = func['function']; // eslint-disable-line

          if (func.datatypes && func.datatypes.includes(dataTypeName)) {
            return (
              <div key={functionName} className="query-editor-info-flannel-row" id={`func-${functionName}`}>
                <div className="query-editor-info-flannel-label">
                  {func.title}
                </div>
                <div className="query-editor-info-flannel-details">
                  {func.description}
                  <a
                    onClick={(e) => this.toggleFunctionDetails(e, functionName)}
                    className="query-editor-info-flannel-see-more"
                    href="">
                    See More
                  </a>
                  <a
                    onClick={(e) => this.toggleFunctionDetails(e, functionName)}
                    className="query-editor-info-flannel-see-less"
                    href="">
                    See Less
                  </a>
                  <div className="query-editor-info-flannel-function-details">
                    <span><strong>{I18n.t('shared.query_editor.signature')}</strong> {signature}</span>
                    <FunctionDocsParameterTable params={params} />
                    <span><strong>{I18n.t('shared.query_editor.returns')}</strong> {returns}</span>
                  </div>
                </div>
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onFunctionDetailsClick: (functionName) => dispatch(showDocumentation('function', functionName))
});

export default connect(null, mapDispatchToProps)(ColumnDocsRelatedFunctions);
