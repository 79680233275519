import { AnyAction } from 'redux';
import I18n from 'common/i18n';

import type {
  CatalogInteractiveUser,
  UsersCatalogSearchResults
} from 'common/types/users/catalogUsers';

import { WatchAssetManagerActions, setExistingSubscriptions } from './actions';
import { getCurrentUser } from 'common/current_user';
import InteractiveUser from 'common/types/users/interactiveUser';
import UserSegment from 'common/types/users/userSegment';
import { ScreenshotSubscription } from 'common/notifications/api/ScreenshotSubscriptionsAPI';
import { getCurrentDomain } from 'common/currentDomain';

// TKB TODO: this is duplicated (for now)
const t = (k: string, params: any = {}) => {
  return I18n.t(k, { ...params, scope: 'shared.components.asset_action_bar.watch_asset' });
};
// Get the local time zone based on non daylight savings time
const getLocalTimeZone = () => {
  const hoursDiffFromUtc = new Date('2022-02-01').getTimezoneOffset() / 60;
  switch (hoursDiffFromUtc) {
    case 8:
      return 'America/Los_Angeles';
    case 7:
      return 'America/Denver';
    case 6:
      return 'America/Chicago';
    case 5:
      return 'America/New_York';
    default:
      return 'America/Los_Angeles';
  }
};

export interface ReducerState {
  query: string;
  results: UsersCatalogSearchResults[];
  // From modal
  recipients: CatalogInteractiveUser[];
  days?: number;
  time: string;
  timeZone: string;
  startDate: string;
  dayError: boolean;
  showDialog: boolean;
  existingSubscriptions: ScreenshotSubscription[];
  domainCname: string;
  createErrors: string[];
  requestId: string;
}

const toCatalogUser = (u?: InteractiveUser) =>
  ({
    id: u?.id,
    email: u?.email,
    screen_name: u?.displayName,
    future_account: false,
    site_member: u?.userSegment === UserSegment.SiteMember,
    community_member: u?.userSegment === UserSegment.CommunityMember
  } as CatalogInteractiveUser);

export const initialState: ReducerState = {
  query: '',
  results: [],
  recipients: [toCatalogUser(getCurrentUser())],
  time: '',
  timeZone: getLocalTimeZone(),
  startDate: '',
  dayError: false,
  showDialog: true,
  existingSubscriptions: [],
  domainCname: getCurrentDomain(),
  createErrors: [],
  requestId: ''
};

const deriveStateFrom = (existingSubscriptions: ScreenshotSubscription[]) => {
  if (existingSubscriptions.length < 1) {
    return {};
  }

  // Currently, all subscriptions should only differ in their recipient list, so we'll use the first one to set state
  const {
    interval: { days },
    first_occurrence,
    first_occurrence_timezone,
    creation_timezone
  } = existingSubscriptions[0];

   const timeZone = first_occurrence_timezone || creation_timezone;
   const firstOccurrenceDate = new Date(first_occurrence);
    // @ts-ignore - using newer options and TS says aren't available to ES6. Not sure if this will work.
   const time = firstOccurrenceDate.toLocaleTimeString(undefined, { timeZone: timeZone,hourCycle: 'h23' }); // ex: 00:00:00; or 23:59:59
    // TODO: Locale/TimeZone is weird and needs more thought.
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
   const startDate = firstOccurrenceDate
      .toLocaleDateString(undefined, { timeZoneName: 'short' })
      .split(', ')[0];

  return {
    days,
    existingSubscriptions,
    time,
    startDate,
    timeZone
  } as Partial<ReducerState>;
};

export default (state = initialState, action: AnyAction): ReducerState => {
  switch (action.type) {
    case WatchAssetManagerActions.SET_RECIPIENT_QUERY:
      return { ...state, query: action.query };
    case WatchAssetManagerActions.RECIPIENT_SEARCH_SUCCESS:
      return { ...state, results: action.response.results };
    case WatchAssetManagerActions.ADD_TO_RECIPIENTS:
      return {
        ...state,
        recipients: [...state.recipients, action.user],
        results: [],
        query: ''
      };
    case WatchAssetManagerActions.REMOVE_FROM_RECIPIENTS:
      return {
        ...state,
        recipients: state.recipients.filter((r) => r.id !== action.user.id)
      };
    case WatchAssetManagerActions.SET_RECIPIENTS:
      return { ...state, recipients: action.recipients };
    case WatchAssetManagerActions.SET_STATE:
      return { ...state, ...action.payload };
    case WatchAssetManagerActions.SET_EXISTING_SUBSCRIPTIONS:
      return { ...state, ...deriveStateFrom(action.existingSubscriptions) };
    default:
      return state;
  }
};
