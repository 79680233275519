import get from 'lodash/get';
import isNil from 'lodash/isNil';

import { PUBLICATION_STAGE } from 'common/views/constants';
import currentUserCanManageViewers from 'common/views/current_user_can_manage_viewers';
import currentUserCanDeleteView from 'common/views/current_user_can_delete';
import currentUserCanManageCollaborators from 'common/views/current_user_can_manage_collaborators';
import currentUserCanTransferOwnership from 'common/views/current_user_can_transfer_ownership';
import currentUserCanWithdrawApprovalRequest from 'common/views/current_user_can_withdraw_approval_request';
import currentUserCanCopy from 'common/views/current_user_can_copy';
import currentUserCanFederate from 'common/views/current_user_can_federate';
import currentUserCanWatchView from 'common/views/current_user_can_watch';
import hasFlag from 'common/views/has_flag';
import { assetIsDraft } from 'common/views/helpers';
import { isStoryDraft } from 'common/views/view_types';
import { AssetStatus } from 'common/views/asset_status';
import { ViewFlag, View, ViewRight } from 'common/types/view';
import { GuidanceSummaryV2 } from 'common/types/approvals';

import assetIsUnsupportedByAssetActionBar from './asset_is_unsupported';
import { hasOneOfRights, hasRights } from 'common/views/has_rights';

/**
 * This will contain a list of secondary actions that the user is allowed to take on an asset.
 */
export interface AllowedSecondaryActions {
  /** Allow other users to edit this asset */
  manageCollaborators: boolean;

  /** Change the audience (public/private/internal) of this asset */
  changeAudience: boolean;

  /** Delete this asset */
  deleteDataset: boolean;

  /** Lock this asset */
  lockAsset: boolean;

  /** Undo unsaved changes (only makes sense for assets that do not auto-save like grid view) */
  revert: boolean;

  /**
   * If show_derived_views_2018_controls is true (see datasets_helper.rb) then we
   * sometimes show an option to do this on the grid view.
   */
  revertChildView: boolean;

  /** Transfer ownership of this asset to someone else */
  transferOwnership: boolean;

  /** View the published copy of this asset */
  viewPublished: boolean;

  /** Withdraw this asset from the approvals queue */
  withdrawApprovalRequest: boolean;

  /** Copy the asset */
  copy: boolean;

  /** View the active draft */
  viewDraft: boolean;

  /** Make a child (aka derived) view */
  createChildView: boolean;

  /** Watch a view(only Stories are supported for now) */
  watchView: boolean;

  /**
   * Save changes.
   *
   * Note: Most apps don't make use of this.
   * They provide their own save button to A2B.
   * Therefore, this option will only be true for
   * assets that use the builtin A2B behavior
   * (only grid view as of this writing).
   */
  save: boolean;

  /** Visit this asset on its source domain (at sourceDomainCName). */
  viewOnSourceDomain: boolean;

  /** Selectively federate this asset out to other domains */
  federate: boolean;

  /** Access the edit matadata page */
  editMetadata: boolean;
}

/** Encapsulates information needed to determine what secondary actions a user can take */
export interface GetAllowedSecondaryActionsArgs {
  /** The view's status; i.e. published, unpublished, or pending approval */
  assetStatus: AssetStatus;

  /** Guidance summary for the view */
  approvalsGuidance: GuidanceSummaryV2;

  /** The view to get actions for */
  view: View;

  /** The UID of the published version of the view, if there is one */
  publishedViewUid: string | undefined;

  /** DSLP sometimes wants to override pretty much everything. */
  showDSLPButton?: boolean;
}

/**
 * This will return a list of "secondary" actions that a user can take on an asset via
 * the AssetActionBar (AAB).
 *
 * These secondary actions are shown in a dropdown that is opened by clicking the three dots (...)
 * button in the asset action bar.
 */
export const getAllowedSecondaryActions = ({
  approvalsGuidance,
  assetStatus,
  view,
  publishedViewUid,
  showDSLPButton
}: GetAllowedSecondaryActionsArgs): AllowedSecondaryActions => {
  if (showDSLPButton || !approvalsGuidance || assetIsUnsupportedByAssetActionBar(view)) {
    return {
      manageCollaborators: false,
      changeAudience: false,
      deleteDataset: false,
      lockAsset: false,
      revert: false,
      revertChildView: false,
      transferOwnership: false,
      viewPublished: false,
      withdrawApprovalRequest: false,
      copy: false,
      viewDraft: false,
      createChildView: false,
      watchView: false,
      save: false,
      viewOnSourceDomain: false,
      federate: false,
      editMetadata: false
    };
  }

  // Instance of Dataset in Old UX land (grid view).
  const gridViewDataset = get(window, 'blist.dataset');

  // Note that this is NOT just a feature flag but is actually controlled by some fun ruby code!
  // See application_helper.rb for where the window.socrata object is created
  // This is only relevant to (and provided to) grid view, and should default to false.
  const showDerivedViews2018Controls =
    get(window, 'socrata.show_derived_views_2018_controls', false) && !!gridViewDataset;

  const viewerOnly =
    get(window as any, 'initialState.view.hasViewerGrant', false) &&
    !hasOneOfRights(view, ViewRight.Write, ViewRight.UpdateView);
  const moreThanViewer = !viewerOnly;

  const actions: AllowedSecondaryActions = {
    manageCollaborators: currentUserCanManageCollaborators(view),
    viewDraft: !!window.STORY_HAS_ACCESSIBLE_DRAFT && !view.locked,
    changeAudience: moreThanViewer && currentUserCanManageViewers(view, approvalsGuidance),
    deleteDataset: currentUserCanDeleteView(view, approvalsGuidance),
    lockAsset:
      hasRights(view, ViewRight.LockView) &&
      !assetIsDraft({
        coreView: view,
        isRevision: window.initialState?.revisionSeq !== undefined,
        isDraftStory: isStoryDraft(view)
      }),
    // We're not including Revert for now as per ChristianH.
    revert: false, // publicationState === 'draft' && publishedViewUid,
    revertChildView:
      moreThanViewer &&
      showDerivedViews2018Controls &&
      assetStatus === AssetStatus.Draft &&
      get(window, 'blist.dataset'), // only exists in grid view
    transferOwnership: currentUserCanTransferOwnership(view, approvalsGuidance),
    viewPublished: moreThanViewer && assetStatus !== AssetStatus.Published && !isNil(publishedViewUid),
    withdrawApprovalRequest: currentUserCanWithdrawApprovalRequest(view, approvalsGuidance),
    federate: currentUserCanFederate(view, approvalsGuidance),
    copy: currentUserCanCopy(view),
    createChildView:
      showDerivedViews2018Controls && gridViewDataset.publicationStage === PUBLICATION_STAGE.PUBLISHED,
    watchView: currentUserCanWatchView(view, approvalsGuidance),
    save:
      !view.locked &&
      showDerivedViews2018Controls &&
      hasRights(view, ViewRight.Write) &&
      gridViewDataset.publicationStage !== PUBLICATION_STAGE.PUBLISHED,
    viewOnSourceDomain:
      hasFlag(view, ViewFlag.InternalToPublicFederatedView) && !isNil(view.sourceDomainCName),
    editMetadata: assetStatus === AssetStatus.Draft && !view.locked && hasRights(view, ViewRight.UpdateView)
  };

  return actions;
};
