import { fetchJsonWithDefaultHeaders, fetchWithDefaultHeaders } from 'common/http';
import I18n from 'common/i18n';
import { includes } from 'lodash';
const DEFAULT_CATEGORIES = ['fun', 'government', 'personal', 'education', 'business'];
const t = (key: string) => I18n.t(key, { scope: 'shared.metadata_template.default_categories' });


export interface Category {
  name: string;
  value: {
    parent?: string | null,
    enabled: boolean
  }
}
export type LocalizedCategory = Category & { localized: string | null };


interface CategoryConfig {
  domainCName: string;
  type: 'view_categories';
  id: number | null;
  properties?: Category[];
}
type Configuration = CategoryConfig | never;


const pluckRealConfig = (configs: Configuration[]) => {
  return (
    configs.find(config => config.domainCName !== 'dontuse.ly' && config.type === 'view_categories') ||
    // this is the default which isn't real.
    { ...configs.find(config => config.type === 'view_categories')!, id: null }
  )!;
};

const getCategories = async (): Promise<CategoryConfig> => (
  pluckRealConfig(await fetchJsonWithDefaultHeaders('/api/configurations?merge=false&defaultOnly=true&type=view_categories'))
);

const getEnabledCategories = async (): Promise<LocalizedCategory[]> => {
  const cats = await getCategories();
  return (cats.properties || []).filter(c => c.value.enabled).map((c: Category) => {
    const localized = includes(DEFAULT_CATEGORIES, c.name) ? t(c.name) : null;
    return {
      ...c,
      localized
    };
  });
};

const createCategories = async (categories: Category[]): Promise<CategoryConfig> => {
  const config: CategoryConfig = await fetchJsonWithDefaultHeaders('/api/configurations', {
    method: 'POST',
    body: JSON.stringify({
      type: 'view_categories',
      name: 'View categories',
      default: true,
      properties: categories
    })
  });

  // the configuration API is so silly
  await Promise.all(
    categories.map(c => createCategory(config.id!, c))
  );

  return await getCategories();
};


const createCategory = async (id: number, cat: Category): Promise<Category> => {
  return fetchJsonWithDefaultHeaders(`/api/configurations/${id}/properties.json`, {
    method: 'POST',
    body: JSON.stringify(cat)
  });
};

const deleteCategory = async (id: number, cat: Category): Promise<void> => {
  return fetchWithDefaultHeaders(`/api/configurations/${id}/properties/${encodeURIComponent(cat.name)}.json`, {
    method: 'DELETE'
  });
};

const updateCategory = async (id: number, cat: Category): Promise<void> => {
  return fetchWithDefaultHeaders(`/api/configurations/${id}/properties/${encodeURIComponent(cat.name)}.json`, {
    method: 'PUT',
    body: JSON.stringify(cat)
  });
};


export {
  getCategories, getEnabledCategories, createCategories, createCategory, deleteCategory, updateCategory
};
