import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ALERT_MODAL_PAGES } from './constants';
import './index.scss';
import I18n from 'common/i18n';

const BUTTON_STATES = {
  [ALERT_MODAL_PAGES.ALERT_TYPE_PAGE]: {
    nextPage: ALERT_MODAL_PAGES.PARAMETERS_PAGE,
    previousPage: null,
    showBackButton: false,
    showCancelButton: true,
    showNextButton: true,
    showSaveButton: false
  },
  [ALERT_MODAL_PAGES.PARAMETERS_PAGE]: {
    nextPage: ALERT_MODAL_PAGES.TRIGGER_PAGE,
    previousPage: ALERT_MODAL_PAGES.ALERT_TYPE_PAGE,
    showBackButton: true,
    showCancelButton: false,
    showNextButton: true,
    showSaveButton: false
  },
  [ALERT_MODAL_PAGES.TRIGGER_PAGE]: {
    nextPage: ALERT_MODAL_PAGES.META_PAGE,
    previousPage: ALERT_MODAL_PAGES.PARAMETERS_PAGE,
    showBackButton: true,
    showCancelButton: true,
    showNextButton: true,
    showSaveButton: false
  },
  [ALERT_MODAL_PAGES.META_PAGE]: {
    nextPage: null,
    previousPage: ALERT_MODAL_PAGES.TRIGGER_PAGE,
    showBackButton: true,
    showCancelButton: true,
    showNextButton: false,
    showSaveButton: true
  }
};
const scope = 'shared.components.create_alert_modal';

class AlertFooter extends Component {
  state = {
    nextPage: ALERT_MODAL_PAGES.PARAMETERS_PAGE,
    previousPage: null,
    showBackButton: false,
    showNextButton: false,
    showSaveButton: false
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.setButtonStates(this.props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setButtonStates(nextProps);
  }

  setButtonStates = (props) => {
    const { currentAlertPage, editMode } = props;
    const nextState = BUTTON_STATES[currentAlertPage] || {};
    if (editMode && currentAlertPage === ALERT_MODAL_PAGES.PARAMETERS_PAGE) {
      nextState.showBackButton = false;
    }
    this.setState(nextState);
  };

  renderValidateInfo() {
    const { currentAlertPage } = this.props;
    if (currentAlertPage === ALERT_MODAL_PAGES.PARAMETERS_PAGE) {
      return <span className="m-r-10">{I18n.t('validate_button_info', { scope })}</span>;
    }

    return null;
  }

  renderDeleteButton() {
    const { onDeleteClick, editMode } = this.props;

    if (editMode) {
      return (
        <button className="m-r-10 m-b-10 delete-button btn btn-error" onClick={onDeleteClick}>
          {I18n.t('button.delete', { scope })}
        </button>
      );
    }

    return null;
  }

  renderSaveButton() {
    const { alertName, onSaveClick } = this.props;
    const { showSaveButton } = this.state;
    if (showSaveButton) {
      return (
        <button
          className="create-button btn btn-primary"
          disabled={_.isEmpty(alertName)}
          onClick={onSaveClick}
        >
          {I18n.t('button.create', { scope })}
        </button>
      );
    }

    return null;
  }

  renderCancelButton() {
    const { onPageCancel } = this.props;
    const { showCancelButton } = this.state;

    if (showCancelButton) {
      return (
        <button className="m-r-10 btn btn-default" onClick={() => onPageCancel()}>
          {I18n.t('button.cancel', { scope })}
        </button>
      );
    }

    return null;
  }

  renderBackButton() {
    const { onPageChange } = this.props;
    const { previousPage, showBackButton } = this.state;

    if (showBackButton) {
      return (
        <button
          className="m-r-10 pull-left back-button btn btn-default"
          onClick={() => onPageChange(previousPage)}
        >
          {I18n.t('button.back', { scope })}
        </button>
      );
    }

    return null;
  }

  renderNextButton() {
    const { enableSaveButton, onPageChange } = this.props;
    const { nextPage, showNextButton } = this.state;
    const disableNextButton = !enableSaveButton && nextPage === ALERT_MODAL_PAGES.TRIGGER_PAGE;

    if (showNextButton) {
      return (
        <button
          className="next-button btn btn-primary"
          disabled={disableNextButton}
          onClick={() => onPageChange(nextPage)}
        >
          {I18n.t('button.next', { scope })}
        </button>
      );
    }

    return null;
  }

  render() {
    return (
      <div className="alert-footer">
        {this.renderBackButton()}
        {this.renderValidateInfo()}
        {this.renderDeleteButton()}
        {this.renderCancelButton()}
        {this.renderNextButton()}
        {this.renderSaveButton()}
      </div>
    );
  }
}

AlertFooter.propTypes = {
  alertName: PropTypes.string,
  currentAlertPage: PropTypes.string,
  editMode: PropTypes.bool,
  enableSaveButton: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  onPageCancel: PropTypes.func,
  onPageChange: PropTypes.func,
  onSaveClick: PropTypes.func
};

export default AlertFooter;
