import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defer } from 'lodash';

import I18n from 'common/i18n';
import SocrataIcon from 'common/components/SocrataIcon';
import Button from 'common/components/Button';

import { actionListItemClicked, hideDocumentation, showDocumentation } from '../redux/QueryEditorActions';

class ListItem extends Component {
  static propTypes = {
    editorInstance: PropTypes.object,
    description: PropTypes.string,
    hasDocs: PropTypes.bool,
    iconName: PropTypes.string,
    name: PropTypes.string.isRequired,
    onListItemClick: PropTypes.func.isRequired,
    onShowInfoClick: PropTypes.func.isRequired,
    type: PropTypes.string
  }

  static defaultProps = {
    hasDocs: false
  }

  render() {
    const {
      editorInstance,
      description,
      hasDocs,
      iconName,
      name,
      onListItemClick,
      onShowInfoClick,
      type
    } = this.props;

    const addKeywordAndMoveCursor = () => {
      const session = editorInstance.getSession();
      const cursorPosition = session.selection.getCursor();

      onListItemClick(name);

      defer(() => {
        session.selection.moveCursorTo(cursorPosition.row, cursorPosition.column + name.length);
        editorInstance.focus();
      });
    };

    return (
      <div className="query-editor-action-list-item" id={name} >
        <div className="query-editor-action-list-item-details">
          {iconName && <SocrataIcon name={iconName} className="query-editor-action-list-item-icon" />}
          <strong className="query-editor-action-list-item-name">
            {name}
          </strong>
          <em className="query-editor-action-list-item-description">
            {description}
          </em>
        </div>
        <div className="query-editor-action-list-item-buttons">
          {/* If hasDocs is true, we show an info button that can be clicked to view docs for the item */}
          {hasDocs &&
            <Button
              className="query-editor-action-list-item-info-button"
              dark
              variant="primary"
              size="sm"
              onClick={() => onShowInfoClick(type, name)}>
              {I18n.t('shared.query_editor.info')}
            </Button>
          }
          <Button
            className="query-edit-action-list-item-add-button"
            dark
            variant="primary"
            size="sm"
            onClick={addKeywordAndMoveCursor}>
            {I18n.t('shared.query_editor.add')}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  editorInstance: state.editor.editorInstance
});

const mapDispatchToProps = dispatch => ({
  onListItemClick: action => dispatch(actionListItemClicked(action)),
  onShowInfoClick: (type, name) => {
    // Hiding first forces the Flannel (if already open) to reposition
    // correctly next to the new target. Otherwise, clicking 'info'
    // for different items in the action list without scrolling in between
    // each click renders the flannel in the same position as the first click
    dispatch(hideDocumentation());
    defer(() => { dispatch(showDocumentation(type, name)); });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);
