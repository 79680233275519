import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import Button from 'common/components/Button';
import SocrataIcon from 'common/components/SocrataIcon';

import { fetchRows } from '../redux/QueryEditorActions';
import ResultsTable from './ResultsTable';

/**
 * Results list that goes under the editor
 * Will show a message prompting the user to click the run button if they haven't
 * Shows a spinner while rows are being fetched, or an error if there is one
 * Shows a table if we have rows and columns
 */
class Results extends Component {
  static propTypes = {
    onRunButtonClick: PropTypes.func.isRequired
  }

  renderSpinnerOrErrorOrTable = () => {
    const {
      fetchingRows,
      rowFetchError,
      rows,
      columns,
      collocating
    } = this.props;

    if (fetchingRows) {
      // waiting for rows to come back, show a spinner...
      return (
        <div className="query-editor-fetching-rows-spinner spinner spinner-default" />
      );
    } else if (rowFetchError) {
      // we got an error! Show it...

      return (
        <div className="query-editor-row-fetch-error alert error">
          {fetchRowErrorMsg(rowFetchError)}
        </div>
      );
    } else if (collocating) {
      return (
        <div className="query-editor-collocating-message">
          <p className="alert info">{I18n.t('shared.query_editor.collocation_warning')}</p>
          <div className="spinner spinner-default query-editor-collocating-spinner" />
        </div>
      );
    } else if (rows && columns) {
      // if we have rows and columns, we have a table...
      return (
        <ResultsTable rows={rows} columns={columns} />
      );
    } else {
      // user hasn't done anything yet...
      return (
        <div className="query-editor-no-results-message alert info">
          {I18n.t('shared.query_editor.click_run')}
        </div>
      );
    }
  }

  render() {
    const { onRunButtonClick } = this.props;

    return (
      <div className="query-editor-results">
        {/* This button is absolutely position to be inside the editor */}
        <Button
          dark
          variant="primary"
          className="query-editor-run-button"
          onClick={onRunButtonClick}>
          {I18n.t('shared.query_editor.run')}
          <SocrataIcon name="play" className="query-editor-run-button-icon" />
        </Button>

        {this.renderSpinnerOrErrorOrTable()}
      </div>
    );
  }
}

const fetchRowErrorMsg = rowFetchError => {
  switch (rowFetchError.code) {
    case 'COLLOCATION.NOT_PERMITTED':
      return I18n.t('shared.query_editor.collocation_not_permitted');
    default:
      return rowFetchError.message ? rowFetchError.message : I18n.t('shared.query_editor.unknown_error');
  }
};

const mapStateToProps = state => ({
  isValid: state.editor.isValid,
  view: state.editor.view,
  rows: state.editor.rows,
  columns: state.editor.columns,
  fetchingRows: state.editor.fetchingRows,
  rowFetchError: state.editor.rowFetchError,
  collocating: state.editor.collocating
});

const mapDispatchToProps = dispatch => ({
  onRunButtonClick: () => dispatch(fetchRows())
});

export default connect(mapStateToProps, mapDispatchToProps)(Results);
