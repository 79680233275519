// Vendor Imports
import _ from 'lodash';
import React from 'react';

// Project Imports
import CalendarDateFilter from './CalendarDateFilter';
import CheckboxFilter from './CheckboxFilter';
import ComputedColumnFilter from './ComputedColumnFilter';
import NumberFilter from './NumberFilter';
import RadiusFilter from './RadiusFilter';
import TextFilter from './TextFilter';
import { isRegionComputedColumn } from 'common/column/utils';

// Constants
import { POINT_COLUMN_TYPES } from 'common/authoring_workflow/constants';
import { FilterEditorProps } from '../types';

const FilterEditor = (props: FilterEditorProps) => {
  const { column } = props;
  if (!column) {
    return null;
  }

  // This needs to be capitalized - JSX checks the case of the first letter.
  let SpecificFilter;

  // This used to be a switch statement, but the babel
  // transpiler crashed in Storyteller when trying to
  // compile. Transforming to an if/else resolved the
  // crash.
  if (isRegionComputedColumn(column)) {
    SpecificFilter = ComputedColumnFilter;
  } else if (_.includes(['calendar_date', 'date'], column.renderTypeName)) {
    SpecificFilter = CalendarDateFilter;
  } else if (column.renderTypeName === 'number') {
    SpecificFilter = NumberFilter;
  } else if (column.renderTypeName === 'text') {
    SpecificFilter = TextFilter;
  } else if (column.renderTypeName === 'checkbox') {
    SpecificFilter = CheckboxFilter;
  } else if (_.includes(POINT_COLUMN_TYPES, column.renderTypeName)) {
    SpecificFilter = RadiusFilter;
  } else {
    return null;
  }

  return <SpecificFilter {...props} />;
};

export default FilterEditor;
