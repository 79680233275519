import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ForgeBadge } from '@tylertech/forge-react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { purifyToFormatting } from 'common/purify';

import { FeatureFlags } from 'common/feature_flags';
import I18n from 'common/i18n';
import { getCurrentDomain } from 'common/currentDomain';
import AssetTypeIcon from 'common/components/AssetTypeIcon';
import SocrataIcon from 'common/components/SocrataIcon';
import withTooltip from 'common/components/hocs/withTooltip';
import * as constants from 'common/components/AssetBrowser/lib/constants';
import { pollForCompletedApproval } from 'common/core/approvals';

import * as assetActions from '../actions/asset_actions';
import Provenance from './provenance';
import AudienceCell from './audience_cell';
import UserCell from './user_cell';

import { APPROVALS } from 'common/core/approvals_enums';
import optionallyLocalizeUrls
  from 'common/site_chrome/app/assets/javascripts/socrata_site_chrome/utils/optionally_localize_urls';


const { APPROVED, FAILED, PENDING, REJECTED } = APPROVALS.STATUS;
const { SUCCESS } = APPROVALS.SUBMISSION_OUTCOME_STATUS;

export class ResultListRow extends Component {
  renderCell = (columnName, index) => {
    const {
      actionElement,
      analysisId,
      approvalStatus,
      approvals,
      description,
      isParent,
      isPublished,
      uid,
      link,
      locked,
      name,
      openInNewTab,
      owner,
      creator,
      provenance,
      publishedCopy,
      setApprovalStatus,
      source,
      type,
      updatedAt
    } = this.props;
    if (_.has(columnName, 'render') && _.isFunction(columnName.render)) {
      return columnName.render(this.props);
    }

    const scope = 'shared.asset_browser.result_list_table';

    const cellClassNames = analysisId && columnName === constants.COLUMN_LAST_UPDATED_DATE
      ? `${columnName} program-analytics-tooltip-parent`
      : columnName;
    const cellTag = (value, icon, addOn = null) => <td className={cellClassNames} key={`${columnName}-${index}`}>{value}{addOn}</td>;

    const statusToClassName = {
      [SUCCESS]: 'socrata-icon-checkmark-alt',
      [APPROVED]: 'spinner-default spinner-small',
      [PENDING]: '',
      [REJECTED]: 'socrata-icon-close-circle',
      [FAILED]: 'TODO' // TODO: EN-26500
    };

    const mapApprovalStatusToCell = (status) => {
      const approvalIcon = <div className={`approval-icon ${statusToClassName[status]}`} />;
      const approvalText = (
        <div className="approval-status">
          {I18n.t(`approval_status_values.${status}`, { scope })}
        </div>
      );

      return cellTag(<div className="approval-cell">{approvalIcon} {approvalText}</div>);
    };

    const currentDomain = getCurrentDomain();

    const rel = openInNewTab ? 'external' : null;
    const target = openInNewTab ? '_blank' : null;

    if (columnName === constants.COLUMN_SOURCE) {
      if (source === currentDomain) {
        return cellTag(
          <span className="federation-source-cell">
            {I18n.t('shared.asset_browser.filters.source.this_site', { domain: currentDomain })}
          </span>
        );
      } else {
        return cellTag(
          <span className="federation-source-cell">
            <SocrataIcon name="federation" />
            <a href={`https://${source}/`} rel={rel} target={target}>
              {source}
            </a>
          </span>
        );
      }
    }

    // This used to be a switch-case, but that form was causing
    // a babel transpiler crash (yeah).
    if (columnName === constants.COLUMN_LAST_UPDATED_DATE) {
      const ProgramAnalyticsTooltip = withTooltip(
        <span className={'icon-info'} />,
        <span>{I18n.t('program_analytics_datasets.tooltip', { scope })}</span>
      );

      return cellTag(
        <div className={'last-updated-contents'}>
          <div>{moment(updatedAt).format('LL')}</div>
          {analysisId && <ProgramAnalyticsTooltip /> }
        </div>
      );
    }

    if (columnName === constants.COLUMN_ACTIONS) {
      return cellTag(React.createElement(actionElement, this.props));
    }

    const recordInQueue = approvals.filter(record => {
      return record.state === 'pending' ||
          (record.state === 'approved' && record.outcome_application_status === 'in_progress');
    });

    // If this serves the approvals queue, we want records matching those that would be
    // in the queue. If not, then the records don't really matter.
    // And note: there cannot be more than one approvals record for an asset in the queue
    const approval = recordInQueue[0] || {};

    if (columnName === constants.COLUMN_APPROVAL_SUBMITTED_AT) {
      const approvalRequestedDateString = moment(approval.submitted_at).format('LL');
      return cellTag(approvalRequestedDateString);
    }

    if (columnName == constants.COLUMN_INTENDED_AUDIENCE) {
      return cellTag(I18n.t(`audience_values.${approval.target_audience}`, { scope }));
    }

    if (columnName == constants.COLUMN_REQUESTER) {
      // mock the user type: fetching from core is unneccary because we have
      // the information we need already
      const requester = {
        user_type: 'interactive', // a team could not take this action
        display_name: approval.submitter_name
      };
      return cellTag(<UserCell user={requester} />);
    }

    if (columnName === constants.COLUMN_APPROVAL_STATUS) {
      const submissionId = _.get(approval, '.submission_id');
      if (submissionId && approvalStatus === APPROVED) {
        pollForCompletedApproval(uid, submissionId).then(status => {
          if (status !== SUCCESS) {
            console.error('error approving asset; outcome status is: ', status);
          }
          setApprovalStatus(uid, status);
        }).catch(err => {
          console.error(err);
        });
      }

      return mapApprovalStatusToCell(approvalStatus);
    }

    if (columnName === constants.COLUMN_NAME) {
      const draftLabel = owner.id === creator.id
        ? I18n.t('draft_header', { scope })
        : I18n.t('draft_header_with_creator', { scope, name: creator.display_name });
      const draftHeader = isPublished ? null : (
        <div className="draft-header">
          <SocrataIcon name="draft-badge" />
          <span dangerouslySetInnerHTML={{ __html: draftLabel }} />
        </div>
      );

      const publishedCopyLink = _.get(publishedCopy, 'permalink');
      const draftFooter = publishedCopyLink ? (
        <div className="draft-footer">
          <span>&#x2514; {I18n.t('draft_footer.view', { scope })}
            <a href={publishedCopyLink} rel={rel} target={target}>
              {I18n.t('draft_footer.published_version', { scope })}
            </a>
          </span>
        </div>
      ) : null;

      const newBanner = analysisId &&
        <span className="new-banner">
          {I18n.t('program_analytics_datasets.flair', { scope }).toUpperCase()}
        </span>;

      const lockedBadge = FeatureFlags.valueOrDefault('enable_asset_locking', false) && locked ? (
        <ForgeBadge theme="danger">
          {I18n.t('shared.components.asset_action_bar.publication_state.locked')}
        </ForgeBadge>
      ) : null;

      return (cellTag(
        <div>
          {draftHeader}
          <div>
            <a href={optionallyLocalizeUrls(link)} rel={rel} target={target}>
              <span className="name">
                {name}
              </span>
              {openInNewTab && <SocrataIcon name="preview" />}
            </a>
            {newBanner}
            {lockedBadge}
            <Provenance provenance={provenance} type={type} includeLabel={false} />
            <span className="description" dangerouslySetInnerHTML={{__html:purifyToFormatting(description)}}></span>
          </div>
          {draftFooter}
        </div>
      ));
    }

    if (columnName === constants.COLUMN_OWNER) {
      return cellTag(<UserCell user={owner} />);
    }

    if (columnName === constants.COLUMN_TYPE) {
      let assetTypeTooltip = I18n.t(`shared.asset_browser.asset_types.${type}`);
      if (type === 'href' && FeatureFlags.value('usaid_features_enabled')) {
        assetTypeTooltip = I18n.t('shared.asset_browser.asset_types.data_asset');
      }

      // If USAID and asset is HREF parent a.k.a. collectionsParent, give it special 'data_asset' type (and icon)
      const displayType = (FeatureFlags.value('usaid_features_enabled') && type === 'href' && isParent === true) ?
        'data_asset' : type;

      return cellTag(<AssetTypeIcon displayType={displayType} tooltip={assetTypeTooltip} />);
    }

    if (columnName === constants.COLUMN_AUDIENCE) {
      const audienceCellProps = _.pick(this.props,
        [
          'approvals',
          'grants',
          'isExplicitlyHidden',
          'isPublic',
          'isPublished',
          'isOwner',
          'visibleToAnonymous',
          'visibleToSite'
        ]
      );

      return cellTag(<AudienceCell {...audienceCellProps} />);
    }

    return cellTag(this.props[columnName]);
  }

  render() {
    const { columns } = this.props;

    return (
      <tr className="result-list-row">
        {columns.map((columnName, index) => this.renderCell(columnName, index))}
      </tr>
    );
  }
}

ResultListRow.defaultProps = {
  approvals: []
};

ResultListRow.propTypes = {
  actionElement: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  activeTab: PropTypes.string.isRequired,
  analysisId: PropTypes.string,
  approvalStatus: PropTypes.string,
  approvals: PropTypes.array,
  category: PropTypes.string,
  columns: PropTypes.array.isRequired,
  description: PropTypes.string,
  getAnalysisId: PropTypes.func,
  grants: PropTypes.array,
  isExplicitlyHidden: PropTypes.bool,
  isOwner: PropTypes.bool.isRequired,
  isPublic: PropTypes.bool.isRequired,
  isPublished: PropTypes.bool.isRequired,
  uid: PropTypes.string.isRequired,
  link: PropTypes.string,
  locked: PropTypes.bool,
  name: PropTypes.string,
  owner: PropTypes.object,
  creator: PropTypes.object,
  provenance: PropTypes.string,
  setApprovalStatus: PropTypes.func,
  updateAudience: PropTypes.func,
  source: PropTypes.string,
  transferOwnership: PropTypes.func,
  type: PropTypes.string,
  updatedAt: PropTypes.string,
  visibleToAnonymous: PropTypes.bool.isRequired
};

// Note: undefined can be returned here, implying that we are on a domain without new approvals.
const approvalStatusFor = (state, ownProps) => {
  const approval = _.find(_.get(ownProps, 'approvals', []), function (record) {
    return record.state === 'pending' ||
        (record.state === 'approved' && record.outcome_application_status === 'in_progress');
  });
  return _.get(state, `assetActions.approvalStatus.${ownProps.uid}`, approval?.state);
};

const mapStateToProps = (state, ownProps) => ({
  activeTab: state.header.activeTab,
  approvalStatus: approvalStatusFor(state, ownProps),
  columns: ownProps.columns || state.assetBrowserProps.columns
});

const mapDispatchToProps = (dispatch) => ({
  setApprovalStatus: (uid, status) => dispatch(assetActions.setApprovalStatus(uid, status))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultListRow);
