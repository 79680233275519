// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Project Imports
import I18n from 'common/i18n';
import Button from 'common/components/Button';
import { resetFiltersToDefaults } from 'common/components/SingleSourceFilterBar/filters';

// Constants
const scope = 'shared.components.filter_bar';

export class ResetFiltersButton extends Component {
  onClick = () => {
    const { datasetUid, filters, isReadOnly, onReset } = this.props;
    const resetFilters = resetFiltersToDefaults(filters, datasetUid, isReadOnly);

    onReset(resetFilters);
  };

  isDisabled = () => {
    const { filters, isReadOnly } = this.props;

    return !_.some(filters, (filter) => {
      const isViewable = !isReadOnly || !filter.isHidden;
      const hasValue = !_.isEmpty(filter.arguments);
      const canValueBeChanged = !filter.isOverridden;

      return isViewable && hasValue && canValueBeChanged;
    });
  };

  render() {
    return (
      <div className="reset-filters">
        <Button
          variant="link"
          size="sm"
          inverse
          className="btn-reset-filters"
          disabled={this.isDisabled()}
          onClick={this.onClick}
        >
          {I18n.t('clear_all_filters', { scope })}
        </Button>
      </div>
    );
  }
}

ResetFiltersButton.propTypes = {
  isReadOnly: PropTypes.bool,
  datasetUid: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.object),
  onReset: PropTypes.func.isRequired
};

export default ResetFiltersButton;
