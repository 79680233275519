// Vendor Imports
import _ from 'lodash';
import React, { Component } from 'react';

// Project Imports
import FilterFooter from '../FilterFooter';
import FilterHeader from '../FilterHeader';

import { getContainsTextFilter, getDefaultFilterForColumn, getEqualityTextFilter } from '../filters';
import Autocomplete from 'common/components/Autocomplete';
import Dropdown from 'common/components/Dropdown';
import Picklist, { PicklistOption, PicklistSizes } from 'common/components/Picklist';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import I18n from 'common/i18n';
import { FILTER_SORTING } from 'common/authoring_workflow/constants';
import { BinaryOperator, FilterValue, SoqlFilter, OPERATOR } from '../SoqlFilter';
import { FilterEditorProps } from '../types';
import { addPopupListener } from './InputFocus';

// Constants
const scope = 'shared.components.filter_bar.text_filter';
export const DEFAULT_FETCH_RESULTS_DEBOUNCE_WAIT_TIME = 400;
// After fetching results matching search term, we only show suggestions that are not already selected.
// So we fetch more items, filter already selected ones and show unselected suggestions
export const FETCH_RESULTS_COUNT = 40;
export const SHOW_RESULTS_COUNT = 10;
export const DEFAULT_ALL_FILTERS = [];
export const FILTER_OFFSET = { DEFAULT: 0, MAX: 20 };
const INFINITE_SCROLL_DATA_LOAD_OFFSET = 25;

interface TextFilterState {
  filter?: SoqlFilter;
  containsValue: FilterValue;
  selectedValues: FilterValue[];
  isLoadingData: boolean;
  topXOptions: PicklistOption[];
  picklistOffset: number;
  isNegated: boolean;
  operator: OPERATOR;
}

class TextFilter extends Component<FilterEditorProps, TextFilterState> {
  autocompleteContainer: HTMLDivElement;
  picklistContainerRef: HTMLDivElement;
  textFilter: HTMLDivElement;
  removePopupListener = () => {};
  popupListenerRemoved = false;

  constructor(props: FilterEditorProps) {
    super(props);

    const { filter } = props;

    let isNegated = _.toLower(_.get(filter, 'joinOn')) === 'and';
    let operator = _.get(filter, 'arguments[0].operator', '');
    let selectedValues: FilterValue[];
    let containsValue;

    if (this.isEqualityFilter(operator)) {
      selectedValues = _.map(filter.arguments, (argument) => {
        if (_.includes(['IS NULL', 'IS NOT NULL'], _.get(argument, 'operator'))) {
          return null;
        }
        return _.get(argument, 'operand');
      });
      containsValue = '';
    } else {
      selectedValues = [];
      containsValue = _.get(filter, 'arguments[0].operand', '');
    }

    if (_.get(filter, 'singleSelect', false)) {
      if (selectedValues.length > 1) {
        selectedValues = [];
      }

      containsValue = '';
      isNegated = false;
      operator = OPERATOR.EQUALS;
    }
    const picklistOffset = FILTER_OFFSET.DEFAULT;

    this.state = {
      containsValue,
      isLoadingData: true,
      isNegated,
      operator,
      picklistOffset,
      selectedValues,
      topXOptions: []
    };

    _.bindAll(this, [
      'applyFilter',
      'getSuggestions',
      'isDirty',
      'onSelectOption',
      'onUnselectOption',
      'renderHeader',
      'renderLoadingSpinner',
      'renderSelectedOption',
      'renderTopXOption',
      'renderSuggestionsAutocomplete',
      'resetFilter',
      'updateSelectedValues'
    ]);
  }

  getDataProvider(props: FilterEditorProps) {
    return props.dataProvider[0];
  }

  componentDidMount() {
    this.getTopXOptionsRespectingFilters();
    const { popupRef } = this.props;
    this.removePopupListener = addPopupListener(popupRef, this.textFilter, () => {
      this.popupListenerRemoved = true;
    });
  }

  componentWillUnmount() {
    const { popupRef } = this.props;
    if (!this.popupListenerRemoved) {
      popupRef?.current?.removeEventListener('forge-popup-position', this.removePopupListener);
    }
  }

  handleScrollForPicklist = _.debounce(() => {
    const picklistContainerScrollTop = this.picklistContainerRef?.scrollTop;
    const picklistContainerHeight = this.picklistContainerRef?.clientHeight;
    const picklistContainerScrollHeight = this.picklistContainerRef?.scrollHeight;
    const picklistContainerBottomPosition = picklistContainerScrollTop + picklistContainerHeight;

    if (picklistContainerBottomPosition + INFINITE_SCROLL_DATA_LOAD_OFFSET > picklistContainerScrollHeight) {
      this.getTopXOptionsRespectingFilters();
    }
  }, DEFAULT_FETCH_RESULTS_DEBOUNCE_WAIT_TIME);

  getTopValuesLabel = () => {
    const { filter } = this.props;
    const defaultFilterOrderBy = _.get(FILTER_SORTING[0], 'orderBy');
    const orderBy = _.get(filter, 'orderBy', defaultFilterOrderBy);

    return _.chain(FILTER_SORTING).find({ orderBy }).get('description').value();
  };

  // Top X options are set up once and placed into state. This
  // get the Top X items and
  // inserts the (No Value) item at the top of the list.
  getTopXOptionsRespectingFilters = () => {
    const { allFilters, column, filter } = this.props;
    const { topXOptions, picklistOffset } = this.state;
    const dataProvider = this.getDataProvider(this.props);

    this.setState({ isLoadingData: true });
    const topValuesOptions = {
      allFilters,
      column,
      filter,
      offset: picklistOffset,
      limit: SHOW_RESULTS_COUNT
    };

    dataProvider.soqlDataProvider
      .getTopXOptionsInColumn(topValuesOptions, dataProvider.datasetUid)
      .then((topColumnOptions) => {
        if (_.isEmpty(topColumnOptions)) {
          this.setState({ isLoadingData: false });
        }

        const topXOptionsValues = _.chain(topColumnOptions)
          .map((option) => {
            const columnValue = _.get(option, column.fieldName);
            return _.isNil(columnValue)
              ? null
              : {
                  group: this.getTopValuesLabel(),
                  render: this.renderTopXOption,
                  title: columnValue,
                  value: columnValue
                };
          })
          .compact()
          .value();

        if (picklistOffset === 0) {
          topXOptionsValues.unshift(this.getNullOption());
        }

        topXOptions.push(...topXOptionsValues);

        const newPicklistOffset = picklistOffset + SHOW_RESULTS_COUNT;
        this.setState({ isLoadingData: false, topXOptions, picklistOffset: newPicklistOffset });
      })
      .catch((error) => {
        this.setState({ isLoadingData: false });
        console.error(
          `Soql like top values failed for ${dataProvider.datasetUid} field ${column.fieldName}:`
        );
        console.error(error);
      });
  };

  getNullOption = () => {
    // Create the "null" suggestion to allow filtering on empty values.
    return {
      title: I18n.t('no_value', { scope }),
      value: null,
      group: this.getTopValuesLabel(),
      render: this.renderTopXOption
    };
  };

  getSuggestions(
    searchTerm: string,
    callback: (results: { results: any[] }) => void,
    offset: number,
    isLazyLoading: boolean
  ) {
    const { column, allFilters } = this.props;
    const { selectedValues } = this.state;
    const dataProvider = this.getDataProvider(this.props);

    if (_.isEmpty(searchTerm)) {
      return callback({ results: [] });
    }

    const searchOptions = {
      columnName: column.fieldName,
      filters: allFilters,
      limit: SHOW_RESULTS_COUNT,
      isLazyLoading,
      searchTerm,
      offset
    };
    const suggestionsPromise = dataProvider.soqlDataProvider.searchInColumn(searchOptions).catch((error) => {
      console.error(`Soql like search failed for ${dataProvider.datasetUid} field ${column.fieldName}:`);
      console.error(error);
    });

    return suggestionsPromise.then((suggestions) => {
      if (_.isEmpty(suggestions)) {
        return { results: [] };
      }

      const results = _.chain(suggestions as any[])
        .without(...selectedValues)
        .map((suggestion) => ({ title: suggestion, matches: [] }))
        .compact()
        .take(SHOW_RESULTS_COUNT)
        .value();
      return { results };
    });
  }

  onSelectOption(option?: PicklistOption | null) {
    if (_.get(this.props, 'filter.singleSelect', false)) {
      this.updateSelectedValues([option?.value], this.applyFilter);
    } else {
      this.updateSelectedValues(_.union(this.state.selectedValues, [option?.value]));
    }
  }

  onUnselectOption(option?: PicklistOption | null) {
    this.updateSelectedValues(_.without(this.state.selectedValues, option?.value));
  }

  getTextFilter() {
    const { column, filter, dataProvider } = this.props;
    const { containsValue, isNegated, operator, selectedValues } = this.state;

    return this.isEqualityFilter(operator)
      ? getEqualityTextFilter(
          column,
          filter as BinaryOperator,
          selectedValues,
          isNegated,
          dataProvider[0].datasetUid
        )
      : getContainsTextFilter(
          column,
          filter as BinaryOperator,
          operator,
          containsValue,
          dataProvider[0].datasetUid
        );
  }

  isEqualityFilter(operator: OPERATOR) {
    return (
      operator !== OPERATOR.CONTAINS &&
      operator !== OPERATOR.DOES_NOT_CONTAIN &&
      operator !== OPERATOR.STARTS_WITH
    );
  }

  updateSelectedValues(nextSelectedValues: FilterValue[], callback?: () => void) {
    this.setState(
      {
        selectedValues: _.uniq(nextSelectedValues)
      },
      callback
    );
  }

  resetFilter() {
    const { column, onUpdate } = this.props;
    const { containsValue } = this.state;

    this.updateSelectedValues([]);

    if (containsValue !== '') {
      this.setState({ containsValue: '' });
    }

    const filter: SoqlFilter = _.cloneDeep(
      getDefaultFilterForColumn(column, this.props.dataProvider[0].datasetUid)
    );
    const orderBy = _.get(this.props, 'filter.orderBy');
    filter.isHidden = _.get(this.props, 'filter.isHidden', false);
    filter.isDrilldown = _.get(this.props, 'filter.isDrilldown', false);
    filter.singleSelect = _.get(this.props, 'filter.singleSelect');

    if (!_.isNil(orderBy)) {
      filter.orderBy = orderBy;
    }
    this.setState({ filter });
    onUpdate(filter);
  }

  applyFilter() {
    this.props.onUpdate(this.getTextFilter());
  }

  isDirty() {
    return !_.isEqual(this.getTextFilter(), this.props.filter);
  }

  renderHeader() {
    const { column } = this.props;
    const headerProps = {
      name: column.name!
    };

    let placeholder;

    switch (this.state.operator) {
      case OPERATOR.NOT_EQUAL:
        placeholder = I18n.t('is_not', { scope });
        break;
      case OPERATOR.STARTS_WITH:
        placeholder = I18n.t('starts_with', { scope });
        break;
      case OPERATOR.CONTAINS:
        placeholder = I18n.t('contains', { scope });
        break;
      case OPERATOR.DOES_NOT_CONTAIN:
        placeholder = I18n.t('does_not_contain', { scope });
        break;
      default: // default to equals
        placeholder = I18n.t('is', { scope });
        break;
    }

    const dropdownProps = {
      onSelection: (option?: PicklistOption | null) => {
        this.setState({
          isNegated: option?.value === OPERATOR.NOT_EQUAL,
          operator: option?.value
        });
      },
      options: [
        { title: I18n.t('is', { scope }), value: OPERATOR.EQUALS },
        { title: I18n.t('is_not', { scope }), value: OPERATOR.NOT_EQUAL },
        { title: I18n.t('starts_with', { scope }), value: OPERATOR.STARTS_WITH },
        { title: I18n.t('contains', { scope }), value: OPERATOR.CONTAINS },
        { title: I18n.t('does_not_contain', { scope }), value: OPERATOR.DOES_NOT_CONTAIN }
      ],
      placeholder,
      size: PicklistSizes.SMALL
    };

    const dropdown = !_.get(this.props, 'filter.singleSelect', false) ? (
      <Dropdown {...dropdownProps} />
    ) : null;

    return <FilterHeader {...headerProps}>{dropdown}</FilterHeader>;
  }

  renderSelectedOption(option: PicklistOption) {
    const title = _.isNull(option.value) ? <em>{option.title}</em> : option.title;

    return (
      <div className="picklist-selected-option">
        <SocrataIcon name={IconName.Filter} />
        <span className="picklist-selected-option-title">{title}</span>
        <SocrataIcon name={IconName.Close2} />
      </div>
    );
  }

  renderTopXOption(option: PicklistOption) {
    const title = _.isNull(option.value) ? <em>{option.title}</em> : option.title;

    return <div className="picklist-suggestion-option">{title}</div>;
  }

  renderSuggestionsAutocomplete() {
    const autocompleteProps = {
      focusFirstResult: true,
      query: '',
      getSearchResults: this.getSuggestions,
      millisecondsBeforeSearch: DEFAULT_FETCH_RESULTS_DEBOUNCE_WAIT_TIME,
      onChooseResult: (suggestion: FilterValue) => this.onSelectOption({ value: suggestion }),
      placeholder: I18n.t('search_placeholder', { scope }),
      onSelectSetSelectionAsQuery: false,
      showLoadingSpinner: true,
      isLazyLoading: true
    };
    return (
      <div
        className="suggestions-autocomplete-container"
        ref={(el: HTMLDivElement) => (this.autocompleteContainer = el)}
      >
        <Autocomplete {...autocompleteProps} />
      </div>
    );
  }

  renderLoadingSpinner() {
    return (
      <div className="loading-spinner-container">
        <span className="spinner-default" />
      </div>
    );
  }

  renderPicklist() {
    const { filter } = this.props;
    const { selectedValues, isLoadingData, topXOptions } = this.state;

    const options = _.filter(topXOptions, (option) => !_.includes(selectedValues, option.value));
    const topXPicklistProps = {
      options,
      onSelection: this.onSelectOption,
      size: PicklistSizes.SMALL,
      value: false // To prevent highlighting of any item no-value option
    };

    const selectedOptions = _.map(selectedValues, (selectedValue) => ({
      group: I18n.t('selected_values', { scope }),
      title: _.isNull(selectedValue) ? I18n.t('no_value', { scope }) : selectedValue,
      value: selectedValue,
      render: this.renderSelectedOption
    }));

    const selectionPicklistProps = {
      options: selectedOptions,
      onSelection: this.onUnselectOption,
      size: PicklistSizes.SMALL,
      value: false, // To prevent highlighting of any item no-value option
      disabled: selectedOptions.length === 0
    };

    const selectedValuesSection = !filter.singleSelect ? (
      <div className="picklist-selected-options">
        <Picklist {...selectionPicklistProps} />
      </div>
    ) : null;
    const picklistContainerAttributes = {
      className: 'picklist-options-container',
      onScroll: this.handleScrollForPicklist,
      ref: (ref: HTMLDivElement) => (this.picklistContainerRef = ref)
    };

    return (
      <div {...picklistContainerAttributes}>
        {selectedValuesSection}
        <div className="picklist-suggested-options">
          <Picklist {...topXPicklistProps} />
          {isLoadingData && this.renderLoadingSpinner()}
        </div>
      </div>
    );
  }

  renderTextBox() {
    const inputProps = {
      className: 'text-input',
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
          containsValue: event.target?.value
        });
      },
      value: this.state.containsValue as string
    };

    return (
      <div className="value-filter-container input-group">
        <input {...inputProps} />
      </div>
    );
  }

  render() {
    const { filter, isReadOnly, onRemove } = this.props;
    const { operator } = this.state;

    const footerProps = {
      disableApplyFilter: !this.isDirty(),
      isDrilldown: filter.isDrilldown,
      isReadOnly,
      onClickApply: () => this.applyFilter(),
      onClickRemove: onRemove,
      onClickReset: this.resetFilter,
      showApplyButton: !filter.singleSelect
    };

    let suggestionsAutocomplete;
    let picklist;
    let textBox;

    if (this.isEqualityFilter(operator)) {
      suggestionsAutocomplete = this.renderSuggestionsAutocomplete();
      picklist = this.renderPicklist();
      textBox = null;
    } else {
      suggestionsAutocomplete = null;
      picklist = null;
      textBox = this.renderTextBox();
    }

    return (
      <div className="filter-controls text-filter" ref={(el: HTMLDivElement) => (this.textFilter = el)}>
        <div className="column-container">
          {this.renderHeader()}
          {suggestionsAutocomplete}
          {picklist}
          {textBox}
        </div>
        <FilterFooter {...footerProps} />
      </div>
    );
  }
}

export default TextFilter;
