// Vendor Imports
import isEqual from 'lodash/isEqual';
import bindAll from 'lodash/bindAll';
import escape from 'lodash/escape';
import toNumber from 'lodash/toNumber';

import React, { Component } from 'react';

// Project Imports
import FilterFooter, { FilterFooterProps } from '../FilterFooter';
import type { ClientContextVariable } from 'common/types/clientContextVariable';
import I18n from 'common/i18n';
import { SoQLType } from 'common/types/soql';
import { getPrecision } from 'common/numbers';

interface ParameterProps {
  parameter: ClientContextVariable;
  onUpdate: (value: string) => void;
}

interface ParameterState {
  value: string;
}

class Parameter extends Component<ParameterProps, ParameterState> {
  private parameter = React.createRef<HTMLDivElement>();

  constructor(props: ParameterProps) {
    super(props);

    bindAll(this, [
      'applyParameterOverride',
    ]);

    this.state = this.getInitialState();
  }

  getInitialState() {
    const { parameter } = this.props;

    const parameterValue = parameter.overrideValue || parameter.defaultValue;

    return {
      value: parameterValue
    };
  }

  shouldDisableApply() {
    // Compare the parameter value returned by initial state to the current state
    // If they are the same disable apply button
    return isEqual(this.getInitialState(), this.state);
  }

  applyParameterOverride() {
    const { onUpdate } = this.props;
    const { value } = this.state;

    onUpdate(value);
  }

  getInputProps() {
    const { parameter } = this.props;
    const { value } = this.state;

    if (parameter.dataType === SoQLType.SoQLTextT) {
      return {
        className: 'text-input',
        'aria-label': I18n.t('shared.components.filter_bar.range_filter.value'),
        type: 'text',
        id: 'parameter-text-value',
        value: value,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {this.setState({value: event.target.value});},
      };
    } else if (parameter.dataType === SoQLType.SoQLNumberT) {
      const originalValue = parameter.overrideValue || parameter.defaultValue;
      const precision = getPrecision(toNumber(originalValue));
      return {
        className: 'number-input',
        'aria-label': I18n.t('shared.components.filter_bar.range_filter.value'),
        type: 'number',
        id: 'parameter-number-value',
        value: value,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {this.setState({value: event.target.value});},
        step: precision
      };
    }
  }

  renderInputField() {
    const inputProps = this.getInputProps();

    return (
      <div className="parameter-input-container">
        <input {...inputProps} />
      </div>
    );
  }

  render() {
    const footerProps: FilterFooterProps = {
      disableApplyFilter: this.shouldDisableApply(),
      onClickApply: this.applyParameterOverride,
      isReadOnly: true
    };

    return (
      <div className="filter-controls parameter" ref={this.parameter}>
        {this.renderInputField()}
        <FilterFooter {...footerProps} />
      </div>
    );
  }
}

export default Parameter;
