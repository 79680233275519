/**
 * Rights that can exist on a Role (see also: https://github.com/socrata/apis/tree/main/specs/roles)
 * This list should more or less match the list from core: https://github.com/socrata/core/blob/main/unobtainium/src/main/java/com/blist/models/account/roles/DomainRights.java
 *
 * Note that these are also defined in a few other places:
 * - ALL_RIGHTS in frontend/lib/user_rights.rb
 * - Exported strings in common/rights.js
 */
enum DomainRights {
  approve_nominations = 'approve_nominations',
  can_collaborate = 'can_collaborate',
  can_make_asset_internal = 'can_make_asset_internal',
  can_make_asset_private = 'can_make_asset_private',
  can_make_asset_public = 'can_make_asset_public',
  can_read_metadata = 'can_read_metadata',
  can_view_analytics = 'can_view_analytics',
  can_view_internal_applications = 'can_view_internal_applications',
  can_view_internal_data = 'can_view_internal_data',
  change_configurations = 'change_configurations',
  chown_datasets = 'chown_datasets',
  configure_approvals = 'configure_approvals',
  create_datasets = 'create_datasets',
  create_gateway_agents = 'create_gateway_agents',
  create_measures = 'create_measures',
  create_pages = 'create_pages',
  create_story = 'create_story',
  edit_nominations = 'edit_nominations',
  edit_others_datasets = 'edit_others_datasets',
  edit_others_stories = 'edit_others_stories',
  edit_pages = 'edit_pages',
  edit_restricted_metadata = 'edit_restricted_metadata',
  edit_sdp = 'edit_sdp',
  edit_site_theme = 'edit_site_theme',
  enroll_in_archival = 'enroll_in_archival',
  change_archive_visibility = 'change_archive_visibility',
  feature_items = 'feature_items',
  federations = 'federations',
  manage_gateways = 'manage_gateways',
  manage_organization = 'manage_organization',
  manage_provenance = 'manage_provenance',
  manage_rdf_namespaces = 'manage_rdf_namespaces',
  manage_role_definitions = 'manage_role_definitions',
  manage_spatial_lens = 'manage_spatial_lens',
  manage_stories = 'manage_stories',
  manage_superadmin_access = 'manage_superadmin_access',
  manage_teams = 'manage_teams',
  manage_users = 'manage_users',
  moderate_comments = 'moderate_comments',
  read_from_all_gateways = 'read_from_all_gateways',
  review_internal_approvals = 'review_internal_approvals',
  review_public_approvals = 'review_public_approvals',
  short_session = 'short_session',
  site_eligible = 'site_eligible',
  update_bulk_metadata = 'update_bulk_metadata',
  use_as_rapid_deploy_source = 'use_as_rapid_deploy_source',
  use_as_rapid_deploy_target = 'use_as_rapid_deploy_target',
  use_data_connectors = 'use_data_connectors',
  view_all_dataset_status_logs = 'view_all_dataset_status_logs',
  view_domain = 'view_domain',
  view_others_datasets = 'view_others_datasets',
  view_data_federation_source_domain = 'view_data_federation_source_domain'
}

export default DomainRights;
