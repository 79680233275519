import _ from 'lodash';
import classnames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import sift from 'common/js_utils/sift';

import { AcceptedFileTypesPropType, MessageShapePropType } from './propTypes';

import SocrataIcon from 'common/components/SocrataIcon';

const UNIDENTIFIABLE_FILE_UPLOAD = {
  entry: {},
  file: {}
};

/**
 * @namespace DOM
 * @typedef DroppedFile
 * @type {object}
 * @property {FileEntry} entry The FileEntry object instance containing metadata about the file including: isDirectory, isFile
 * @property {File} file The File object instance containing the file data itself including: name, size, type, lastModified
 */

export default class DragDropUpload extends Component {

  state = {
    draggingOver: false,
    candidateFile: null
  };

  static preventDefault = (e) => {
    e.stopPropagation();
    e.preventDefault();
  }

  static dropZoneClassNames = (isBusy, isDraggingOver, mimeTypeIsValid) => classnames(
    'dropZone', {
      dropZoneInvalidDragging: (isBusy && isDraggingOver) || !mimeTypeIsValid,
      dropZoneDragging: !isBusy && isDraggingOver
    }
  );

  handleDragOver = (e) => {
    DragDropUpload.preventDefault(e);
    this.setState({
      draggingOver: true,
      candidateFile: _.get(e, 'dataTransfer.items.0')
    });
  }

  handleDragLeave = (e) => {
    DragDropUpload.preventDefault(e);
    this.setState({
      draggingOver: false,
      candidateFile: null
    });
  }

  /**
   * @namespace DOM
   * @function handleDrop
   * @param  {DOM#event:drop} e
   * @listens DOM#event:drop
   * @returns {DroppedFile} See typedef above
   */
  handleDrop = (e) => {
    const { onDrop } = this.props;

    // It appears that React discards the synthetic event (i.e. the `e` argument) before invoking the
    // setState callback function, so we capture the event details up here first.
    const normalizedFile = this.normalizeDroppedFiles(e);

    DragDropUpload.preventDefault(e);
    this.setState({
      draggingOver: false,
      candidateFile: _.get(e, 'dataTransfer.items.0')
    }, () => {
      if (this.candidateFileIsValid()) {
        onDrop(normalizedFile);
      }
    });
  }

  /**
   * @namespace DOM
   * @function handleBrowseFileChange
   * @param  {DOM#event:mousedown} e
   * @listens DOM#event:change
   * @returns {DroppedFile} See typedef above
   */
  handleBrowseFileChange = (e) => {
    const { onDrop } = this.props;

    DragDropUpload.preventDefault(e);
    this.setState({
      draggingOver: false,
      candidateFile: null
    });

    onDrop(this.normalizeDroppedFiles(e));
  }

  /**
   * Event normalizing function to eliminate the event differences between a dropped file and a file
   * uploaded via the file upload form input element.
   *
   * @function normalizeDroppedFiles
   * @param {object} event The event containing the file information
   * @returns {DroppedFile} See typedef above
   */
  normalizeDroppedFiles = (event) => {
    const droppedItem = _.get(event, 'dataTransfer.items.0', {});
    // Normalizing the source of the file property because DnD is `dataTransfer`, but file upload is `target`
    const normalizedFile = sift(event, 'dataTransfer.files.0', 'target.files.0');
    const normalizedGetAsEntry = sift(droppedItem, 'getAsEntry', 'webkitGetAsEntry');

    if (!normalizedFile) {
      return UNIDENTIFIABLE_FILE_UPLOAD;
    }

    const entry = _.isFunction(normalizedGetAsEntry) ?
      normalizedGetAsEntry.apply(droppedItem) : {
        filesystem: {
          name: normalizedFile.name,
          root: {}
        },
        fullPath: `/${normalizedFile.name}`,
        isDirectory: false,
        isFile: true,
        name: normalizedFile.name
      };

    return {
      file: normalizedFile,
      entry
    };
  }

  candidateFileIsValid = () => {
    const { acceptedFileTypes, acceptedFileTypeExtensions } = this.props;
    const { candidateFile } = this.state;

    // The wildcard accepted file type extension of '*' means all MIME types are valid
    if (acceptedFileTypeExtensions === '*') {
      return true;
    }

    return _.map(acceptedFileTypes, 'mimeType').includes(_.get(candidateFile, 'type'));
  }

  render() {
    const { acceptedFileTypes, acceptedFileTypesDescription, acceptedFileTypeExtensions, messages } = this.props;

    const { candidateFile } = this.state;

    const accept = acceptedFileTypeExtensions ||
      _.map(acceptedFileTypes, (type) => `.${type.extension}`).join(',');

    const classNames = DragDropUpload.dropZoneClassNames(
      this.props.isBusy,
      this.state.draggingOver,
      candidateFile ? this.candidateFileIsValid() : true
    );

    return (
      <section id="dragDropUpload">
        <div
          onDrop={this.handleDrop}
          onDragOver={this.handleDragOver}
          onDragLeave={this.handleDragLeave}
          className={classNames}>
          <div className="imageContainer">
            <div className="content">
              <SocrataIcon name="file-upload" />
            </div>
          </div>
          <div className="textContainer">
            <div className="content">
              <div>
                <h2>{messages.title}</h2>
                <div className="subtitle">{messages.subtitle}</div>
                <label id="upload-label" className="btn btn-primary uploadButton" htmlFor="file">
                  {messages.buttonLabel}
                </label>
                <input
                  id="file"
                  name="file"
                  type="file"
                  accept={accept}
                  aria-labelledby="upload-label"
                  className="uploadInput"
                  onChange={this.handleBrowseFileChange} />
                <div className="fileTypes">
                  {acceptedFileTypesDescription}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

}

// See index.js for a description of the behavior of these props

DragDropUpload.propTypes = {
  isBusy: PropTypes.bool,
  onDrop: PropTypes.func.isRequired,
  acceptedFileTypeExtensions: PropTypes.string,
  acceptedFileTypes: AcceptedFileTypesPropType,
  acceptedFileTypesDescription: PropTypes.object,
  messages: MessageShapePropType
};
