import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';

import I18n from 'common/i18n';
import Checkbox from 'common/components/Checkbox';
import Dropdown from 'common/components/Dropdown';
import './index.scss';
import { FREQUENCY_OPTIONS } from 'common/components/CreateAlertModal/constants';
import { getTimeZones, getDayNames } from 'common/components/CreateAlertModal/helpers/DateHelpers';
import FieldTitle from 'common/components/CreateAlertModal/components/FieldTitle';

const scope = 'shared.components.create_alert_modal.alert_trigger_page';

class TimeBasedTriggerOptions extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      hour: '',
      minute: ''
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    // eslint-disable-line camelcase
    const { alertSentTime } = this.props;
    this.setTimeStates(alertSentTime);
  }

  setTimeStates = (sentTime) => {
    if (_.isEmpty(sentTime)) {
      return;
    }

    this.setState({
      hour: moment(sentTime, 'hh:mm').format('HH'),
      minute: moment(sentTime, 'hh:mm').format('mm')
    });
  };

  updateTimeChange = (hour, minute) => {
    const sentTime = hour + ':' + minute;
    this.props.onAlertSentTimeChange(sentTime);
  };

  handleHourChange = (e) => {
    if (e.target.validity.valid) {
      this.setState({ hour: e.target.value });
      this.updateTimeChange(e.target.value, this.state.minute);
    }
  };

  handleMinutesChange = (e) => {
    if (e.target.validity.valid) {
      this.setState({ minute: e.target.value });
      this.updateTimeChange(this.state.hour, e.target.value);
    }
  };

  handleDaysChange = (day) => {
    const { alertSentDays, onAlertSentDaysChange } = this.props;
    let sentDays;
    if (_.includes(alertSentDays, day)) {
      sentDays = _.without(alertSentDays, day);
    } else {
      sentDays = _.concat(alertSentDays, day);
    }
    onAlertSentDaysChange(sentDays);
  };

  handleDayOfMonthChange = (e) => {
    if (e.target.validity.valid) {
      this.props.onAlertSentDaysChange([e.target.value]);
    }
  };

  renderWeekDays() {
    const { alertSentDays, periodicFrequency } = this.props;
    if (periodicFrequency === FREQUENCY_OPTIONS.DAILY) {
      const dayOptions = getDayNames();
      const dayContents = dayOptions.map((day, index) => {
        const checkboxAttributes = {
          checked: _.includes(alertSentDays, day.value),
          disabled: false,
          id: 'day-' + index,
          onChange: () => {
            this.handleDaysChange(day.value);
          }
        };

        return (
          <Checkbox {...checkboxAttributes} className="day-checkbox" key={index}>
            <span>{day.title}</span>
          </Checkbox>
        );
      });
      return (
        <div className="week-days-options">
          <div className="days-options">{dayContents}</div>
        </div>
      );
    }
  }

  renderDayInMonthField() {
    const { alertSentDays, periodicFrequency } = this.props;

    if (periodicFrequency === FREQUENCY_OPTIONS.MONTHLY) {
      const dayOnMonth = alertSentDays[0];
      let errorContent;

      if (Number(dayOnMonth) > 31) {
        errorContent = (
          <div className="error-info day-error-info">
            {I18n.t('day_error_info', { scope })}
          </div>
        );
      }

      return (
        <div className="day-on-month day-on-month-field">
          <FieldTitle
            flyoutId="time-based-day-flyout-info"
            flyoutText={I18n.t('day_flyout_text', { scope })}
            title={I18n.t('day', { scope })}
          />
          <span>{I18n.t('on_the', { scope })}</span>
          <input
            type="text"
            pattern="[0-9]*"
            value={dayOnMonth}
            onChange={(e) => this.handleDayOfMonthChange(e)}
          />
          <span>{I18n.t('day_of_month', { scope })}</span>
          {errorContent}
        </div>
      );
    }

    return null;
  }

  renderTimeZone() {
    const { alertTimeZone, onAlertTimeZoneChange } = this.props;
    const dropDownOption = {
      options: getTimeZones(),
      onSelection: (option) => onAlertTimeZoneChange(option.value),
      showOptionsBelowHandle: true,
      size: 'small',
      value: alertTimeZone
    };

    return (
      <div className="drop-down-container time-zone-drop-down">
        <label>{I18n.t('time_zone', { scope })}:</label>
        <Dropdown className="zone-drop-down" {...dropDownOption} />
      </div>
    );
  }

  renderTimeInput() {
    const { hour, minute } = this.state;
    let errorContent;

    if (Number(hour) >= 24 || Number(minute) >= 60) {
      errorContent = (
        <div className="error-info time-error-info">
          {I18n.t('time_error_info', { scope })}
        </div>
      );
    }

    return (
      <div className="time-field-container">
        <label>{I18n.t('time', { scope })}</label>
        <div className="time-split">
          <div>
            <input
              className="hour-field timeinput"
              type="text"
              pattern="[0-9]*"
              value={hour}
              onChange={(e) => this.handleHourChange(e)}
            />
          </div>
          <div className="separate">:</div>
          <div>
            <input
              className="minute-field timeinput"
              type="text"
              pattern="[0-9]*"
              value={minute}
              onChange={(e) => this.handleMinutesChange(e)}
            />
          </div>
        </div>
        {errorContent}
      </div>
    );
  }

  renderFrequencyOptions() {
    const { periodicFrequency, onAlertFrequencyChange } = this.props;
    const dropDownOption = {
      options: [
        {
          title: I18n.t('daily_or_week', { scope }),
          value: FREQUENCY_OPTIONS.DAILY
        },
        {
          title: I18n.t('monthly', { scope }),
          value: FREQUENCY_OPTIONS.MONTHLY
        }
      ],
      onSelection: (option) => onAlertFrequencyChange(option.value),
      showOptionsBelowHandle: true,
      size: 'small',
      value: periodicFrequency
    };

    return (
      <div className="drop-down-container trigger-frequency-options">
        <FieldTitle
          flyoutId="time-based-alert-trigger-flyout-info"
          flyoutText={I18n.t('trigger_flyout_text', { scope })}
          title={I18n.t('trigger_title', { scope })}
        />

        <Dropdown className="dropdown-size-large" {...dropDownOption} />
      </div>
    );
  }

  render() {
    return (
      <div className="time-based-options time-based-trigger-options">
        <div className="time-option">
          {this.renderFrequencyOptions()}
          {this.renderTimeInput()}
          {this.renderTimeZone()}
        </div>
        <form>{this.renderWeekDays()}</form>
        {this.renderDayInMonthField()}
      </div>
    );
  }
}

TimeBasedTriggerOptions.propTypes = {
  alertSentDays: PropTypes.array,
  alertSentTime: PropTypes.string,
  alertTimeZone: PropTypes.string,
  onAlertFrequencyChange: PropTypes.func,
  onAlertSentDaysChange: PropTypes.func,
  onAlertSentTimeChange: PropTypes.func,
  onAlertTimeZoneChange: PropTypes.func,
  periodicFrequency: PropTypes.string
};

export default TimeBasedTriggerOptions;
