import { fetchJsonWithDefaultHeaders, fetchWithDefaultHeaders } from 'common/http';

const BASE_URL = '/api/configurations';

export const fetchDomainConfigs = (targetCname, params = {}) => {
  const queryStr = new URLSearchParams({ ...params, domain: targetCname }).toString();
  return fetchJsonWithDefaultHeaders([BASE_URL, queryStr].join('?'));
};

export const fetchConfigsByType = (targetCname, type, defaultOnly = true) =>
  fetchDomainConfigs(targetCname, { type, defaultOnly });

export const fetchFeatureSetConfig = async (targetCname) => {
  const featureSetConfigs = await fetchConfigsByType(targetCname, 'feature_set');
  if (featureSetConfigs.length > 1) {
    throw new Error('fetchFeatureSetConfig: found more than one matching configuration');
  }
  return featureSetConfigs[0];
};

export const fetchSiteThemeConfig = async (targetCname) => {
  const siteThemeConfigs = await fetchConfigsByType(targetCname, 'site_theme');
  if (siteThemeConfigs.length > 1) {
    throw new Error('fetchSiteThemeConfig: found more than one matching configuration');
  }
  return siteThemeConfigs[0];
};

export const fetchInheritMetadataConfig = async () => {
  const metadataConfigs = await fetchJsonWithDefaultHeaders('/api/configurations?type=metadata_inheritance');
  if (metadataConfigs.length > 1) {
    throw new Error('fetchInheritMetadataConfig: found more than one matching configuration');
  }
  return metadataConfigs[0] || null;
};

export const handleSiteThemeConfig = (siteThemeConfig) => {
  const themes = (siteThemeConfig.properties || [])
    .map((property) => ({ name: property.name, value: property.value }));
  return themes;
};

export const addConfigProperty = (configId, propertyName, enabled = true) => {
  const apiPath = `${BASE_URL}/${configId}/properties`;
  const body = {
    name: propertyName,
    value: enabled
  };

  const options = {
    body: JSON.stringify(body),
    method: 'POST'
  };

  return fetchJsonWithDefaultHeaders(apiPath, options);
};

export const updateConfigProperty = (configId, propertyName, enabled) => {
  const apiPath = `${BASE_URL}/${configId}/properties/${propertyName}`;
  const body = {
    name: propertyName,
    value: enabled
  };

  const options = {
    body: JSON.stringify(body),
    method: 'PUT'
  };

  return fetchJsonWithDefaultHeaders(apiPath, options);
};

export const deleteConfigProperty = (configId, propertyName) => {
  const apiPath = `${BASE_URL}/${configId}/properties/${propertyName}`;
  const options = {
    method: 'DELETE'
  };

  return fetchWithDefaultHeaders(apiPath, options); // no response
};

// note that this api does not create the properties for the config, you need to call updateConfigProperty after calling this for a new config
export const createConfig = (config) => {
  const apiPath = `${BASE_URL}`;

  const options = {
    body: JSON.stringify(config),
    method: 'POST'
  };
  return fetchJsonWithDefaultHeaders(apiPath, options);
};
