// Vendor Imports
import _ from 'lodash';
import React, { Component } from 'react';

// Project Imports
import FilterFooter from '../FilterFooter';
import FilterHeader from '../FilterHeader';
import { getComputedColumnFilter, getDefaultFilterForColumn } from '../filters';
import Autocomplete from 'common/components/Autocomplete';
import Dropdown from 'common/components/Dropdown';
import Picklist, { PicklistOption, PicklistSizes } from 'common/components/Picklist';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import I18n from 'common/i18n';
import SoqlDataProvider from 'common/visualizations/dataProviders/SoqlDataProvider';
import { addPopupListener } from './InputFocus';

// Constants
import {
  DEFAULT_FETCH_RESULTS_DEBOUNCE_WAIT_TIME,
  FETCH_RESULTS_COUNT,
  SHOW_RESULTS_COUNT,
  DEFAULT_ALL_FILTERS,
  FILTER_OFFSET
} from './TextFilter';
import { FilterEditorProps } from '../types';
import { OPERATOR, SoqlFilter } from '../SoqlFilter';

const scope = 'shared.components.filter_bar.text_filter';

interface CuratedRegion {
  uid: string;
  geometryLabel: string;
}

interface ComputedColumnFilterState {
  selectedValues: PicklistOption[];
  isLoadingData: boolean;
  isNegated: boolean;
  operator: OPERATOR;
  topXOptions: PicklistOption[];
  filter?: SoqlFilter;
}

/**
 * This is very similar to `TextFilter`, but does some extra stuff to properly resolve
 * the label of computed columns. Ultimately, it would be cool to extract the
 * data-fetching logic from these components and pass the results into a shared UI component.
 */
class ComputedColumnFilter extends Component<FilterEditorProps, ComputedColumnFilterState> {
  curatedRegions: CuratedRegion[];
  autocompleteContainer: HTMLDivElement;
  computedColumnFilter: HTMLDivElement;
  removePopupListener = () => {};
  popupListenerRemoved = false;

  constructor(props: FilterEditorProps) {
    super(props);

    const { filter } = props;

    let isNegated = _.toLower(_.get(filter, 'joinOn')) === 'and';
    let operator = _.get(filter, 'arguments[0].operator', '');
    let selectedValues = _.chain(filter.arguments)
      .map((argument) =>
        _.includes(['IS NULL', 'IS NOT NULL'], _.get(argument, 'operator'))
          ? undefined
          : {
              title: _.get(argument, 'operandLabel'),
              value: _.get(argument, 'operand')
            }
      )
      .compact()
      .value();

    if (_.get(filter, 'singleSelect', false)) {
      if (selectedValues.length > 1) {
        selectedValues = [];
      }

      isNegated = false;
      operator = OPERATOR.EQUALS;
    }

    this.state = {
      isLoadingData: true,
      isNegated,
      operator,
      selectedValues,
      topXOptions: []
    };

    this.curatedRegions = [];

    _.bindAll(this, [
      'applyFilter',
      'getSuggestions',
      'getTopXOptions',
      'isDirty',
      'onSelectOption',
      'onUnselectOption',
      'renderHeader',
      'renderLoadingSpinner',
      'renderSelectedOption',
      'renderSuggestionsAutocomplete',
      'renderTopXOption',
      'resetFilter',
      'updateSelectedValues'
    ]);
  }

  getDataProvider(props: FilterEditorProps) {
    return props.dataProvider[0];
  }

  async componentDidMount() {
    const { popupRef } = this.props;
    const dataProvider = this.getDataProvider(this.props);

    this.removePopupListener = addPopupListener(popupRef, this.computedColumnFilter, () => {
      this.popupListenerRemoved = true;
    });

    try {
      this.curatedRegions = await dataProvider.metadataProvider.getCuratedRegions();
    } catch (error) {
      console.log('Error fetching curatedRegions in getCuratedRegions.');
      console.error(error);
    }
    await this.getTopXOptions();
  }

  componentWillUnmount() {
    const { popupRef } = this.props;
    if (!this.popupListenerRemoved) {
      popupRef?.current?.removeEventListener('forge-popup-position', this.removePopupListener);
    }
  }
  /**
   * Get the soql data provider for the _spatial lens_, which is a different dataset
   * than the one we're currently filtering.
   * @returns SoqlDataProvider
   */
  getSpatialLensDataProvider() {
    const { column } = this.props;
    const dataProvider = this.getDataProvider(this.props);
    return new SoqlDataProvider({
      datasetUid: _.get(column, 'uid') ?? dataProvider.datasetUid,
      domain: dataProvider.domain
    });
  }

  async getTopXOptionsRespectingFilters(nullOption: PicklistOption) {
    const { allFilters, column, filter } = this.props;
    const dataProvider = this.getDataProvider(this.props);

    // First get the top X values
    let topColumnValues;

    try {
      const topValuesOptions = {
        allFilters,
        column,
        filter,
        offset: FILTER_OFFSET.DEFAULT,
        limit: SHOW_RESULTS_COUNT
      };

      topColumnValues = await dataProvider.soqlDataProvider.getTopXOptionsInColumn(
        topValuesOptions,
        dataProvider.datasetUid
      );
    } catch (error) {
      this.setState({
        isLoadingData: false,
        topXOptions: []
      });
      console.log('Error fetching dataset in getTopXOptionsInColumn.');
      console.error(error);
      return;
    }

    if (
      topColumnValues.length == 0 ||
      (topColumnValues.length == 1 && topColumnValues[0].__count_alias__ === '0')
    ) {
      this.setState({
        isLoadingData: false,
        topXOptions: []
      });
      return;
    }

    // Now get the region names matching the top X
    const primaryKey = column.computationStrategy?.parameters?.primary_key;
    const regionValues = _.chain(topColumnValues)
      .map((value) => value[column.fieldName])
      .compact()
      .value();

    let regions: CuratedRegion[];

    try {
      regions = await this.getSpatialLensDataProvider().getSpatialLensRegions(primaryKey, regionValues);
    } catch (error) {
      this.setState({
        isLoadingData: false,
        topXOptions: []
      });
      console.log('Error fetching dataset in getSpatialLensRegions.');
      console.error(error);
      return;
    }

    // Now build the top X options
    const datasetUid = _.get(column, 'uid');
    const curatedRegion = _.find(this.curatedRegions, (item) => item.uid === datasetUid);
    const geometryLabel = curatedRegion?.geometryLabel;
    const noValueLabel = I18n.t('no_value', { scope });

    const getTitle = (columnValue: string) => {
      if (!geometryLabel || !primaryKey) {
        return noValueLabel;
      }
      const region = _.find(regions, (item) => item[primaryKey] === columnValue);
      return _.isNil(region) || _.isNil(region[geometryLabel]) ? noValueLabel : region[geometryLabel];
    };

    const topXOptions = _.map(topColumnValues, (topColumnValue) => {
      const columnValue = _.get(topColumnValue, column.fieldName);
      return _.isNil(columnValue)
        ? nullOption
        : {
            group: I18n.t('suggested_values', { scope }),
            render: this.renderTopXOption,
            title: getTitle(columnValue),
            value: columnValue
          };
    });

    this.setState({
      isLoadingData: false,
      topXOptions
    });
  }

  async getTopXOptions() {
    // Create the "null" suggestion to allow filtering on empty values.
    const nullOption = {
      title: I18n.t('no_value', { scope }),
      value: null,
      group: I18n.t('suggested_values', { scope }),
      render: this.renderTopXOption
    };

    // Default top X stored on the column is not available for computed columns.
    // We must query for it.
    await this.getTopXOptionsRespectingFilters(nullOption);
  }

  async getSuggestions(searchTerm: string) {
    const { column } = this.props;
    const { selectedValues } = this.state;

    if (_.isEmpty(searchTerm)) {
      return { results: [] };
    }

    // Get the search column name which is the shape label (geometry label) and
    // the associated data column which is the primary key value.
    const datasetUid = _.get(column, 'uid');
    const curatedRegion = _.find(this.curatedRegions, (item) => item.uid === datasetUid) || {};
    const searchColumnName = _.get(curatedRegion, 'geometryLabel');
    const associatedDataColumnName = _.get(column, 'computationStrategy.parameters.primary_key');

    if (_.isEmpty(searchColumnName) || _.isEmpty(associatedDataColumnName)) {
      return { results: [] };
    }

    // Now do the search in the shapefile's dataset for regions matching the search term.
    const searchOptions = {
      associatedDataColumnName,
      filters: DEFAULT_ALL_FILTERS,
      limit: FETCH_RESULTS_COUNT,
      searchColumnName,
      searchTerm
    };

    const selectedValuesValues = _.map(selectedValues, (selectedValue) => selectedValue.value);
    let suggestions;

    try {
      suggestions = await this.getSpatialLensDataProvider().searchInSpatialLensDataset(searchOptions);
    } catch (error) {
      console.log('Error searching spatial lens dataset in searchInSpatialLensDataset.');
      return { results: [] };
    }

    // Process the results from the query.
    const results = _.chain(suggestions)
      .reject((suggestion) => _.includes(selectedValuesValues, suggestion.__associated_data))
      .map((suggestion) => ({
        matches: [],
        title: suggestion.__suggestion,
        value: suggestion.__associated_data
      }))
      .compact()
      .take(SHOW_RESULTS_COUNT)
      .value();

    return { results };
  }

  onSelectOption(option?: PicklistOption | null) {
    if (!option) {
      return;
    }

    const selectedValue = _.pick(option, ['title', 'value']);

    if (_.get(this.props, 'filter.singleSelect', false)) {
      this.updateSelectedValues([selectedValue], this.applyFilter);
    } else {
      const unionedValues = _.unionBy(
        this.state.selectedValues,
        [selectedValue],
        (existingValue: PicklistOption) => existingValue.value
      );
      this.updateSelectedValues(unionedValues);
    }
  }

  onUnselectOption(option?: PicklistOption | null) {
    if (!option) {
      return;
    }

    const selectedValues = _.reject(
      this.state.selectedValues,
      (selectedValue) => selectedValue.value === option.value
    );

    this.updateSelectedValues(selectedValues);
  }

  getFilter() {
    const { column, dataProvider, filter } = this.props;
    const { isNegated, selectedValues } = this.state;

    return getComputedColumnFilter(column, filter, selectedValues, dataProvider[0].datasetUid, isNegated);
  }

  updateSelectedValues(nextSelectedValues: PicklistOption[], callback?: () => void) {
    this.setState(
      {
        selectedValues: nextSelectedValues
      },
      callback
    );
  }

  resetFilter() {
    const { column, onUpdate } = this.props;

    this.updateSelectedValues([]);

    const filter = _.cloneDeep(getDefaultFilterForColumn(column, this.props.dataProvider[0].datasetUid));
    filter.isHidden = _.get(this.props, 'filter.isHidden', false);
    filter.singleSelect = _.get(this.props, 'filter.singleSelect');

    this.setState({ filter });
    onUpdate(filter);
  }

  applyFilter() {
    this.props.onUpdate(this.getFilter());
  }

  isDirty() {
    return !_.isEqual(this.getFilter(), this.props.filter);
  }

  renderHeader() {
    const { column } = this.props;
    const headerProps = {
      name: column.name || ''
    };

    const placeholder =
      this.state.operator === OPERATOR.NOT_EQUAL ? I18n.t('is_not', { scope }) : I18n.t('is', { scope }); // default to equals

    const dropdownProps = {
      onSelection: (option: PicklistOption) => {
        this.setState({
          isNegated: option.value === OPERATOR.NOT_EQUAL,
          operator: option.value
        });
      },
      options: [
        { title: I18n.t('is', { scope }), value: OPERATOR.EQUALS },
        { title: I18n.t('is_not', { scope }), value: OPERATOR.NOT_EQUAL }
      ],
      placeholder,
      size: PicklistSizes.SMALL
    };

    const dropdown = !_.get(this.props, 'filter.singleSelect', false) ? (
      <Dropdown {...dropdownProps} />
    ) : null;

    return <FilterHeader {...headerProps}>{dropdown}</FilterHeader>;
  }

  renderSelectedOption(option: PicklistOption) {
    const title = _.isNull(option.value) ? <em>{option.title}</em> : option.title;

    return (
      <div className="picklist-selected-option">
        <SocrataIcon name={IconName.Filter} />
        <span className="picklist-selected-option-title">{title}</span>
        <SocrataIcon name={IconName.Close2} />
      </div>
    );
  }

  renderTopXOption(option: PicklistOption) {
    const title = _.isNull(option.value) ? <em>{option.title}</em> : option.title;
    return <div className="picklist-suggestion-option">{title}</div>;
  }

  renderSuggestionsAutocomplete() {
    const autocompleteProps = {
      focusFirstResult: true,
      query: '',
      getSearchResults: this.getSuggestions,
      millisecondsBeforeSearch: DEFAULT_FETCH_RESULTS_DEBOUNCE_WAIT_TIME,
      onChooseResult: (title: string, result: PicklistOption) =>
        this.onSelectOption({
          title: result.title,
          value: result.value
        }),
      placeholder: I18n.t('search_placeholder', { scope }),
      onSelectSetSelectionAsQuery: false,
      showLoadingSpinner: true
    };
    return (
      <div
        className="suggestions-autocomplete-container"
        ref={(el: HTMLDivElement) => (this.autocompleteContainer = el)}
      >
        <Autocomplete {...autocompleteProps} />
      </div>
    );
  }

  renderLoadingSpinner() {
    return (
      <div className="loading-spinner-container">
        <span className="spinner-default" />
      </div>
    );
  }

  renderPicklist() {
    const { filter } = this.props;
    const { selectedValues, isLoadingData, topXOptions } = this.state;

    const options = _.filter(topXOptions, (option) => {
      const value = _.find(selectedValues, (selectedValue) => selectedValue.value === option.value);
      return _.isNil(value);
    });

    const topXPicklistProps = {
      options,
      onSelection: this.onSelectOption,
      size: PicklistSizes.SMALL,
      value: false // To prevent highlighting of any item no-value option
    };

    const selectedOptions = _.map(selectedValues, (selectedValue) => ({
      group: I18n.t('selected_values', { scope }),
      title: _.isNull(selectedValue.title) ? I18n.t('no_value', { scope }) : selectedValue.title,
      value: selectedValue.value,
      render: this.renderSelectedOption
    }));

    const selectionPicklistProps = {
      options: selectedOptions,
      onSelection: this.onUnselectOption,
      size: PicklistSizes.SMALL,
      value: false // To prevent highlighting of any item no-value option
    };

    const selectedValuesSection = !filter.singleSelect ? (
      <div className="picklist-selected-options">
        <Picklist {...selectionPicklistProps} />
      </div>
    ) : null;

    return (
      <div className="picklist-options-container">
        {selectedValuesSection}
        <div className="picklist-suggested-options">
          {isLoadingData ? this.renderLoadingSpinner() : <Picklist {...topXPicklistProps} />}
        </div>
      </div>
    );
  }

  render() {
    const { filter, isReadOnly, onRemove } = this.props;

    const footerProps = {
      disableApplyFilter: !this.isDirty(),
      isReadOnly,
      onClickApply: () => this.applyFilter(),
      onClickRemove: onRemove,
      onClickReset: this.resetFilter,
      showApplyButton: !filter.singleSelect
    };

    const suggestionsAutocomplete = this.renderSuggestionsAutocomplete();
    const picklist = this.renderPicklist();

    return (
      <div
        className="filter-controls computed-column-filter text-filter"
        ref={(el: HTMLDivElement) => (this.computedColumnFilter = el)}
      >
        <div className="column-container">
          {this.renderHeader()}
          {suggestionsAutocomplete}
          {picklist}
        </div>
        <FilterFooter {...footerProps} />
      </div>
    );
  }
}

export default ComputedColumnFilter;
