import { isObject } from 'lodash';
import type { FunctionComponent } from 'react';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
// @ts-expect-error
import EditCell from './EditCell';
// @ts-expect-error
import TypedCell from './TypedCell';


interface Props {
  isDropping: boolean;
  isError: boolean;
  isEditing: boolean;
  updateCell: () => void;
  value: { json: Record<string, unknown> } | null
}

const JsonCell: FunctionComponent<Props> = ({ isEditing, value, updateCell, isDropping }) => {
  const formatted = isObject(value) ? JSON.stringify(value.json, null, 2) : '';
  if (isEditing) {
    return <EditCell value={formatted} updateCell={updateCell} />;
  } else {
    return <TypedCell isDropping={isDropping} value={formatted} />;
  }
};

export default JsonCell;
