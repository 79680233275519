// Vendor Imports
import _ from 'lodash';
import React, { Component } from 'react';

// Project Imports
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import FilterFooter from '../FilterFooter';
import FilterHeader from '../FilterHeader';
import { getCheckboxFilter, getDefaultFilterForColumn } from '../filters';
// @ts-expect-error
import SearchablePicklist from '../SearchablePicklist';
import I18n from 'common/i18n';
import { addPopupListener } from './InputFocus';
import { FilterEditorProps } from '../types';
import { FILTER_FUNCTION, FilterValue } from '../SoqlFilter';
import { PicklistOption, PicklistSizes } from 'common/components/Picklist';
import { BinaryOperator } from '../SoqlFilter';

interface CheckboxFilterState {
  value: string;
  selectedValues: FilterValue[];
}

export class CheckboxFilter extends Component<FilterEditorProps, CheckboxFilterState> {
  checkboxFilter: HTMLDivElement;
  removePopupListener = () => {};
  popupListenerRemoved = false;

  constructor(props: FilterEditorProps) {
    super(props);

    _.bindAll(this, [
      'applyFilter',
      'booleanToLocalizedString',
      'booleanToString',
      'isDirty',
      'onSelectOption',
      'onUnselectOption',
      'renderHeader',
      'renderSelectedOption',
      'renderSuggestedOption',
      'resetFilter',
      'stringToBoolean',
      'updateSelectedValues'
    ]);

    // get the initial state from props
    const { filter } = props;

    let selectedValues: FilterValue[] = [];

    if (filter.function === FILTER_FUNCTION.BINARY_OPERATOR) {
      selectedValues = _.map(filter.arguments, (argument) => {
        if (_.includes(['IS NULL', 'IS NOT NULL'], argument.operator)) {
          return null;
        }

        return argument.operand;
      });
    }

    this.state = {
      selectedValues,
      value: ''
    };
  }

  componentDidMount() {
    const { popupRef } = this.props;
    this.removePopupListener = addPopupListener(popupRef, this.checkboxFilter, () => {
      this.popupListenerRemoved = true;
    });
  }

  componentWillUnmount() {
    const { popupRef } = this.props;
    if (!this.popupListenerRemoved) {
      popupRef?.current?.removeEventListener('forge-popup-position', this.removePopupListener);
    }
  }

  onSelectOption(option: PicklistOption) {
    const value = this.stringToBoolean(option.value);
    this.updateSelectedValues(_.union(this.state.selectedValues, [value]));
  }

  onUnselectOption(option: PicklistOption) {
    const value = this.stringToBoolean(option.value);
    this.updateSelectedValues(_.without(this.state.selectedValues, value));
  }

  booleanToLocalizedString(value: FilterValue) {
    if (value === true) {
      return I18n.t('shared.components.filter_bar.checkbox_filter.true_value');
    } else if (value === false) {
      return I18n.t('shared.components.filter_bar.checkbox_filter.false_value');
    } else if (this.props.showNullsAsFalse) {
      return I18n.t('shared.components.filter_bar.checkbox_filter.false_value');
    } else {
      return I18n.t('shared.components.filter_bar.checkbox_filter.no_value');
    }
  }

  booleanToString(value: FilterValue) {
    if (value === true) {
      return 'true';
    } else if (value === false) {
      return 'false';
    } else {
      return null;
    }
  }

  stringToBoolean(value: string) {
    if (value === 'true') {
      return true;
    } else if (value === 'false') {
      return false;
    } else {
      return null;
    }
  }

  resetFilter() {
    const { column, onUpdate } = this.props;

    this.updateSelectedValues([]);

    if (this.state.value !== '') {
      this.setState({ value: '' });
    }

    const filter = _.cloneDeep(getDefaultFilterForColumn(column, this.props.dataProvider[0].datasetUid));
    filter.isHidden = _.get(this.props, 'filter.isHidden', false);
    filter.isDrilldown = _.get(this.props, 'filter.isDrilldown', false);
    onUpdate(filter);
  }

  applyFilter() {
    const { column, dataProvider, filter, onUpdate } = this.props;
    const { selectedValues } = this.state;

    onUpdate(getCheckboxFilter(column, filter as BinaryOperator, selectedValues, dataProvider[0].datasetUid));
  }

  updateSelectedValues(nextSelectedValues: FilterValue[]) {
    this.setState({
      selectedValues: _.uniq(nextSelectedValues)
    });
  }

  isDirty() {
    const { column, dataProvider, filter } = this.props;
    const { selectedValues } = this.state;

    return !_.isEqual(
      getCheckboxFilter(column, filter as BinaryOperator, selectedValues, dataProvider[0].datasetUid),
      filter
    );
  }

  renderHeader() {
    const { column } = this.props;
    const headerProps = {
      name: column.name || column.fieldName
    };

    return <FilterHeader {...headerProps} />;
  }

  renderSelectedOption(option: PicklistOption) {
    const title =
      _.isNull(option.value) && !this.props.showNullsAsFalse ? <em>{option.title}</em> : option.title;

    return (
      <div className="searchable-picklist-selected-option">
        <SocrataIcon name={IconName.Filter} />
        <span className="searchable-picklist-selected-option-title">{title}</span>
        <SocrataIcon name={IconName.Close2} />
      </div>
    );
  }

  renderSuggestedOption(option: PicklistOption) {
    const title =
      _.isNull(option.value) && !this.props.showNullsAsFalse ? <em>{option.title}</em> : option.title;

    return <div className="searchable-picklist-option">{title}</div>;
  }

  render() {
    const { filter, isReadOnly, onRemove, showNullsAsFalse } = this.props;
    const { selectedValues, value } = this.state;

    const possibleOptions: PicklistOption[] = showNullsAsFalse
      ? [
          {
            // null as false option
            title: I18n.t('shared.components.filter_bar.checkbox_filter.false_value'),
            value: null,
            group: I18n.t('shared.components.filter_bar.checkbox_filter.suggested_values'),
            render: this.renderSuggestedOption
          },
          {
            // true option
            title: I18n.t('shared.components.filter_bar.checkbox_filter.true_value'),
            value: 'true',
            group: I18n.t('shared.components.filter_bar.checkbox_filter.suggested_values'),
            render: this.renderSuggestedOption
          }
        ]
      : [
          {
            // null option
            title: I18n.t('shared.components.filter_bar.checkbox_filter.no_value'),
            value: null,
            group: I18n.t('shared.components.filter_bar.checkbox_filter.suggested_values'),
            render: this.renderSuggestedOption
          },
          {
            // true option
            title: I18n.t('shared.components.filter_bar.checkbox_filter.true_value'),
            value: 'true',
            group: I18n.t('shared.components.filter_bar.checkbox_filter.suggested_values'),
            render: this.renderSuggestedOption
          },
          {
            // false option
            title: I18n.t('shared.components.filter_bar.checkbox_filter.false_value'),
            value: 'false',
            group: I18n.t('shared.components.filter_bar.checkbox_filter.suggested_values'),
            render: this.renderSuggestedOption
          }
        ];

    const options = _.filter(possibleOptions, (option) => {
      return !_.includes(selectedValues, this.stringToBoolean(option.value));
    });

    const selectedOptions = _.map(selectedValues, (selectedValue) => {
      return {
        title: this.booleanToLocalizedString(selectedValue),
        value: this.booleanToString(selectedValue),
        render: this.renderSelectedOption
      };
    });

    const picklistAttributes = {
      hideSearchInput: true,
      onBlur: _.noop,
      onChangeSearchTerm: _.noop,
      onClickSelectedOption: this.onUnselectOption,
      onSelection: this.onSelectOption,
      options,
      selectedOptions,
      size: PicklistSizes.SMALL,
      value
    };

    const footerAttributes = {
      disableApplyFilter: !this.isDirty(),
      isDrilldown: filter.isDrilldown,
      isReadOnly,
      onClickApply: this.applyFilter,
      onClickRemove: onRemove,
      onClickReset: this.resetFilter
    };

    return (
      <div
        className="filter-controls checkbox-filter text-filter"
        ref={(el: HTMLDivElement) => (this.checkboxFilter = el)}
      >
        <div className="column-container">
          {this.renderHeader()}
          <SearchablePicklist {...picklistAttributes} />
        </div>
        <FilterFooter {...footerAttributes} />
      </div>
    );
  }
}

export default CheckboxFilter;
