// Vendor Imports
import get from 'lodash/get';
import React from 'react';

// Project Imports
import CalendarDateParameter from './CalendarDateParameter';
import Parameter from './Parameter';
import type { DateClientContextVariable, ClientContextVariable } from 'common/types/clientContextVariable';
import { SoQLType } from 'common/types/soql';

interface ValueEditorProps {
  parameter: ClientContextVariable;
  onUpdate: (value: string) => void;
}

const ValueEditor = (props: ValueEditorProps): React.ReactElement | null => {
  const { parameter, onUpdate } = props;

  if (!parameter) { return null; }
  switch (get(parameter, 'dataType')) {
    case SoQLType.SoQLFloatingTimestampT:
    case SoQLType.SoQLFixedTimestampT:
      // TODO: find alternative type protected solution rather than casting to DateParameter
      const calendarDateParameter = parameter as DateClientContextVariable;
      return (<CalendarDateParameter parameter={calendarDateParameter} onUpdate={onUpdate} />);
    case SoQLType.SoQLNumberT:
    case SoQLType.SoQLTextT:
      return (<Parameter parameter={parameter} onUpdate={onUpdate}/>);
    default:
      return null;
  }
};

export default ValueEditor;
