import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { noop, stubObject } from 'lodash';

import { Flannel, FlannelHeader, FlannelContent, FlannelFooter } from 'common/components/Flannel';
import { PLACEMENTS } from 'common/components/Flannel/Constants';

import { hideDocumentation } from '../redux/QueryEditorActions';

class Docs extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    target: PropTypes.oneOfType([
      PropTypes.instanceOf(HTMLElement),
      PropTypes.element
    ]),
    name: PropTypes.string.isRequired,
    onCloseFlannel: PropTypes.func.isRequired,
    renderFooter: PropTypes.func
  }

  static defaultProps = {
    renderFooter: noop
  }

  render() {
    const { title, name, onCloseFlannel } = this.props;
    const listItem = document.getElementById(name);

    if (!listItem) {
      console.warn(`Could not find QueryEditor list item with id: ${name}`);
      return;
    }

    const infoFlannelProps = {
      id: 'query-editor-action-list-item-flannel',
      target: listItem,
      placement: PLACEMENTS.LEFT,
      title,
      showArrow: true
    };

    return (
      <Flannel {...infoFlannelProps}>
        <FlannelHeader title={name} onDismiss={onCloseFlannel} />
        <FlannelContent>
          {this.props.children}
        </FlannelContent>
        <FlannelFooter>
          {this.props.renderFooter()}
        </FlannelFooter>
      </Flannel>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onCloseFlannel: () => dispatch(hideDocumentation())
});

export default connect(stubObject, mapDispatchToProps)(Docs);
