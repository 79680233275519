import isEmail from 'validator/lib/isEmail.js';
import { put } from 'redux-saga/effects';
import fp from 'lodash/fp';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { canChangeVisibility } from 'common/core';
import FeatureFlags from 'common/feature_flags';
import I18n from 'common/i18n';
import { buildQueryString, fetchJsonWithParsedError } from 'common/http';
import { mapPermissionScopeToTargetAudience, withGuidanceV2 } from 'common/core/approvals/index_new';
import { ensureCsrfToken } from 'common/http';
import { isForm, isMeasure, isVisualizationCanvas } from 'common/views/view_types';
import * as uiActions from 'common/components/AccessManager/actions/UiActions';
import { showToastNow, ToastType } from 'common/components/ToastNotification/Toastmaster';
import DomainRights from 'common/types/domainRights';
import { currentUserHasRight, currentUserIsSiteMember } from 'common/current_user';
import { AudienceScope, AccessLevelName, AccessLevelVersion } from 'common/types/view';

import { getCurrentUser, currentUserIsLoggedIn } from 'common/current_user';

import type InteractiveUser from 'common/types/users/interactiveUser';
import type { FederatedSite, GuidanceSummaryV2 } from 'common/types/approvals';
import type {
  CatalogUserOrTeam,
  CatalogInteractiveUser,
  UsersCatalogSearchResult,
  UsersCatalogSearchResults
} from 'common/types/users/catalogUsers';
import type { ViewUser, AccessLevel, View, ViewPermissions } from 'common/types/view';
import { FEATURE_FLAGS, MODES } from './Constants';
import { GuidanceSummaryV2Helper } from 'common/core/approvals/Types';
import { WorkflowTargetAudience } from 'common/core/approvals_enums';

export const strictPermissionsEnabled = () => FeatureFlags.value(FEATURE_FLAGS.STRICT_PERMISSIONS);
export const internalSharingEnabled = strictPermissionsEnabled;
export const usaidFeaturesEnabled = () => FeatureFlags.value(FEATURE_FLAGS.USAID_FEATURES_ENABLED);

/**
 * Returns true is the "userList" already contains a user that has the
 * given email.
 * @param {array} userList List of users with emails
 * @param {string} email Email to check for
 */
const userListContainsEmail = (userList: CatalogUserOrTeam[], email?: string) =>
  // note that only "interactive users" actually have emails; this list includes teams which will have an undefined email
  userList && userList.some((user) => (user as CatalogInteractiveUser).email === email);

/**
 * Returns true if the given email has already been used in some fashion for this view
 * @param {array} selectedUsers List of users selected from multiselect
 * @param {array} addedUsers List of users who currently have access to the view
 * @param {string} email Email to check for
 */
const userWithEmailAbsent = (
  selectedUsers: CatalogUserOrTeam[],
  addedUsers: CatalogUserOrTeam[],
  email?: string
) => !userListContainsEmail(selectedUsers, email) && !userListContainsEmail(addedUsers, email);

/**
 * Returns true if the given email exists in the list of users given
 * @param {string} email Email to search for
 * @param {array} results List of users to check for email in
 */
const emailExistsInResults = (email: string, results: UsersCatalogSearchResults[]) =>
  results.some((result) => result.user?.email === email);

/**
 * Filter a list of search results to not display users that have already been selected in some way
 * @param {array} searchResults List of search results
 * @param {array} selectedUsers Users that have already been selected in the combo box
 * @param {array} addedUsers Users that have already been added with permissions
 * @param query
 */
export const filterSearchResults = (
  searchResults: UsersCatalogSearchResult,
  selectedUsers: CatalogUserOrTeam[],
  addedUsers: CatalogUserOrTeam[],
  query: string,
  mode?: MODES
) => {
  const filteredResults = searchResults.results.filter((result) => {
    if (result.team) {
      // Result is a team: filter it if the team has already been added/selected
      return (
        !addedUsers.some((addedUser) => addedUser.id === result.team?.id) &&
        !selectedUsers.some((selectedUser) => selectedUser.id === result.team?.id)
      );
    } else {
      // Result is not a team: filter it if the email has already been added.
      return userWithEmailAbsent(selectedUsers, addedUsers, result.user?.email);
    }
  });

  // if the search query is a valid email address, we add it to the end
  // to allow users to add community (unroled) users to the view
  if (
    mode !== MODES.MANAGE_PLUGIN &&
    !strictPermissionsEnabled() &&
    isEmail(query) &&
    userWithEmailAbsent(selectedUsers, addedUsers, query) &&
    !emailExistsInResults(query, searchResults.results)
  ) {
    filteredResults.push({
      user: {
        email: query
        // NOTE: The types are weird here and _technically_ this is an InteraciveUser
        // but since we didn't get it back from the catalog, we have to fake some things
      } as unknown as CatalogInteractiveUser
    });
  }

  // Users without the manage_users right shouldn't be seeing emails in search results
  if (currentUserIsLoggedIn()) {
    if (currentUserIsSiteMember() || currentUserHasRight(DomainRights.manage_users)) {
      return {
        ...searchResults,
        results: [...filteredResults]
      };
    }
  }

  // If the user has no rights at all, then they are a community user and we should allow them to
  // add whomsoever they wish as a collaborator, so we avoid erasing the email for this specific case.
  if (!isEmpty(getCurrentUser()?.rights)) {
    filteredResults.forEach((result) => (result.user!.email = ''));
  }

  return {
    ...searchResults,
    results: [...filteredResults]
  };
};

/**
 * Filters out the current owner from the list of search results
 * @param {array} searchResults Results to filter
 * @param {object} currentOwner Current owner of asset
 */
export const filterOwnerSearchResults = (searchResults: UsersCatalogSearchResult, currentOwner: ViewUser) => {
  const enableTeamsFlag = FeatureFlags.value(FEATURE_FLAGS.ENABLE_TEAMS);

  let results = searchResults.results;

  // if the enable_teams flag is off, we don't include any teams here
  if (!enableTeamsFlag) {
    results = results.filter((result) => !result.team);
  }

  // if the current owner is deleted, they will still exist but they will not have an id
  if (currentOwner?.id) {
    results = results.filter(
      (result) => result.user?.id !== currentOwner.id && result.team?.id !== currentOwner.id
    );
  }

  return {
    ...searchResults,
    results
  };
};

const userHasGranularRight = (right: DomainRights) => (user?: InteractiveUser) =>
  strictPermissionsEnabled() ? fp.flow(fp.get('rights'), fp.includes(right))(user) : true;

/**
 * Check whether the given user is allowed to make an asset public
 * user -> boolean
 */
export const userCanMakeAssetInternal = userHasGranularRight(DomainRights.can_make_asset_internal);

/**
 * Check whether the given user is allowed to make an asset public
 * user -> boolean
 */
export const userCanMakeAssetPrivate = userHasGranularRight(DomainRights.can_make_asset_private);

/**
 * Check whether the given user is allowed to make an asset public
 * user -> boolean
 */
export const userCanMakeAssetPublic = userHasGranularRight(DomainRights.can_make_asset_public);

export const userCanChangeAudience = fp.overSome([
  userCanMakeAssetPrivate,
  userCanMakeAssetInternal,
  userCanMakeAssetPublic
]);

/**
 * Find a user in a given list with the given access level
 * @param {array} users List of users
 * @param {string} accessLevelName Access level to find
 */
export const findUserWithAccessLevel = (users: ViewUser[], accessLevelName: AccessLevelName) =>
  users.find((user) => userHasAccessLevel(user, accessLevelName));

/**
 * Find the index of a user in the given list with the given access level
 * @param {array} users List of users
 * @param {string} accessLevelName Access level to find
 */
export const findUserIndexWithAccessLevel = (users: ViewUser[], accessLevelName: AccessLevelName) =>
  users.findIndex((user) => userHasAccessLevel(user, accessLevelName));

/**
 * Returns whether the two given access levels are equal
 * @param {object} first First access level
 * @param {object} second Second access level
 */
export const accessLevelsEqual = (first: AccessLevel, second: AccessLevel) =>
  first && second && first.name === second.name && first.version === second.version;

/**
 * Determine if the given user has the given access level
 * @param {object} user User to check
 * @param {string} accessLevelName Access level to check
 * @param {string} accessLevelVersion (Optional) Version to check for
 */
export const userHasAccessLevel = (
  user: ViewUser,
  accessLevelName: AccessLevelName,
  accessLevelVersion?: AccessLevelVersion
) =>
  user.accessLevels.some(
    (level) =>
      level.name === accessLevelName &&
      (!accessLevelVersion || accessLevelVersion === level.version || level.version === 'all')
  );

// TODO don't use window.location.host here (or maybe it's fine...?)
/**
 * Get the current domain
 */
export const getDomain = () => window.location.host;

const userAutocompletePath = '/api/catalog/v1/users/autocomplete';

/**
 * Get the URL to hit to query the catalog for a list of registered users
 * and teams (future users will not be included).
 * @param {string} query User search query
 * @param {string} domain Only return users with accounts on this domain
 * @param disabled
 * @param rights
 */
export const userAndTeamAutocompleteUrl = (
  query: string,
  domain: string,
  { disabled = false, rights = [] as DomainRights[], includeTeams = true } = {}
) =>
  fp.flow(
    fp.compact,
    fp.join('?')
  )([
    userAutocompletePath,
    buildQueryString({
      q: query,
      include_teams: includeTeams,
      domain,
      only: 'site_members',
      disabled,
      future: false,
      rights
    })
  ]);

/**
 * Get the url to hit for the permissions of an asset
 * @param {string} assetUid UID (4x4) of asset to get/put permissions for
 */
export const permissionsUrl = (assetUid: string) => `/api/views/${assetUid}/permissions`;

/**
 * Get the url to hit to publish an asset
 * @param {object} View metadata of object to publish.
 * @param {bool} publishAsync Whether or not to do an async publication. Only makes sense for datasets.
 */
export const publishUrl = (view: View, publishAsync = false) => {
  if (!view.viewType) {
    throw new Error(`Expected object to have a viewType: ${view}`);
  }

  if (view.viewType === 'story') {
    return `/stories/api/v1/stories/${view.id}/published`;
  } else {
    return `/api/views/${view.id}/publication.json${publishAsync ? '?async=true' : ''}`;
  }
};

/**
 * Determines if, when saving, the asset should be published after changing
 * its permissions.
 * @param {string} mode Current mode of access manager
 */
export const shouldPublishOnSave = (mode: MODES) => mode === MODES.PUBLISH;

/**
 * Whather or not the "Confim" button in the footer should default to disabled
 * when the access manager opens up with the given mode
 * @param {string} mode Current mode of access manager
 */
export const confirmButtonDisabledByDefault = (mode: MODES) =>
  mode === MODES.CHANGE_OWNER ||
  // if changing audience or publishing,
  // a saga will check if the asset will go into approvals
  // and then set the status of the button afterwards (see uiReducer/sagas)
  mode === MODES.CHANGE_AUDIENCE ||
  mode === MODES.PUBLISH;

/**
 * Whether or not the "Confirm" button in the footer should default to busy
 * when the access manager comes up in the given mode
 * @param {string} mode  Current mode of access manager
 */
export const confirmButtonBusyByDefault = (mode: MODES) =>
  // if changing audience or publishing,
  // a saga will check if the asset will go into approvals
  // and then set the status of the button afterwards (see uiReducer/sagas)
  mode === MODES.CHANGE_AUDIENCE || mode === MODES.PUBLISH;

/**
 * If we have a grid view dataset (that is, window.blist.dataset) then this returns its "queryChanged"
 * This usually means we have a derived view that has been changed and has not been saved yet.
 *
 * Returns false if there is no grid view dataset.
 */
export const shouldUpdateViewOnPublish = () => {
  // this isn't actually a "real" feature flag, it's added in application_helper and represents various values on the view
  // AND the value of force_use_of_modifying_lens_id_in_all_derived_views
  const showDerivedViews2018Controls = get(window, 'socrata.show_derived_views_2018_controls', false);

  const dataset = get(window, 'blist.dataset');
  return showDerivedViews2018Controls && dataset && dataset.queryChanged();
};

/**
 * Intended to be yield-ed in a saga; will end in a put with an action
 * that will determine if the approval message should be shown
 * @param {object} approvalsGuidance object, see common/core/approvals.js#fetchApprovalsGuidance
 * @param {string} targetScope Scope view will have
 * @param {View} view The current view
 */
export function* checkWillEnterApprovalQueue(
  approvalsGuidance: GuidanceSummaryV2,
  targetScope: AudienceScope,
  view: Partial<View>
) {
  const currentScope: AudienceScope | undefined = view.permissions?.scope;

  // If the asset is not currently public and the scope is changing to public or internal (site),
  // then we need to check if it will enter the approval queue.
  if (
    currentScope !== AudienceScope.Public &&
    currentScope !== targetScope &&
    (targetScope === AudienceScope.Public || targetScope === AudienceScope.Site)
  ) {
    const targetAudience: WorkflowTargetAudience = yield mapPermissionScopeToTargetAudience(targetScope);
    const guidanceHelper: GuidanceSummaryV2Helper = yield withGuidanceV2(approvalsGuidance);
    const showApprovalMessage: boolean = yield guidanceHelper.willEnterApprovalQueue(targetAudience);
    const sitesFederatedTo: FederatedSite[] =
      // Only need the list of sites if trying to go public
      targetScope === AudienceScope.Public
        ? yield guidanceHelper.sitesThatWillBeFederatedToIfMadePublic()
        : [];

    yield put(
      uiActions.showApprovalMessageChanged(showApprovalMessage, sitesFederatedTo, currentScope, targetScope)
    );
  } else {
    yield put(uiActions.showApprovalMessageChanged(false, [], currentScope, targetScope));
  }
}

/**
 * Save the permissions for the given asset
 * @param {string} assetUid UID of asset to save permissions for
 * @param {object} permissions Permissions blob to save
 */
export const savePermissions = async (assetUid: string, permissions: ViewPermissions) =>
  await fetchJsonWithParsedError(permissionsUrl(assetUid), {
    method: 'PUT',
    body: JSON.stringify(permissions)
  });

/**
 * Calls the default publish URL for the given asset ui
 * @param {object} View metadata of object to publish.
 */
export const publishView = async (view: View, publishAsync: boolean) =>
  await fetchJsonWithParsedError(publishUrl(view, publishAsync), {
    method: 'POST'
  });

/** Object passed to the `onPermissionsSaved` function */
export interface PermissionsSavedPayload {
  /** UID (4x4) of the asset to save permissions for */
  assetUid: string;

  /** Permissions to save for the asset */
  permissions: ViewPermissions;

  /** Current access manager mode (passed in by access manager during callback) */
  mode: MODES;

  /** Whether the permission change will not happen b/c the view was submitted to the approvals queue */
  willEnterApprovalQueue?: boolean;
}

/**
 * Save permissions and call a calback afterwards.
 * Useful as a generic "onConfirm" for the access manager
 * @param {string} assetUid UID of asset (passed in by access manager during callback)
 * @param {object} permissions Permissions to update asset with (passed in by access manager during callback)
 * @param {string} mode Current access manager mode (passed in by access manager during callback)
 * @param {function} onPermissionsSaved (optional) Function to call after saving permissions
 * @param {string | boolean} showToast (optional, defaults to true) Indicates whether to show a toast message.
 *  IF string - Will show a toast with the supplied string as its message.
 *  IF false - Will not show a toast message.
 *  IF (undefined || true) - Will show a default toast message (like it did before).
 */
export const savePermissionsWithCallback = async (
  mode: MODES,
  assetUid: string,
  permissions: ViewPermissions,
  /* eslint @typescript-eslint/no-shadow: "warn" */
  onPermissionsSaved: ({ assetUid, permissions, mode }: PermissionsSavedPayload) => void,
  showToast: string | boolean = true
) => {
  await savePermissions(assetUid, permissions);
  if (showToast) {
    // If we were passed a string, use that, otherwise use the default text
    const toastText =
      typeof showToast === 'string'
        ? showToast
        : I18n.t(`shared.site_chrome.access_manager.${mode}.success_toast`);

    showToastNow({
      type: ToastType.SUCCESS,
      content: toastText
    });
  }

  if (onPermissionsSaved) {
    onPermissionsSaved({ assetUid, permissions, mode });
  }
};

/**
 * A default function to use for the access manager's onConfirm.
 * Will save permissions and then call the onPermissionsSaved callback.
 * @param {function} onPermissionsSaved (optional) Function to call after permissions are save
 * @param {string | boolean} showToast (optional) Indicates whether to show a toast message.
 *  IF string - Will show a toast with the supplied string as its message.
 *  IF false - Will not show a toast message.
 *  IF (undefined || true) - Will show a default toast message (like it did before).
 */
export const defaultAccessManagerOnConfirm =
  (
    onPermissionsSaved: ({ assetUid, permissions }: PermissionsSavedPayload) => void,
    showToast?: string | boolean
  ) =>
  (mode: MODES, assetUid: string, permissions: ViewPermissions) =>
    savePermissionsWithCallback(mode, assetUid, permissions, onPermissionsSaved, showToast);

/**
 * Function to use for the access manager's onConfirm within Storyteller.
 * Will assure that the correct csrf token is set within the cookie before saving
 * permissions. Replace with default once Storyteller and Frontend stop overwriting
 * each others csrf tokens in cookie - EN-38002
 * @param {function} onPermissionsSaved (optional) Function to call after permissions are save
 * @param {string | boolean} showToast (optional) Indicates whether to show a toast message.
 *  IF string - Will show a toast with the supplied string as its message.
 *  IF false - Will not show a toast message.
 *  IF (undefined || true) - Will show a default toast message (like it did before).
 */
export const storytellerAccessManagerOnConfirm =
  (
    onPermissionsSaved: ({ assetUid, permissions }: PermissionsSavedPayload) => void,
    showToast?: string | boolean
  ) =>
  (mode: MODES, assetUid: string, permissions: ViewPermissions) => {
    ensureCsrfToken();
    savePermissionsWithCallback(mode, assetUid, permissions, onPermissionsSaved, showToast);
  };

export const confirmButtonShown = (mode: MODES, view: View) => {
  if (mode === MODES.CHANGE_AUDIENCE) {
    return view && canChangeVisibility(view);
  } else {
    return true;
  }
};

// Returns the i18n key for an access level string
export const accessLevelKey = (name: AccessLevelName) =>
  strictPermissionsEnabled()
    ? `shared.site_chrome.access_manager.access_levels_strict_permissions.${name}`
    : `shared.site_chrome.access_manager.access_levels.${name}`;

/**
 * @param {object} view View to get parent scope for
 * @returns The current scope of the given view's parent
 */
export const getParentScope = (view: Partial<View>) => view?.permissions?.parent?.scope;

/**
 * @param {object} view View to get whether private audience is disabled or not
 * @returns Whether or not the given view is allowed to be made private
 */
export const isPrivateAudienceDisabled = (view: Partial<View>) => {
  // Forms and measures are never able to be changed to private when their parent is public. See
  // https://socrata.atlassian.net/wiki/spaces/DOCS/pages/759497541/Grant+Inheritance+Behavior for details.
  const isMeasureOrForm = isMeasure(view) || isForm(view);
  const viewCanNotBeMadePrivate =
    view.permissions?.scope &&
    view.permissions?.scope !== AudienceScope.Private &&
    isMeasureOrForm &&
    getParentScope(view) === AudienceScope.Public;

  // whether or not a user can actually make something private is actually a function of
  // their role (the `can_make_asset_private` domain right)
  // along with some other things...
  const canMakeAssetPrivate = userCanMakeAssetPrivate(getCurrentUser());

  // If we return a true value here then it means the user will NOT be able to make the asset "private" scope
  return (!canMakeAssetPrivate || viewCanNotBeMadePrivate) as boolean;
};

/**
 * @param {object} view View to check
 * @param {boolean} visualizationCanvasHasPublicDataSource Whether or not the view is a vizcan based on a pubic asset
 * @returns Whether or not the given view is allowed to be made public
 */
export const isPublicAudienceDisabled = (
  view: Partial<View>,
  visualizationCanvasHasPublicDataSource?: boolean
) => {
  // whether or not a user can actually make something private is actually a function of
  // their role (the `can_make_asset_public` domain right)
  const canMakeAssetPublic = userCanMakeAssetPublic(getCurrentUser());

  // vizcans and data lenses with a private data source can NOT be made public
  const isVizCanWithNonPublicDataSource = viewIsVizCanWithNonPublicDataSource(
    view,
    visualizationCanvasHasPublicDataSource
  );

  return !canMakeAssetPublic || isVizCanWithNonPublicDataSource;
};

/**
 * Viz cans that use public assets can NOT be made private.
 * NOTE: The word "parent" is explicitly being avoided here because vizcans are not "true" children of the assets they use.
 * They are a weird, special-cased type of lens that just uses another asset.
 * @param {object} view View to check
 * @param {boolean} visualizationCanvasHasPublicDataSource Whether or not the view is a vizcan based on a pubic asset
 * @returns Whether or not the view is a vizcan with a public data source
 */
export const viewIsVizCanWithNonPublicDataSource = (
  view: Partial<View>,
  visualizationCanvasHasPublicDataSource?: boolean
) => isVisualizationCanvas(view) && !visualizationCanvasHasPublicDataSource;

/**
 * @returns Whether or not the current user can make an asset internal (aka site)
 */
export const isInternalAudienceDisabled = () =>
  !strictPermissionsEnabled() || !userCanMakeAssetInternal(getCurrentUser());

/**
 * Given a view, determines which "scope" to use for the initial state of the access manager
 * @param {object} view View to get scope for
 * @param {boolean} visualizationCanvasHasPublicDataSource Whether or not this view is a vizcan with a public source
 * @returns The default permissions scope to use for this view when opening the access manager
 */
export const getDefaultScope = (view: View, visualizationCanvasHasPublicDataSource?: boolean) => {
  const privateAudienceDisabled = isPrivateAudienceDisabled(view);
  const internalAudienceDisabled = isInternalAudienceDisabled();
  const publicAudienceDisabled = isPublicAudienceDisabled(view, visualizationCanvasHasPublicDataSource);
  const currentScope = view.permissions?.scope;
  let response;

  if (currentScope) {
    // If the view has a scope, return that as our default
    response = currentScope;
  } else {
    // The view has no scope

    // If the user can publish to at least one audience level,
    //  return the most restrictive scope they can publish to as the default
    if (!privateAudienceDisabled) {
      response = AudienceScope.Private;
    } else if (!internalAudienceDisabled) {
      response = AudienceScope.Site;
    } else if (!publicAudienceDisabled) {
      response = AudienceScope.Public;
    } else {
      // If the user can't publish to any audience level, return the most restrictive scope by default
      response = AudienceScope.Private;
    }
  }

  return response;
};

/**
 * Compares if the given user is the user u. Due to our permission and teams, certain
 *  cases mean that you cannot see the email of another user. This allows us to check
 *  and user both options
 * @param firstUser usually self user object
 * @param secondUser another user
 * @returns true if users are the same user or false otherwise
 */
export const isSameUser = (firstUser: ViewUser, secondUser: ViewUser) => {
  if (firstUser.hasOwnProperty('email') && secondUser.hasOwnProperty('email')) {
    return firstUser.email === secondUser.email;
  }

  return firstUser.id === secondUser.id;
};
